// import Dashboard from '../views/Examples/Dashboard'
import store from "@/store/index";
import SummerSchool from "@/views/Summer/summerSchool/SummerSchool.vue";
import SummerCamp from "@/views/Summer/summerSchool/SummerCamp.vue";
// import SamerCamp from "@/views/samer/SummerCamp.vue";
import AcademicYear from "@/views/Summer/year/AcademicYear.vue";
import Countries from "../views/Summer/country/Countries.vue";
import Governorate from "../views/Summer/governorate/Governorate.vue";
import Directorate from "../views/Summer/directorate/Directorate.vue";
import Initiative from "@/views/Summer/initiative/Initialization.vue";
import initiativeassigin from "@/views/Summer/initiative/initiativeassigin.vue";



import ActivityOpreations from "@/views/Summer/activity/ActivityOpreations.vue";

import dicultsandslotions from "@/views/Summer/activity/dicultsandslotions.vue";
import ActivityStudentAssigin from "@/views/Summer/activity/ActivityStudentAssigin.vue";

import SummerSchedule from "@/views/Summer/initiative/Schedule.vue";
import commitsSummer from "@/views/Summer/teacherView/commitsSummer.vue";
import SubjectInitialization from "@/views/Summer/subject/SubjectInitialization.vue";
import SupervisorInitialization from "@/views/Summer/supervisor/SupervisorInitialization.vue";
import Division from "../views/Summer/division/Initialization.vue";
import Classperouid from "@/views/Summer/classes/Classperouid.vue";
// import skills from "../views/Summer/employee/employeeskills.vue";
import Level from "@/views/Summer/level/Initialization.vue";

// yousef


import EmployeeStatusManage from "../views/Summer/employee/‏‏EmployeeStatusManage.vue";
import employeeassigin from "../views/Summer/employee/employeeassigin.vue";
import Skills from "../views/Summer/employee/employeeskills.vue";
import membercommitassigin from "../views/Summer/employee/membercommitassigin.vue";
import ShowMemberCommit from "../views/Summer/employee/ShowMemberCommit.vue";

import Dropout from "@/views/Summer/dropout/Initialization.vue";
import AddStudentMovable from "@/views/Summer/student/AddStudentMovable.vue";
import Lectures from "@/views/Summer/Lectures/Initialization.vue";
import ShowLectures from "@/views/Summer/Lectures/ShowLectures.vue";
import LessonRecord from "@/views/Summer/Lectures/LessonRecord.vue";
import AddandEditAssignTeacher from "@/views/Summer/AssignTeacher/AddandEditAssignTeacher.vue";
import ShowTeachers from "@/views/Summer/AssignTeacher/ShowTeachers.vue";
import AssigningDivisionsToLevel from "@/views/Summer/level/AssigningDivisionsToLevel.vue";
import Visitor from "@/views/Summer/visitor/Visitor.vue";
import AddVisitor from "@/views/Summer/visitor/AddVisitor.vue";
import SubjectsStatics from "@/views/Summer/Statistic/SubjectsStatics.vue";

import SummerStatistic from "@/views/Summer/Statistic/SummerStatistic.vue";
import ManagamentTasks from "@/views/Summer/Statistic/ManagamentTasks.vue";
import AddManagamentTasks from "@/views/Summer/Statistic/AddManagamentTasks.vue";
import Supplies from "@/views/Summer/Statistic/Supplies.vue";
import AddSupplies from "@/views/Summer/Statistic/AddSupplies.vue";
import Recomendations from "@/views/Summer/recomendations/Recomendations.vue";

import TypeActivity from "@/views/Summer/activity/TypeActivity.vue";
import Showdicultsandslotions from "@/views/Summer/activity/Showdicultsandslotions.vue";
import WeeklyActivityAssign from "@/views/Summer/activity/WeeklyActivityAssign.vue";
import AddTalent from "@/views/Summer/talent/AddTalent.vue";

import Week from "@/views/Summer/calender/InitializationWeek.vue";
import summerdays from "@/views/Summer/calender/summerdays.vue";
import Vacation from "@/views/Summer/calender/InitializationVacation.vue";
import Calendar from "@/views/Summer/calender/InitializeCalendar.vue";
import Address from "@/views/Summer/address/Initialization.vue";
import StudentAttendance from "@/views/Summer/student/StudentAttendance.vue";
import StudentList from "@/views/Summer/student/StudentList.vue";
import StudentView from "@/views/Summer/student/StudentView.vue";
import Parent from "../views/Summer/parent/ParentView.vue";
import ParentList from "../views/Summer/parent/ParentList.vue";
import ReceiveCurriculum from "@/views/Summer/Curriculum/ReceiveCurriculum.vue";

import AddStudent from "@/views/Summer/student/AddStudent.vue";
import InitializationEmployee from "@/views/Summer/employee/InitializationEmployee.vue";
import EmployeeList from "@/views/Summer/employee/EmployeeList.vue";
import EmployeeAttendance from "@/views/Summer/employee/EmployeeAttendance.vue";
import Dashboard from "@/views/Examples/SummerDashboard";

import talentassigins from "../views/Summer/talent/talentassigin.vue";
// import store from "@/store/index";
import WeeklyMarks from "../views/Summer/marks/WeeklyMarks.vue";
import TimeTable from "@/views/Summer/WeeklySchedule/TimeTableAdd";
import TimeTableUpdate from "@/views/Summer/WeeklySchedule/TimeTableUpdate";
import BackUp from "../views/Summer/backup/BackUp";
// wae
import Activity from "@/views/Summer/activity/Activity.vue";
import ActivityList from "@/views/Summer/activity/ActivityList.vue";
// import Settings from "../views/Globals/Settings.vue";
// const hasPermission = (prem) => {
//   store.dispatch("updatePermission", localStorage.getItem("userinfo"));
//   return store.getters.checkpermission(prem);
// };

function CheckSummerCenterSystem(to, from, next) {
  const summer_center = localStorage.getItem("summer_center");
  if (summer_center === "true") {
    next();
  } else {
    next({
      path: "/",
      query: { redirect: from.fullPath },
    });
  }
}
// const requirePermission = (perm) => {
//   return (to, from, next) => {
//     if (
//       hasPermission(perm) &&
//       localStorage.getItem("summer_center") == "true"
//     ) {
//       next();
//     } else {
//       next({
//         path: "/",
//         query: { redirect: from.fullPath },
//       });
//     }
//   };
// };
const hasPermission = (prem,role) => {
  return store.getters.checkpermission(prem,role);
};
const requirePermission = (perm,role) => {
  return (to, from, next) => {
      if (
      hasPermission(perm,role) 
      ){
      next();
      } else {
      next({
          path: "/",
          query: { redirect: from.fullPath },
      });
      }
  };
};
const requireRole = (role) => {
  return (to, from, next) => {
      if (store.getters.checkrole(role)){
      next();
      } else {
      next({
          path: "/",
          query: { redirect: from.fullPath },
      });
      }
  };
};

export default [
  {
    path: "home",
    name: "summer_dashboard",
    alias:'/',
    component: Dashboard,
    // beforeEnter: requirePermission(""),
  },
  {
    path: "summer-school",
    name: "summer-school",
    component: SummerSchool,
    beforeEnter: requirePermission("summer_center.view_schoolsummer",['sys_admin','gen_admin','dir_admin','gov_admin','summer_admin','user']),
  },
  {
    path: "summer-school/:id/edit",
    name: "summer-school-edit",
    component: SummerSchool,
    props: true,
    beforeEnter: requirePermission("summer_center.view_schoolsummer"),
  },
  {
    path: "summer-camps",
    name: "summer-camps-list",
    component: SummerCamp,
    beforeEnter: requirePermission("summer_center.view_schoolsummer"),
  },
  
  {
    path: "dicultsandslotions/add",
    name: "dicultsandslotions_add",
    component: dicultsandslotions,
    beforeEnter: requirePermission("summer_center.view_activitydifficulty",['sys_admin','summer_admin','user']),
  },
  {
    path: "activity-student-assigin",
    name: "ActivityStudentAssigin",
    component: ActivityStudentAssigin,
    beforeEnter: requirePermission("summer_center.view_studentactivity",['sys_admin','summer_admin','user']),
  },
  {
    path: "dicultsandslotions/:id/edit",
    name: "dicultsandslotions_edit",
    component: dicultsandslotions,
    props: true,
    beforeEnter: requirePermission("summer_center.view_activitydifficulty",['sys_admin','summer_admin','user']),
  },
  {
    path: "dicultsandslotions/list",
    name: "dicultsandslotions_list",
    component: Showdicultsandslotions,
    beforeEnter: requirePermission("summer_center.view_activitydifficulty",['sys_admin','summer_admin','user']),
  },
  {
    path: "Activity-Opreations",
    name: "Activity-Opreations",
    component: ActivityOpreations,
    beforeEnter: requirePermission("summer_center.view_activityoperation",['sys_admin','summer_admin','user']),
  },
  {
    path: "summer-schedule",
    name: "summer-schedule",
    component: SummerSchedule,
    beforeEnter: requirePermission("summer_center.view_schedule"),
  },

  {
    path: "summer-academic-year",
    name: "summer-academic-year",
    component: AcademicYear,
    beforeEnter: requirePermission("globaltable.view_yearofstudy"),
  },
  {
    path: "countries",
    name: "summer-countries",
    component: Countries,
    beforeEnter: requirePermission("globaltable.add_country"),
  },
  {
    path: "governorate",
    name: "summer-governorate",
    component: Governorate,
    beforeEnter: requirePermission("globaltable.view_governorate"),
  },
  {
    path: "directorate",
    name: "summer-directorate",
    component: Directorate,
    beforeEnter: requirePermission("globaltable.view_directorate"),
  },
  
  {
    path: "commits-summer",
    name: "commits-summer",
    component: commitsSummer,
    beforeEnter: requirePermission("summer_center.view_commitee"),
  },

  {
    path: "initiative",
    name: "summer_initiative",
    component: Initiative,
    beforeEnter: requirePermission("summer_center.view_initiative",['sys_admin','summer_admin','user']),
  },


  {
    path: "initiativeassigin",
    name: "initiativeassigin",
    component: initiativeassigin,
    beforeEnter: requirePermission("summer_center.view_initiative",['sys_admin','summer_admin','user']),
  },

  {
    path: "initialization-subjects",
    name: "initialization-subjects",
    component: SubjectInitialization,
    beforeEnter: requirePermission("summer_center.view_subjectsummer"),
  },
  {
    path: "initialization-supervisor",
    name: "initialization-supervisor",
    component: SupervisorInitialization,
    beforeEnter: requirePermission("summer_center.view_supervisor"),
  },
  {
    path: "initialization-divisions",
    name: "initialization-divisions",
    component: Division,
    beforeEnter: requirePermission("summer_center.view_divisionsummer"),
  },
  {
    path: "skills",
    name: "skills",
    component: Skills,
    beforeEnter: requirePermission("summer_center.view_skill"),
  },
  {
    path: "SubjectsStatics",
    name: "SubjectsStatics",
    component: SubjectsStatics,

    beforeEnter: requirePermission("summer_center.view_approach",['sys_admin','summer_admin','user']),
  },

  {
    path: "membercommit/add",
    name: "membercommit_add",
    component: membercommitassigin,
    beforeEnter: requirePermission("summer_center.view_commitee",['sys_admin','summer_admin','user']),
  },
  {
    path: "membercommit/:id/edit",
    name: "membercommit_edit",
    component: membercommitassigin,
    beforeEnter: requirePermission("summer_center.change_commite",['sys_admin','summer_admin','user']),
    props: true,
    // beforeEnter: requirePermission("summer_center.view_lecturesummer"),
  },
  {
    path: "membercommit/list",
    name: "membercommit_list",
    component: ShowMemberCommit,
    beforeEnter: requirePermission("summer_center.view_commitee",['sys_admin','summer_admin','user']),
  },

  {
    path: "level",
    name: "level",
    component: Level,
    beforeEnter: requirePermission("summer_center.view_level"),
  },
  {
    path: "lecture/add",
    name: "lecture_add",
    component: Lectures,
    beforeEnter: requirePermission("summer_center.add_lecturesummer",['sys_admin','gen_admin']),
  },
  {
    path: "lecture/:id/edit",
    name: "lecture_edit",
    component: Lectures,
    beforeEnter: requirePermission("summer_center.change_lecturesummer",['sys_admin','gen_admin']),
    props: true,
    beforeEnter: requirePermission("summer_center.view_lecturesummer"),
  },
  {
    path: "lecture/list",
    name: "lecture_list",
    component: ShowLectures,
    beforeEnter: requirePermission("summer_center.view_lecturesummer"),
  },
  {
    path: "lesson-record",
    name: "lesson-record",
    component: LessonRecord,
    beforeEnter: requirePermission("summer_center.view_lecturelog",['sys_admin','summer_admin','user']),
  },
  {
    path: "assign-teacher/add",
    name: "assign-teacher_add",
    component: AddandEditAssignTeacher,
    beforeEnter: requirePermission("summer_center.view_teachersubjectsummer",['sys_admin','summer_admin','user']),
  },
  {
    path: "assign-teacher/:id/edit",
    name: "assign-teacher_edit",
    component: AddandEditAssignTeacher,
    beforeEnter: requirePermission("summer_center.change_teachersubjectsummer",['sys_admin','summer_admin','user']),
    props: true,
    beforeEnter: requirePermission("summer_center.view_teachersubjectsummer",['sys_admin','summer_admin','user']),
  },
  {
    path: "assign-teacher/list",
    name: "assign-teacher_list",
    component: ShowTeachers,
    beforeEnter: requirePermission("summer_center.view_teachersubjectsummer",['sys_admin','summer_admin','user']),
  },
  {
    path: "dropout",
    name: "dropout",
    component: Dropout,
    beforeEnter: requirePermission("summer_center.view_levelstudentyear",['sys_admin','summer_admin','user']),
  },
  {
    path: "add-student-movable",
    name: "add-student-movable",
    component: AddStudentMovable,
    beforeEnter: requirePermission("summer_center.view_studentsummer",['sys_admin','summer_admin','user']),
  },

  {
    path: "talent",
    name: "talent",
    component: AddTalent,
    beforeEnter: requirePermission("summer_center.view_talent"),
  },
  {
    path: "assigning-divisions-to-level",
    name: "assigning-divisions-to-level",
    component: AssigningDivisionsToLevel,
    beforeEnter: requirePermission("summer_center.view_leveldivision",['sys_admin','summer_admin','user']),
  },

  // wael

  {
    path: "add-activity",
    name: "add_activity",
    component: Activity,
    beforeEnter: requirePermission("summer_center.add_activitysummer",['sys_admin','gen_admin']),
  },
  {
    path: ":id/edit-activity",
    name: "edit_activity",
    component: Activity,
    beforeEnter: requirePermission("summer_center.change_activitysummer",['sys_admin','gen_admin']),
    props: true,
  },
  {
    path: "activity-list",
    name: "activity_list",
    component: ActivityList,
    beforeEnter: requirePermission("summer_center.view_activitysummer",['sys_admin','gen_admin','summer_admin','user']),
  },
  {
    path: "type-activity",
    name: "type-activity",
    component: TypeActivity,
    beforeEnter: requirePermission("summer_center.view_activitytype"),
  },

  {
    path: "week",
    name: "week",
    component: Week,
    beforeEnter: requirePermission("summer_center.view_week"),
  },
  {
    path: "summerdays",
    name: "summerdays",
    component: summerdays,
    beforeEnter: requirePermission("summer_center.view_daysummer"),
  },

  {
    path: "vacation",
    name: "vacation",
    component: Vacation,
    beforeEnter: requirePermission("summer_center.view_vacation"),
  },
  {
    path: "calendar",
    name: "summer-calendar",
    component: Calendar,
    beforeEnter: requirePermission("summer_center.view_calendar"),
  },
  {
    path: "address",
    name: "address",
    component: Address,
    beforeEnter: requirePermission("summer_center.view_schoolsummer"),
  },
  {
    path: "classperouid",
    name: "classperouid",
    component: Classperouid,
    beforeEnter: requirePermission("summer_center.view_schedulesummer"),
  },
  {
    path: "student-attendance",
    name: "student-attendance-view",
    component: StudentAttendance,
    beforeEnter: requirePermission(
      "summer_center.add_studentattendancesummer",['sys_admin','summer_admin','user']
    ),
  },
  {
    path: "student/add",
    name: "summer-student-add",
    component: StudentView,
    beforeEnter: requirePermission("summer_center.add_studentsummer",['sys_admin','summer_admin','user']),
  },
  {
    path: "student/:id/edit",
    name: "summer-student-edit",
    component: StudentView,
    props: true,
    beforeEnter: requirePermission("summer_center.change_studentsummer"),
  },
  {
    path: "receive-curriculum",
    name: "receive-curriculum",
    component: ReceiveCurriculum,
    beforeEnter: requirePermission("summer_center.change_employeelevel",['sys_admin','summer_admin','user']),
  },
  {
    path: "student/list",
    name: "summer-student-list",
    component: StudentList,
    beforeEnter: requirePermission("summer_center.view_studentsummer"),
  },
  {
    path: "parent",
    name: "parent",
    component: Parent,
    beforeEnter: requirePermission("summer_center.add_parentsummer",['sys_admin','summer_admin','user']),
    props: true,
  },
  {
    path: "visitor/list",
    name: "visitor",
    component: Visitor,
    beforeEnter: requirePermission("summer_center.view_visitor",['sys_admin','summer_admin','user']),
  },
  {
    path: "visitor/add",
    name: "add-visitor",
    component: AddVisitor,
    beforeEnter: requirePermission("summer_center.add_visitor",['sys_admin','summer_admin','user']),
  },
  {
    path: "visitor/:id/edit",
    name: "visitor_edit",
    component: AddVisitor,
    beforeEnter: requirePermission("summer_center.change_visitor",['sys_admin','summer_admin','user']),
    props: true,
    beforeEnter: requirePermission("summer_center.view_visitor",['sys_admin','summer_admin','user']),
  },
  {
    path: "parent/:id/edit",
    name: "summer-parent",
    component: Parent,
    beforeEnter: requirePermission("summer_center.change_parentsummer",['sys_admin','summer_admin','user']),
    props: true,
  },
  {
    path: "parents-list",
    name: "parents-list",
    component: ParentList,
    beforeEnter: requirePermission("summer_center.view_parentsummer",['sys_admin','summer_admin','user']),
  },
  {
    path: "employeeassigin",
    name: "employeeassigin",
    component: employeeassigin,
    beforeEnter: requirePermission("summer_center.change_employee",['sys_admin','summer_admin','user']),
  },
  {
    path: "EmployeeStatusManage",
    name: "EmployeeStatusManage",
    component: EmployeeStatusManage,
    beforeEnter: requirePermission("summer_center.view_employee",['sys_admin','summer_admin','user']),
  },

  
  {
    path: "add-student",
    name: "add-student",
    component: AddStudent,
    beforeEnter: requirePermission("summer_center.view_studentsummer"),
  },
  {
    path: "add-employee",
    name: "add-employee",
    component: InitializationEmployee,
    beforeEnter: requirePermission("summer_center.add_employee",['sys_admin','summer_admin','user']),
  },
  {
    path: "edit-employee/:id",
    name: "edit-employee",
    component: InitializationEmployee,
    props: true,
    beforeEnter: requirePermission("summer_center.change_employee",['sys_admin','summer_admin','user']),
  },
  {
    path: "employees",
    name: "employees",
    component: EmployeeList,
    beforeEnter: requirePermission("summer_center.view_employee"),
  },
  {
    path: "employee-attendance",
    name: "employee-attendance",
    component: EmployeeAttendance,
    beforeEnter: requirePermission("summer_center.view_employeeattendance",['sys_admin','summer_admin','user']),
  },
  {
    path: "weekly-marks",
    name: "summer_weekly_marks",
    component: WeeklyMarks,
    beforeEnter: requirePermission("summer_center.view_marksummer",['sys_admin','summer_admin','user']),
  },
  {
    path: "talentassigins",
    name: "talentassigins",
    component: talentassigins,
    beforeEnter: requirePermission("summer_center.view_studentsummer",['sys_admin','summer_admin','user']),
  },
  {
    path: "supplies/list",
    name: "supplies",
    component: Supplies,
    beforeEnter: requirePermission("summer_center.view_supplies",['sys_admin','summer_admin','user']),
  },
  {
    path: "supplies/add",
    name: "add-supplies",
    component: AddSupplies,
    beforeEnter: requirePermission("summer_center.add_supplies",['sys_admin','summer_admin','user']),
  },
  {
    path: "supplies/:id/edit",
    name: "Supplies_edit",
    component: AddSupplies,
    beforeEnter: requirePermission("summer_center.change_supplies",['sys_admin','summer_admin','user']),
    props: true,
    beforeEnter: requirePermission("summer_center.view_supplies",['sys_admin','summer_admin','user']),
   
  },
  {
    path: "summer-statistic",
    name: "summer_statistic",
    component: SummerStatistic,
    // beforeEnter: requirePermission("summer_center.view_managmenttask"),
  },
  {
    path: "managament-tasks/list",
    name: "managament_tasks",
    component: ManagamentTasks,
    beforeEnter: requirePermission("summer_center.view_managmenttask",['sys_admin','summer_admin','user']),
  },
  {
    path: "managament-tasks/add",
    name: "add-managament-tasks",
    component: AddManagamentTasks,
    beforeEnter: requirePermission("summer_center.add_managmenttask",['sys_admin','summer_admin','user']),
  },
  {
    path: "managament-tasks/:id/edit",
    name: "managament-tasks_edit",
    component: AddManagamentTasks,
    beforeEnter: requirePermission("summer_center.change_managmenttask",['sys_admin','summer_admin','user']),
    props: true,
    beforeEnter: requirePermission("summer_center.view_managmenttask",['sys_admin','summer_admin','user']),
  },
  {
    path: "positives-negatives",
    name: "recomendations",
    component: Recomendations,
    beforeEnter: requirePermission("summer_center.view_recommendation",['sys_admin','summer_admin','user']),
  },
  {
    path: "time-table-add",
    name: "summer_time_table_add",
    component: TimeTable,
    beforeEnter: requirePermission("summer_center.view_schoolweeklyschedulesummer"),
  },
  // {
  //   path: "time-table-update",
  //   name: "summer_time_table_update",
  //   component: TimeTableUpdate,
  //   beforeEnter: requirePermission("summer_center.view_schoolweeklyschedulesummer"),
  // },
  {
    path: "activity-weekly-assign",
    name: "WeeklyActivityAssign",
    component: WeeklyActivityAssign,
    beforeEnter: requirePermission("summer_center.view_schoolweeklyactivity",['sys_admin','gen_admin']),
  },
  // {
  //   path: "summer-settings",
  //   name: "summer-settings",
  //   component: Settings,
  //   beforeEnter: requirePermission("globaltable.change_settingsystem"),
  // },
  {
    path: "backup",
    name: "summer_backup",
    component: BackUp,
  },

];
