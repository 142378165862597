<template>
  <VCard class="justify-center">
    <v-card flat v-if="
          (CheckGetPermission('summer_center.change_commitee') ||
          CheckGetPermission('summer_center.add_commitee')) &&
          checkrole(['sys_admin', 'gen_admin'])
        ">
      <v-card-text class="pb-0">
          <VForm ref="form">
            <VRow
              class="mt-2"
            >
              <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="5" lg="5">
                <VCardItem class="pa-0">
                  <VAutocomplete
                    :items="committeeType"
                    v-model="estmation.type"
                    prepend-inner-icon="mdi-account-tie-outline"
                    item-title="name"
                    item-value="id"
                    autofocus
                    :label="$t('summer.type-of-commit')"
                    persistent-hint
                    :rules="rules.required_rule"
                  ></VAutocomplete>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow 
            >
              <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="5" lg="5">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="estmation.name"
                    prepend-inner-icon="mdi-account-group-outline"
                    :label="$t('school.committee_name')"
                    :placeholder="$t('school.committee_name')"
                    density="compact"
                    type="text"
                    clearable
                    :rules="rules.required_rule"
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
              <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="5" lg="5">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="estmation.purpose"
                    prepend-inner-icon="mdi-account-multiple-outline"
                    :label="$t('summer.purpose-of-commit')"
                    :placeholder="$t('summer.purpose-of-commit')"
                    density="compact"
                    type="text"
                    clearable
                    :rules="rules.required_rule"
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow
              
            >
              <VCol cols="12" class="pa-0 ma-0" xs="12" sm="12" md="10" lg="10">
                <VCardItem class="px-3">
                  <VTextarea
                    v-model="estmation.note"
                    prepend-inner-icon="mdi-note-outline"
                    clearable
                    :label="$t('globals.note')"
                    counter
                    no-resize
                    density="compact"
                    rows="2"
                    :rules="rules.note"
                  >
                  </VTextarea>
                </VCardItem>
              </VCol>
            </VRow>
          </VForm>
      </v-card-text>
      <VCardActions
        class="px-3"
        elevation="4"
      >
        <VBtn
          :loading="loading"
          v-if="
            !is_update &&
            CheckGetPermission('summer_center.add_commitee', ['sys_admin', 'gen_admin'])
          "
          class="bg-indigo"
          @click="saveEstimation"
          size="small"
        >
          <span class="text-white">
            {{ $t("globals.add") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn
          :loading="loading"
          v-if="
            is_update &&
            CheckGetPermission('summer_center.change_commitee', ['sys_admin', 'gen_admin'])
          "
          class="bg-success"
          @click="updateEstimation"
          size="small"
        >
          <span class="text-white">
            {{ $t("globals.edit") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="resetForm">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
    <!-- ##########    data Table  ########### -->
    <v-divider style="border-width: -1px; opacity: 0.25" />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card  flat>
      <v-card-text>
      <div class="d-flex justify-space-between mb-2">
        <div class="d-flex flex-fill">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>

          <div style="width: 100%">
            <v-text-field
              color="indigo"
              v-model="txt_search"
              style="
                border-radius: 30px;
                border: 1px solid #ccc;
                color: black;
                height: 30px;
                width: 90%;
              "
              class="search"
              hide-details
              prepend-inner-icon="mdi-magnify"
              single-line
              :placeholder="$t('globals.search-here')"
              density="compact"
              variant="text"
              @input="getCommittee((page = 1))"
            >
            </v-text-field>
          </div>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table-server
        :headers="selectedHeaders"
        :items="tableList"
        v-model:sort-by="sortBy"
        fixed-footer
        :search="txt_search"
        density="compact"
        class="elevation-1 rounded"
        @update:options="getCommittee((page = 1))"
      >
        <!-- @update:options="getCommittee" -->
        <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template>
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
              </v-checkbox>
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <v-btn
                  variant="text"
                  class="me-1 v-btn-icon"
                  size="large"
                  v-if="
                    CheckGetPermission('summer_center.change_commitee', [
                      'sys_admin',
                      'gen_admin',
                    ])
                  "
                  @click="editEstimation(item.raw)"
                >
                  <v-icon color="warning">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  size="small"
                  class="v-btn-icon"
                  @click="(estimate_del_id = item.raw.id), (del_dialog = true)"
                  v-if="
                    CheckGetPermission('summer_center.delete_commitee', [
                      'sys_admin',
                      'gen_admin',
                    ])
                  "
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="pagination.num_pages"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ pagination.count }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div>
        </template>
      </v-data-table-server>
      </v-card-text>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("globals.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("globals.confirm-delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (estimate_del_id = undefined)"
            >{{ $t("globals.cancel") }}</v-btn
          >
          <v-btn :loading="del_loading" color="red" @click="deleteEstimation">{{
            $t("globals.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import {
  email,
  required,
  helpers,
  maxLength,
  url,
  alpha,
  alphaNum,
  numeric,
  integer,
} from "@vuelidate/validators";
export default {
  data() {
    return {
      committee: [],
      loading: false,
      del_loading: false,
      rules: {
        required_rule: [(value) => !!value || this.$t("globals.required_field")],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
        phone_number: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) => /^[0-9+]+$/.test(value) || this.$t("globals.must_be_numbers"),
        ],
      },

      v$: useValidate(),
      estmation: {
        name_ar: undefined,
        name_en: undefined,
        degree_max: undefined,
        degree_min: undefined,

        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      committeeType: [
        {
          name: "اجتماعي",
          id: 1,
        },
        {
          name: "ثقافي",
          id: 2,
        },
      ],
      is_update: false,
      del_dialog: false,
      estimate_del_id: undefined,

      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      headers: [
        { title: this.$t("school.committee_name"), key: "name" },
        { title: this.$t("summer.purpose-of-commit"), key: "purpose" },
        { title: this.$t("summer.type-of-commit"), key: "committee_type" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ],
      selectedHead: ["name", "purpose", "committee_type", "note", this.checkrole(['sys_admin', 'gen_admin']) ? "actions":null,],
      txt_search: undefined,
      name_ar_rule: [(value) => !!value || this.$t("globals.required_field")],
      name_en_rule: [(value) => !!value || this.$t("globals.required_field")],

      perPage: 10,
      length: 0,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 10000000, text: this.$t("globals.all") },
      ],
      page: 1,
      totalItems: null,
      pagination: {},
      sortBy: [
        {
          key: "name",
          order: "asc",
        },
      ],
    };
  },
  validations() {
    return {
      estmation: {
        name_ar: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
        },
        name_en: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
        },

        note: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(250)
          ),
        },
      },
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
        if(this.checkrole(['sys_admin', 'gen_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    ...mapState({
      user: (state) => state.User,
    }),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },

    tableList() {
      let list = [];
      this.committee.forEach((element) => {
        list.push({
          name: element.name,
          purpose: element.purpose,
          type: element.type,
          committee_type: element.committee_type,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    async getCommittee(page = 1) {
      await this.axios
        .get(this.base_url + "api/summer/committee", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            search: this.txt_search,
            page: page,
            page_size: this.perPage,
            sort_by: this.order_data,
          },
        })
        .then((response) => {
          if (response.data) {
            this.committee = response.data.results;

            this.pagination = response.data.pagination;
            this.length = response.data.pagination.num_pages;
            this.totalItems = response.data.pagination.count;
          }
        });
    },
    ...mapActions({}),
    resetForm() {
      this.$refs.form.reset();
      this.v$.estmation.$reset();
      this.is_update = false;
      this.estmation.id = undefined;
      this.updated_id = undefined;
    },
    editEstimation(data) {
      let estimates = this.committee.find((item) => item.id == data.id);

      if (estimates != undefined) {
        for (const key in estimates) this.estmation[key] = estimates[key];
        this.estmation.data_entry = undefined;
        this.estmation.updated_by = undefined;
        this.is_update = true;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveEstimation() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.loading = true;
        this.estmation.data_entry = this.user;

        let result = await this.axios
          .post(this.base_url + "api/summer/committee", this.estmation, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.loading = false;
            this.$emit("successAlert", this.$t("globals.data_added"));
          })
          .catch((error) => {
            this.loading = false;

            if (error.response.data.name) {
              this.$emit("errorAlert", this.$t("globals.error_in_repeted_data"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });

        this.resetForm();
        this.getCommittee();

        setTimeout(() => {}, 1500);
      }
    },
    async updateEstimation() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.loading = true;
        this.estmation.data_entry = this.user;
        this.estmation.updated_by = this.user;
        let result = await this.axios
          .put(
            `${this.base_url}/api/summer/committee/${this.estmation.id}`,
            this.estmation,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((responce) => {
            this.loading = false;
            this.$emit("successAlert", this.$t("globals.data_updated"));
          })
          .catch((error) => {
            this.loading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });

        this.resetForm();
        this.getCommittee();
        setTimeout(() => {}, 1500);
      }
    },
    async deleteEstimation() {
      if (this.estimate_del_id != undefined) {
        this.del_loading = true;
        let result = await this.axios
          .delete(`${this.base_url}/api/summer/committee/${this.estimate_del_id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.del_loading = false;
            this.$emit("successAlert", this.$t("globals.data_deleted"));
          })
          .catch((error) => {
            this.del_loading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.del_dialog = false;
        this.estimate_del_id = undefined;

        this.getCommittee();
        setTimeout(() => {}, 1500);
      }
    },
  },

  watch: {
    perPage() {
      this.getCommittee();
    },
    page() {
      this.getCommittee(this.page);
    },
  },
};
</script>
