<template>
  <VCard class="mb-2 pa-0">
    <v-card-text class="pb-0">
      <VForm
        ref="form"
        @submit.prevent="saveManagementTasks"
        v-if="
          CheckGetPermission('summer_center.add_managmenttask', [
            'sys_admin',
            'summer_admin',
            'user',
          ]) ||
          CheckGetPermission('summer_center.change_managmenttask', [
            'sys_admin',
            'summer_admin',
            'user',
          ])
        "
      >
      
      <VRow class="mt-2">
        <VCol  cols="12" xs="10" md="5" lg="5">
          <v-autocomplete
            v-model="fk_week"
            :label="$t('summer.calendar.week-name')"
            persistent-hint
            item-title="week_name"
            item-value="id"
            clearable
            density="compact"
            :items="weeks"
            prepend-inner-icon="mdi-calendar-week"
            :rules="rules.week"
            @update:modelValue="getManagementTasks()"
          ></v-autocomplete>
        </VCol>
      </VRow>
      <v-progress-linear 
        color="indigo" 
        :reverse="$i18n.locale=='ar'"
        height="3px" 
        indeterminate 
        v-if="loading"
      >
      </v-progress-linear>
      <v-table
        density="compact"
        class="mt-3 elevation-1 rounded"
        v-else-if="managment_task.length>0"
      >
        <thead>
          <tr>
            <th class="text-center">{{ $t("globals.#") }}</th>
            <th class="text-center">{{ $t("summer.name-administrative") }}</th>
            <th>
              {{ $t("summer.is-done") }}
            </th>
            <th>
              {{$t('globals.note')}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(task, index) in managment_task" :key="task">
            <td class="text-center">{{ index + 1 }}</td>
            <td class="text-center">
              <span>{{ task.management_name }}</span>
            </td>
            <td>
              <v-checkbox
                v-model="task.is_found"
                hide-details
                density="compact"
                color="indigo"
              >
              </v-checkbox>
            </td>
            <td class="w-25">
                <v-text-field 
                  v-model="task.note"
                  density="compact"
                  variant="underlined"
                  color="indigo"
                  counter="250"
                  :placeholder="$t('globals.note')"
                  :rules="rules.note"
                  hide-details
                >

                </v-text-field>
              </td>
          </tr>
        </tbody>
      </v-table>
      <v-card
          v-else-if="managment_task.length<1"
          class="pa-2 text-center mb-2"
        >
          <span >
            {{ $t("globals.not-found-items") }}
          </span>
        </v-card>
    </VForm>
    </v-card-text>
    <VCardActions class="px-3" v-if="!loading&&managment_task.length>1">
      <VBtn
        v-if="
          CheckGetPermission('summer_center.add_managmenttask', [
            'sys_admin',
            'summer_admin',
            'user',
          ])
        "
        class="bg-indigo"
        @click="saveManagementTasks()"
        size="small"
        :loading="saveloading"
      >
        <span class="text-white">
          {{ $t("globals.save") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="
          is_update &&
          CheckGetPermission('summer_center.change_managmenttask', [
            'sys_admin',
            'summer_admin',
            'user',
          ])
        "
        class="bg-success"
        @click="updatestatistic()"
        size="small"
        :loading="saveloading"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        class="mx-3"
        size="small"
        @click="resetForm()"
        v-if="
          CheckGetPermission('summer_center.add_managmenttask', [
            'sys_admin',
            'summer_admin',
            'user',
          ])
        "
      >
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
    <!-- ######## Header Of Data Table  ####### -->
  </VCard>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import Datetime from "vue3-datetime-js";
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      saveloading: false,
      loading:false,
      fk_week:undefined,
      rules: {
        week: [(value) => !!value || this.$t("globals.required_field")],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      v$: useValidate(),
      managment_task:[]
    };
  },
  computed: {
    
    ...mapState({
      weeks: (state) => state.summer.weeks,
    }),
    ...mapGetters({}),
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getweeks();
      await this.getManagementTasks();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getweeks: "summer/getweeks",
    }),
    resetForm() {
      this.$refs.form.resetValidation();
      this.fk_week=undefined
      this.getManagementTasks()
    },
    async getManagementTasks(){
        this.loading=true
        await this.axios(this.base_url + "api/summer/managment-task",{
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params:{
            fk_week:this.fk_week
          }
        }).then(response=>{
          this.managment_task=response.data
        })
        this.loading=false
    },
    async saveManagementTasks() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveloading = true;
        let result = await this.axios
          .post(this.base_url + "api/summer/managment-task", this.managment_task.filter(item=>item.is_found==true), {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params:{
              fk_week:this.fk_week
            }
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("alert.success.save"));
            this.saveloading = false;
          })
          .catch((error) => {
            this.saveloading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            
          });
      }else{
        this.$emit("errorAlert", this.$t("globals.error_in_data"));
      }
    },
  },
 
};
</script>

<style scoped>
.v-application__wrap {
  background-color: #999;
}
</style>
