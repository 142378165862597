<template>
  <VCard class="h-100 mb-2 pa-0">
    <VForm ref="form" class="" @submit.prevent="saveAddress">
      <VCard-title class="my-4">
        <VIcon icon="mdi-map-marker" />
        <h3 class="d-inline" style="padding: 0 4px">
          {{
            is_update
              ? $t("summer.address.edit-address")
              : $t("summer.address.add-address")
          }}
        </h3>
        <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
      </VCard-title>
      <VRow class="pt-0 px-2 px-md-16 pb-0">
        <VCol class="pt-0 px-3" cols="12" md="5">
          <VCardItem class="pa-0">
            <VAutocomplete
              :items="countries"
              v-model="address.country"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.country')"
              persistent-hint
              density="compact"
              :rules="rules.country"
              prepend-inner-icon="mdi-flag"
              @update:modelValue="
                (address.governorate = undefined), (address.directorate = undefined)
              "
            ></VAutocomplete>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-3" cols="12" md="5">
          <VCardItem class="pa-0">
            <VAutocomplete
              :items="filterGovernorates(address.country)"
              v-model="address.governorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.governorate')"
              persistent-hint
              density="compact"
              :rules="rules.governorate"
              prepend-inner-icon="mdi-city"
              @update:modelValue="address.directorate = undefined"
            ></VAutocomplete>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-3" cols="12" md="5">
          <VCardItem class="pa-0">
            <VAutocomplete
              :items="filterDirectorates(address.governorate)"
              v-model="address.fk_directorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.directorate')"
              persistent-hint
              density="compact"
              prepend-inner-icon="mdi-map-outline"
              :rules="rules.directorate"
            ></VAutocomplete>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-3" cols="12" md="5">
          <VCardItem class="pa-0">
            <VTextField
              v-model="address.name"
              :label="$t('summer.address.name')"
              persistent-hint
              clearable
              density="compact"
              prepend-inner-icon="mdi-map-marker"
              :rules="rules.name"
            ></VTextField>
          </VCardItem>
        </VCol>
        <div class="w-100"></div>
        <VCol cols="12" class="pa-0 ma-0" xs="12" md="5" lg="10">
          <VCardItem class="px-3">
            <VTextarea
              v-model="address.note"
              prepend-inner-icon="mdi-note-outline"
              clearable
              :label="$t('summer.initiative.note')"
              no-resize
              counter="250"
              density="compact"
              rows="2"
              :rules="rules.note"
            ></VTextarea>
          </VCardItem>
        </VCol>
      </VRow>
    </VForm>
    <VCardActions
      class="mx-16"
      v-if="
        CheckGetPermission('summer_center.add_address') ||
        CheckGetPermission('summer_center.change_address')
      "
    >
      <VBtn v-if="!is_update" class="bg-indigo" @click="saveAddress()" size="small">
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn v-if="is_update" class="bg-success" @click="updateAddress()" size="small">
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
    <v-divider class="my-4" />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="pa-2">
      <div class="d-flex justify-space-between pa-2">
        <div class="d-flex flex-fill">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
          <div style="width: 100%">
            <v-text-field
              color="indigo"
              v-model="txt_search"
              @input="getAddresses((page = 1))"
              style="
                border-radius: 30px;
                border: 1px solid #ccc;
                color: black;
                height: 30px;
                width: 100%;
              "
              class="search"
              hide-details
              prepend-inner-icon="mdi-magnify"
              single-line
              :placeholder="$t('globals.search-here')"
              density="compact"
              variant="text"
            >
            </v-text-field>
          </div>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table-server
        :loading="loading"
        :headers="selectedHeaders"
        :items="tableList"
        v-model:sort-by="sortBy"
        item-value="name"
        item-key="id"
        id="vDataTable"
        fixed-footer
        @update:options="getAddresses((page = 1))"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <v-btn
                  variant="text"
                  class="me-1 v-btn-icon"
                  size="small"
                  @click="editAddress(item.raw)"
                  v-if="CheckGetPermission('summer_center.change_address')"
                >
                  <v-icon color="warning">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  size="small"
                  class="v-btn-icon"
                  @click="(dialogVisible = true), (this.delete_id = item.raw.id)"
                  v-if="CheckGetPermission('summer_center.delete_address')"
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="pagination.num_pages"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ pagination.count }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div>
        </template>
      </v-data-table-server>
    </v-card>
  </VCard>
  <VDialog v-model="dialogVisible" scrollable max-width="350px">
    <VCard>
      <VCardTitle class="text-blue">
        {{ $t("globals.confirmation") }}
      </VCardTitle>
      <VCardText class="text-blue-grey-darken-4">
        {{ $t("globals.confirm_delete") }}
      </VCardText>
      <VCardActions>
        <VBtn
          class="font-weight-bold me-2"
          color="error"
          @click.prevent="deleteInitiative"
        >
          {{ $t("globals.delete") }}
        </VBtn>
        <VBtn
          class="bg-white font-weight-bold"
          color="blue"
          @click.prevent="cancelDeleteItem"
        >
          {{ $t("globals.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
export default {
  data() {
    return {
      addresses: [],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      address: {},
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],
      rules: {
        name: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        country: [(value) => !!value || this.$t("globals.required_field")],
        governorate: [(value) => !!value || this.$t("globals.required_field")],
        directorate: [(value) => !!value || this.$t("globals.required_field")],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      dialogVisible: false,

      v$: useValidate(),
      process_alert: false,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      selectedHead: [
        "name",
        "country_name",
        "governorate_name",
        "directorate_name",
        "note",
        "actions",
      ],
      headers: [
        // { title: "", key: "checkbox" },
        { title: this.$t("summer.address.name"), key: "name" },
        { title: this.$t("school.country_name"), key: "country_name" },
        { title: this.$t("school.governorate_name"), key: "governorate_name" },
        { title: this.$t("school.directorate_name"), key: "directorate_name" },
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions" },
      ],
      txt_search: undefined,
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.school.countries,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      user: (state) => state.User,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.addresses) {
        return JSON.parse(JSON.stringify(this.addresses));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getAddresses();
      await this.getCountries();
      await this.getGovernorates();
      await this.getDirectorate();
      this.address.country=this.countries[0].id
      this.address.governorate=parseInt(localStorage.getItem('governorate'))
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getAddresses: "summer/getAddresses",
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    resetForm() {
      this.$refs.form.resetValidation();
      this.address = {};
      this.is_update = false;
      this.updated_id = undefined;
    },
    editAddress(address) {
      this.address = address;
      this.address.country = address.country_id;
      this.address.governorate = address.governorate_id;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async saveAddress() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.address.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/address", this.address, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.icon_color = "success";
            this.icon = "check-circle";
            this.alert_title = this.$t("globals.success");
            this.alert_message = this.$t("globals.data_added");
            this.address = {};
          })
          .catch((error) => {
            this.icon_color = "error";
            this.icon = "cancel";
            this.alert_title = this.$t("globals.failure");
            this.alert_message = this.$t("globals.error_in_data");
          });
        this.process_alert = true;
        this.resetForm();
        this.getAddresses();
        setTimeout(() => {
          this.process_alert = false;
        }, 2000);
      }
    },
    async updateAddress() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.address.data_entry = this.user;
        let result = await this.axios
          .put(this.base_url + "api/summer/address/" + this.address.id, this.address, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.icon_color = "success";
            this.icon = "check-circle";
            this.alert_title = this.$t("globals.success");
            this.alert_message = this.$t("globals.data_updated");
          })
          .catch((error) => {
            this.icon_color = "error";
            this.icon = "cancel";
            this.alert_title = this.$t("globals.failure");
            this.alert_message = this.$t("globals.error_in_data");
          });
        this.process_alert = true;
        this.resetForm();
        this.getAddresses();
        setTimeout(() => {
          this.process_alert = false;
        }, 2000);
      }
    },
    async deleteInitiative() {
      if (this.delete_id) {
        let result = await this.axios
          .delete(this.base_url + "api/summer/address/" + this.delete_id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.icon_color = "success";
            this.icon = "check-circle";
            this.alert_title = this.$t("globals.success");
            this.alert_message = this.$t("globals.data_deleted");
          })
          .catch((error) => {
            this.icon_color = "error";
            this.icon = "cancel";
            this.alert_title = this.$t("globals.failure");
            this.alert_message = this.$t("globals.error_in_data");
          });
        this.dialogVisible = false;
        this.delete_id = undefined;
        this.process_alert = true;
        this.getAddresses();
        setTimeout(() => {
          this.process_alert = false;
        }, 2000);
      }
    },
    async getAddresses(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/address`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.addresses = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  watch: {
    perPage() {
      this.getAddresses();
    },
    page() {
      this.getAddresses(this.page);
    },
  },

};
</script>

<style>
.v-application__wrap {
  background-color: #999;
}
</style>
