// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import * as labs from 'vuetify/labs/components'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'


// Vuetify

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides


// Light Theme 
const customLightTheme = {
  dark: false,
  colors:{
    background: '#d7d7d7',
    primary: '#6200EE',
    'primary-darken-1': '#00ffff',
    secondary: '#00ffff',
    'secondary-darken-1': '#00ffff',
    error: '#EB4E3d',
    info: '#2196F3',
    success: '#5DC466',
    warnning: '#F19A37',
    indigo:'#3487F6',
    'golden':'#d0bc39',
    'ccc':'#f4f5f7',
    'icon-color':'#8892a4'
  }
}



  // display:{
  //   mobileBreakpoint: 'xs',
  //   thresholds:{
  //     xs: 100,
  //     sm: 340,
  //     md: 540,
  //     lg: 800,
  //     xl: 1280, 
  //   },
  // },

export default createVuetify({
  components: {
    ...labs,
  },
  directives,
  defaults:{
    global:{
      
    }, 
    VAutocomplete:{
      density:"compact",
      color:'indigo',
      clearable:true

    },
    VProgressLinear:{
      height:3,
      color:'indigo',
    },
    
    VChip:{
      // density:'compact',
      size:'x-small'
    },
    VTextField:{
      color:'indigo',
      density:"compact",
    },
    VTextarea:{
      color:'indigo',
      density:"compact",
      clearable:true
    },
    
    VAppBar:{
      VTextField:{
        color: 'white',
        
      },
      VIcon:{
        color:'grey-lighten-1'
      }
    },
    VNavigationDrawer:{
      // color: '#f4f5f7',
      color: 'white',
      VListItem:{
        density:'compact',
        minHeight:30
        
      },
      
    },
    VListItem:{
      density:'compact',
      
    },
    VList:{
      density:'compact',
    },
    VListGroup:{
      density:'compact',
      
    },
    
    VExpansionPanel:{
      // VExpansionPanelTitle:{
      //   color: 'blue-grey-darken-4',
      // },
      // bgColor: 'blue-grey-lighten-4',
      // elevation:8,
    },

    VCol:{
      // VBtn:{
      //   color: 'blue-grey-darken-4',
      //   variant:'outlined',
      //   VIcon: {
      //     elevation: 6,
      //     color: 'blue-grey-darken-4',
      //   },
      // },  
    },
  },
  
  theme:{
    letterSpacing:{
      default:'normal'
    },
    defaultTheme: 'customLightTheme',
    variations:{
      lighten:1,
      darken: 2,
    },
    themes:{
      customLightTheme,
    }
  }
})
