<template>
  <v-card class="mb-3 sort-hiden" v-if="!isexit">
    <v-form @submit.prevent>
      <v-expansion-panels model-value="1">
        <v-expansion-panel elevation="1" value="1">
          <v-expansion-panel-title class="px-4">
            <v-icon icon="mdi-sort-calendar-ascending"></v-icon>
            <span class="mx-2">{{ $t("globals.select-others-criteria") }}</span>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <VRow style="align-items: center">
              <v-col cols="6" md="3" sm="4">
                <v-autocomplete
                  color="indigo"
                  v-model="lessonRecord.fk_week"
                  density="compact"
                  item-title="week_name"
                  item-value="id"
                  prepend-inner-icon="mdi-calendar-week"
                  clearable
                  :items="weeks"
                  :label="$t('summer.calendar.week-name')"
                  @update:model-value="getDateList(), resetForm(1)"
                  :rules="rules"
                />
              </v-col>
              <v-col cols="6" md="3" sm="4">
                <v-autocomplete
                  color="indigo"
                  v-model="lessonRecord.date"
                  density="compact"
                  item-title="week_name"
                  item-value="id"
                  prepend-inner-icon="mdi-calendar-today"
                  clearable
                  :items="listWeeks"
                  :label="$t('summer.calendar.hijri-date')"
                  :rules="rules"
                  @update:model-value="resetForm(0)"
                />
              </v-col>
              <v-col cols="6" md="3" sm="4">
                <v-autocomplete
                  color="indigo"
                  v-model="summer_type_id"
                  density="compact"
                  item-title="type"
                  item-value="id"
                  prepend-inner-icon="mdi-calendar-week"
                  :items="typeList"
                  :label="$t('summer.summer_type')"
                />
              </v-col>
              <VCardActions class="px-3">
                <VBtn
                  ty
                  class="bg-indigo"
                  type="submit"
                  @click="checkRule()"
                  size="small"
                >
                  <span class="text-white">
                    {{ $t("globals.show") }}
                  </span>
                  <VIcon icon="mdi-presentation" color="white" end></VIcon>
                </VBtn>
                <VBtn class="mx-3" size="small" @click="clearweek()">
                  {{ $t("globals.clear") }}
                  <VIcon icon="mdi-broom" color="golden" end></VIcon>
                </VBtn>
              </VCardActions>
            </VRow>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-progress-linear
        color="indigo"
        v-if="loading"
        indeterminate
        height="2px"
        :reverse="$i18n.locale == 'ar'"
      >
      </v-progress-linear>
    </v-form>
  </v-card>
  <v-card id="report" v-if="isexit">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.TotalNumberOfActivitiesCarriedOut") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
        <v-btn density="compact" class="mx-1" @click="isexit = !isexit">
          <span class="text-indigo">{{ $t("globals.back") }}</span>
        </v-btn>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.TotalNumberOfActivitiesCarriedOut") }}
              {{ $t("reportpublic.InDirectorate") }}
              {{
                this.$i18n.locale == "ar"
                  ? results.directorate_name_ar
                  : results.directorate_name_en
              }}
                 ( {{typeList[summer_type_id].type}} )
            </v-sheet>
           
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{ " هـ " }} {{ lessonRecord.date }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item v-if="this.header.length > 0" >
      <table class="report-table text-center">
        <thead>
          <tr>
            <th rowspan="2">{{ $t("reportpublic.ActiivtyType") }}</th>
            <th :colspan="header ? header.length : ''">
              {{ $t("reportpublic.CenterName") }}
            </th>
            <th rowspan="2">{{ $t("reportpublic.Total") }}</th>
          </tr>
          <tr>
            <th v-for="(data, index) in header" :key="index">
              {{
                this.$i18n.locale == "ar"
                  ? data.summer_name_ar
                  : data.summer_name_en
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in results.activity_types" :key="index">
            <td>{{ data.activity_name }}</td>
            <td v-for="(subdata, index) in data.summer_data" :key="index">
              {{ subdata.activities }}
            </td>
            <td>{{ data.total_activities }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>{{ $t("reportpublic.GrandTotal") }}</th>
            <th
              v-for="(data, index) in results.total_activities_governorate"
              :key="index"
            >
              {{ data.activities }}
            </th>
            <th>{{ results.total_for_all_activities }}</th>
          </tr>
        </tfoot>
      </table>
    </v-card-item>
         <v-row v-else justify="center" align-content="center" class="pa-4">
          لا يوجد بيانات
        </v-row>
  </v-card>
</template>

<script>
import axios from "axios";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions } from "vuex";
export default {
async mounted() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getWeeks();
      await this.fetchWeek();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getWeeks: "summer/getWeeks",
    }),
    async getDateList() {
      if (this.lessonRecord.fk_week) {
        await this.axios
          .get(this.base_url + "api/summer/get-work-days", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              week_id: this.lessonRecord.fk_week,
            },
          })
          .then((response) => {
            this.listWeeks = response.data.date;
          })
          .catch((error) => {
              
          });
      } else {
        this.resetForm();
      }
    },
       async fetchWeek() {
      await axios
        .get(
             `${this.base_url}statistics/get-current-date`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.lessonRecord.fk_week = response.data.week_id;
          this.lessonRecord.date =  response.data.current_date;
          this.currnet_week_id = response.data.week_id;
          this.currnet_date_id = response.data.current_date;
        })
        .catch((error) => {
            
        });
    },
    async fetchData() {
      await axios
        .get(
          this.lessonRecord.date
            ? `${this.base_url}statistics/directorate/total-number-of-activities-carried-out?day=${this.lessonRecord.date}&type=${this.summer_type_id}`
            : `${this.base_url}statistics/directorate/total-number-of-activities-carried-out?type=${this.summer_type_id}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.results = response.data.results;
          this.header = this.results.activity_types[0]?this.results.activity_types[0].summer_data:[];
          this.isexit = true;
        })

        .catch((error) => {
            
        });
    },

    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
    async checkRule() {
      if (this.lessonRecord.date && this.lessonRecord.fk_week) {
        this.fetchData();
      }
    },
    resetForm(resetAll) {
      if (resetAll) {
        this.lessonRecord.date = null;
        this.listWeeks = [];
        this.data_charts = [];
        this.results2 = [];
        this.results = [];
      } else {
        this.results = [];
        this.results2 = [];
      }
    },
   async gettoday() {
      this.fetchData();
    },
    async clearweek(){
      this.listWeeks = [];
      this.data_charts = [];
      this.results = [];
      this.results2 = [];
      this.lessonRecord.date = this.currnet_date_id;
      this.lessonRecord.fk_week = this.currnet_week_id;
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      currnet_date_id:null,
      currnet_week_id:null,
      summer_type_id: 0,
      typeList: [
        { id: 0, type: this.$t("globals.all") },
        { id: 1, type: this.$t("reportpublic.Open") },
        // { id: 2, type: this.$t("reportpublic.Close") },
        { id: 2, type: this.$t("reportpublic.Typical") },
      ],
      isexit: false,
      header: [],
      id: 5,
      results: [],
      lessonRecord: {},
      listWeeks: [],
      rules: [(value) => (value ? true : this.$t("globals.required_field"))],
    };
  },
  computed: {
    ...mapState({
      weeks: (state) => state.summer.weeks,
    }),
  },

  components: {
    ReportHeader,
  },
};
</script>
