<template>
  <VCard class="justify-center">
    <v-card flat 
      v-if="
        CheckGetPermission('summer_center.change_daysummer', [
          'sys_admin',
          'gen_admin',
        ]) ||
        CheckGetPermission('summer_center.add_daysummer', ['sys_admin', 'gen_admin'])
      ">
      <v-card-text>
        <VForm ref="form">
          <VRow class="mt-2">
            <VCol  cols="12" xs="12" sm="12" md="6" lg="6">
              <VTextField
                v-model="day.name"
                autofocus
                prepend-inner-icon="mdi-calendar-edit"
                :label="$t('day.day')"
                :placeholder="$t('day.day_placeholder')"
                density="compact"
                type="text"
                clearable
                :rules="rules.name"
              >
              </VTextField>
            </VCol>
            <VCol  cols="12" xs="12" sm="12" md="5" lg="5">
              <VCheckbox v-model="day.is_holiday" :label="$t('day.day_off')" color="blue">
              </VCheckbox>
            </VCol>
            <VCol cols="12"  xs="12" sm="12" md="8" lg="8">
                <VTextarea
                  v-model="day.note"
                  hide-details="auto"
                  clearable
                  color="indigo"
                  :label="$t('globals.note')"
                  counter
                  no-resize
                  density="compact"
                  rows="2"
                  :rules="rules.note"
                >
                </VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions
        class="px-3"
      >
        <VBtn
          :loading="loading"
          v-if="
            !is_update &&
            CheckGetPermission('summer_center.add_daysummer', ['sys_admin', 'gen_admin'])
          "
          class="bg-indigo"
          @click="saveDay"
          size="small"
        >
          <span class="text-white">
            {{ $t("globals.add") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn
          :loading="loading"
          v-if="
            is_update &&
            CheckGetPermission('summer_center.change_daysummer', ['sys_admin', 'gen_admin'])
          "
          class="bg-success"
          @click="updateDay"
          size="small"
        >
          <span class="text-white">
            {{ $t("globals.edit") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="resetForm">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>

    <!-- ##########    data Table  ########### -->
    <v-divider style="border-width: -1px; opacity: 0.25" />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card >
      <v-card-text>
        <div class="d-flex justify-space-between mb-2">
          <div class="d-flex flex-fill">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                  <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                  <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="header in headers"
                    :key="header.key"
                    :label="header.title"
                    :value="header.key"
                    color="indigo"
                    v-model="selectedHead"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>

            <div style="width: 100%">
              <v-text-field
                color="indigo"
                v-model="txt_search"
                style="
                  border-radius: 30px;
                  border: 1px solid #ccc;
                  color: black;
                  height: 30px;
                  width: 90%;
                "
                class="search"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                :placeholder="$t('globals.search-here')"
                density="compact"
                variant="text"
                @input="getDays((page = 1))"
              >
              </v-text-field>
            </div>
          </div>
        </div>
        <!-- ######## End Header Of Data Table Server ####### -->
        <v-data-table-server
          :loading="loading"
          :headers="selectedHeaders"
          :items="tableList"
          v-model:sort-by="sortBy"
          fixed-footer
          density="compact"
          class="elevation-1 rounded"
          @update:options="getDays((page = 1))"
        >
          <!-- <template v-slot:column.checkbox="{ column }">
            <v-checkbox hide-details density="compact"> </v-checkbox>
          </template> -->
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
                </v-checkbox>
                <span v-if="key == 'is_holiday'">
                  <v-icon v-if="val == true" color="success">mdi-check-circle</v-icon>
                  <v-icon v-if="val == false" color="error">mdi-close-circle</v-icon>
                </span>
                <span v-else> {{ val }}</span>
                <span v-if="key === 'actions'">
                  <v-btn
                    variant="text"
                    class="me-1 v-btn-icon"
                    size="large"
                    v-if="
                      CheckGetPermission('summer_center.change_daysummer', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="editDay(item.raw)"
                  >
                    <v-icon color="warning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    variant="text"
                    size="small"
                    class="v-btn-icon"
                    @click="(day_del_id = item.raw.id), (del_dialog = true)"
                    v-if="
                      CheckGetPermission('summer_center.delete_daysummer', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </template>

          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </v-card-text>

    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("globals.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("globals.confirm-delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="info" @click="(del_dialog = false), (day_del_id = undefined)">{{
            $t("globals.cancel")
          }}</v-btn>
          <v-btn :loading="del_loading" color="red" @click="deleteDay">{{
            $t("globals.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
  <alert-protected-records
    v-model="protected_records"
    :data_message="data_message"
  ></alert-protected-records>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      rules: {
        name: [
          (value) => {
            if (value == null || value == undefined)
              return this.$t("globals.required_field");
            if (value.length > 20) {
              return `${this.$t("globals.max_characters")} 20`;
            }
            return true;
          },
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      loading: false,
      protected_records: false,
      data_message: [],
      del_loading: false,
      day: {
        name: undefined,
        is_holiday: false,
        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      days: [],
      is_update: false,
      del_dialog: false,
      day_del_id: undefined,
      process_alert: undefined,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      headers: [
        { title: this.$t("day.day"), key: "name" },
        { title: this.$t("day.day_off"), key: "is_holiday" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ],
      selectedHead: ["name", "is_holiday", "note", this.checkrole(['sys_admin', 'gen_admin']) ? "actions":null,],
      txt_search: undefined,
      perPage: 10,
      length: 0,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      page: 1,
      totalItems: null,
      pagination: {},
      sortBy: [
        {
          key: "name",
          order: "asc",
        },
      ],
    };
  },
  async created() {
    await this.$store.commit("updateLoadingValue", true);
    if(this.checkrole(['sys_admin', 'gen_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })
    this.$store.commit("updateLoadingValue", false);
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },

    tableList() {
      let list = [];
      this.days.forEach((element) => {
        list.push({
          name: element.name,
          is_holiday: element.is_holiday,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
      this.day.id = undefined;
      this.updated_id = undefined;
      this.day.is_holiday = false;
    },
    editDay(data) {
      let day = this.days.find((item) => item.id == data.id);
      if (day != undefined) {
        for (const key in day) this.day[key] = day[key];
        this.day.data_entry = undefined;
        this.day.updated_by = undefined;
        this.is_update = true;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveDay() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.days.length < 7) {
          this.day.data_entry = this.user;
          this.loading = true;
          await this.axios
            .post(this.base_url + "api/summer/day-summer", this.day, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((response) => {
              this.loading = false;
              this.$emit("successAlert", this.$t("globals.data_added"));
              this.resetForm();
              this.getDays();
            })
            .catch((error) => {
              this.loading = false;

              if (error.response && error.response.data == "overload") {
                this.$emit("errorAlert", this.$t("day.day_overload"));
              } else this.$emit("errorAlert", this.$t("globals.error_in_data"));

              if (error.response.data.name) {
                this.$emit("errorAlert", this.$t("globals.error_in_repeted_data"));
              } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            });
        }
        else {
        this.$emit("infoAlert", this.$t("day.day_overload"));
      }}
    },
    async updateDay() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.day.id != undefined) {
        this.day.updated_by = this.user;
        this.loading = true;
        await this.axios
          .put(`${this.base_url}api/summer/day-summer/${this.day.id}`, this.day, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.loading = false;
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.resetForm();
            this.getDays();
          })
          .catch((error) => {
            this.loading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },

    async deleteDay() {
      if (this.day_del_id != undefined) {
        this.del_loading = true;
        await this.axios
          .delete(`${this.base_url}api/summer/day-summer/${this.day_del_id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.del_loading = true;
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.getDays();
          })
          .catch((error) => {
            this.del_loading = false;
            if ((error.code = 418)) {
              this.protected_records = true;
              let error_messages = [];
              error.response.data.data.forEach((error) => {
                error_messages.push(
                  this.$t(`models.${error.model}`) + ` = ${error.count}`
                );
              });
              this.data_message = error_messages;
            } else if (
              error.response &&
              error.response.data &&
              error.response.data == "current"
            )
              this.$emit("errorAlert", this.$t("globals.errpr_current"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.del_dialog = false;
        this.day_del_id = undefined;
      }
    },
    async getDays(page = 1) {
      await this.axios
        .get(this.base_url + "api/summer/day-summer", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            search: this.txt_search,
            page: page,
            page_size: this.perPage,
            sort_by: this.order_data,
          },
        })
        .then((response) => {
          if (response.data) {
            this.days = response.data.results;
            this.pagination = response.data.pagination;
            this.length = response.data.pagination.num_pages;
            this.totalItems = response.data.pagination.count;
          }
        });
    },
  },

  watch: {
    perPage() {
      this.getDays();
    },
    page() {
      this.getDays(this.page);
    },
  },
};
</script>
