<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between ">
        <div class="d-flex" style="flex: 10">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                <v-icon style="color: #5e7e96" class="me-2"
                  >mdi-eye-settings-outline</v-icon
                >
                <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                  color="indigo"
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-text-field
            v-model="txt_search"
            @input="getParent((page = 1))"
            style="border-radius: 30px; border: 1px solid #ccc; color: black; height: 30px"
            class="search w-100 px-2"
            hide-details
            append-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('globals.search-here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
        <div class="d-flex justify-end ps-2">
          <!-- <v-btn class="mx-1" size="small" @click="exportToExcel">
            <v-icon size="large">mdi-file-excel</v-icon>
          </v-btn>
          <v-btn class="mx-1" size="small" @click="exportToPDF">
            <v-icon size="large">mdi-file-pdf</v-icon>
          </v-btn> -->
          <VBtn class="bg-success" @click="$router.push({ name: 'parent' })" size="small">
            <span class="text-white">{{ $t("globals.create") }}</span>
            <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
          </VBtn>
          
        </div>
      </div>
    <v-progress-linear
      color="indigo"
      class="my-1"
      :indeterminate="loading"
      height="2px"
      :reverse="$i18n.locale == 'ar'"
    >
    </v-progress-linear>
    <v-data-table-server
      :headers="selectedHeaders"
      :items="tableList"
      id="dataTable"
      density="compact"
      class="elevation-1"
      hide-default-header
      v-model:sort-by="sortBy"
      @update:options="getParent((page = 1))"
    >
      <template v-slot:header="{ props }">
        <th v-for="head in props.headers" :key="head">
          {{ head.text }}
        </th>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td v-for="(val, key) in item.columns" :key="key">
            <span v-if="key !== 'identity_image' && key !== 'actions'">{{ val }}</span>
            <span v-if="key == 'identity_image'">
              <button @click="showImage(val)" v-if="val">
                <v-avatar
                  :image="
                    val != null && val.search(base_url) != -1 ? val : base_url + val
                  "
                  class=""
                  size="small"
                ></v-avatar>
              </button>
              <span v-else>
                {{ this.$t("summer.not-found-image") }}
              </span>
            </span>
            <span v-if="key === 'actions'">
                  <v-btn
                      variant="text"
                      class="me-1 v-btn-icon"
                      size="small"
                      @click="parentEdit(item.raw.id)"
                      v-if="CheckGetPermission('summer_center.change_parentsummer',['sys_admin','summer_admin','user'])"
                    >
                      <v-icon color="warning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                      variant="text"
                      size="small"
                      class="v-btn-icon"
                      @click="(parent_del_id = item.raw.id), (del_dialog = true)"
                      v-if="CheckGetPermission('summer_center.delete_parentsummer',['sys_admin','summer_admin','user'])"

                    >
                      <v-icon color="error">mdi-trash-can-outline</v-icon>
                    </v-btn>
                </span>
          </td>
        </tr>
      </template>

      <template v-slot:bottom>
        <div class="d-flex" id="pagination-bar">
          <v-pagination
            v-model="page"
            :length="length"
            density="compact"
            show-first-last-page
          >
          </v-pagination>
          <div class="d-flex">
            <v-select
              v-model="perPage"
              class="pa-0"
              :items="itemsPerPage"
              item-value="value"
              item-title="text"
              density="compact"
              hide-details
              variant="text"
            >
            </v-select>
            <span class="mt-2 px-2"
              >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [ {{ totalItems }} ]
              {{ $t("globals.item") }}</span
            >
          </div>
        </div>
      </template>
    </v-data-table-server>
    </v-card-text>
    <v-dialog v-model="del_dialog" transition="dialog-top-transition" width="auto">
      <v-card>
        <v-card-title>
          {{ $t("globals.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("globals.confirm-delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (parent_del_id = undefined)"
            >{{ $t("globals.cancel") }}</v-btn
          >
          <v-btn color="red" @click="deleteParent" :loading="DataLoading">{{
            $t("globals.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
  <VDialog v-model="showImg_dialog" max-width="400px" max-height="400px">
    <VCard align="center">
      <VCardTitle>
        <h1 class="text-h6">{{ dialog_title }}</h1>
      </VCardTitle>
      <VCardItem align="center">
        <VImg :src="dialog_image" max-height="300px"></VImg>
      </VCardItem>
      <VCardText>
        <tr v-for="(item, index) in dialog_text" :key="index">
          <td class="text-grey-darken-1 font-weight-bold">{{ item.type }} :</td>
          <td>{{ item.contact }}</td>
        </tr>
      </VCardText>
      <VCardActions>
        <VBtn class="bg-info" @click="showImg_dialog = false">{{
          $t("globals.ok")
        }}</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <alert-protected-records
    v-model="protected_records"
    :data_message="data_message"
  ></alert-protected-records>
</template>

<script>
import ExcelJS from 'exceljs'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs=pdfFonts.pdfMake.vfs
import { saveAs } from 'file-saver'
import { mapState, mapActions } from "vuex";
export default {
  name: "ParentsList",
  data() {
    return {
      DataLoading: false,
      protected_records: false,
      data_message: [],
      selectedParent: "",
      overlay: false,
      selectedHead: [
        "name",
        "identity_type",
        "identity_number",
        "identity_image",
        "job",
        "address",
        "phone_number",
        "home_number",
        "actions",
      ],
      selectItems: "",
      txt_search: "",
      parent_del_id: undefined,
      del_dialog: false,
      showImg_dialog: false,
      dialog_image: undefined,
      dialog_title: undefined,
      dialog_text: undefined,
      alert: false,
      totalItems: null,
      alertTitle: "",
      alertMessage: "",
      alertIcon: "",
      sortBy: [{ key: "id", order: "asc" }],
      perPage: 10,
      length: 0,
      imageRender: "",
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 100000, text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      parents: [],
      loading: false,
    };
  },
  components: {},
  computed: {
    ...mapState({
      identify_type: (state) => state.summer_globals.identify_type,
    }),
    role(){
      return localStorage.getItem('role')
    },
    headers() {
      return [
        { title: this.$t("school.parent_name"), key: "name" },
        { title: this.$t("school.parent_id"), key: "identity_type" },
        { title: this.$t("school.parent_id_no"), key: "identity_number" },
        {
          title: this.$t("school.parent_id_image"),
          key: "identity_image",
          sortable: false,
        },
        { title: this.$t("school.parent_job"), key: "job" },
        { title: this.$t("school.parent_address"), key: "address" },
        { title: this.$t("school.parent_phone"), key: "phone_number" },
        { title: this.$t("school.parent_landline"), key: "home_number" },
        { title: this.$t("school.notes"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ];
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      let list = [];
      this.parents.forEach((element) => {
        let identity = this.identify_type.find(
          (item) => item.id == element.identity_type
        );
        if (identity != undefined) identity = identity.name;
        list.push({
          name: element.name,
          identity_type: identity,
          identity_number: element.identity_number,
          identity_image: element.identity_image,
          job: element.job,
          address: element.address,
          phone_number: element.phone_number,
          home_number: element.home_number,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      // await this.getParent();
      await this.getIdentifyType();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getIdentifyType: "summer_globals/getIdentifyType",
    }),
  
    parentEdit(id) {
      this.$router.push(`parent/${id}/edit/`);
    },
    selectImage(e) {
      this.parent.image = e;
    },
    showImage(value) {
      this.dialog_text = [];
      if (value != null && value.search(this.base_url) != -1) this.dialog_image = value;
      else this.dialog_image = this.base_url + value;
      this.dialog_title = this.$t("school.parent_id_image");
      this.showImg_dialog = true;
    },
    async getParent(page = 1) {
      this.loading = true;
      return await this.axios(`${this.base_url}/api/summer/parent/`, {
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.parents = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.itemsPerPage[4].value = response.data.pagination.count;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    async deleteParent() {
      if (this.parent_del_id != undefined) {
        this.DataLoading = true;
        let result = await this.axios
          .delete(`${this.base_url}/api/summer/parent/${this.parent_del_id}/`, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.getParent();
            this.DataLoading = false;
          })
          .catch((error) => {
            this.DataLoading = false;
            if (error.message.includes(418)) {
              this.protected_records = true;
              let error_messages = [];
              error.response.data.data.forEach((error) => {
                error_messages.push(
                  this.$t(`models.${error.model}`) + ` = ${error.count}`
                );
              });
              this.data_message = error_messages;
            } else {
              this.$emit("errorAlert", this.$t("alert.failure.delete"));
            }
          });
        this.del_dialog = false;
        this.parent_del_id = undefined;
      }
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getParent();
    },
    page() {
      this.getParent(this.page);
    },
  },
};
</script>
<style scoped>
#dataTable span {
  font-size: 12px;
}
</style>
