<template>
  <v-card >
    <v-card flat v-if="CheckGetPermission('globaltable.add_yearofstudy', ['sys_admin', 'gen_admin'])">
      <v-card-text class="pb-0">
        <v-row class="mt-4" >
          <v-col cols="12" md="10" lg="10">
            <v-form ref="form" @submit.prevent="saveAcademicYear" >
              <v-row >
                <v-col cols="12" md="4">
                  <v-text-field
                    color="indigo"
                    v-model="academicYear.year"
                    :placeholder="$t('summer.year.placeholder.year-h')"
                    counter="5"
                    autofocus
                    prepend-inner-icon="mdi-calendar-range"
                    :label="$t('year.name-h')"
                    :rules="rules.year"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    color="indigo"
                    v-model="academicYear.year_m"
                    clearable
                    counter="5"
                    :placeholder="$t('summer.year.placeholder.year-m')"
                    density="compact"
                    prepend-inner-icon="mdi-calendar-range"
                    :label="$t('year.name-m')"
                    :rules="rules.year_m"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox
                    :disabled="update && academicYear.curentyears"
                    v-model="academicYear.curentyear"
                    :label="$t('year.current-year')"
                    color="indigo"
                    @change="checkMustBeTrue"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    color="indigo"
                    v-model="academicYear.note"
                    hide-details="auto"
                    density="compact"
                    counter="250"
                    rows="2"
                    clearable
                    prepend-inner-icon="mdi-note-outline"
                    :label="$t('globals.note')"
                    :rules="rules.note"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-3 mt-3" >
        <v-btn
          v-if="
            !update &&
            CheckGetPermission('globaltable.add_yearofstudy', [
              'sys_admin',
              'gen_admin',
            ])
          "
          @click="saveAcademicYear"
          class="bg-indigo"
          size="small"
          :loading="saveDataLoading"
        >
          <span class="text-white">
            {{ $t("globals.add") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
        <v-btn
          v-if="update"
          @click="updateAcademicYear(selectedAcademicYear)"
          class="bg-success"
          size="small"
          :loading="editDataLoading"
        >
          <span class="text-white">
            {{ $t("globals.edit") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
        <v-btn @click="resetForm" class="mx-3 bg-ccc" size="small">
          <span>
            {{ $t("globals.clear") }}
          </span>
          <v-icon icon="mdi-broom" color="golden" end></v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-divider></v-divider>
    <!-- ##########    data Table Server ########### -->
    <v-card flat v-if="CheckGetPermission('globaltable.view_yearofstudy')">
      <v-card-text>
        <!-- ######## Header Of Data Table Server ####### -->
        <div class="d-flex justify-space-between mb-2">
          <div class="d-flex flex-fill">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                  <v-icon style="color: #5e7e96" class="me-2"
                    >mdi-eye-settings-outline</v-icon
                  >
                  <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="header in headers"
                    :key="header.key"
                    :label="header.title"
                    :value="header.key"
                    color="indigo"
                    v-model="selectedHead"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <div class="w-100">
              <v-text-field
                color="indigo"
                v-model="txt_search"
                style="
                  border-radius: 30px;
                  border: 1px solid #ccc;
                  color: black;
                  height: 30px;
                  width: 90%;
                "
                class="search"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                :placeholder="$t('globals.search-here')"
                density="compact"
                variant="text"
                @input="getAcademicYears((page = 1))"
              >
              </v-text-field>
            </div>
          </div>
        </div>
        <!-- ######## End Header Of Data Table Server ####### -->
        <v-data-table-server
          :headers="selectedHeaders"
          :items="academicYears"
          item-value="name_en"
          item-key="id"
          fixed-footer
          :search="txt_search"
          density="compact"
          class="elevation-1 rounded"
          :loading="loading"
          v-model:sort-by="sortBy"
          @update:options="getAcademicYears((page = 1))"
        >
          <template v-slot:column.checkbox="{ column }">
            <v-checkbox hide-details density="compact"> </v-checkbox>
          </template>
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
                </v-checkbox>
                <span v-else-if="key == 'curentyear'">
                  <v-icon v-if="val == true" color="success">mdi-check-circle</v-icon>
                  <v-icon v-if="val == false" color="error">mdi-close-circle</v-icon>
                </span>
                <span v-else> {{ val }}</span>
                <span v-if="key === 'actions'">
                  <v-btn
                    variant="text"
                    class="me-1 v-btn-icon"
                    size="large"
                    v-if="
                      CheckGetPermission('globaltable.change_yearofstudy', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="selectAcademicYear(item.raw)"
                  >
                    <v-icon color="warning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    variant="text"
                    size="small"
                    class="v-btn-icon"
                    v-if="
                      CheckGetPermission('globaltable.delete_yearofstudy', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="(selectedAcademicYear = item.raw.id), (dialog = true)"
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </v-card-text>

    </v-card>
    <!-- ############### Dialog Delete  ################### -->

    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
  <v-dialog
    color="indigo"
    v-model="dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        {{ $t("globals.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("globals.confirm-delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="info"
          @click="(dialog = false), (selectedAcademicYear = undefined)"
          >{{ $t("globals.cancel") }}</v-btn
        >
        <v-btn
          color="red"
          @click="deleteAcademicYear(selectedAcademicYear)"
          :loading="deleteDataLoading"
          >{{ $t("globals.delete") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <alert-protected-records
    v-model="protected_records"
    :data_message="data_message"
  ></alert-protected-records>
</template>

<script>
import Datetime from "vue3-datetime-js";
import { mapState, mapActions } from "vuex";
export default {
  name: "AcademicYear",
  components: {
    Datetime,
  },
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
      if(this.checkrole(['sys_admin', 'gen_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);  
    }
  },
  data() {
    return {
      rules: {
        year_m: [
          (value) => !!value || this.$t('globals.required_field'),
          (value) => (value && value?.length<=5) || `${this.$t('globals.biggest_number_of_character')} 99999`,
          (value) => (value && /^[0-9]+$/.test(value)) || this.$t('globals.must_be_numbers')
        ],
        year: [
          (value) => !!value || this.$t('globals.required_field'),
          (value) => (value && value?.length<=5) || `${this.$t('globals.biggest_number_of_character')} 99999`,
          (value) => (value && /^[0-9]+$/.test(value)) || this.$t('globals.must_be_numbers')
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      protected_records: false,
      data_message: [],
      academicYears: [],
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      ok: false,
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,
      alert: false,

      user: null,
      current_year: null,
      alert: {
        color: undefined,
        icon: undefined,
        title: undefined,
        message: undefined,
      },
      selectedHead: ["year", "year_m", "curentyear",this.checkrole(['sys_admin', 'gen_admin']) ? "actions":null,],
      selectedAcademicYear: null,
      academicYear: {},

      must_be_true: [(value) => value == true || this.$t("errors.must-be-ok")],

      headers: [
        // { title: "", key: "checkbox" },
        { title: this.$t("year.name-h"), key: "year" },
        { title: this.$t("year.name-m"), key: "year_m" },
        { title: this.$t("year.current-year"), key: "curentyear" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      sortBy: [
        {
          key: "year",
          order: "asc",
        },
      ],
      txt_search: null,
    };
  },
  computed: {
    years() {
      return this.academicYears.map((item) => item.year.split("/")[0]);
    },
    years_m() {
      return this.academicYears.map((item) => item.year_m.split("/")[0]);
    },

    ...mapState({
      // academicYears: (state) => state.school.academic_years,
    }),
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.academicYears) {
        return JSON.parse(JSON.stringify(this.countries));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
  },

  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      // getAcademicYears: "school/getAcademicYears",
    }),
    async getCurrentYear() {
      await this.axios
        .get(`${this.base_url}/academic-years/current-year/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          localStorage.setItem("current_year", res.data.id);
        });
    },
    checkMustBeTrue() {
      let year = this.academicYears.find((item) => item.curentyear == true);
      if (year) {
        if (
          this.update &&
          this.selectedAcademicYear == year.id &&
          this.academicYear.curentyear == false
        ) {
          this.academicYear.curentyear = true;
          this.$emit("errorAlert", this.$t("alert.attention.current-year"));
        }
      }
    },
    async submitDelete() {
      const { valid } = await this.$refs.delete.validate();
      if (valid) {
        this.deleteAcademicYear(this.selectedAcademicYear);
      }
    },
    selectAcademicYear(item) {
      this.selectedAcademicYear = item.id;
      this.academicYear = Object.assign({}, item);
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      this.update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveAcademicYear() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.academicYear.data_entry = this.user;
        this.axios
          .post(`${this.base_url}academic-years/`, this.academicYear, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("alert.success.insert"));
            this.resetForm();
            this.getAcademicYears();
            this.getCurrentYear();
            this.saveDataLoading = false;
          })
          .catch((error) => {
            if (error.message.includes("Network Error")) {
              this.$emit("errorAlert", this.$t("alert.failure.connect"));
            } else {
              this.$emit("errorAlert", this.$t("alert.failure.insert"));
            }
            if (error.response.data.year_m) {
              this.$emit("warningAlert", this.$t("summer.already-year"));
            }
            if (error.response.data.year) {
              this.$emit("warningAlert", this.$t("summer.already-year-m"));
            }
            this.saveDataLoading = false;
          });
      }
    },
    resetForm() {
      this.update = false;
      this.academicYear.curentyear = false;
      this.$refs.form.reset();
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
    },
    async updateAcademicYear(id) {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.editDataLoading = true;
        this.academicYear.updated_by = this.user;
        delete this.academicYear.id;
        await this.axios
          .put(`${this.base_url}academic-years/${id}/`, this.academicYear, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then((response) => {
            this.editDataLoading = false;
            this.update = false;
            this.$emit("successAlert", this.$t("alert.success.update"));
            this.getAcademicYears();
            this.resetForm();
            this.getCurrentYear();
          })
          .catch((error) => {
            this.editDataLoading = false;
            if (error.response.data.year) {
              this.$emit("warningAlert", this.$t("summer.already-year"));
            } else if (error.response.data.year_m) {
              this.$emit("warningAlert", this.$t("summer.already-year-m"));
            } else this.$emit("errorAlert", this.$t("alert.failure.update"));
          });
      }
    },
    deleteAcademicYear(id) {
      this.deleteDataLoading = true;
      this.axios
        .delete(`${this.base_url}academic-years/${id}/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.deleteDataLoading = false;
          this.dialog = false;
          this.$emit("successAlert", this.$t("globals.data_deleted"));
          this.getAcademicYears();
        })
        .catch((error) => {
          this.deleteDataLoading = false;
          this.dialog = false;
          if (error.message.includes(418)) {
            this.protected_records = true;
            let error_messages = [];
            error.response.data.data.forEach((error) => {
              error_messages.push(this.$t(`models.${error.model}`) + ` = ${error.count}`);
            });
            this.data_message = error_messages;
          } else {
            this.$emit("errorAlert", this.$t("alert.failure.delete"));
          }
        });
    },
    async getAcademicYears(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}academic-years-for-data-table/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.academicYears = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  watch: {
    perPage() {
      this.getAcademicYears();
    },
    page() {
      this.getAcademicYears(this.page);
    },
    dialog(val){
      if(val==false){
        this.deleteDataLoading=false
      }
    }
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>
