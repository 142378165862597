<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.TotalStudentsMalesFemalesByGovernorate") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
         <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>

      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.TotalStudents") }}
              {{ $t("reportpublic.InGovernorates") }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4 v-if="results.totals">
                {{ results.totals.total }}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="px-2 pt-1 my-4" style="height: 350px">
            <chart :option="totalOfStudentsMF" autoresize />
          </v-card>
        </v-col>
        <v-col cols="12">
          <table class="report-table table text-center rounded mb-5">
            <thead class="">
              <tr>
                <th>{{ $t("reportpublic.Governorate") }}</th>
                <th>{{ $t("reportpublic.MaleStudents") }}</th>
                <th>{{ $t("reportpublic.FemaleStudents") }}</th>
                <th>{{ $t("reportpublic.Students") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, key) in results.data" :key="key">
                <td>
                  {{
                    this.$i18n.locale == "ar"
                      ? data.governorate_ar
                      : data.governorate_en
                  }}
                </td>
                <td>{{ data.student_number_male }}</td>
                <td>{{ data.student_number_fmale }}</td>
                <td>{{ data.student_number }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr v-if="results.totals">
                <th>{{ $t("reportpublic.GrandTotal") }}</th>
                <th>{{ results.totals.total_male }}</th>
                <th>{{ results.totals.total_fmale }}</th>
                <th>{{ results.totals.total }}</th>
              </tr>
            </tfoot>
          </table>
        </v-col>
      </v-row>
    </v-card-item>
  </v-card>
</template>

<script>
import axios from "axios";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }

    this.totalOfStudentsMF = {
      color: ["#ff5252", "#445069", "#536dfe", "#FFD95A"],
      title: {
        text: this.$t("reportpublic.TotalStudentsMalesFemalesByGovernorate"),
        right: "center",
        textStyle: {
          fontFamily: "Almarai",
          fontSize: 12,
        },
        subtextStyle: {
          fontFamily: "Almarai",
          fontSize: 10,
        },
      },
      dataset: [
        {
          dimensions: [
            "name",
            this.$t("reportpublic.FemaleStudents"),
            this.$t("reportpublic.MaleStudents"),
            "",
          ],
          source: this.results.data.map((item) => [
            this.$i18n.locale == "ar"
              ? item.governorate_ar
              : item.governorate_en,
            item.student_number_fmale,
            item.student_number_male,
          ]),
        },
        {
          transform: {
            type: "sort",
            config: { dimension: "constant", order: "desc" },
          },
        },
      ],
      xAxis: {
        type: "category",
        axisLabel: {
          interval: 0,
          rotate: 60,
        },
      },
      yAxis: {
        type: "value",
        position: this.$i18n.locale == "ar" ? "right" : "",
        boundaryGap: [0, 0.2],
      },
      series: [
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            offset: [5, 7],
          },
        },
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            offset: [5, 7],
          },
        },
      ],
      legend: {
        bottom: "bottom",
        left: "center",
        itemHeight: 10,
        itemWidth: 13,
        borderWidth: 1,
        borderColor: "black",
      },
      transform: {
        type: "sort",
        config: { dimension: "constant", order: "desc" },
      },

      toolbox: {
        feature: {
          saveAsImage: {
            title: this.$t("dashboard.save-as-png"),
          },
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (params) {
          let tooltipText = params[0].axisValue + "<br/>";
          params.forEach((item, index) => {
            tooltipText +=
              item.value[index + 1] +
              "&nbsp;&nbsp;&nbsp;" +
              item.marker +
              " " +
              item.seriesName +
              "<br/>";
          });

          return tooltipText;
        },
      },
      grid: {
        bottom: "30%",
        height: "50%",
        top: "20%",
      },
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(`${this.base_url}public-reports/summer-student-detailed-summary`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.results = response.data.results;
        })
        .catch((erroe) => {
          console.error("خطأ في السيرفر", erroe);
        });
    },

    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      results: {},
      totalOfStudentsMF: {},
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  components: {
    ReportHeader,
  },
};
</script>
