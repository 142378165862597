<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.SchoolStatisticsTypeSchoolMFFirstReport") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="pl-0">
            <v-sheet class="px-2 chart-label-sheet" elevation="0" rounded="0">
  {{ $t("reportpublic.SchoolStatisticsTypeSchoolMFFirstReport") }}
              ( {{
                this.$i18n.locale == "ar"
                  ? results.gov_name_ar
                  : results.gov_name_en
              }}
              )
              
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{ results.summer_count }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-container>
        <v-row>
          <table class="report-table text-center">
            <thead>
              <tr>
                <th>{{ $t("reportpublic.directorate") }}</th>
                <th>{{ $t("reportpublic.MalesSchools") }}</th>
                <th>{{ $t("reportpublic.FemalesSchools") }}</th>
                <th>{{ $t("reportpublic.GenerlSchools") }}</th>
                <th>{{ $t("reportpublic.TotalSchools") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in results.directorates_data"
                :key="index"
              >
                <td>
                  {{
                    this.$i18n.locale == "ar"
                      ? data.directorate_name_ar
                      : data.directorate_name_en
                  }}
                </td>
                <td>{{ data.males_summers_counts }}</td>
                <td>{{ data.females_summers_counts }}</td>
                <td>{{ data.general_summers_counts }}</td>
                <td>{{ data.summers_counts }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th class="right-footer rounded-be">
                  {{ $t("reportpublic.GrandTotal") }}
                </th>
                <th
                  v-for="(data, index) in results.totals"
                  :key="index"
                  class="rounded-bs"
                >
                  {{ data }}
                </th>
              </tr>
            </tfoot>
          </table>
        </v-row>
      </v-container>
    </v-card-item>
  </v-card>
</template>

<script>
import axios from "axios";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async fetchData() {
      await axios
        .get(
          `${this.base_url}public-reports/governorate-reports/summer-statistics-by-student-type`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.results = response.data.results;
        })
        .catch((error) => {
            
        });
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      results: [],
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  components: {
    ReportHeader,
  },
};
</script>
