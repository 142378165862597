<template>
  <v-card >
    <v-card flat v-if="CheckGetPermission('summer_center.change_division',['sys_admin','gen_admin'])">
      <v-card-text class="pb-0" >
        <v-form ref="form" @submit.prevent="saveDivision">
          <v-row class="mt-2">
            <v-col cols="12" md="5">
              <v-text-field
                v-model="division.name"
                counter="30"
                autofocus
                :rules="uniqueDivision"
                prepend-inner-icon="mdi-home-outline"
                :label="$t('division.name')"
                :error-messages="v$.division.name.$errors.map((e) => e.$message)"
              />
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                v-model="division.symbol"
                counter="3"
                :rules="uniqueSymbol"
                prepend-inner-icon="mdi-shape-outline"
                :label="$t('division.symbol')"
                :error-messages="v$.division.symbol.$errors.map((e) => e.$message)"
              />
            </v-col>
            <v-col cols="12" md="10">
              <v-textarea
                v-model="division.note"
                hide-details="auto"
                counter="250"
                rows="2"
                prepend-inner-icon="mdi-note-outline"
                :label="$t('globals.note')"
                :error-messages="v$.division.note.$errors.map((e) => e.$message)"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="px-3">
        <v-btn
          v-if="!update && CheckGetPermission('summer_center.add_division',['sys_admin','gen_admin'])"
          @click="saveDivision"
          class="bg-indigo"
          size="small"
          :loading="saveDataLoading"
        >
          <span class="text-white">
            {{ $t("globals.add") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
        <v-btn
          v-if="update && CheckGetPermission('summer_center.change_division',['sys_admin','gen_admin'])"
          @click="updatedivision(selectedDivision)"
          class="bg-success"
          size="small"
          :loading="editDataLoading"
        >
          <span class="text-white">
            {{ $t("globals.edit") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
        <v-btn @click="cleandivisionForm" class="mx-3 bg-ccc" size="small">
          <span>
            {{ $t("globals.clear") }}
          </span>
          <v-icon icon="mdi-broom" color="golden" end></v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-divider/>
    <!-- ##########    data Table Server ########### -->
    <v-card  v-if="CheckGetPermission('summer_center.view_division')">
      <v-card-text>
        <!-- ######## Header Of Data Table Server ####### -->
        <div class="d-flex justify-space-between mb-2">
          <div class="d-flex flex-fill">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                  <v-icon style="color: #5e7e96" class="me-2"
                    >mdi-eye-settings-outline</v-icon
                  >
                  <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="header in headers"
                    :key="header.key"
                    :label="header.title"
                    :value="header.key"
                    color="indigo"
                    v-model="selectedHead"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <div class="w-100 px-2">
              <v-text-field
                color="indigo"
                v-model="txt_search"
                style="
                  border-radius: 30px;
                  border: 1px solid #ccc;
                  color: black;
                  height: 30px;
                "
                class="search"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                :placeholder="$t('globals.search-here')"
                density="compact"
                variant="text"
                @input="getDivisions((page = 1))"
              >
              </v-text-field>
            </div>
          </div>
        </div>
        <!-- ######## End Header Of Data Table Server ####### -->
        <v-data-table-server
          :headers="selectedHeaders"
          :items="divisions"
          item-value="symbol"
          item-key="id"
          fixed-footer
          :search="txt_search"
          density="compact"
          class="elevation-1 rounded"
          :loading="loading"
          v-model:sort-by="sortBy"
          @update:options="getDivisions((page = 1))"
        >
          <template v-slot:header="{ props }">
            <th v-for="head in props.headers" :key="head" style="border: 1px solid black">
              {{ head.text }}
            </th>
          </template>
          <template v-slot:column.checkbox="{ column }">
            <v-checkbox hide-details density="compact"> </v-checkbox>
          </template>
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
                </v-checkbox>

                <span v-else> {{ val }}</span>
                <span v-if="key === 'actions'">
                  <v-btn
                    variant="text"
                    class="me-1 v-btn-icon"
                    size="small"
                    @click="selectDivision(item.raw)"
                    v-if="CheckGetPermission('summer_center.change_division',['sys_admin','gen_admin'])"
                  >
                    <v-icon color="warning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    variant="text"
                    size="small"
                    class="v-btn-icon"
                    @click="(selectedDivision = item.raw.id), (dialog = true)"
                    v-if="CheckGetPermission('summer_center.delete_division',['sys_admin','gen_admin'])"
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </v-card-text>
    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <VDialog       
      v-model="dialog"
      scrollable max-width="350px">
    <VCard>
      <VCardTitle class="text-blue">
        {{ $t("globals.confirmation") }}
      </VCardTitle>
      <VCardText class="text-blue-grey-darken-4">
        {{ $t("globals.confirm_delete") }}
      </VCardText>
      <VCardActions>
        <VBtn
          class="font-weight-bold me-2"
          color="error"
          @click="deleteClass(selectedDivision)"
          :loading="deleteDataLoading"
        >
          {{ $t("globals.delete") }}
        </VBtn>
        <VBtn
          class="bg-white font-weight-bold"
          color="blue"
          @click="(dialog = false), (selectedDivision = '')"
        >
          {{ $t("globals.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
  <alert-protected-records
    v-model="protected_records"
    :data_message="data_message"
  ></alert-protected-records>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import {
  email,
  required,
  helpers,
  maxLength,
  url,
  alpha,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
import ImageInput from "@/components/Libraries/ImageInput.vue";

const englishOnly = helpers.regex(/^[a-zA-Z\-'\s]+$/);
const arabicOnly = helpers.regex(
  /^[\u0600-\u06ff\u0750-\u077f-\u080a-\u08ff\ufb50-\ufdff\ufe70-\ufeff\s]*$/
);

const nameRegular = helpers.regex(/^[\u0621-\u064A\u0660-\u0669a-zA-Z\s]*$/);
const dateValidation = function (value) {
  const date = new Date().toJSON().slice(0, 10);
  return value && value > date ? true : false;
};

export default {
  name: "Adddivision",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      // await this.getDivisions();
      if(this.checkrole(['sys_admin', 'gen_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      protected_records: false,
      divisions: [],
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      v$: useValidate(),
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      sortBy: [
        {
          key: "name",
          order: "asc",
        },
      ],
      name: "",
      symbol: "",
      txt_search: null,
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,
      selectedHead: ["name", "symbol", this.checkrole(['sys_admin', 'gen_admin']) ? "actions":null,],
      selectedDivision: null,
      division: {
        name: "",
        symbol: "",
        note: "",
        data_entry: null,
        updated_by: null,
      },
      defaultdivision: {
        name: "",
        symbol: "",
        note: "",
        data_entry: null,
        updated_by: null,
      },
      uniqueDivision: [
        (value) => {
          if (!this.update || this.name != this.division.name.trim())
            return !this.divisionNames.includes(value.trim())
              ? true
              : this.$t("errors.unique");
          return true;
        },
      ],
      uniqueSymbol: [
        (value) => {
          const list = this.symbols.map((item) => item.toLowerCase());
          if (!this.update || this.symbol != this.division.symbol.trim().toLowerCase())
            return !list.includes(value.trim().toLowerCase())
              ? true
              : this.$t("errors.unique");
          return true;
        },
      ],
      headers: [
        // { title: "", key: "checkbox" },
        { title: this.$t("division.name"), key: "name" },
        { title: this.$t("division.symbol"), key: "symbol" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ],
    };
  },
  computed: {
    divisionNames() {
      return this.divisions.map((item) => item.name);
    },
    symbols() {
      return this.divisions.map((item) => item.symbol);
    },
    ...mapState({
      // divisions: (state) => state.summer.divisions,
    }),
    tableList() {
      if (this.divisions) {
        return JSON.parse(JSON.stringify(this.divisions));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },

  validations() {
    return {
      division: {
        name: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(30)),
        },
        symbol: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(3)),
        },
        note: {
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(250)),
        },
      },
    };
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      // getDivisions: "summer/getDivisions",
    }),

    selectDivision(item) {
      this.selectedDivision = item.id;
      this.name = item.name.trim();
      this.symbol = item.symbol.trim().toLowerCase();
      delete item.id;
      this.division = Object.assign({}, item);
      this.update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    submitDivisionForm() {
      this.v$.$validate();
    },
    saveDivision() {
      this.submitDivisionForm();
      if (!this.v$.$error) {
        this.saveDataLoading = true;
        this.axios
          .post(`${this.base_url}api/summer/division-summer`, this.division, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.cleandivisionForm();
            this.getDivisions();
            this.saveDataLoading = false;
          })
          .catch((error) => {
            if (error.response.data.name)
              this.$emit("warningAlert", this.$t("summer.already-division"));
            else if (error.response.data.symbol)
              this.$emit("warningAlert", this.$t("summer.already-division"));
            else this.$emit("errorAlert", this.$t("alert.failure.insert"));
            this.saveDataLoading = false;
          });
      }
    },
    cleandivisionForm() {
      this.update = false;
      this.v$.division.$reset();
      this.$nextTick(() => {
        this.division = Object.assign({}, this.defaultdivision);
      });
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
    },
    async updatedivision(id) {
      this.submitDivisionForm();
      if (!this.v$.$error) {
        this.editDataLoading = true;
        await this.axios
          .put(`${this.base_url}api/summer/division-summer/${id}`, this.division, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.update = false;
            this.$emit("successAlert", this.$t("alert.success.update"));
            this.getDivisions();
            this.cleandivisionForm();
            this.editDataLoading = false;
          })
          .catch((error) => {
            if (error.response.data.name)
              this.$emit("warningAlert", this.$t("summer.already-division"));
            else if (error.response.data.symbol)
              this.$emit("warningAlert", this.$t("summer.already-division"));
            else this.$emit("errorAlert", this.$t("alert.failure.update"));
            this.editDataLoading = false;
          });
      }
    },
    deleteClass(id) {
      this.deleteDataLoading = true;

      this.axios
        .delete(`${this.base_url}api/summer/division-summer/${id}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.deleteDataLoading = false;
          this.dialog = false;

          this.$emit("successAlert", this.$t("alert.success.delete"));
          this.getDivisions();
        })
        .catch((error) => {
          this.deleteDataLoading = false;
          this.protected_records = true;
          this.dialog=false
          if ((error.code = 418)) {
            let error_messages = [];
            error.response.data.data.forEach((error) => {
              error_messages.push(this.$t(`models.${error.model}`) + ` = ${error.count}`);
            });
            this.data_message = error_messages;
          } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getDivisions(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/division-summer`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.divisions = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  watch: {
    perPage() {
      this.getDivisions();
    },
    page() {
      this.getDivisions(this.page);
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>
