<template>
  <!-- filter data  -->
  <v-card flat class="justify-center" id="lectures-report">
    <ReportHeader>
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">{{ $t("report.positives_negatives") }}</div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="my-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-start" style="flex: 1"></div>
        <!-- search box  -->

        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            density="compact"
            class="pa-0 "
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>
        </div>
      </div>

      <div id="myTable">
        <v-card  :loading="loading" v-if="items.length > 0" rounded="0" flat>
          <h3 class="text-grey-darken-2 mt-3 mb-3">{{ $t("globals.positive") }} :</h3>
          <table id="stable" class="table" style="width: 100%; border-collapse: collapse">
            <thead class="table-light">
              <th class="bg-blue-grey-lighten-4 pa-2">م</th>
              <th class="bg-blue-grey-lighten-4 pa-2">
                {{ $t("summer.calendar.week-name") }}
              </th>
              <th class="bg-blue-grey-lighten-4 pa-2">
                {{ $t("globals.address") }}
              </th>
              <th class="bg-blue-grey-lighten-4 ">
                {{ $t("summer.recommendations.implementation-officer") }}
              </th>
              <th class="bg-blue-grey-lighten-4">
                {{ $t("summer.initiative.note") }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(item, key) in items" :key="item">
                <td class="text-center border">
                  {{ key + 1 }}
                </td>
                <td class="text-center border">
                  {{ item.fk_week }}
                </td>
                <td class="text-center border">
                  {{ item.title }}
                </td>
                <td class="text-center border">
                  {{ item.responsible }}
                </td>
                <td class="text-center border">
                  {{ item.note }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card
          class="pt-2"
          :loading="loading"
          v-if="negatives.length > 0"
          rounded="0"
          flat
        >
          <h3 class="text-grey-darken-2 mt-3 mb-3">{{ $t("globals.negative") }} :</h3>
          <table id="stable" class="table" style="width: 100%; border-collapse: collapse">
            <thead class="table-light">
              <th class="bg-blue-grey-lighten-4">م</th>
              <th class="bg-blue-grey-lighten-4">
                {{ $t("summer.calendar.week-name") }}
              </th>
              <th class="bg-blue-grey-lighten-4">
                {{ $t("globals.address") }}
              </th>
              <th class="bg-blue-grey-lighten-4">
                {{ $t("summer.recommendations.solutions") }}
              </th>
              <th class="bg-blue-grey-lighten-4">
                {{ $t("summer.recommendations.implementation-officer") }}
              </th>
              <th class="bg-blue-grey-lighten-4">
                {{ $t("summer.initiative.note") }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(item, key) in negatives" :key="item">
                <td class="text-center border bg-blue-grey-lighten-4">
                  {{ key + 1 }}
                </td>
                <td class="text-center border">
                  {{ item.fk_week }}
                </td>
                <td class="text-center border">
                  {{ item.title }}
                </td>
                <td class="text-center border">
                  {{ item.solution }}
                </td>
                <td class="text-center border">
                  {{ item.responsible }}
                </td>
                <td class="text-center border">
                  {{ item.note }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import ReportHeader from "../../../components/SummerGlobals/ReportHeader";
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
      await this.getData();
      await this.getWeeks();
      await this.getYears();

      this.current_year = this.getYearName(localStorage.getItem("current_year"));
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      },
      current_year: "",
      v$: useValidate(),
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      exist: false,
      items: [],
      negatives: [],
      loading: false,
      txt_search: "",
      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      weeks: (state) => state.summer.weeks,
    }),

    ...mapGetters({}),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getWeeks: "summer/getWeeks",
    }),
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/positives-negatives`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            week: this.filter_data.fk_week,
          },
        })
        .then((response) => {
          ``;
          this.exist = true;

          this.items = response.data.positives;
          this.negatives = response.data.negatives;
          this.loading = false;
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  validations() {
    return {
      filter_data: {
        fk_week: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
}

#stable th {
  padding: 10px;
  border: 1px solid #649add;
  background-color: #fff;
}

#stable th:first-child {
 
  background-color: #cfd8dc;
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
  padding: 4px;
}
.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}

.inside-th-cells {
  border-left-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 0px !important;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>
