<template>
  <VCard>
    <v-card flat>
      <v-card-text class="pb-0">
        <VForm ref="form" @submit.prevent="saveSkill">
          <VRow class="mt-2"
            v-if="
              CheckGetPermission('summer_center.add_skill', ['sys_admin', 'gen_admin']) ||
              CheckGetPermission('summer_center.change_skill', ['sys_admin', 'gen_admin'])
            "
          >
            <VCol class="pt-0 px-3" xs="12" md="10" lg="10">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="skill.title"
                  :label="$t('summer.add-skill')"
                  persistent-hint
                  clearable
                  autofocus
                  counter="50"
                  density="compact"
                  prepend-inner-icon="mdi-star"
                  :rules="rules.subjectNameArRule"
                ></VTextField>
              </VCardItem>
            </VCol>

            <div class="w-100"></div>
            <VCol cols="12" class="pa-0 ma-0" xs="12" md="10" lg="10">
              <VCardItem class="px-3">
                <VTextarea
                  v-model="skill.note"
                  clearable
                  color="indigo"
                  :label="$t('summer.initiative.note')"
                  no-resize
                  counter="250"
                  density="compact"
                  rows="2"
                  :rules="rules.note"
                  prepend-inner-icon="mdi-note-outline"
                ></VTextarea>
              </VCardItem>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions
            class="px-3"
            v-if="
              CheckGetPermission('summer_center.add_skill', ['sys_admin', 'gen_admin']) ||
              CheckGetPermission('summer_center.change_skill', ['sys_admin', 'gen_admin'])
            "
          >
            <VBtn
              v-if="!is_update"
              :loading="loading"
              class="bg-indigo"
              @click="saveSkill()"
              size="small"
            >
              <span class="text-white">
                {{ $t("globals.add") }}
              </span>
              <VIcon icon="mdi-content-save" color="white" end></VIcon>
            </VBtn>
            <VBtn
              :loading="loading"
              v-if="is_update"
              class="bg-success"
              @click="updateSkill()"
              size="small"
            >
              <span class="text-white">
                {{ $t("globals.edit") }}
              </span>
              <VIcon icon="mdi-content-save" color="white" end></VIcon>
            </VBtn>
            <VBtn class="mx-3" size="small" @click="resetForm()">
              {{ $t("globals.clear") }}
              <VIcon icon="mdi-broom" color="golden" end></VIcon>
            </VBtn>
      </VCardActions>
    </v-card>
    <v-divider  />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card flat>
      <v-card-text>
        <div class="d-flex justify-space-between mb-2">
          <div class="d-flex flex-fill">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                  <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                  <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="header in headers"
                    :key="header.key"
                    :label="header.title"
                    :value="header.key"
                    color="indigo"
                    v-model="selectedHead"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <div style="width: 100%">
              <v-text-field
                color="indigo"
                v-model="txt_search"
                @input="getSkills((page = 1))"
                style="
                  border-radius: 30px;
                  border: 1px solid #ccc;
                  color: black;
                  height: 30px;
                  width: 100%;
                "
                class="search"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                :placeholder="$t('globals.search-here')"
                density="compact"
                variant="text"
              >
              </v-text-field>
            </div>
          </div>
        </div>
        <!-- ######## End Header Of Data Table Server ####### -->
        <v-data-table-server
          :loading="loading"
          :headers="selectedHeaders"
          :items="tableList"
          v-model:sort-by="sortBy"
          item-value="title"
          item-key="id"
          id="vDataTable"
          fixed-footer
          @update:options="getSkills((page = 1))"
          density="compact"
          class="elevation-1 rounded"
        >
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <span> {{ val }}</span>
                <span v-if="key === 'actions'">
                  <v-btn
                    variant="text"
                    class="me-1 v-btn-icon"
                    size="large"
                    v-if="
                      CheckGetPermission('summer_center.change_skill', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="editInitiative(item.raw)"
                  >
                    <v-icon color="warning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    variant="text"
                    size="small"
                    class="v-btn-icon"
                    v-if="
                      CheckGetPermission('summer_center.delete_skill', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="(dialogVisible = true), (this.delete_id = item.raw.id)"
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </v-card-text>
    </v-card>
  </VCard>
  <VDialog v-model="dialogVisible" scrollable max-width="350px">
    <VCard>
      <VCardTitle class="text-blue">
        {{ $t("globals.confirmation") }}
      </VCardTitle>
      <VCardText class="text-blue-grey-darken-4">
        {{ $t("globals.confirm_delete") }}
      </VCardText>
      <VCardActions>
        <VBtn
          :loading="del_loading"
          class="font-weight-bold me-2"
          color="error"
          @click.prevent="deleteInitiative"
        >
          {{ $t("globals.delete") }}
        </VBtn>
        <VBtn
          class="bg-white font-weight-bold"
          color="blue"
          @click.prevent="cancelDeleteItem"
        >
          {{ $t("globals.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
export default {
  data() {
    return {
      loading: false,
      del_loading: false,
      skillPhaseChoice: [
        { key: 1, name: "تأهيلي" },
        { key: 2, name: "أساسي" },
        { key: 3, name: "متوسط" },
        { key: 4, name: "عالي" },
      ],
      skills: [],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      skill: {},
      sortBy: [
        {
          key: "title",
          order: "asc",
        },
      ],
      rules: {
        subjectNameArRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (value && value.length <= 50) ||
            `${this.$t("globals.biggest_number_of_character")} 50`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        subjectNameEnRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[A-Za-z  0-9]+$/.test(value) ||
            this.$t("globals.must_be_letters_and_numbers_en"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        subjectPhaseRule: [(value) => !!value || this.$t("globals.required_field")],
        subjectRule: [(value) => !!value || this.$t("globals.required_field")],
        note: [
          (value) =>
           !value || value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      dialogVisible: false,

      v$: useValidate(),

      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      selectedHead: ["title", "note", this.checkrole(['sys_admin', 'gen_admin']) ? "actions":null],
      headers: [
        { title: this.$t("summer.skill-name"), key: "title" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ],
      txt_search: undefined,
    };
  },
  computed: {
    ...mapGetters({}),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.skills) {
        return JSON.parse(JSON.stringify(this.skills));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      // await this.getSkills();
      if(this.checkrole(['sys_admin', 'gen_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.skill = {};
      this.is_update = false;
      this.updated_id = undefined;
    },
    editInitiative(skill) {
      this.skill = skill;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async saveSkill() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        (this.loading = true), (this.skill.data_entry = this.user);
        let result = await this.axios
          .post(this.base_url + "api/summer/skill", this.skill, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            (this.loading = false),
              this.$emit("successAlert", this.$t("globals.data_added"));

            this.skill = {};
          })
          .catch((error) => {
            this.loading = false;

            if (error.response.data.title) {
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });

        this.resetForm();
        this.getSkills();
        setTimeout(() => {}, 2000);
      }
    },
    async updateSkill() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        (this.loading = true), (this.skill.data_entry = this.user);
        let result = await this.axios
          .put(this.base_url + "api/summer/skill/" + this.skill.id, this.skill, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            (this.loading = false),
              this.$emit("successAlert", this.$t("globals.data_updated"));
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.data.title) {
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });

        this.resetForm();
        this.getSkills();
        setTimeout(() => {}, 2000);
      }
    },
    async deleteInitiative() {
      if (this.delete_id) {
        this.del_loading = true;
        let result = await this.axios
          .delete(this.base_url + "api/summer/skill/" + this.delete_id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            (this.del_loading = false),
              this.$emit("successAlert", this.$t("globals.data_deleted"));
          })
          .catch((error) => {
            (this.del_loading = false), (this.icon_color = "error");

            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.dialogVisible = false;
        this.delete_id = undefined;

        this.getSkills();
        setTimeout(() => {}, 2000);
      }
    },
    async getSkills(page = 1) {
      await this.axios(`${this.base_url}/api/summer/skill`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.skills = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
      });
    },
  },
  watch: {
    perPage() {
      this.getSkills();
    },
    page() {
      this.getSkills(this.page);
    },
  },
};
</script>

<style Scoped>
.v-application__wrap {
  background-color: #999;
}
@media (max-width: 767px) {
  .contect {
    padding: 0px;
  }
}
.contect {
  padding: 16px;
}
</style>
