<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.SummerWorkProgress") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.SummerWorkProgress") }}
              {{ $t("reportpublic.InGovernorate") }}
              {{
                this.$i18n.locale == "ar"
                  ? results.gov_name_ar
                  : results.gov_name_en
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>
                {{ total }}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-container class="overflow-x-hidden">
        <v-row>
          <table class="report-table text-center rounded">
            <thead>
              <tr>
                <th>{{ $t("reportpublic.SummerType") }}</th>
                <th>{{ $t("reportpublic.TotalSchools") }}</th>
                <th>{{ $t("reportpublic.Workers") }}</th>
                <th>{{ $t("reportpublic.TotalMalesStudents") }}</th>
                <th>{{ $t("reportpublic.TotalFemalesStudents") }}</th>
                <th>{{ $t("reportpublic.TotalStudents") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in results.summers_data" :key="index">
                <td>{{ data.school_type }}</td>
                <td>{{ data.summer_count }}</td>
                <td>{{ data.workers_count }}</td>
                <td>{{ data.males_student_count }}</td>
                <td>{{ data.females_student_count }}</td>
                <td>{{ data.student_count }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>{{ $t("reportpublic.GrandTotal") }}</th>
                <th v-for="(data, index) in results.total" :key="index">
                  {{ data }}
                </th>
              </tr>
            </tfoot>
          </table>
        </v-row>
      </v-container>
      <v-row class="flex-chart">
        <v-col v-for="(data, index) in summers_data" :key="index">
          <OperationCenterRoomChart
            :data-chart="data"
          ></OperationCenterRoomChart>
        </v-col>
      </v-row>
    </v-card-item>
  </v-card>
</template>

<script>
import axios from "axios";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import OperationCenterRoomChart from "@/components/reports/OperationCenterRoomChart.vue";

export default {
  components: {
    OperationCenterRoomChart,
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  async mounted() {},
  methods: {
    async fetchData() {
      await axios
        .get(
          `${this.base_url}public-reports/governorate-reports/workflow-in-summer?gov_id=${this.id}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.results = response.data.results;
          this.summers_data = this.results.summers_data;
          this.total = this.results.total.total_summers;
        });
    },

    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      id: 1,
      results: [],
      summers_data: [],
      chart: {},
      statistics: [],
      OpenOCR: {},
      CloseOCR: {},
      TypicalOCR: {},
      size: null,
      total: 0,
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  components: { ReportHeader, OperationCenterRoomChart },
};
</script>
