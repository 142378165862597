<template>
  <VCard>
    <v-card flat
      v-if="
        is_update &&
        CheckGetPermission('summer_center.change_week', ['sys_admin', 'gen_admin'])
      ">
      <v-card-text class="pb-0">
        <VForm ref="form" >
          <VRow class="mt-2">
            <VCol  cols="12" md="5">
              <v-text-field
                style="min-width: 220px"
                v-model="week.statrt_week"
                disabled
                density="compact"
                prepend-inner-icon="mdi-calendar-clock-outline"
              >
              </v-text-field>
            </VCol>
            <VCol  cols="12" md="5">
              <v-text-field
                style="min-width: 220px"
                v-model="week.end_week"
                density="compact"
                prepend-inner-icon="mdi-calendar-clock-outline"
                disabled
              >
              </v-text-field>
            </VCol>
            <VCol  cols="12" md="5">
                <VAutocomplete
                  :items="week_list"
                  v-model="week.name"
                  item-title="name"
                  item-value="id"
                  :label="$t('summer.calendar.week-name')"
                  persistent-hint
                  prepend-inner-icon="mdi-calendar-week"
                  density="compact"
                  disabled
                ></VAutocomplete>
            </VCol>
            <VCol cols="12"  md="3">
                <VRadioGroup
                  inline
                  :label="$t('summer.calendar.is-it-the-current-week')"
                  v-model="week.curent_week"
                >
                  <VRadio :label="$t('globals.yes')" :value="true"></VRadio>
                  <VRadio :label="$t('globals.no')" :value="false"></VRadio>
                </VRadioGroup>
            </VCol>
            <VCol cols="12" md="10">
                <VTextarea
                  v-model="week.note"
                  clearable
                  :label="$t('summer.initiative.note')"
                  no-resize
                  counter="250"
                  density="compact"
                  rows="2"
                  :rules="rules.note"
                  prepend-inner-icon="mdi-note-outline"
                ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="px-3">
        <VBtn
          class="bg-success"
          @click="updateWeek()"
          size="small"
          :loading="saveProgress"
        >
          <span class="text-white">
            {{ $t("globals.edit") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
    <v-divider  v-if="is_update" />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card  v-if="CheckGetPermission('summer_center.view_week')">
      <v-card-text>
        <div class="d-flex justify-space-between mb-2">
          <div class="d-flex flex-fill">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                  <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                  <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="header in headers"
                    :key="header.key"
                    :label="header.title"
                    :value="header.key"
                    color="indigo"
                    v-model="selectedHead"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <div style="width: 100%">
              <!-- @input="getWeeks((page = 1))" -->
              <v-text-field
                color="indigo"
                v-model="txt_search"
                style="
                  border-radius: 30px;
                  border: 1px solid #ccc;
                  color: black;
                  height: 30px;
                  width: 100%;
                "
                class="search"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                :placeholder="$t('globals.search-here')"
                density="compact"
                variant="text"
              >
              </v-text-field>
            </div>
          </div>
        </div>
        <v-data-table
          :headers="selectedHeaders"
          :items="weeks"
          item-value="name"
          item-key="id"
          id="vDataTable"
          fixed-footer
          density="compact"
          class="elevation-1 rounded"
          :search="txt_search"
        >
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <span v-if="key === 'curent_week'" class="d-flex justify-center">
                  <v-icon v-if="val == true" color="success">mdi-check-circle</v-icon>
                  <v-icon v-if="val == false" color="error">mdi-close-circle</v-icon>
                </span>
                <span v-else-if="key === 'actions'">
                  <v-btn
                    variant="text"
                    class="me-1 v-btn-icon"
                    size="large"
                    v-if="
                      CheckGetPermission('summer_center.change_week', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="editWeek(item.raw.id)"
                  >
                    <v-icon color="warning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    variant="text"
                    size="small"
                    class="v-btn-icon"
                    v-if="
                      CheckGetPermission('summer_center.delete_week', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="(del_dialog = true), (week_del_id = item.raw.id)"
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
                <span v-else>{{ val }}</span>
              </td>
            </tr>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table>
      </v-card-text>

    </v-card>
  </VCard>
  <v-dialog
    color="indigo"
    v-model="del_dialog"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        {{ $t("globals.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("globals.confirm-delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="(del_dialog = false), (week_del_id = undefined)">{{
          $t("globals.cancel")
        }}</v-btn>
        <v-btn color="red" @click="deleteWeek">{{ $t("globals.delete") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <alert-protected-records
    v-model="protected_records"
    :data_message="data_message"
  ></alert-protected-records>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      saveProgress:false,
      protected_records: false,
      data_message: [],
      weekSelect: [],
      weekList: [],
      weeks: [],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      weeks: [],
      week: {},
      sortBy: [
        {
          key: "name",
          order: "asc",
        },
      ],
      rules: {
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },

      is_update: false,
      updated_id: undefined,
      del_dialog: false,
      week_del_id: undefined,
      selectedHead: [
        "year_name",
        "week_name",
        "curent_week",
        "statrt_week",
        "end_week",
        "note",
        this.checkrole(['sys_admin', 'gen_admin']) ? "actions":null,
      ],
      headers: [
        { title: this.$t("summer.calendar.academic-year"), key: "year_name" },
        { title: this.$t("summer.calendar.week-name"), key: "week_name" },
        { title: this.$t("summer.calendar.is-it-the-current-week"), key: "curent_week" },
        { title: this.$t("summer.calendar.start-date-of-the-week"), key: "statrt_week" },
        { title: this.$t("summer.calendar.end-date-of-the-week"), key: "end_week" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ],
      txt_search: undefined,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      week_list: (state) => state.summer_globals.week,
    }),
    ...mapGetters({}),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.getWeekChoises();
      this.getWeeks();
      if(this.checkrole(['sys_admin', 'gen_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      getWeekChoises: "summer_globals/getWeek",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.week = {};
      this.is_update = false;
      this.updated_id = undefined;
    },
    editWeek(week_id) {
      let week = this.weeks.find((item) => item.id == week_id);
      if (week) {
        this.week = Object.assign({},week);
        this.is_update = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    async updateWeek() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        let week = {
          curent_week: this.week.curent_week,
          note: this.week.note,
          data_entry: this.user,
          fk_year: localStorage.getItem("current_year"),
        };
        this.week.data_entry = this.user;
        this.week.fk_year = localStorage.getItem("current_year");
        this.saveProgress=true
        let result = await this.axios
          .put(this.base_url + "api/summer/week/" + this.week.id, week, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.resetForm();
            this.getWeeks();
            this.saveProgress = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveProgress = false;

          });
      }
    },
    async deleteWeek() {
      if (this.week_del_id) {
        this.saveProgress=true
        let result = await this.axios
          .delete(this.base_url + "api/summer/week/" + this.week_del_id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.getWeeks();
            this.saveProgress=false

          })
          .catch((error) => {
            this.dialogVisible = false;
            this.delete_id = undefined;
            if (error.message.includes(418)) {
              this.protected_records = true;
              let error_messages = [];
              error.response.data.data.forEach((error) => {
                error_messages.push(
                  this.$t(`models.${error.model}`) + ` = ${error.count}`
                );
              });
              this.data_message = error_messages;
            } else {
              this.$emit("errorAlert", this.$t("alert.failure.delete"));
            }
            this.saveProgress=false
          });
        this.del_dialog = false;
      }
    },
    async getWeeks(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/week`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.weeks = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  watch: {
    perPage() {
      this.getWeeks();
    },
    page() {
      this.getWeeks(this.page);
    },
  },
};
</script>

<style scoped>
.v-application__wrap {
  background-color: #999;
}
</style>
