<template>
  <v-card class="mb-3 sort-hiden" v-if="!isexit">
    <v-form @submit.prevent>
      <v-expansion-panels model-value="1">
        <v-expansion-panel elevation="1" value="1">
          <v-expansion-panel-title class="px-4">
            <v-icon icon="mdi-sort-calendar-ascending"></v-icon>
            <span class="mx-2">{{ $t("globals.select-others-criteria") }}</span>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <VRow style="align-items: center">
              <v-col
                class="pt-0 px-3"
                cols="12"
                xs="12"
                sm="12"
                md="4"
                lg="4"
                v-if="visiGovernorat"
              >
                <v-card-item class="pa-0">
                  <v-autocomplete
                    v-model="fk_governorate"
                    item-value="id"
                    item-title="name_ar"
                    prepend-inner-icon="mdi-city"
                    :items="governorate"
                    :label="$t('globals.governorate')"
                    :rules="rules"
                    persistent-hint
                    density="compact"
                    clearable
                    @update:modelValue="fk_directorate = undefined"
                  ></v-autocomplete>
                </v-card-item>
              </v-col>
              <v-col
                v-if="visiDirectorate"
                align-self=""
                class="pt-0 px-3"
                cols="12"
                xs="12"
                sm="12"
                md="4"
                lg="4"
              >
                <v-card-item class="pa-0">
                  <v-autocomplete
                    v-model="fk_directorate"
                    item-value="id"
                    item-title="name_ar"
                    prepend-inner-icon="mdi-map-outline"
                    :items="getDirectorateByGov(fk_governorate)"
                    :label="$t('globals.directorate')"
                    :rules="rules"
                    persistent-hint
                    density="compact"
                    clearable
                  ></v-autocomplete>
                </v-card-item>
              </v-col>
            </VRow>
            <VRow style="align-items: center">
              <v-col cols="6" md="3" sm="4">
                <v-autocomplete
                  color="indigo"
                  v-model="lessonRecord.fk_week"
                  density="compact"
                  item-title="week_name"
                  item-value="id"
                  prepend-inner-icon="mdi-calendar-week"
                  clearable
                  :items="weeks"
                  :label="$t('summer.calendar.week-name')"
                  @update:model-value="getDateList(), resetForm(1)"
                  :rules="rules"
                />
              </v-col>
              <v-col cols="6" md="3" sm="4">
                <v-autocomplete
                  color="indigo"
                  v-model="lessonRecord.date"
                  density="compact"
                  item-title="week_name"
                  item-value="id"
                  prepend-inner-icon="mdi-calendar-today"
                  clearable
                  :items="listWeeks"
                  :label="$t('summer.calendar.hijri-date')"
                  :rules="rules"
                  @update:model-value="resetForm(0)"
                />
              </v-col>
              <v-col cols="6" md="3" sm="4">
                <v-autocomplete
                  color="indigo"
                  v-model="summer_type_id"
                  density="compact"
                  item-title="type"
                  item-value="id"
                  prepend-inner-icon="mdi-calendar-week"
                  :items="typeList"
                  :label="$t('summer.summer_type')"
                />
              </v-col>
              <VCardActions class="px-3">
                <VBtn
                  ty
                  class="bg-indigo"
                  type="submit"
                  @click="checkRule()"
                  size="small"
                  :loading="btn_loading"
                >
                  <span class="text-white">
                    {{ $t("globals.show") }}
                  </span>
                  <VIcon icon="mdi-presentation" color="white" end></VIcon>
                </VBtn>
                <VBtn
                  class="mx-3"
                  size="small"
                  @click="clearweek(), getDateList()"
                >
                  {{ $t("globals.clear") }}
                  <VIcon icon="mdi-broom" color="golden" end></VIcon>
                </VBtn>
              </VCardActions>
            </VRow>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-progress-linear
        color="indigo"
        v-if="loading"
        indeterminate
        height="2px"
        :reverse="$i18n.locale == 'ar'"
      >
      </v-progress-linear>
    </v-form>
  </v-card>
  <v-card id="report" v-if="isexit">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{
              $t("reportpublic.VariousActivitiesDuringTheSummerCentersPeriod2")
            }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
            <v-list id="print-menu" density="compact">
              <v-list-item @click="printTable((all = false))">{{
                $t("globals.shown-records")
              }}</v-list-item>
              <v-list-item @click="printTable((all = true))">{{
                $t("globals.all-records")
              }}</v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-btn density="compact" class="mx-1" @click="isexit = !isexit">
          <span class="text-indigo">{{ $t("globals.back") }}</span>
        </v-btn>
      </div>

      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.TotalActivitiesLabel") }}
              ( {{ typeList[summer_type_id].type }} )
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
              height="25"
            >
              <h4>{{ " هـ " }} {{ lessonRecord.date }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-row class="flex-chart">
        <v-col v-for="(data, index) in data_charts" :key="index">
          <v-card class="px-2 py-2 my-4">
            <activites-chart :data="data"></activites-chart>
          </v-card>
        </v-col>
      </v-row>
    </v-card-item>
  </v-card>
</template>
<script>
import axios from "axios";
import ActivitesChart from "@/components/reports/ActivitesChart.vue";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    ActivitesChart,
    ReportHeader,
  },
  async created() {
    try {
      const role = localStorage.getItem("role");
      const gov  = localStorage.getItem("governorate");
      const dir  = localStorage.getItem("directorate");
      if(role >1 )
      {
        this.visiGovernorat = false;
        this.fk_governorate = gov;
      }
      if(role > 2 )
      {
        this.visiDirectorate = false;
        this.fk_governorate = gov;
        this.fk_directorate = dir;
      }

    
      await this.$store.commit("updateLoadingValue", true);
      if(role < 2)  await this.getGovernorates();
      if(role < 3)  await this.getDirectorate();
      await this.getWeeks();
      await this.fetchWeek();
      this.getDateList();

      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      fk_governorate: null,
      fk_directorate: null,
      currnet_date_id: null,
      currnet_week_id: null,
      summer_type_id: 0,
      visiGovernorat: true,
      visiDirectorate: true,
      btn_loading: false,
      typeList: [
        { id: 0, type: this.$t("globals.all") },
        { id: 1, type: this.$t("reportpublic.Open") },
        // { id: 2, type: this.$t("reportpublic.Close") },
        { id: 2, type: this.$t("reportpublic.Typical") },
      ],
      isexit: false,
      id: 5,
      data_charts: [],
      results: [],
      results2: [],
      lessonRecord: {},
      listWeeks: [],

      rules: [
        (value) => {
          return value ? true : this.$t("globals.required_field");
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getWeeks: "summer/getWeeks",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    getDirectorateByGov(gov) {
      if (localStorage.getItem("role") == 3) {
        var l = this.getDirectorateByGovernorates(gov);

        return l.filter(
          (item) => item.id == localStorage.getItem("directorate")
        );
      }

      return this.getDirectorateByGovernorates(gov);
    },
    async getDateList() {
      if (this.lessonRecord.fk_week) {
        await this.axios
          .get(this.base_url + "api/summer/get-work-days", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              week_id: this.lessonRecord.fk_week,
            },
          })
          .then((response) => {
            this.listWeeks = response.data.date;
          })
          .catch((error) => {
              
          });
      } else {
        this.resetForm();
      }
    },
    async fetchWeek() {
      await axios
        .get(`${this.base_url}statistics/get-current-date`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.lessonRecord.fk_week = response.data.week_id;
          this.lessonRecord.date = response.data.current_date;
          this.currnet_week_id = response.data.week_id;
          this.currnet_date_id = response.data.current_date;
        })
        .catch((error) => {
            
        });
    },
    async fetchData() {
      this.btn_loading = true;
      await axios
        .get(`${this.base_url}statistics/puplic-report/activities`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            day: this.lessonRecord.date,
            type: this.summer_type_id,
            gov_id: this.fk_governorate,
            directorate_id: this.fk_directorate,
          },
        })
        .then((response) => {
          this.data_charts = [];
          this.results = response.data.results.details;
          this.results2 = response.data.results;

          this.results.forEach((e) => {
            const newItem = {
              name: e.activity_name,
              data: e.activity_count,
              total: response.data.results.total_activities,
            };
            this.data_charts.push(newItem);
          });
          if (response.data.results.details.length > 0) {
            this.isexit = true;
          } else {
            this.$emit("infoAlert", this.$t("summer.not-found-data"));
          }
        })
        .catch((error) => {
            
        });
      this.btn_loading = false;
    },
    async checkRule() {
      if (
        this.lessonRecord.date &&
        this.lessonRecord.fk_week &&
        this.fk_governorate &&
        this.fk_directorate
      ) {
        this.fetchData();
      }
    },
    resetForm(resetAll) {
      if (resetAll) {
        this.lessonRecord.date = null;
        this.listWeeks = [];
        this.data_charts = [];
        this.results = [];
        this.results2 = [];
      } else {
        this.results = [];
        this.results2 = [];
      }
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
    async gettoday() {
      this.fetchData();
    },
    async clearweek() {
      this.listWeeks = [];
      this.data_charts = [];
      this.results = [];
      this.results2 = [];
      this.lessonRecord.date = this.currnet_date_id;
      this.lessonRecord.fk_week = this.currnet_week_id;
    },
  },
  computed: {
    ...mapState({
      weeks: (state) => state.summer.weeks,
      governorate: (state) => state.school.governorates,
    }),
    ...mapGetters({
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
    }),
  },
};
</script>
