<template>
    <v-expansion-panels  >
        <v-expansion-panel elevation="1" >
            <v-expansion-panel-title class="px-4">
                <span>{{$t('globals.select-criteria')}}</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text >
            <v-row class="py-2" >
                <v-col v-if="getLocalStorageRole < 2"
                    class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-card-item class="pa-0">
                        <v-autocomplete
                            v-model="fk_governorate"
                            item-value="id"  
                            item-title="name_ar" 
                            prepend-inner-icon="mdi-city"   
                            :items="governorate"
                            :label="$t('globals.governorate')"
                            persistent-hint
                            density="compact"
                            clearable
                            @update:modelValue="getUsers(page=1),fk_directorate=undefined,fk_schoolsummer=undefined,filter.fk_governorate=fk_governorate"
                        ></v-autocomplete>
                    </v-card-item>
                </v-col>
                <v-col v-if=" getLocalStorageRole < 3 || getLocalStorageRole == 8"
                    class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-card-item class="pa-0">
                        <v-autocomplete
                            v-model="fk_directorate"
                            item-value="id"  
                            item-title="name_ar"
                            prepend-inner-icon="mdi-map-outline"    
                            :items="getDirectorateByGovernorates(filter.fk_governorate)"
                            :label="$t('globals.directorate')"
                            persistent-hint
                            density="compact"
                            clearable
                            @update:modelValue="getUsers(page=1),sendSummerCenterRequest(fk_directorate),fk_schoolsummer=undefined"
                        ></v-autocomplete>
                    </v-card-item>
                </v-col>
                <v-col v-if="getLocalStorageRole < 4 || getLocalStorageRole == 8" 
                class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-card-item class="pa-0">
                        <v-autocomplete
                            v-model="fk_schoolsummer"
                            item-value="id"  
                            item-title="name_ar"
                            prepend-inner-icon="mdi-domain"    
                            :items="view_centers"
                            :label="$t('globals.summer-centre')"
                            persistent-hint
                            density="compact"
                            clearable
                            @update:modelValue="getUsers(page=1)"
                        ></v-autocomplete>
                    </v-card-item>
                </v-col>
                <v-col class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-autocomplete
                        color="indigo"
                        v-model="fk_group" 
                        density="compact"
                        item-title="name"
                        item-value="pk"
                        prepend-inner-icon="mdi-account-group"
                        clearable
                        :items="fk_schoolsummer?[]:group_list"
                        :label="$t('user.group')"
                        @update:modelValue="getUsers(page=1)"
                    ></v-autocomplete>
                </v-col>
                <v-col class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-autocomplete
                        color="indigo"
                        v-model="fk_role" 
                        density="compact"
                        item-title="name"
                        item-value="id"
                        prepend-inner-icon="mdi-account-hard-hat"
                        clearable
                        :items="fk_schoolsummer?[]:roleList"
                        :label="$t('user.role')"
                        @update:modelValue="getUsers(page=1)"
                    ></v-autocomplete>
                </v-col>
            </v-row>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
    <VCard class="mt-2"> 
        <v-card-text>
            <div class="d-flex justify-space-between mb-2">
                <div class="d-flex" style="flex:10;">
                    <v-menu
                    :close-on-content-click="false">
                        <template
                            v-slot:activator="{ props }">
                            <v-btn
                                v-bind="props"
                                variant="outlined"
                                color="#ccc"
                                size="small">
                                <v-icon style="color:#5e7e96;" class="me-2">mdi-eye-outline</v-icon>
                                <v-icon style="color:#5e7e96;" >mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-card >
                            <v-card-text>
                                <v-checkbox v-for="header in headers" :key="header.key"
                                :label="header.title"
                                :value="header.key"
                                color="indigo"
                                v-model="selectedHead"
                                density="compact"
                                hide-details
                                >
                                </v-checkbox>
                            </v-card-text>
                        </v-card>
                        
                    </v-menu>
                    <v-text-field 
                        color="indigo"
                        v-model="txt_search"
                        style="border-radius:30px;border:1px solid #ccc; color:black;height:30px; width:100%;"
                        class=" search "
                        @input="getUsers(page=1)" 
                        hide-details 
                        prepend-inner-icon="mdi-magnify" 
                        single-line
                        :placeholder="$t('globals.search-here')" 
                        density="compact"
                        variant="text"
                        >
                    </v-text-field>
                </div>
                <div class="d-flex  justify-end" style="flex:1;" >
                    <v-btn-group density="compact"  divided >
                        <v-btn  size="small"  @click="exportToExcel">
                            <span>{{$t('export.export')}} </span>
                            <v-icon size="large" color="green-darken-2" end>mdi-file-excel</v-icon>
                        </v-btn>
                        <VBtn  @click="addUser" size="small" >
                            <span >{{$t('globals.create')}}</span>
                            <VIcon icon="mdi-plus-thick" color="success"  end></VIcon>
                        </VBtn>
                    </v-btn-group>
                </div>
            </div>
            <!-- ######## End Header Of Data Table Server ####### -->
            <v-progress-linear 
                color="indigo"
                class="my-1"
                :indeterminate="loading"
                height="2px"
                :reverse="$i18n.locale=='ar'"
            >
            </v-progress-linear>
            <v-data-table-server
                :headers="selectedHeaders"
                :items="table_list"
                item-value="name"
                item-key="id"
                fixed-footer
                :items-per-page="perPage"
                :search="txt_search"
                density="compact"
                class="elevation-1"
                v-model:sort-by="sortBy"
                @update:options="getUsers(page=1)"
            >   
                
                <template v-slot:column.checkbox="{ column }">
                    <v-checkbox 
                        hide-details
                        density="compact">
                    </v-checkbox>
                </template>
                <template v-slot:item="{item}">
                    <tr class="row-hover">
                        <td v-for="(val,key) in item.columns" :key="key">
                            <v-checkbox 
                                v-if="key=='checkbox'"
                                hide-details
                                density="compact">
                            </v-checkbox>
                            <span v-if="key=='groups' && val" >
                                <v-chip  v-for="group in val.split(' - ')"  size="small" class="mx-1" :key="group" density="compact"  >
                                    <span  >{{group}}</span>
                                </v-chip>
                            </span>
                            <span v-else-if="key=='reset'">
                                <v-btn @click="resetDialog=true,selectedUser=item.value.username,selectedUserID=item.value.id" color="indigo" rounded size="small">
                                    {{$t('globals.restore')}}
                                </v-btn>
                            </span>
                            <span v-else-if="key === 'actions'">
                                <v-btn
                                    variant="text"
                                    class="me-1 v-btn-icon"
                                    size="large"
                                    v-if="
                                    CheckGetPermission('usermanage.change_user')
                                    "
                                    @click="editUser(item.raw.id)"
                                >
                                    <v-icon color="warning">mdi-pencil-outline</v-icon>
                                </v-btn>
                                <v-btn
                                    variant="text"
                                    size="small"
                                    class="v-btn-icon"
                                    v-if="
                                    CheckGetPermission('usermanage.delete_user')
                                    "
                                   @click="user_del_id=item.raw.id,del_dialog=true"
                                >
                                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </span>
                            <span v-else> {{val}}</span>

                        </td>
                    </tr>
                </template>
                <template v-slot:bottom>
                <div class="d-flex "  id="pagination-bar">
                    <v-pagination  
                        v-model="page" 
                        :length="length"   
                        density="compact"
                        show-first-last-page
                        >
                    </v-pagination>
                    <div class="d-flex ">
                        <v-select 
                            v-model="perPage" 
                            class="pa-0" 
                            :items="itemsPerPage"  
                            item-value="value" 
                            item-title="text" 
                            density="compact" 
                            hide-details
                            variant="text"
                            >
                        </v-select>
                        <span class="mt-2 px-2">{{$t('globals.per-page')}} {{$t('globals.from')}} [ {{totalItems}} ] {{$t('globals.item')}}</span>
                    </div>
                </div>
            </template>
                
            </v-data-table-server>
            </v-card-text>

            <!-- </VCard> -->
            <!-- ############### Dialog Delete  ################### -->
            <v-dialog
                color="indigo"
                v-model="del_dialog"
                transition="dialog-top-transition"
                width="auto"
            >
                <v-card > 
                    <v-card-title>
                        {{$t('globals.confirmation')}}
                    </v-card-title>
                    <v-card-text>
                        {{$t('globals.confirm-delete')}}
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="info"  @click="del_dialog=false,user_del_id=undefined">{{$t('globals.cancel')}}</v-btn>
                        <v-btn color="red" :loading="deleteProgress" @click="deleteUser" >{{$t('globals.delete')}}</v-btn>
                    </v-card-actions>

                </v-card>
            </v-dialog>
    </VCard>
    <v-dialog
      v-model="resetDialog"
      width="300"
      :dir="$i18n.locale=='ar'?'rtl':'ltr'"
      class="text-center"
    >
      <v-card >
        <v-card-title>
          <h3 >
            {{ $t("globals.confirm") }}
          </h3>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-2 py-1">
            <h4>{{selectedUser}}</h4>
          <small>{{$t('user.confirm-reset')}}</small>
        </v-card-text>
        <v-divider  />
        <v-card-actions class="  pa-2 justify-space-between">
          <v-btn :loading="resetProgress" @click="resetPassword"  class="w-50">
            <span class="text-error">{{ $t("globals.restore") }}</span>
          </v-btn>
          <v-divider vertical />
          <v-btn @click="resetDialog = false"    class="w-50">
           <span class="text-indigo"> {{ $t("globals.cancel") }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <alert-protected-records v-model="protected_records" :data_message="data_message"></alert-protected-records>
</template>

<script>
 
import useValidate from '@vuelidate/core'
import {mapState,mapActions,mapGetters} from 'vuex'
export default({
    name:"summer_user_list",
    async created(){
        try {
            await this.$store.commit("updateLoadingValue", true);
            await this.axios.get(`${this.base_url}user-role/`).then((response)=>{
            this.role_list=response.data
            })
            this.getGovernorates()
            this.getDirectorate()
            // this.getAllSummerCenters()
            if(localStorage.getItem('role') >= 2 || localStorage.getItem('role') == 8)
                this.filter.fk_governorate=localStorage.getItem('governorate')

            if(localStorage.getItem('role') >= 3){
                this.filter.fk_directorate = localStorage.getItem('directorate')
                this.getAllSummerCenters(this.filter.fk_directorate)
                }

            await this.axios.get(`${this.base_url}groups/?user=1`,{
                headers:{'Authorization':'Bearer '+localStorage.getItem('token')}
                }).then((response)=>{
                this.group_list=response.data
            })
            this.$store.commit("updateLoadingValue", false);
            } catch (error) {
            this.$store.commit("updateLoadingValue", false);
        }
        
    },
    data(){
        return{
            selectedUser:'',
            selectedUserID:null,
            resetProgress:false,
            resetDialog:false,
            protected_records:false,
            data_message:[],
            del_dialog:false,
            user_del_id:undefined,
            deleteProgress:false,
            filter:{
                fk_governorate:undefined,
                fk_directorate:undefined,
            },
            role_list:[],
            group_list:[],
            fk_group:undefined,
            fk_role:undefined,
            fk_governorate:undefined,
            fk_directorate:undefined,
            fk_schoolsummer:undefined,
            perPage:10,
            length:0,
            page:1,
            totalItems:null,
            loading:false,
            totalItems:null,
            sortBy:[
                { key:'id',order:'asc'}
            ],
            pagination:{},
            users_list:[],
            txt_search:undefined,
            itemsPerPage: [
                {value:10,text:"10"},
                {value:25,text:"25"},
                {value:50,text:"50"},
                {value:100,text:"100"},
                {value:100000,text:this.$t('globals.all')},
            ],
            v$: useValidate(),
            selectedHead:['username','role_name','groups','reset','fk_governorate','fk_directorate','is_active','actions'],
            headers:[
                {title:this.$t('user.username'),key:"username"},
                {title:this.$t('user.role'),key:"role_name",sortable:false},
                {title:this.$t('user.fname'),key:"first_name"},
                {title:this.$t('user.lname'),key:"last_name"},
                {title:this.$t('user.email'),key:"email"},
                {title:this.$t('user.group'),key:"groups"},
                {title:this.$t('user.active'),key:"is_active"},
                {title:this.$t('user.reset-password'),key:"reset",sortable:false},
                {title:this.$t('globals.governorate'),key:"fk_governorate",sortable:false},
                {title:this.$t('globals.directorate'),key:"fk_directorate",sortable:false},
                {title:this.$t('globals.actions'),key:"actions",sortable:false},
            ]
        }
    },
    computed:{
        dateToday(){
            const today = new Date()
            const options ={year:'numeric',month:'2-digit',day:'2-digit'}
            const dateString = today.toLocaleDateString('en-US',options).replace(/\//g,'-')
            return dateString
        },
        ...mapState({
            user: (state) => state.User,
            governorate: (state) => state.school.governorates,
            all_summer_centers: (state) => state.summer.all_summer_centers,
        }),
        ...mapGetters({
            getDirectorateByGovernorates:'school/getDirectorateByGovernorates',
        }),
        view_centers(){
            if(this.fk_directorate != undefined || localStorage.getItem('role') >= 3)
                return this.all_summer_centers
            else 
                return []
        },
        selectedHeaders(){
            return this.headers.filter(header=>
            this.selectedHead.includes(header.key))
        },
        order_data(){
            try {
                return this.sortBy[0].order=='desc'?`-${this.sortBy[0].key}`:this.sortBy[0].key
            } catch (error) {
                return 'id'
            }
        },
        CheckGetPermission(){
            return (prem)=>{
                return this.$store.getters.checkpermission(prem);
            }
        },
        CheckRole(){
            return (prem)=>{
                return this.$store.getters.checkrole(prem);
            }
        },
        getLocalStorageRole(){
            return localStorage.getItem('role')
        },
        roleList(){
            return this.role_list.filter(item=>
                (localStorage.getItem('role') == 'undefined' && item.id < 5 && item.id >0) || 
                (localStorage.getItem('role') <= item.id   && item.id < 5 && item.id >0) ||
                (localStorage.getItem('role') == 4 && item.id > 3) ||
                (localStorage.getItem('role') <= 2 && item.id == 8) || 
                (localStorage.getItem('role') == 8 && ((item.id < 5 && item.id > 2) || item.id == 8))
            )
        },
        table_list(){
            let list=[]
            if(this.role_list)
                this.users_list.forEach(element => {
                    list.push({
                        date_joined: element.date_joined,
                        email: element.email? element.email:'',
                        first_name: element.first_name?element.first_name:'',
                        groups:this.getGroupNames(element.groups),
                        id: element.id,
                        is_active: element.is_active?"مفعل":"معطل",
                        last_login: element.last_login,
                        last_name: element.last_name?element.last_name:'',
                        role_name: element.role?element.role_name:'',
                        username: element.username?element.username:'',
                        fk_governorate:element.governorate?element.governorate:'',
                        fk_directorate:element.directorate?element.directorate:''
                    })
                });
            return list
        }
    },
    methods:{
        async resetPassword(){
            this.resetProgress=true
            await this.axios(`${this.base_url}api/user/reset-password`, {
            params:{
                user_id:this.selectedUserID
            },
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            }).then(response=>{
                this.$emit('successAlert',this.$t('alert.success.reset-password'))
                this.resetProgress=false
                this.resetDialog=false
            }).catch(error=>{
                this.$emit('successAlert',this.$t('alert.failure.reset-password'))
                this.resetProgress=false
                this.resetDialog=false

            })
        },
        exportToExcel() {
            const headers=this.headers.filter(el=>!['actions','reset'].includes(el.key)).map(item=>{
                return{ header:item.title,key:item.key }
            })
           this.$ExportToExcel(headers,this.table_list,'بيانات المستخدمين')
        },
        ...mapActions({
            getGovernorates: "school/getGovernorates",
            getDirectorate: "school/getDirectorate",
            getAllSummerCenters: "summer/getAllSummerCenters",
        }),
        getGroupNames(groups){
            let names=[]
            groups.forEach(g=>{
                let group=this.group_list.find(item=>item.pk==g)
                if(group != undefined){
                    names.push(group.name)
                }
            }) 
            return names.join(' - ')
        },
        getGroupName(id){
            let group=this.group_list.find(item=>item.pk==id)
            if(group != undefined)
                return group.name
            else
                return group
        },
        editUser(id){
            this.$router.push(`${id}/edit/`)
        },
        addUser(){
            // this.changeCurrentPage({page:this.length,count:this.perPage})
            this.$router.push('add')
        },
        sendSummerCenterRequest(dir){
            if(dir)
                this.getAllSummerCenters(dir)
        },
        async deleteUser(){
            if(this.user_del_id!=undefined){
                this.deleteProgress=true
                let result = await this.axios.delete(`${this.base_url}userdata/${this.user_del_id}/`,
                {headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})
                .then((responce)=>{
                    this.del_dialog=false
                    this.$emit('successAlert',this.$t('globals.data_deleted'))
                    this.getUsers()
                    this.deleteProgress=false
                }).catch((error)=>{
                    this.del_dialog=false
                    if(error.message.includes(418)){
                        this.protected_records=true
                        let error_messages=[]
                        error.response.data.data.forEach(error=>{
                            error_messages.push(this.$t(`models.${error.model}`)+` = ${error.count}`)
                        })
                        this.data_message=error_messages
                    }
                    else {
                        this.$emit('errorAlert',this.$t('alert.failure.delete'))
                    }
                    this.deleteProgress=false
                })
                
                
            }
        },
        async getUsers(page=1){
            this.loading=true
            return await this.axios(`${this.base_url}userdata/`,{
                params:{
                    search:this.txt_search,
                    page:page,
                    page_size:this.perPage,
                    sort_by:this.order_data,
                    group:this.fk_group,
                    role:this.fk_role,
                    governorate:this.fk_governorate,
                    directorate:this.fk_directorate,
                    schoolsummer:this.fk_schoolsummer,
                    sys_type:2
                },
                headers:{'Authorization':'Bearer '+localStorage.getItem('token')}
            }).then((response)=>{
                this.users_list=response.data.results
                this.pagination = response.data.pagination
                this.length = response.data.pagination.num_pages
                this.itemsPerPage[4].value=response.data.pagination.count
                this.totalItems=response.data.pagination.count
                this.loading=false
            }).catch(err=>{
                this.loading=false
            })
        },
    },
    watch:{
        perPage(){
            this.getUsers()
        },
        page(){
            this.getUsers(this.page)
        },
        resetDialog(){
            if(this.resetDialog==false){
                this.selectedUser=''
                this.selectedUserID=null
            }
        },

    } 
})
</script>

<style scoped>
.row-hover:hover{
    background: #d4d3d3;
}
</style>