<template  >
    <SideBar  :dir="getdir"   :drawer="drawer" @selectOption="onSelectOption" :location="location" id="sidebar"  :direction="getdir" />
    <Header :dir="getdir" :direction="getdir"   @handelDrawer="drawer = !drawer" />
    <VMain :dir="getdir"   class="mainbg" >
        <VContainer class="h-100" ref="homePage"> 
            <v-card v-if="breadcrumb.length>0&&showBreadcrumb" class="mb-2">
                <v-row class="py-1 " >
                    <v-col cols="12" md="8" sm="6" >
                        <v-breadcrumbs     :items="breadcrumb"></v-breadcrumbs>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <v-breadcrumbs   color="indigo"  class="d-flex location-bread justify-end"  :items="LocationBreadcrumb"></v-breadcrumbs>
                    </v-col>
                </v-row>
            </v-card>
            <router-view v-if="!error_connection"  @errorAlert="setErrorAlert" @successAlert="setSuccessAlert" @warningAlert="setInfoAlert" @infoAlert="setInfoAlert"></router-view>
            <v-sheet
                v-if="error_connection" 
                class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4 mt-8"
                elevation="2"
                height="200"
                max-width="500"
                rounded
            >
            <div>
                <v-icon color="indigo" size="50">mdi-database-alert-outline</v-icon>
                <h1  style="font-size:2rem;">{{$t('errors.sorry')}}</h1>
                <h3 >
                   {{ $t('errors.error-connection') }}
                </h3>
                <v-btn 
                    @click="windowReload" 
                    color="indigo" 
                    class="mt-4" 
                    rounded
                    >
                   {{$t('globals.retry')}}
                </v-btn>
            </div>
            </v-sheet>
        </VContainer>
    </VMain>
    <Alert
        v-model="show_alert"
        :iconColor="alert_icon_color"
        :icon="alert_icon"
        :title="alert_title"
        :message="alert_message"
        >
    </Alert>
    <v-dialog
        color="indigo"
        :close-on-content-click="false"
        v-model="loadingPage"
        persistentid="loadingPage"
        width="auto"
    >
        <h1 v-if="!loadingImage" class="text-white ">l o a d i n g . . .</h1>
        <div style="width:80px;height:80px;" >
            <v-img :src="loadingImage"></v-img>
        </div>
    </v-dialog>
</template>

<script>
import Header from '@/components/Globals/Header.vue'
import SideBar from '@/components/Globals/SideBar.vue'
import { mapGetters,mapActions,mapState} from 'vuex'
import moment from 'moment-hijri'
import { watch } from '@vue/runtime-core'

//   changeLocale: locale => current.value = locale

export default {
    components: {
        Header,
        SideBar,
    },
    mounted(){
        document.addEventListener('keydown',this.handelKeydown)
    },
    beforeMount(){
        document.removeEventListener('keydown',this.handelKeydown)
    },
    data() {
        return {
            items:[],
            drawer: true,
            isWithColor: true,
            show_alert:false,
            alert_icon_color:undefined,
            alert_icon:undefined,
            alert_title:undefined,
            alert_message:undefined,
            user_profile:{}
        }
    },
    
    methods: {
        windowReload(){
            window.location.reload()
        },
        async getUserProfile(){
            await this.axios.get(`${this.base_url}api/user/profile`,{
                headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
            }).then(response=>{
                this.user_profile=response.data
            })
        },
        onSelectOption(isColor) {
            isWithColor.value = isColor
        },
        getCurrentDate(){
            let current_date = moment().format("iYYYY/iMM/iDD")
            return current_date 
        },
        getCurrentDayName(){
            let formatter = new Intl.DateTimeFormat('ar',{weekday:"long"})
            let current_day_name = formatter.formatToParts(new Date())
            .find(part => part.type === 'weekday').value
            return current_day_name
        },
        setErrorAlert(message){
            this.alert_icon_color="error"
            this.alert_icon="cancel"
            this.alert_title=this.$t('globals.failure')
            this.alert_message=message
            this.showAlert()
        },
        setSuccessAlert(message){
            this.alert_icon_color="success"
            this.alert_icon="check-circle"
            this.alert_title=this.$t('globals.success')
            this.alert_message=message
            this.showAlert()
        },
        setWarningAlert(message){
            this.alert_icon_color="warning"
            this.alert_icon="alert"
            this.alert_title=this.$t('globals.warning')
            this.alert_message=message
            this.showAlert()
        },
        setInfoAlert(message){
            this.alert_icon_color="info"
            this.alert_icon="information"
            this.alert_title=this.$t('globals.info')
            this.alert_message=message
            this.showAlert()
        },
        showAlert(){
            this.show_alert=true
            setTimeout(()=>{
                this.show_alert=false
            },1500)
        },
        handelKeydown(event){
            const {ctrlKey,keyCode} = event
            if(ctrlKey){
                switch(keyCode){
                case 36:
                    this.sctollToTop();
                    break;
                case 35:
                    this.scrollToEnd();
                    break;
                }
            }
        },
        sctollToTop(){
            const table = this.$refs.homePage.$el;
            table.scrollIntoView({block:'start'})
        },
        scrollToEnd(){
            const table = this.$refs.homePage.$el;
            table.scrollIntoView({block:'end'})
        },
        
    },
    created(){ 
        this.getUserProfile()
        
    },
    computed:{
        ...mapState({
            loadingPage:'loading',
            breadcrumb:'breadcrumb',
            error_connection:'error_connection'
        }),
        showBreadcrumb(){
            if(this.$route.path=='/' || this.$route.path=='/summer/home'){
                // if(localStorage.getItem('role')==4)
                //     return true
                return false
            }
            return true
        },
        LocationBreadcrumb(){
            const list_data=[]
            if(this.user_profile.governorate)
                list_data.push(this.user_profile.governorate)
            if(this.user_profile.directorate)
                list_data.push(this.user_profile.directorate)
            if(this.user_profile.date_today)
                list_data.push(this.user_profile.date_today)
            return list_data
        },
        loadingImage(){
            try {
                return require('../../assets/loading.gif')
            } catch (error) {
                return false
            }
        },
        ...mapGetters({getdir:'getdir',}),
        location(){
            return this.getdir==='rtl'?'right':'left'
            // return this.$i18n.locale==='ar'?'right':'left'
        } 
    },
    watch:{
        loadingPage(){
            setTimeout(() => {
                this.$store.commit('updateLoadingValue',false)
            }, 60000);
        },
        error_connection(val){
            if(val){
                this.$store.commit('updateLoadingValue',false)
            }
        }
    }
    
}

</script>
<style>
/* to change direction with rtl  */
  [dir="rtl"] div {
    direction: rtl ;
}
 

.v-navigation-drawer  {
    color:#42526e !important;
    font-weight: bold;

}

.v-navigation-drawer .vListHome{
    background-color: rgba(9, 30, 66, 0.48) !important;
    width: 250px !important ;
    border-radius: 10px !important;
}

.search input{
    padding-top: 2px;

}
.v-breadcrumbs-item--disabled {
    opacity: unset ;
}
.location-bread li{
   font-size: 0.85rem !important; 
}
</style>

