<template>
    <div class="dashboard" >
        <v-expansion-panels   class="mb-6" v-if="(role>=0 && role<=5) || role==8">
            <v-expansion-panel    elevation="1" >
            <v-expansion-panel-title class="px-4 ">
                <div class="d-flex justify-space-between w-75">
                    <div >
                        <v-icon >mdi-tune</v-icon>   <span class="mx-1">{{$t('globals.select-criteria')}}</span>
                    </div>
                    <div class=" pt-1">
                        <span v-for="item in LocationBreadcrumb" :key="item" class=" text-indigo mx-1">{{item}}</span>
                    </div>
                </div>
                
            </v-expansion-panel-title>
            <v-expansion-panel-text >
                <v-row class="py-2" >
                    <v-col class="mt-1" cols="6" md="3" sm="6">
                        <v-autocomplete
                            color="indigo"
                            density="compact"
                            v-model="date"
                            item-title="date"
                            item-value="date"
                            prepend-inner-icon="mdi-calendar-today"
                            clearable
                            hide-details
                            :items="dates"
                            :label="$t('summer.calendar.hijri-date')"
                            @update:modelValue="getData()"
                        />
                    </v-col>
                    <VCol class="mt-1" cols="12" md="3" sm="6"  v-if="role==0||role==1">
                        <VAutocomplete
                        :items="filterGovernorates(1)"
                        v-model="fk_governorate"
                        item-title="name_ar"
                        item-value="id"
                        :label="$t('globals.governorate')"
                        persistent-hint
                        density="compact"
                        hide-details
                        clearable
                        :no-data-text="$t('governorate.not-found')"
                        prepend-inner-icon="mdi-city"
                        @update:modelValue="getSummerSchools(),getData(),fk_directorate=null,fk_summer=null"
                        ></VAutocomplete>
                    </VCol>
                    <VCol class="mt-1" cols="12" md="3" sm="6" v-if="(role>=0&&role<=2)||role==8">
                        <VAutocomplete
                        :items="filterDirectorates(fk_governorate)"
                        v-model="fk_directorate"
                        item-title="name_ar"
                        item-value="id"
                        :label="$t('globals.directorate')"
                        persistent-hint
                        hide-details
                        density="compact"
                        clearable
                        :no-data-text="$t('directorate.not-found')"
                        prepend-inner-icon="mdi-map-outline"
                        @update:modelValue="getSummerSchools(),getData(),fk_summer=null"
                        ></VAutocomplete>
                    </VCol> 
                    <VCol class="mt-1" cols="12" md="3" sm="6" v-if="role>=0&&role<=3">
                        <VAutocomplete
                            v-model="fk_summer"
                            item-value="id"  
                            item-title="name_ar"
                            prepend-inner-icon="mdi-domain"    
                            :items="summer_schools"
                            :label="$t('globals.summer-centre')"
                            persistent-hint
                            hide-details
                            density="compact"
                            :no-data-text="$t('summer.not-found')"
                            clearable
                            @update:modelValue="getData()"
                        ></VAutocomplete>
                    </VCol>
                </v-row>
            </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-item-group mandatory class="mt-2"> 
            <v-row justify="center" v-if="true"> 
                <v-col cols="6" sm="6" md="3" class="mb-2"  v-if="CheckGetPermission('summer_center.view_schoolsummer')&&(role>=0&&role<=3)">
                    <v-item >
                        <v-alert
                            color="white"
                            height="80"
                            border="start"
                            border-color="orange-accent-2"
                            elevation="2"
                            @click="toggle"
                        >
                            <div class="d-flex justify-space-between align-center" >
                                <div class="text-center">
                                    <span class="text-medium-emphasis">{{$t('dashboard.summer-count')}}</span>
                                    <span class="mt-1 mt-2 mb-1"  >
                                        <h1>{{summer_count['count']}}</h1>
                                    </span>
                                </div>
                                <div>
                                    <v-icon size="50"  class="rounded pa-4 bg-orange-accent-2"  color="white">mdi-office-building-marker</v-icon>
                                </div>
                            </div>
                        </v-alert>
                    </v-item>
                </v-col>
                <v-col cols="6" sm="6" md="3" class="mb-2"  v-if="CheckGetPermission('summer_center.view_studentsummer')">
                    <v-item >
                        <v-alert
                            color="white"
                            height="80"
                            border="start"
                            border-color="red-accent-2"
                            elevation="2"
                        >
                            <div class="d-flex justify-space-between align-center" >
                                <div class="text-center">
                                    <span class="text-medium-emphasis" >{{$t('globals.present-students')}}</span>
                                    <span class="mt-1 mt-2 mb-1" >
                                        <h1>{{students['count']}}</h1>
                                    </span>
                                </div>
                                <div>
                                    <v-icon size="50"  class="rounded pa-4 bg-red-accent-2"   color="white">mdi-school</v-icon>
                                </div>
                            </div>
                        </v-alert>
                    </v-item>
                </v-col>
                <v-col cols="6" sm="6" md="3" class="mb-2" v-if="CheckGetPermission('summer_center.view_employee')" >
                    <v-item >
                        <v-alert
                            color="white"
                            height="80"
                            border="start"
                            border-color="indigo-accent-2"
                            elevation="2"
                            @click="toggle"
                        >
                            <div class="d-flex justify-space-between align-center" >
                                <div class="text-center">
                                    <span class="text-medium-emphasis" >{{$t('globals.present-employees')}}</span>
                                    <span class="mt-1 mt-2 mb-1"  >
                                        <h1>{{teachers['count']}}</h1>
                                    </span>
                                </div>
                                <div>
                                    <v-icon size="50"  class="rounded pa-4 bg-indigo-accent-2" color="white">mdi-account-tie</v-icon>
                                </div>
                            </div>
                        </v-alert>
                    </v-item>
                </v-col>
                <v-col cols="6" sm="6" md="3" class="mb-2"  v-if="CheckGetPermission('summer_center.view_lecturelog')&&(role>3)">
                    <v-item >
                        <v-alert
                            color="white"
                            height="80"
                            border="start"
                            border-color="green-accent-2"
                            elevation="2"
                            @click="toggle"
                        >
                            <div class="d-flex justify-space-between align-center" >
                                <div class="text-center">
                                    <span class="text-medium-emphasis" >{{$t('lecture.studied-lectures')}}</span>
                                    <span class="mt-1 mt-2 mb-1"  >
                                        <h1>{{subjects['count']}}</h1>
                                    </span>
                                </div>
                                <div>
                                    <v-icon size="50"  class="rounded pa-4 bg-green-accent-2"  color="white">mdi-book-multiple</v-icon>
                                </div>
                            </div>
                        </v-alert>
                    </v-item>
                </v-col>
                <v-col cols="6" sm="6" md="3" class="mb-2"  v-if="CheckGetPermission('summer_center.view_activityoperation')">
                    <v-item >
                        <v-alert
                            color="white"
                            height="80"
                            border="start"
                            border-color="orange-accent-2"
                            elevation="2"
                            @click="toggle"
                        >
                            <div class="d-flex justify-space-between align-center" >
                                <div class="text-center">
                                    <span class="text-medium-emphasis">{{$t('summer.activity.excuted-activity')}}</span>
                                    <span class="mt-1 mt-2 mb-1"  >
                                        <h1>{{activities['count']}}</h1>
                                    </span>
                                </div>
                                <div>
                                    <v-icon size="50"  class="rounded pa-4 bg-orange-accent-2"  color="white">mdi-file-multiple</v-icon>
                                </div>
                            </div>
                        </v-alert>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>
        <PieChart 
            v-if="
                CheckGetPermission('summer_center.view_studentsummer')&&
                CheckGetPermission('summer_center.view_employee')
            " 
            :students="students"
            :teachers="teachers"
        />
        <BarChart :statistics="statistics" v-if=" CheckGetPermission('summer_center.view_studentsummer')"/>
    </div>
    
    <!-- <GradientCart/> -->
    <!-- <StackedLine/> -->
</template>

<script>
import PieChart from './SummerPieChart.vue'
import BarChart from './BarChartSummer.vue'
import GradientCart from './GradientStackedAreaChart.vue'
import StackedLine from './StackedLine.vue'
import { mapState, mapActions, mapGetters } from "vuex";
import moment from 'moment-hijri'


export default {
    name: 'Home',
    async created(){
        await this.getSummerSchools()
        try {
            await this.$store.commit('updateLoadingValue',true)
            if(this.role==2||this.role==8){
                this.fk_governorate=localStorage.getItem('governorate')
            }else if(this.role==3)
            {
             this.fk_directorate=localStorage.getItem('directorate')
            
            }else if(this.role==4||this.role==5)
            {
             this.fk_summer=localStorage.getItem('schoolsummer')
            }
            await this.getUserProfile()
            await this.getCountries()
            await this.getGovernorates()
            await this.getDirectorate()
            await this.getData()
            await this.getCalendar()
            this.$store.commit('updateLoadingValue',false)
        } catch (error) {
            this.$store.commit('updateLoadingValue',false)
            
        }

    },
    data() {
        return {
            fk_governorate:null,
            fk_directorate:null,
            fk_summer:null,
            dates:[],
            date:null,
            teachers:{},
            students:{},
            activities:0,
            subjects:0,
            summer_count:0,
            summer_schools:[],
            statistics:{},
            user_profile:{}
        };
    },
    computed:{
        ...mapState({
            countries: (state) => state.school.countries,
            governorates: (state) => state.school.governorates,
            directorates: (state) => state.school.directorate,
        }),
        LocationBreadcrumb(){
            const list_data=[]
            if(this.user_profile.governorate)
                list_data.push(this.user_profile.governorate)
            if(this.user_profile.directorate)
                list_data.push(this.user_profile.directorate)
            if(this.user_profile.date_today)
                list_data.push(this.user_profile.date_today)
            return list_data
        },
        ...mapGetters({
            filterGovernorates: "school/getGovernoratesByCountry",
            filterDirectorates: "school/getDirectorateByGovernorates",
        }),
        role(){
            return localStorage.getItem('role')
        },
        students_data(){
            return this.students
        },
        CheckGetPermission(){
            return (prem)=>{
                return this.$store.getters.checkpermission(prem);
            }
        },
    },
    methods:{
        ...mapActions({
            getCountries: "school/getCountries",
            getGovernorates: "school/getGovernorates",
            getDirectorate: "school/getDirectorate",
        }),
        async getCalendar(){
            await this.axios
                .get(this.base_url + "api/summer/calendar", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                })
                .then((response) => {
                if (response.data) {
                    this.dates = response.data.results;
                }
            });
        },
        async getUserProfile(){
            await this.axios.get(`${this.base_url}api/user/profile`,{
                headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
            }).then(response=>{
                this.user_profile=response.data
            })
        },
        async getStudentsCount(){
            if(this.CheckGetPermission('summer_center.view_studentsummer')){
                await this.axios.get(`${this.base_url}api/summer/student-count`,{
                    params:{
                        fk_governorate:this.fk_governorate,
                        fk_directorate:this.fk_directorate,
                        fk_summer:this.fk_summer,
                        date:this.date                        
                    },
                    headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
                }).then(response=>{
                    this.students=response.data

                })
            }
        },
        async getTeachersCount(){
             if(this.CheckGetPermission('summer_center.view_employee')){
                await this.axios.get(`${this.base_url}api/summer/teacher-count`,{
                    params:{
                        fk_governorate:this.fk_governorate,
                        fk_directorate:this.fk_directorate,
                        fk_summer:this.fk_summer,
                        date:this.date
                    },
                    headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
                }).then(response=>{
                    this.teachers=response.data
                })
            }
        },
        async getActivitiesCount(){
            if(this.CheckGetPermission('summer_center.view_activityoperation')){
                await this.axios.get(`${this.base_url}api/summer/executed-activities-count`,{
                    params:{
                        fk_governorate:this.fk_governorate,
                        fk_directorate:this.fk_directorate,
                        fk_summer:this.fk_summer,
                        date:this.date
                    },
                    headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
                }).then(response=>{
                    this.activities=response.data
                })
            }
        },
        async getSubjectsCount(){
            if(this.CheckGetPermission('summer_center.view_lecturelog')){
                await this.axios.get(`${this.base_url}api/summer/subjects-count`,{
                    params:{
                        fk_governorate:this.fk_governorate,
                        fk_directorate:this.fk_directorate,
                        fk_summer:this.fk_summer,
                        date:this.date
                    },
                    headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
                }).then(response=>{
                    this.subjects=response.data
                })
            }
        },
        async getSchoolSummerCount(){
            if(this.role>=0&&this.role<4){
                await this.axios(`${this.base_url}api/summer/summer-count`,{
                    params:{
                        governorate:this.fk_governorate,
                        directorate:this.fk_directorate
                    },
                    headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
                }).then(response=>{
                    this.summer_count=response.data
                })
            }
        },
        async getStudentStatistics(){
            await this.axios.get(`${this.base_url}api/summer/students-statistics`,{
                params:{
                    governorate:this.fk_governorate,
                    directorate:this.fk_directorate,
                    fk_summer:this.fk_summer
                },
                headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
            }).then(response=>{
                this.statistics=response.data

            })
        },
        async getData(){
            await this.getStudentsCount()
            await this.getTeachersCount()
            await this.getActivitiesCount()
            await this.getSubjectsCount()
            await this.getSchoolSummerCount()
            await this.getStudentStatistics()
        },
        async getSummerSchools(){
            await this.axios.get(`${this.base_url}api/summer/school-select-list`, {
            params:{
                directorate:this.fk_directorate,
                governorate:this.fk_governorate
            },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            }).then(response=>{
                this.summer_schools=response.data
            })
        },
    },
    components: {
        PieChart,
        BarChart,
        GradientCart,
        StackedLine
    },
    
}

</script>

