<template>
  <v-card class="justify-center" > 
    <v-card-text >
      <v-row class="justify-center">
          <v-col cols="12"  >
            <v-form ref="table">
                <v-card  class="mt-4" >
                    <div>
                        <v-table >
                            <thead>
                                <tr>
                                    <th>
                                        {{$t('globals.day')}}
                                    </th>
                                    <th>
                                        {{$t('summer.activity.name')}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="fk_day,index in school_weekly_activity.days" :key="fk_day">
                                    <td class="w-25">
                                        <span>{{day_list[index].name}}</span>
                                    </td>
                                    <td class="w-75">
                                        <v-autocomplete 
                                            v-model="fk_day.fk_activity"
                                            density="compact"
                                            item-title="name"
                                            clearable
                                            multiple
                                            chips
                                            closable-chips
                                            item-value="id"
                                            :no-data-text="$t('subject.not-found-activity')"
                                            :items="fk_activity"
                                            hide-details
                                            :placeholder="$t('summer.activity.select')"
                                            variant="underlined"
                                        >
                                        <template v-slot:chip="{props , item}">
                                          <v-chip 
                                            class="mx-1"
                                            v-bind="props" :text="item.raw.name">
                                          </v-chip>
                                        </template>
                                        </v-autocomplete>                                                
                                    </td>                                                                                       
                                </tr>                                        
                            </tbody>
                        </v-table>
                    </div>
                </v-card>
            </v-form>
          </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="px-3" >
      <v-btn v-if="CheckGetPermission('summer_center.add_schoolweeklyactivity',['sys_admin','gen_admin'])" @click="saveSchoolWeeklySchedule(date=true)" :loading="saveloading" class="bg-indigo"  size="small">
          <span class="text-white">
              {{$t('globals.save')}}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn
      v-if="
          is_update && CheckGetPermission('summer_center.delete_schoolweeklyactivity',['sys_admin','gen_admin'])
      "
      @click="dialog_delete=true"
      class="bg-error"
      size="small"
      >
      <span class="text-white">
          {{ $t("globals.delete") }}
      </span>
      <v-icon
          icon="mdi-trash-can-outline"
          color="white"
          end
      ></v-icon>
      </v-btn>                        
    </v-card-actions>
  </v-card>
  <!-- ############### Dialog Delete  ################### -->
  <v-dialog
    color="indigo"
    v-model="dialog_delete"
    transition="dialog-top-transition"
    width="auto"
  >
    <v-card>
      <v-card-title>
        {{ $t("globals.confirmation") }}
      </v-card-title>
      <v-card-text>
        {{ $t("globals.confirm-delete") }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" @click="dialog_delete = false">{{
          $t("globals.cancel")
        }}</v-btn>
        <v-btn
          color="red"
          @click="
            cleanSchoolWeeklySchedule(),
              saveSchoolWeeklySchedule((data = false)),
              (dialog_delete = false),
              (this.is_update = false)
          "
          >{{ $t("globals.delete") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- ############### end Dialog Delete  ################### -->

</template>

<script>
import useValidate from "@vuelidate/core";
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  name: "WeeklyScheduleAdd",

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getActivities();
      await this.addActivities();
      await this.createTable();
      await this.getDays();
      setTimeout(() => {
        this.getSchoolWeekly();
      }, 400);
      this.day_list = this.days.filter((item) => item.is_holiday == false);
      await this.getActivityType();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      loading: false,
      saveloading: false,
      uniqueData: [null, null, null],
      schedules_data: [],
      v$: useValidate(),
      showAlert: false,
      update: false,
      user: null,
      fk_activity: [],
      day_list: [],
      dialog_delete: false,
      is_update: false,
      alert: {
        color: undefined,
        icon: undefined,
        title: undefined,
        message: undefined,
      },
      user: null,
      school_weekly_activity: {
        days: [],
      },
    };
  },
  computed: {
    ...mapState({
      activityType: (state) => state.summer.activityType,
      days: (state) => state.summer.days,
      schedules: (state) => state.summer.schedules,
    }),
    ...mapGetters({}),
    timetable_schedules() {
      return this.schedules.filter((el) => el.add_to_table == true);
    },
    classesName() {
      return this.$i18n.locale == "ar" ? "name_ar" : "name_en";
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  beforeUnmount() {
    this.$store.commit("updateLoadingValue", true);
  },

  validations() {
    return {
      school_weekly_activity: {},
    };
  },
  methods: {
    ...mapActions({
      getDays: "summer/getDays",
      getActivityType: "summer/getActivityType",
    }),
    createTable() {
      this.loading = true;
      this.school_weekly_activity.days = [];
      setTimeout(() => {
        this.addDays(this.day_list.length);
      }, 300);
    },
    cleanTable() {
      this.$refs.table.reset();
    },
    addDays(number) {
      this.school_weekly_activity.days = [];
      for (let index = 0; index < number; index++) {
        if (!this.day_list[index].is_holiday) {
          this.school_weekly_activity.days.push({
            fk_day: this.day_list[index].id,
            fk_activity: [],
            fk_year: localStorage.getItem("current_year"),
          });
        } else {
        }
      }
    },
    async getActivities() {
      await this.axios
        .get(`${this.base_url}api/summer/activity`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          this.fk_activity = res.data.results;
        });
    },
    submitSchoolWeeklySchedule() {
      this.v$.$validate();
    },
    validations(){
        return{
            school_weekly_activity:{},
        }
    },
      addActivities(){
          this.school_weekly_activity.days.forEach(element => {
              this.timetable_schedules.forEach(schedule=>{
                  element.fk_activity.push(
                      {
                          fk_schedule:schedule.id,
                      }
                  )
              })
              this.loading=false
          });
      },
      // async getActivities(){
      //     await this.axios.get(`${this.base_url}api/summer/activity`,{
      //         headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
      //     }).then(res=>{
      //         this.fk_activity=res.data.results
      //     })
      // },
      submitSchoolWeeklySchedule(){
          this.v$.$validate()
      },
      async getSchoolWeekly() {
        this.is_update = false;
        await this.axios
          .get(`${this.base_url}api/summer/school-weekly-activity`, {
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then((response) => {
              response.data.results.forEach(element => {
                  this.school_weekly_activity.days.forEach(element2 => {
                      if(element.fk_day==element2.fk_day)
                          element2.fk_activity.push(element.fk_activity)
                  });
                  });
                              if (response.data.results.length > 0) {
                                  this.checkIsUpdate(response.data.results);
          }
              
          });
      },
      async saveSchoolWeeklySchedule(data){
          this.submitSchoolWeeklySchedule()
          if(!this.v$.$error){
              this.saveloading = true ;
              this.school_weekly_activity.data_entry=this.user
              this.axios.post(`${this.base_url}api/summer/school-weekly-activity`,this.school_weekly_activity.days,{
                  headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
              }).then(response=>{
                          this.$emit("successAlert", this.$t("globals.data_saved"));
                          this.saveloading = false ;
                          this.is_update = data
              }).catch((error)=>{
                  this.saveloading = false ;
                  this.$emit("errorAlert", this.$t("alert.failure.title"));
                  this.alert.icon="alert-circle-outline"
                  try {
                      if(error.response.data.non_field_errors[0].includes("The fields fk_activity, fk_day must make a unique set.")){
                          this.alert.message=this.$t('alert.failure.unique')
                      }else{
                          this.alert.message=this.$t('alert.failure.insert')
                      }
                  } catch (e) {
                      this.alert.message=this.$t('alert.failure.insert')
                  }

              })
          }
          else{
                  this.$emit("errorAlert", this.$t("alert.failure.title"));
          }
      },
      checkIsUpdate(data) {
          const student_with_id = data.filter((el) => {
          return Object.keys(el).includes("id");
          });
          if (student_with_id.length > 0) {
          this.is_update = true;
          }
      },
      cleanSchoolWeeklySchedule(){
          this.$refs.table.reset()
          this.is_update = false
      },
    },       
}
</script>
