<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.TotalActivitiesLabel") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
      <v-container class="px-3">
        <v-row align="center">
          <v-col cols="auto">
            <v-sheet class="chart-label-sheet" elevation="0">
              {{ $t("reportpublic.TotalActivitiesLabel") }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{ data.totalActivities }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-item>
      <v-card class="pt-3 px-5 pb-5" variant="outlined" style="width: 100%">
        <v-row class="px-5 pt-3 justify-center">
          <v-card
            v-for="(activity, key) in data.details"
            :key="key"
            class="d-flex flex-row"
            width="min-content"
            elevation="0"
            rounded="0"
            style="gap: 1px"
          >
            <div class="text-center w-auto">
              <span
                class="px-1"
                style="
                  writing-mode: vertical-rl;
                  transform: rotate(180deg);
                  height: 15vh;
                  white-space: wrap;
                  margin-bottom: -10px;
                "
                >{{ activity.activity_name }}
              </span>
              <v-icon
                color="orange"
                size="sm"
                icon="mdi-triangle"
                style="transform: rotate(60deg)"
                class="mx-1 ml-2"
              >
              </v-icon>
              <v-sheet
                class="text-center d-flex align-center flex-grow-1 pa-2"
                style="background-color: #11698e; height: 13vh; width: 100%"
                :rounded="
                  key == 0
                    ? this.$i18n.locale == 'ar'
                      ? 'e'
                      : 's'
                    : key == data.details.length - 1
                    ? this.$i18n.locale == 'ar'
                      ? 's'
                      : 'e'
                    : false
                "
              >
                <p
                  class="text-center text-white"
                  style="
                    writing-mode: vertical-lr;
                    transform: rotate(180deg);
                    width: 100%;
                  "
                >
                  {{ activity.activity_summer_count }}
                </p>
              </v-sheet>
            </div>
            <v-divider
              v-if="data.details.length !== key + 1"
              thickness="1"
              color="black"
              vertical
              class="w-100 h-50 border-opacity-75"
            >
            </v-divider>
          </v-card>
        </v-row>
      </v-card>
    </v-card-item>
  </v-card>
</template>
<script>
import axios from "axios";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      data: {},
    };
  },
  methods: {
    fetchData() {
      axios
        .get(`${this.base_url}public-reports/summer-activities`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.data.totalActivities = response.data.results.total_activities;
          this.data.details = response.data.results.details;
        })
        .catch((error) => {
          console.error("خطأ في السيرفر", error);
        });
    },

    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },

  computed: {
    ...mapGetters({ getdir: "getdir" }),
  },
  components: {
    ReportHeader,
  },
};
</script>
