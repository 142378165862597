<template>
 
  <v-card class="mb-3 sort-hiden" v-if="!isExists">
    <v-card-text>
      <v-row class="pa-10">
          <v-col cols="6" md="3" sm="4">
        <v-autocomplete
          color="indigo"
          v-model="date"
          :items="calendar"
          item-title="date"
          item-value="date"
          prepend-inner-icon="mdi-calendar-week"
          clearable
          hide-details="auto"
          :label="$t('globals.day')"
        />
      </v-col>
       <v-col cols="6" sm="2" class="d-flex align-center">
        <v-btn
          density="comfortable"
          append-icon="mdi-presentation"
          class="w-100"
          color="indigo"
          :loading="loading"
          @click="fetchData()"
        >
          <span>{{ $t("globals.show") }}</span>
        </v-btn>
      </v-col>
      </v-row>
     
     
     </v-card-text>

   
  </v-card>
  <v-card v-else id="report">
    <v-container>
      <ReportHeader class="pb-0">
        <template v-slot:header-title>
          <div class="d-flex justify-center py-2">
            <div class="text-h5">
              {{ $t("reportpublic.VisitsCarriedOutDuringTheDay") }}
            </div>
          </div>
        </template>
      </ReportHeader>
      <v-card-title>
        <div class="d-flex justify-space-between mb-2" id="tableHead">
          <div class="d-flex justify-end" style="flex: 1">
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  density="compact"
                  class="pa-0 mx-1"
                  style="min-width: 25px"
                  @click="printTable((all = true))"
                >
                  <v-icon color="icon-color">mdi-printer</v-icon>
                  <v-tooltip class="tooltip" activator="parent" location="top">
                    <small>{{ $t("globals.print-file") }}</small>
                  </v-tooltip>
                </v-btn>
              </template>
            </v-menu>
              <v-btn density="compact" class="mx-1" @click="isExists = !isExists">
              <span class="text-indigo">{{ $t("globals.back") }}</span>
            </v-btn>
          </div>
        </div>
        <v-container>
          <v-row align="center">
            <v-col cols="auto" class="pl-0">
              <v-sheet class="px-2 chart-label-sheet" elevation="0" rounded="0">
                {{ $t("reportpublic.VisitsCarriedOutDuringTheDay") }}
              </v-sheet>
            </v-col>
            <v-col class="pa-0" cols="auto">
              <v-sheet
                :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
              >
                <h4>
                  {{ " هـ " + (gover[0] ? gover[0].date : "") }}
                </h4>
              </v-sheet>
            </v-col>
            <v-col cols="max" align-self="center" class="pa-0">
              <v-divider
                :thickness="1"
                class="border-opacity-100"
                color="black"
              ></v-divider>
            </v-col>
            <v-sheet
              color="black"
              height="6"
              width="6"
              style="border-radius: 50%"
            >
            </v-sheet>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-item>
        <v-row v-if="vis_header.length >= 1">
          <v-col cols="12" md="12">
            <v-container>
              <table class="report-table text-center">
                <thead>
                  <tr>
                    <th rowspan="2" class="right-header">
                      {{ $t("reportpublic.governorate1") }}
                    </th>
                    
                    <th :colspan="vis_header.length">
                      {{ $t("reportpublic.Authorities") }}
                    </th>
                    <th rowspan="2" class="right-header">
                      {{ $t("reportpublic.TotalVisits") }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      v-for="(data, index) in vis_header"
                      :key="index"
                      class="right-header"
                    >
                      {{ data.visitor_name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(data, index) in results2.governorate_data"
                    :key="index"
                  >
                    <td>
                      {{
                        this.$i18n.locale == "ar"
                          ? data.governorate_name_ar
                          : data.governorate_name_en
                      }}
                    </td>
                    <td
                      v-for="(data2, index) in data.visitors_in_entity"
                      :key="index"
                      class="right-header"
                    >
                      {{ data2.visitors_summer_count }}
                    </td>
                    <th>{{ data.total_visitors }}</th>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>{{ $t("reportpublic.GrandTotal") }}</th>
                    <th
                      v-for="(
                        data2, index
                      ) in results2.total_visitors_in_entity"
                      :key="index"
                    >
                      {{ data2.visitors }}
                    </th>
                    <th>
                      {{ results2.total_for_all_visitors }}
                    </th>
                  </tr>
                </tfoot>
              </table>
            </v-container>
          </v-col>
          <v-col cols="12" md="12">
            <v-container>
              <v-card class="px-2 pt-1 my-4" style="height: 350px">
                <chart :option="option_10" autoresize />
              </v-card>
            </v-container>
          </v-col>
        </v-row>
        <v-card v-else class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
      </v-card-item>
    </v-container>
  </v-card>
</template>

<script>
import axios from "axios";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async created() {
    await this.getCalendar();
    
  },

 
  methods: {
    async fetchData() {
      this.loading=true
      await axios
        .get(
          `${this.base_url}public-reports/daily-reports/summer-visits-carried-out-day`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          params:{
            date:this.date
          }
          }
        )
        .then((response) => {
          this.results2 = response.data.results;
          this.header = this.results2.governorate_data[0];
          this.vis_header = this.header ? this.header.visitors_in_entity : [];
           this.gover = response.data.results.governorate_data;           
           if ( this.results2.governorate_data.length == 0)
             this.$emit("infoAlert", this.$t("globals.not-found-items"))
            else this.isExists=true
         
      
        
        })

        .catch((error) => {});
      this.loading=false

    },
    async getCalendar() {
       await axios
        .get(`${this.base_url}api/summer/calendar`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params:{
            date_str:this.date
          }
        })
        .then((response) => {
          this.calendar = response.data.results;
         })
        .catch((error) => {});
     },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  
  data() {
    return {
      loading:false,
      date: undefined,
      calendar: [],
      isExists: false,
      vis_header: [],
      gover: [],
      source: [],
      header: [],
      header2: [],
      statistics: [],
      size: null,
      results: [],
      results2: [],
       colspan: 0,
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  components: {
    ReportHeader,
  },
};
</script>
