<template>
    <v-container :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
        <h1>test correct </h1>
        <DatetimePicker  v-model="date"></DatetimePicker>
    </v-container>

</template>
<script>
import DatetimePicker from '@/components/Globals/DatetimePicker.vue'

 export default{
    data(){
        return{
            date:""
        }
    },
    components:{
        DatetimePicker
    },
    methods:{
        
    }
 }
</script>