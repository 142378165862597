<template>
   <VListGroup :value="$t('report.general-management')" class="border-bottom" :fluid="false">
    <template v-slot:activator="{ props }">
      <VListItem v-bind="props" min-height="40" v-if="checkRole(['gen_admin'])">
        <template v-slot:title>
          <span class="v-select-title">{{
            $t("report.general-management")
          }}</span>
        </template>

        <template v-slot:prepend>
          <v-tooltip :text="$t('globals.general-initialize')">
            <template v-slot:activator="{ props }">
              <v-icon v-show="expand == true" v-bind="props">
                mdi-chart-pie
              </v-icon>
              <v-icon v-show="expand == false"> mdi-chart-pie </v-icon>
            </template>
          </v-tooltip>
        </template>
      </VListItem>
    </template>
    <VListItem
      :to="{ name: 'school-report' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SchoolReport')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SchoolReport")
        }}</span>
      </template>
    </VListItem>
   
    <VListItem
      :to="{ name: 'users-report' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.UsersReport')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.UsersReport")
        }}</span>
      </template>
    </VListItem>
     <VListItem
      :to="{ name: 'summer-center-number-student-employees' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.the_total_number_student_employees')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.the_total_number_student_employees")
        }}</span>
      </template>
    </VListItem>    

     <VListItem
      :to="{ name: 'summer-center-student-data-report' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.summer_center_student_data')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.summer_center_student_data")
        }}</span>
      </template>
    </VListItem>
    
      
 
     <VListItem
      :to="{ name: 'summer-center-employees-data-report' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.summer_center_employee_data')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.summer_center_employee_data")
        }}</span>
      </template>
    </VListItem>
    

    <VListItem
      :to="{ name: 'openSchoolsWorkersStudents' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.CentersOWS')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.CentersOWS")
        }}</span>
      </template>
    </VListItem>
 
    <VListItem
      :to="{ name: 'feeding_report' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('summer-sidebar.feeding-report')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t('summer-sidebar.feeding-report')
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'feeding_report_periods' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('summer-sidebar.feeding-report-periods')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t('summer-sidebar.feeding-report-periods')
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'openCoursesClosedSchools' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.TotalOpenAndClosedCenters')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.TotalOpenAndClosedCenters")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'totalSchoolsEmployeesStudents' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.TotalWorkersStudentsCenters')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.TotalWorkersStudentsCenters")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'totalOpenCenters' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.TotalOpenCenters')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.TotalOpenCenters")
        }}</span>
      </template>
    </VListItem>
    <!-- <VListItem
      :to="{ name: 'totalClosedCenters' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.TotalCloseCenters')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.TotalCloseCenters")
        }}</span>
      </template>
    </VListItem> -->
    <VListItem
      :to="{ name: 'summer-courses-typical' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.TotalTypicalCenters')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.TotalTypicalCenters")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'totalNumberStudentsGovernoratesLevel' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.TotalStudentsByLevel')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.TotalStudentsByLevel")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'summerCenterStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummerCouresStatistics')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SummerCouresStatistics")
        }}</span>
      </template>
    </VListItem>
 
    <VListItem
      :to="{ name: 'statisticsSummerCentersCapitalsMunicipalityDistricts' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.StatisticsOfCerntersInDistricts')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.StatisticsOfCerntersInDistricts")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'summer-curricula' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.PrintedCurricula')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.PrintedCurricula")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'summer-all-detailed-summary' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummerAllDetailedSummary')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SummerAllDetailedSummary")
        }}</span>
      </template>
    </VListItem>
    <!-- <VListItem
      :to="{ name: 'summer-all-detailed-summary-table' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummerAllDetailedSummaryre2')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SummerAllDetailedSummaryre2")
        }}</span>
      </template>
    </VListItem> -->
    <VListItem
      :to="{ name: 'summer-school-detailed-summary' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummerSchoolDetailedSummary')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SummerSchoolDetailedSummary")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'summer-worker-detailed-summary' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummerWorkerDetailedSummary')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SummerWorkerDetailedSummary")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'summer-student-detailed-summary' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummerStudentDetailedSummary')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SummerStudentDetailedSummary")
        }}</span>
      </template>
    </VListItem>
    <!-- <VListItem
      :to="{ name: 'summer-activities' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SummerActivities')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SummerActivities")
        }}</span>
      </template>
    </VListItem> -->
  </VListGroup>
  <VListGroup
    :value="$t('report.daily')"
    v-if="checkRole(['gen_admin'])"
    :fluid="false"
    class="border-bottom"
  >
    <template v-slot:activator="{ props }">
      <VListItem v-bind="props" min-height="40">
        <template v-slot:title>
          <span class="v-select-title">{{ $t("report.daily") }}</span>
        </template>

        <template v-slot:prepend>
          <v-tooltip :text="$t('globals.general-initialize')">
            <template v-slot:activator="{ props }">
              <v-icon v-show="expand == true" v-bind="props">
                mdi-chart-pie
              </v-icon>
              <v-icon v-show="expand == false"> mdi-chart-pie </v-icon>
            </template>
          </v-tooltip>
        </template>
      </VListItem>
    </template>
     <!-- <VListItem
      :to="{ name: 'dialy-summer-operations-report' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.daily_summer_operations_report')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.daily_summer_operations_report")
        }}</span>
      </template>
    </VListItem> -->
    <VListItem
      :to="{ name: 'student-employee-present-actual' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.data-student-employee-present-actual')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.data-student-employee-present-actual")
        }}</span>
      </template>
    </VListItem>


    <!-- <VListItem
      :to="{ name: 'CoursesForEachTypePublicStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.number-positions-each-TOC-typical')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.number-positions-each-TOC-typical")
        }}</span>
      </template>
    </VListItem> -->
       <VListItem
      :to="{ name: 'variousActivitiesDuringSummerCentersPeriodCapital' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="
        this.$t('reportpublic.VariousActivitiesDuringTheSummerCentersPeriod2')
      "
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.VariousActivitiesDuringTheSummerCentersPeriod2")
        }}</span>
      </template>
    </VListItem>
    <!-- <VListItem
      :to="{ name: 'ActivitiesPublicStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.ActivitiesInTheDirectorate2')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.ActivitiesInTheDirectorate2")
        }}</span>
      </template>
    </VListItem> -->
    
    <!-- <VListItem
      :to="{ name: 'StudentByLevelsPublicStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.TotalStudentsByLevelForCenters2')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.TotalStudentsByLevelForCenters2")
        }}</span>
      </template>
    </VListItem>  -->
   <!-- <VListItem

      :to="{ name: 'ActivitiesStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="
        this.$t('reportpublic.SummerTotalActivitiesCarriedOutDuringDayHeader2')
      "
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t(
            "reportpublic.SummerTotalActivitiesCarriedOutDuringDayHeader2"
          )
        }}</span>
      </template>
    </VListItem>  
   -->
  
    <VListItem
      :to="{ name: 'VisitorsPublicStatistics' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.VisitsCarriedOutDuringTheDay2')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.VisitsCarriedOutDuringTheDay2")
        }}</span>
      </template>
    </VListItem>
  </VListGroup>
</template>

<script>
export default {
  props: {
    show: Boolean,
    expand: Boolean,
    direction: String,
    CheckGetPermission: {
      type: Function,
      required: true,
    },
  },
  computed: {
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
  },
};
</script>
<style scoped>
.prtl-5 {
  padding-right: 5px !important;
}
.prtl-10 {
  padding-right: 10px !important;
}
.prtl-15 {
  padding-right: 15px !important;
}
.prtl-20 {
  padding-right: 20px !important;
}
.prtl-25 {
  padding-right: 25px !important;
}
.prtl-30 {
  padding-right: 30px !important;
}
.prtl-40 {
  padding-right: 40px !important;
}
.prtl-45 {
  padding-right: 45px !important;
}
.prtl-50 {
  padding-right: 52px !important;
}
.prtl-60 {
  padding-right: 62px !important;
}
.pltr-5 {
  padding-left: 5px !important;
}
.pltr-10 {
  padding-left: 10px !important;
}
.pltr-15 {
  padding-left: 15px !important;
}
.pltr-20 {
  padding-left: 20px !important;
}
.pltr-25 {
  padding-left: 25px !important;
}
.pltr-30 {
  padding-left: 30px !important;
}
.pltr-40 {
  padding-left: 40px !important;
}
.pltr-45 {
  padding-left: 45px !important;
}
.pltr-50 {
  padding-left: 52px !important;
}
.pltr-60 {
  padding-left: 62px !important;
}
</style>
