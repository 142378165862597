import axios from "axios";
import host from "./../../main";

export default {
  namespaced: true,
  state: {
    summerSchoolData: null,
    all_summer_centers:[],
    initiatives: [],
    initiatives_students: [],
    activities_students: [],
    subjects: [],
    levels: [],
    skills: [],
    divisions: [],
    activityType: [],
    activities: [],

    students: [],
    calendars: [],
    addresses: [],
    weeks: [],
    lectures: [],
    employees: [],
    committee: [],
    activityOpreations: [],
    activites: [],
    activityplans: [],

    teachers: [],
    committee: [],
    class_assign_hall: [],
    talents: [],
    schedules: [],
    days: [],
    weeks: [],
    shiftTimes: [],
    SuperVisorType: [],
    supervisors: [],
    placeTypesList: [],
    shoolTypes: [],
    evaluations: [],
    studentTypes: [],
    roles: [],
    qualifications: [],
    genders: [],
    maritalStatus: [],
    employeeStates: [],
    workPeriods: [],
    school_phases:[]
  },
  getters: {
    getDivisionName: (state) => (id) => {
      let division = state.divisions.find((item) => item.id == id);
      if (division) {
        return division.name;
      }
    },

    getClassDivisions: (state, getters) => (fk_level) => {
      if (state.class_assign_hall && fk_level) {
        let divisions = state.class_assign_hall.filter(
          (item) => item.id == fk_level
        );
        if (divisions[0] && divisions[0].divisions) {
          let divisions_data = [];
          divisions[0].divisions.forEach((element) => {
            divisions_data.push({
              id: element,
              name: getters.getDivisionName(element),
            });
          });
          return divisions_data;
        }
      }
    },
    getLevelName: (state) => (id) => {
      let level = state.levels.find((item) => item.id == id);
      if (level) return level.level_with_phase_name;
    },
  },
  actions: {
    async getSchoolPhases({state,commit}){
      let school_phases = (
        await axios.get(host() + "api/summer/school-phases", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;

      return commit("getactivites", school_phases);
    },
    async getactivites({ state, commit }) {
      let activites = (
        await axios.get(host() + "api/summer/activity", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;

      return commit("getactivites", activites);
    },
    async getSchedules({ state, commit }) {
      let schedules = (
        await axios.get(host() + "api/summer/schedule-summer", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;

      return commit("getSchedules", schedules);
    },
    async getDays({ state, commit }) {
      let days = (
        await axios.get(host() + "api/summer/day-summer", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;
      return commit("getDays", days);
    },

    async getStudents({ state, commit }) {
      let students = (
        await axios.get(host() + "api/summer/student-summer", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;
      return commit("getStudents", students);
    },

    async getweeks({ state, commit }) {
      let weeks = (
        await axios.get(host() + "api/summer/week", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;
      return commit("getweeks", weeks);
    },

    async gettalents({ state, commit }) {
      let talents = (
        await axios.get(host() + "api/summer/talent", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;
      return commit("gettalents", talents);
    },

    async getSummerSchoolData({ state, commit }) {
      let summerSchoolData = (
        await axios.get(host() + "api/summer/school", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data;
      return commit("getSummerSchoolData", summerSchoolData);
    },
    async getAllSummerCenters({ state, commit },dirs=0) {
      let all_summer_centers = (
        await axios.get(host() + "api/summer/school-select-list?directorate=" + dirs, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data;
      return commit("getAllSummerCenters", all_summer_centers);
    },
    async getCommittee({ state, commit }) {
      let committee = (
        await axios.get(host() + "api/summer/committee", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;
      return commit("getCommittee", committee);
    },

    async getactivityOpreations({ state, commit }) {
      let activityOpreations = (
        await axios.get(host() + "api/summer/activity-operation", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;
      return commit("getactivityOpreations", activityOpreations);
    },
    // async getactivityplans({ state, commit }) {
    //   let activityplans = (
    //     await axios.get(host() + "api/summer/plan", {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     })
    //   ).data.results;
    //   return commit("getactivityplans", activityplans);
    // },
    
    async getInitiativesStudents({ state, commit }) {
      let initiatives_students = (
        await axios.get(host() + "api/summer/student-initiative", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;
      return commit("getInitiativesStudents", initiatives_students);
    },
    async getActivitiesStudents({ state, commit }) {
      let activities_students = (
        await axios.get(host() + "api/summer/add-activity-to-student", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data;
      return commit("getActivitiesStudents", activities_students);
    },
    async getInitiatives({ state, commit }) {
      let initiatives = (
        await axios.get(host() + "api/summer/initiative", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;
      return commit("getInitiatives", initiatives);
    },
    async getSubjects({ state, commit }) {
      let subjects = (
        await axios.get(host() + "api/summer/subject", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data.results;
      return commit("getSubjects", subjects);
    },
    async getLevels({ state, commit }) {
      let levels = (
        await axios.get(host() + "api/summer/level", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;
      return commit("getLevels", levels);
    },
    async getSkills({ state, commit }) {
      let skills = (
        await axios.get(host() + "api/summer/skill", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;
      return commit("getSkills", skills);
    },
    async getEmployees({ state, commit }) {
      let employees = (
        await axios.get(host() + "api/summer/employee", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data;
      return commit("getEmployees", employees);
    },

    async getDivisions({ state, commit }) {
      let divisions = (
        await axios.get(host() + "api/summer/division-summer", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;
      return commit("getDivisions", divisions);
    },
    async getActivities({ state, commit }) {
      let divisions = (
        await axios.get(host() + "/api/summer/activity", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getActivities", divisions);
    },
    async getActivityType({ state, commit }) {
      let activityType = (
        await axios.get(host() + "api/summer/activity-type", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getActivityType", activityType);
    },
    async getEmployees({ state, commit }) {
      let employees = (
        await axios.get(host() + "api/summer/employee", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getEmployees", employees);
    },
    async getCalendars({ state, commit }) {
      let calendars = (
        await axios.get(host() + "api/summer/calendar", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;
      return commit("getCalendars", calendars);
    },
    async getAddresses({ state, commit }) {
      let addresses = (
        await axios.get(host() + "api/summer/address", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data.results;
      return commit("getAddresses", addresses);
    },
    async getWeeks({ state, commit }) {
      let weeks = (
        await axios.get(host() + "api/summer/week", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data.results;
      return commit("getWeeks", weeks);
    },
    async getLectures({ state, commit }) {
      let lectures = (
        await axios.get(host() + "api/summer/lecture-summer", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data.results;
      return commit("getLectures", lectures);
    },
    async getAllTeachers({ state, commit }) {
      let teachers = (
        await axios.get(host() + "api/summer/get-teacher", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getAllTeachers", teachers);
    },
    async getClassAssignHall({ state, commit }) {
      let class_assign_hall = (
        await axios.get(host() + "/api/summer/level-division-data", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page_size: 1000,
          },
        })
      ).data;
      return commit("getClassAssignHall", class_assign_hall);
    },
    async getWeeks({ state, commit }) {
      let weeks = (
        await axios.get(host() + "/api/summer/week", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data.results;
      return commit("getWeeks", weeks);
    },
    async getShiftTimes({ state, commit }) {
      let shiftTimes = (
        await axios.get(host() + "api/summer/choices/shift-time", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getShiftTimes", shiftTimes);
    },
    async getSuperVisorType({ state, commit }) {
      let SuperVisorType = (
        await axios.get(host() + "api/summer/choices/super-visor", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getSuperVisorType", SuperVisorType);
    },
    async getSupervisors({ state, commit }) {
      let supervisors = (
        await axios.get(host() + "api/summer/supervisor/list/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getSupervisors", supervisors);
    },
    async getPlaceTypesList({ state, commit }) {
      let placeTypesList = (
        await axios.get(host() + "api/summer/choices/place-type", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getPlaceTypesList", placeTypesList);
    },
    async getShoolTypes({ state, commit }) {
      let shoolTypes = (
        await axios.get(host() + "api/summer/choices/school-type", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getShoolTypes", shoolTypes);
    },
    async getEvaluations({ state, commit }) {
      let evaluations = (
        await axios.get(host() + "api/summer/choices/evaluation", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getEvaluations", evaluations);
    },
    async getStudentTypes({ state, commit }) {
      let studentTypes = (
        await axios.get(host() + "api/summer/choices/student-type", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getStudentTypes", studentTypes);
    },
    async getRoles({ state, commit }) {
      let roles = (
        await axios.get(host() + "api/summer/choices/role", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getRoles", roles);
    },
    async getQualifications({ state, commit }) {
      let qualifications = (
        await axios.get(host() + "api/summer/choices/qulification", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getQualifications", qualifications);
    },
    async getGenders({ state, commit }) {
      let genders = (
        await axios.get(host() + "api/summer/choices/gender", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getGenders", genders);
    },
    async getMaritalStatus({ state, commit }) {
      let maritalStatus = (
        await axios.get(host() + "api/summer/choices/marital-status ", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getMaritalStatus", maritalStatus);
    },
    async getEmployeeStates({ state, commit }) {
      let employeeStates = (
        await axios.get(host() + "api/summer/choices/employee-state ", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getEmployeeStates", employeeStates);
    },
    async getWorkPeriods({ state, commit }) {
      let workPeriods = (
        await axios.get(host() + "api/summer/choices/work-periods ", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
      ).data;
      return commit("getWorkPeriods", workPeriods);
    },
  },
  mutations: {
    getSchoolPhases(state, school_phases) {
      state.school_phases = school_phases;
    },
    getWorkPeriods(state, workPeriods) {
      state.workPeriods = workPeriods;
    },
    getEmployeeStates(state, employeeStates) {
      state.employeeStates = employeeStates;
    },
    getMaritalStatus(state, maritalStatus) {
      state.maritalStatus = maritalStatus;
    },
    getGenders(state, genders) {
      state.genders = genders;
    },
    getQualifications(state, qualifications) {
      state.qualifications = qualifications;
    },
    getRoles(state, roles) {
      state.roles = roles;
    },
    getStudentTypes(state, studentTypes) {
      state.studentTypes = studentTypes;
    },
    getEvaluations(state, evaluations) {
      state.evaluations = evaluations;
    },
    getShoolTypes(state, shoolTypes) {
      state.shoolTypes = shoolTypes;
    },
    getPlaceTypesList(state, placeTypesList) {
      state.placeTypesList = placeTypesList;
    },
    getSupervisors(state, supervisors) {
      state.supervisors = supervisors;
    },
    getSuperVisorType(state, SuperVisorType) {
      state.SuperVisorType = SuperVisorType;
    },
    getShiftTimes(state, shiftTimes) {
      state.shiftTimes = shiftTimes;
    },
    getWeeks(state, weeks) {
      state.weeks = weeks;
    },
    getClassAssignHall(state, class_assign_hall) {
      state.class_assign_hall = class_assign_hall;
    },
    getSummerSchoolData(state, summerSchoolData) {
      state.summerSchoolData = summerSchoolData;
    },
    getAllSummerCenters(state, all_summer_centers) {
      state.all_summer_centers = all_summer_centers;
    },
    getCommittee(state, committee) {
      state.committee = committee;
    },

    getweeks(state, weeks) {
      state.weeks = weeks;
    },
    getStudents(state, students) {
      state.students = students;
    },

    getactivites(state, activites) {
      state.activites = activites;
    },
    getSchedules(state, schedules) {
      state.schedules = schedules;
    },
    getDays(state, days) {
      state.days = days;
    },

    getactivityOpreations(state, activityOpreations) {
      state.activityOpreations = activityOpreations;
    },
    // getactivityplans(state, activityplans) {
    //   state.activityplans = activityplans;
    // },

    getInitiatives(state, initiatives) {
      state.initiatives = initiatives;
    },

    getInitiativesStudents(state,initiatives_students){
      state.initiatives_students = initiatives_students;
    },
    getActivitiesStudents(state,activities_students){
      state.activities_students = activities_students;
    },
    getSubjects(state, subjects) {
      state.subjects = subjects;
    },
    getLevels(state, levels) {
      state.levels = levels;
    },
    getSkills(state, skills) {
      state.skills = skills;
    },
    getEmployees(state, employees) {
      state.employees = employees;
    },
    getDivisions(state, divisions) {
      state.divisions = divisions;
    },
    getActivityType(state, activityType) {
      state.activityType = activityType;
    },
    getActivities(state, activities) {
      state.activities = activities;
    },

    getCalendars(state, calendars) {
      state.calendars = calendars;
    },
    getAddresses(state, addresses) {
      state.addresses = addresses;
    },
    getWeeks(state, weeks) {
      state.weeks = weeks;
    },
    getLectures(state, lectures) {
      state.lectures = lectures;
    },
    getAllTeachers(state, teachers) {
      state.teachers = teachers;
    },

    gettalents(state, talents) {
      state.talents = talents;
    },
  },
};
