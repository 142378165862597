  <template>
<VForm ref="form">
  <v-expansion-panels>
    <v-expansion-panel  elevation="1" >
      <v-expansion-panel-title class="px-4">
          <span>{{$t('globals.select-criteria')}}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text >
        <VRow class="py-2">
          <VCol  cols="12" md="4">
              <VAutocomplete
                :items="levels"
                v-model="studenttalent.level"
                prepend-inner-icon="mdi-stairs-box"
                item-title="level_with_phase_name"
                item-value="id"
                :label="$t('summer.level.name_ar')"
                persistent-hint
                density="compact"
                clearable
                hide-details
                @update:modelValue="
                  getDivisionByLevel(),getStudentsbylevel(),
                    (this.studenttalent.division = null),
                    (this.student_levels = [])
                "
              ></VAutocomplete>
          </VCol>
          <VCol  cols="12" md="4">
              <VAutocomplete
                :items="divisions"
                v-model="studenttalent.division"
                prepend-inner-icon="mdi-shape-outline"
                item-title="name"
                item-value="fk_division"
                :label="$t('division.name')"
                clearable
                persistent-hint
                hide-details
                density="compact"
                @update:modelValue="getStudentsbylevel"
              ></VAutocomplete>
          </VCol>
          <VCol  cols="12" class="mt-2">
              <VAutocomplete
                :items="talents"
                v-model="talent_selected"
                return-object
                prepend-inner-icon="mdi-star"
                item-title="title"
                multiple
                clearable
                hide-details
                chips
                closable-chips
                :item-value="item=>item.id"
                :label="$t('summer.name-talent')"
                persistent-hint
                density="compact"
              >
                <template v-slot:chip="{props , item}">
                  <v-chip 
                    class="mx-1"
                    style="font-size:0.9rem"
                    v-bind="props" :text="item.raw.name">
                  </v-chip>
                </template>
              </VAutocomplete>
          </VCol>
        </VRow>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <!-- <v-progress-linear 
    color="indigo" 
    :reverse="$i18n.locale!='en'"
    height="3px" 
    indeterminate 
    v-if="loading"
    >
  </v-progress-linear> -->
  <VCard class="mt-2 " :loading="loading" :disabled="loading">
    <v-card-text class="pb-0">
        <VRow class="mb-2 mt-1">
          <VCol cols="12" md="6">
            <v-text-field
              color="indigo"
              v-model="txt_search"
              density="compact"
              hide-details
              clearable
              @input="getStudentsbylevel(page=1)"
              prepend-inner-icon="mdi-magnify"
              :label="$t('globals.student-search')"
            />
          </VCol>
        </VRow>
        <v-card  v-if="student_levels.length>0" class="mt-2 overflow-x-auto" >
          <div class="overflow-x-auto">
            <v-table  
              density="compact"
              class="w-100"
              :height="student_levels?.length>9?420:null"
              fixed-header
              >
              <thead>
                <tr>
                  <th  class="text-center">{{$t('globals.number')}}</th>
                  <th  style="min-width:200px">{{$t('school.student-name')}}</th>
                  <th  v-for="talent in filter_talents" :key="talent" style="">
                    {{talent.title}}
                  </th>
                </tr>
                <tr>
                  
                </tr>
              </thead>
              <tbody>
                <tr v-for="(student,index) in student_levels" :key="student" >
                  <td style="width:10px;" class="text-center">{{index+1}}</td>
                  <td style="width:250px;">
                      <span>{{student.name}}</span>
                  </td>
                  <td v-for="talent in filter_talents" :key="talent" > 
                    <v-lazy>
                      <v-checkbox 
                        v-model="student.talent"
                        :value="talent.id"
                        hide-details
                        density="compact" 
                        @change="student.changed!==true?student.changed=true:false"
                        color="indigo" 
                        >
                      </v-checkbox>
                    </v-lazy>

                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>

          <v-divider/>
          <div class="d-flex mt-1 "  id="pagination-bar" >
            <v-pagination  
                v-model="page" 
                :length="length"   
                density="compact"
                show-first-last-page
                >
            </v-pagination>
            <div class="d-flex ">
              <v-select 
                v-model="perPage" 
                class="pa-0" 
                :items="itemsPerPage"  
                item-value="value" 
                item-title="text" 
                density="compact" 
                hide-details
                variant="text"
                >
              </v-select>
              <span class="mt-2 px-2">{{$t('globals.per-page')}} {{$t('globals.from')}} [ {{totalItems}} ] {{$t('globals.item')}}</span>
            </div>
          </div>
        </v-card>
        <v-card  v-else  class="pa-2 my-2 text-center" >
          <span >
            {{ $t("globals.not-found-items") }}
          </span>
        </v-card>
    </v-card-text>
    <VCardActions
      class="px-3"
      v-if="
        student_levels.length>0
      "
    >
       <VBtn
        :loading="loadingSave"
        v-if="
          CheckGetPermission('summer_center.add_studentsummer',['sys_admin','summer_admin','user'])||
          CheckGetPermission('summer_center.add_studentsummer',['sys_admin','summer_admin','user'])
        "
        class="bg-indigo"
        @click="saveData"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.save") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
  </VCard>
 
</VForm>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      students:[],
      change_student_talen:[],
      txt_search:null,
      perPage:10,
      itemsPerPage: [
        {value:10,text:"10"},
        {value:25,text:"25"},
        {value:50,text:"50"},
        {value:100,text:"100"},
        {value:null,text:this.$t("globals.all")},
      ],
      pagination:{},
      page:1,
      length:null,
      totalItems:null,
      talent_selected:[],
      filter_talents:[],
      loading:false,
      student_levels: [],
      loadingSave:false,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      divisions: [],
      studenttalent: {
        division: undefined,
        level: undefined,
      },
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.fk_year = localStorage.getItem("current_year");
      await this.getLevels();
      await this.gettalents();
      await this.getStudentsbylevel()
      this.selectTalents()
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      levels: (state) => state.summer.levels,
      talents: (state) => state.summer.talents,
      students: (state) => state.summer.students,
      user: (state) => state.User,
    }),
    displayItems(){
      const start_index=(this.currentPage -1) * this.perPage
      const end_index= start_index+this.perPage
      return this.student_talents.slice(start_index,end_index)
    },
    students_talents_data(){
      return this.student_levels.filter(item=>this.change_student_talen.includes(item.id)).map(item=>{
        return {id:item.id,talent:item.talent}
      })
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  methods: {
    ...mapActions({
      getLevels: "summer/getLevels",
      getStudents: "summer/getStudents",
      gettalents: "summer/gettalents",
    }),
    selectTalents(){
      const  not_assigned_talents=this.talents.filter(talent=>!this.student_levels.some(student=>student.talent.includes(talent.id)))
      if(this.talents.length>0){
        if(not_assigned_talents.length==0){
          this.filter_talents=[this.talents[this.talents.length-1]]
        }else{
          this.filter_talents=not_assigned_talents
        }
      }
    },
    addStudentsToList(id){
      if(!this.change_student_talen.includes(id)){
        this.change_student_talen.push(id)
      }
    },
    async getStudentsbylevel(page=1) {
      this.loading=true
      await this.axios.get(
        this.base_url + "api/summer/add-talent-to-student",
        {
          params: {
            level: this.studenttalent.level,
            division: this.studenttalent.division,
            search: this.txt_search,
            page: page,
            page_size: this.perPage,
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      ).then(response=>{
        this.loading=false
        this.student_levels=response?.data?.results??[]
        this.pagination=response.data.pagination
        this.length=this.pagination?.num_pages
        this.itemsPerPage[4]['value']=this.pagination?.count
        this.totalItems=this.pagination?.count
      })
      this.loading=false
    },
    async getDivisionByLevel() {
      if(this.studenttalent.level){
        let levelDivisions = await this.axios.get(
          this.base_url + `api/summer/get-level-division/${this.studenttalent.level}`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        );
        this.divisions = levelDivisions.data.division;
      }
    },
    resetForm() {
      this.studenttalent.division=null
      this.studenttalent.level=null
      // this.talent_selected=[]
      this.studenttalent = {};
      this.change_student_talen=[]      
      this.getStudentsbylevel()
    },
    async saveData() {
      this.loadingSave=true
      let result = await this.axios
        .post(this.base_url + `api/summer/add-talent-to-student`,this.student_levels.filter(student=>student.changed==true), {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.loadingSave=false
          this.$emit("successAlert", this.$t("globals.data_added"));
          // this.resetForm();
        })
        .catch((error) => {
          this.loadingSave=false
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
  watch:{
    talent_selected(){
      if(this.talent_selected.length>0){
        this.filter_talents=this.talent_selected
      }else{
        this.selectTalents()
      }
    },
    perPage() {
      this.getStudentsbylevel();
    },
    page() {
      this.getStudentsbylevel(this.page);
    },
  }
};
</script>
<style scoped>

.select-container {
  height: 280px;
  box-shadow: rgb(0, 0, 0) -5px 5px 3px -5px inset;
  overflow-y: auto;
}
.v-label{
  overflow: visible !important;
}
.v-input--density-compact {
    --v-input-control-height: 35px;
    --v-input-padding-top: 8px;
}
td span{
  padding: 0 !important;
  font-size: 12px !important;
}
#pagination-bar *{
  font-size: 0.95rem ;
}
</style>
