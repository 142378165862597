<template>
  <v-form ref="form" @submit.prevent>
    <v-card class="pa-2" v-if="!exist">
      <h3 class="text-grey-darken-2 px-2">
        {{ $t("globals.select-criteria") }}
      </h3>
      <v-container class="pa-0">
        <div
          class="my-2 mt-6 d-flex align-center flex-wrap flex-grow-1"
          style="gap: 1.5rem"
        >
          <v-col
            class="pt-0 px-3"
            cols="12"
            xs="12"
            sm="12"
            md="3"
            lg="3"
            v-if="visiGovernorat"
          >
            <v-card-item class="pa-0">
              <v-autocomplete
                v-model="dropList.gov"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-city"
                :items="governorates"
                :label="$t('globals.governorate')"
                persistent-hint
                density="compact"
                clearable
                @update:modelValue="
                  (dropList.dir = undefined),
                    (dropList.schoolSummer = undefined)
                "
              ></v-autocomplete>
            </v-card-item>
          </v-col>
          <v-col
            v-if="visiDirectorate"
            align-self=""
            class="pt-0 px-3"
            cols="12"
            xs="12"
            sm="12"
            md="3"
            lg="3"
          >
            <v-card-item class="pa-0">
              <v-autocomplete
                v-model="dropList.dir"
                item-value="id"
                item-title="name_ar"
                prepend-inner-icon="mdi-map-outline"
                :items="getDirectorateByGov(dropList.gov)"
                :label="$t('globals.directorate')"
                persistent-hint
                density="compact"
                clearable
                @update:modelValue="
                  sendSummerCenterRequest(dropList.dir),
                    (dropList.schoolSummer = undefined),
                    (fk_role = undefined)
                "
              ></v-autocomplete>
            </v-card-item>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
            <v-autocomplete
              v-model="dropList.schoolSummer"
              item-value="id"
              item-title="name_ar"
              prepend-inner-icon="mdi-domain"
              :items="summerSchools"
              :label="$t('globals.summer-centre')"
              persistent-hint
              density="compact"
              clearable
            ></v-autocomplete>
          </v-col>
          <!-- <v-col cols="6" md="3" sm="3">
            <v-autocomplete
              color="indigo"
              v-model="dropList.type_id"
              density="compact"
              item-title="type"
              item-value="id"
              clearable="false"
              prepend-inner-icon="mdi-calendar-week"
              :items="[
                { id: 1, type: this.$t('reportpublic.Open') },
                { id: 2, type: this.$t('reportpublic.Typical') },
              ]"
              :label="$t('summer.summer_type')"
            />
          </v-col> -->
          <v-col cols="6" md="3" sm="3">
            <v-autocomplete
              color="indigo"
              v-model="dropList.start_data"
              :items="calendar"
              density="compact"
              item-title="date"
              item-value="date"
              prepend-inner-icon="mdi-calendar-week"
              clearable
              :label="$t('globals.from')"
            />
          </v-col>
          <v-col cols="6" md="3" sm="3">
            <v-autocomplete
              color="indigo"
              v-model="dropList.end_data"
              :items="calendar"
              density="compact"
              item-title="date"
              item-value="date"
              prepend-inner-icon="mdi-calendar-week"
              clearable
              :label="$t('globals.to')"
            />
          </v-col>
          <div>
            <v-btn
              :loading="dataLoading"
              max-width="100px"
              density="comfortable"
              append-icon="mdi-presentation"
              class="table"
              style="width: 100%; border-collapse: collapse"
              color="indigo"
              type="submit"
              @click="fetchData((page = 1))"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-card>
  </v-form>
  <v-card id="report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t('reportpublic.daily_summer_operations_report')}}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title class="mb-5 pa-0">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            @click="printTable((all = true))"
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="visiBack"
            density="compact"
            class="mx-1"
            @click="exist = false"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{  $t('reportpublic.daily_summer_operations_report') }}

              {{ title }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>
                {{ length }}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-item v-if="data?.length > 0">
      <v-table class="elevation-1 rounded stable">
        <thead>
          <tr>
            <th v-for="header in headers" :key="header.key">
              {{ header.title }}
            </th>
          </tr>
        </thead>
        <tbody v-for="(i, index) in data" :key="index">                         
            <tr>               
              <td style="border-bottom: 0 !important" v-if="i.gov_name !== ''">
                {{  i.gov_name }}
              </td>
              <td
                v-else
                style="border-top: 0 !important; border-bottom: 0 !important"
              >
              </td>
                <td style="border-bottom: 0 !important" v-if=" i.dir_name !== ''">
                {{  i.dir_name }}
              </td>
              <td
                v-else
                style="border-top: 0 !important; border-bottom: 0 !important"
              >
              </td>            
                <td style="border-bottom: 0 !important" v-if=" i.school_name !== ''">
                {{  i.school_name }}
              </td>
              <td
                v-else
                style="border-top: 0 !important; border-bottom: 0 !important"
              >
              </td>
          
              <td>{{ i.date }}</td>
              <td>{{ i.students }}</td>
              <td class>{{ i.students_attendance }}</td>
              <td>{{ i.employees }}</td>
              <td>{{ i.employees_attendance }}</td>
            </tr>
        </tbody>
      </v-table>
    </v-card-item>

    <!-- <div v-if="data?.length != 0" class="d-flex" id="pagination-bar">
      <v-pagination
        v-model="page"
        :length="length"
        density="compact"
        show-first-last-page
      >
      </v-pagination>
       <div class="d-flex">
        <v-select
          v-model="perPage"
          class="pa-0"
          :items="itemsPerPage"
          item-value="value"
          item-title="text"
          density="compact"
          variant="text"
        >
        </v-select>
        <span class="mt-2 px-2"
          >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
          {{ totalItems }} ] {{ $t("globals.item") }}</span
        >
      </div>
    </div> -->
    <v-card-item v-else>
      <h3 class="text-center text-grey-darken-2">
        {{ $t("globals.not-found-items") }}
      </h3>
    </v-card-item>
  </v-card>
</template>

<script>
import axios from "axios";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    ReportHeader,
  },
  data() {
    return {
      title: "",
      dropList: {},
      calendar: [],
      summerSchools: [],
      length: 0,
      exist: false,
      visiBack: true,
      visiGovernorat: false,
      visiDirectorate: false,
      governorates: [],
      data: [],
      dataLoading: false,
      headers: [],
      rules: [(value) => (value ? true : this.$t("globals.required_field"))],
    };
  },
  created() {
    if (localStorage.getItem("role") >= 2)
    {
      var gov = localStorage.getItem("governorate");
      this.dropList.gov = parseInt(gov);
    } 
      
  },
  async beforeCreate() {
    await this.$store.commit("updateLoadingValue", true);
    try {
      await this.getGovernorates();
      await this.getDirectorate();
      await this.getCalendar();
      this.visiGovernorat = true;
      this.visiDirectorate = true;
      var role = localStorage.getItem("role");
      var gov = localStorage.getItem("governorate");
      if (role == 0 || role == 1) {
        this.governorates = this.governorate;
      } else if (role == 2) {
          this.visiGovernorat = false;
      } else if (role == 3) {
      
 
         
        this.dropList.dir = parseInt(localStorage.getItem("directorate"));

        this.visiGovernorat = false;
        this.visiDirectorate = false;
        this.sendSummerCenterRequest(this.dropList.dir);
      }
    } catch {}
    await this.$store.commit("updateLoadingValue", false);
  },
  computed: {
    ...mapState({
      governorate: (state) => state.school.governorates,
      all_summer_centers: (state) => state.summer.all_summer_centers,
    }),
    ...mapGetters({
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
    }),
  },
  methods: {
    ...mapActions({
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
      getAllSummerCenters: "summer/getAllSummerCenters",
    }),
    getTitle() {
      this.title = "";
       let dir = '';
      if (this.dropList.gov) {
        this.title +=
          " في محافظة " +
          " ( " +
          this.governorate.find((item) => item.id == this.dropList.gov)
            .name_ar +
          " ) ";
         dir=  this.getDirectorateByGov(this.dropList.gov)
      } else {
        this.title += " في كل المحافظات ";
      }
      if (this.dropList.dir) {
       
        
        this.title +=
          " في مديرية " +
          " ( " +
          dir.find((item) => item.id == this.dropList.dir).name_ar +
          " ) ";
      } else {
        this.title += " في كل المديريات ";
      }

      if (this.dropList.schoolSummer) {
        this.title +=
          " الدورة الصيفية " +
          " ( " +
          this.all_summer_centers.find(
            (item) => item.id == this.dropList.schoolSummer
          ).name_ar +
          " ) ";
      } else {
        this.title += "  لكل المراكز الصيفية ";
      }
      if (this.dropList.start_data && !this.dropList.end_data)
        this.title += " في تاريخ " + this.dropList.start_data;
      else if (this.dropList.start_data && this.dropList.end_data)
        this.title +=
          " من " +
          "(" +
          this.dropList.start_data +
          ")" +
          " الى " +
          "(" +
          this.dropList.end_data +
          ")";
      else if (this.dropList.end_data)
        this.title += " الى تاريخ " + this.dropList.end_data;
    },
    async sendSummerCenterRequest(dir) {
      if (dir) {
        await this.getAllSummerCenters(dir);
        this.summerSchools = this.all_summer_centers;
      } else this.summerSchools = [];
    },
    getDirectorateByGov(gov) {
      if (localStorage.getItem("role") == 3) {
        var l = this.getDirectorateByGovernorates(gov);

        return l.filter(
          (item) => item.id == localStorage.getItem("directorate")
        );
      }

      return this.getDirectorateByGovernorates(gov);
    },

    async getCalendar() {
      await this.axios
        .get(this.base_url + "api/summer/calendar", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          if (response.data) {
            this.calendar = response.data.results;
          }
        });
    },
    async fetchData(page) {
      // let name = this.$t("school.governorates");
      // if (this.dropList.schoolSummer || this.dropList.dir)
      //   name = this.$t("globals.summer-centre");
      // else if (this.dropList.gov) name = this.$t("globals.directorate");

      this.headers = [
        
          {
          title: this.$t("globals.governorate"),
          key: "gov_name",
          sortable: false,
        },
          {
          title: this.$t("globals.directorate"),
          key: "dir_name",
          sortable: false,
        },
        {
          title: this.$t("globals.summer-centre"),
          key: "school_name",
          sortable: false,
        },
        {
          title: this.$t("financial.date"),
          key: "date",
          sortable: false,
        },
        {
          title: this.$t("school.student_number"),
          key: "students",
          sortable: false,
        },
        {
          title: this.$t("globals.present-students"),
          key: "students_attendance",
          sortable: false,
        },
        {
          title: this.$t("summer.employee_count"),
          key: "employees",
          sortable: false,
        },
        {
          title: this.$t("statistics.male-employee"),
          key: "employees_attendance",
          sortable: false,
        },
      ];

      this.getTitle();
      this.dataLoading = true;
      await this.axios(
          `${this.base_url}public-reports/dialy-summer-operations-report`,
        {
          params: {
            gov: this.dropList.gov,
            dir: this.dropList.dir,
            summer_id: this.dropList.schoolSummer,
            start_data: this.dropList.start_data,
            end_data: this.dropList.end_data,
          },

          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((response) => {
          this.data = response.data;
          this.length = this.data.length;
          this.exist = true;
          // this.pagination = response.data.pagination;
          // this.length = response.data.pagination.num_pages;
          // this.itemsPerPage[4].value = response.data.pagination.count;
          // this.totalItems = response.data.pagination.count;
        })
        .catch((err) => {});
      this.dataLoading = false;
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  watch: {
    perPage() {
      this.fetchData();
    },
    page() {
      this.fetchData(this.page);
    },
  },
};
</script>
<style>
.stable {
  width: 100%;
}

.stable th {
  padding: 10px;
  border: 0.1px solid #649add !important;
  background-color: #cfd8dc !important;
}

.stable td {
  background-color: #fff;
  padding: 8px;
}
</style>
