<template>
  <v-card class="pa-2" v-if="exist">
    <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
    <v-container class="mb-0">
      <v-form @submit.prevent>
        <VRow>
          <v-col cols="6" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="lessonRecord.fk_week"
              density="compact"
              item-title="week_name"
              item-value="id"
              prepend-inner-icon="mdi-bookshelf"
              clearable
              :items="weeks"
              :label="$t('summer.calendar.week-name')"
              @update:model-value="getDateList(), resetForm(1)"
              :rules="rules"
            />
          </v-col>
          <v-col cols="6" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="lessonRecord.date"
              density="compact"
              item-title="week_name"
              item-value="id"
              prepend-inner-icon="mdi-bookshelf"
              clearable
              :items="listWeeks"
              :label="$t('summer.calendar.hijri-date')"
              :rules="rules"
              @update:model-value="resetForm(0)"
            />
          </v-col>
         
          <v-col>
              <v-btn
              type="submit"
              max-width="100px"
              density="comfortable"
              append-icon="mdi-presentation"
              class="w-100 text-white"
              color="indigo"
              @click="checkRule()"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
                <VBtn class="mx-3" size="small" @click="clearweek()">
                  {{ $t("globals.clear") }}
                  <VIcon icon="mdi-broom" color="golden" end></VIcon>
                </VBtn>
          </v-col>
           
        </VRow>
      </v-form>
    </v-container>
  </v-card>
  <v-card id="report" v-else>
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.VisitsCarriedOutDuringTheDay") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
          <v-btn density="compact" class="mx-1" @click="exist = !exist">
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.VisitsCarriedOutDuringTheDay") }}
              {{ $t("reportpublic.InDirectorate") }}
              {{
                this.$i18n.locale == "ar"
                  ? results2.directorate_name_ar
                  : results2.directorate_name_en
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{ " هـ " + results2.date }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-item>
      <v-row v-if="header.visitors_in_entity">
        <v-col>
          <v-container class="overflow-x-hidden">
            <table class="report-table text-center">
              <thead>
                <tr>
                 
                  <th rowspan="2">
                    {{ $t("globals.summer-centre") }}
                  </th>
                  
                  <th :colspan="header.visitors_in_entity.length">
                    {{ $t("reportpublic.Authorities") }}
                  </th>
                  <th rowspan="2">
                    {{ $t("reportpublic.TotalVisits") }}
                  </th>
                  
                </tr>
                <tr>
                  <th
                    v-for="(data, index) in header
                      ? header.visitors_in_entity
                      : []"
                    :key="index"
                    class="right-header"
                  >
                    {{ data.entity_name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in results2.summer_data" :key="index">
                  <td>
                    {{
                      this.$i18n.locale == "ar"
                        ? data.summer_name_ar
                        : data.summer_name_en
                    }}
                  </td>
                  <td
                    v-for="(data2, index) in data.visitors_in_entity"
                    :key="index"
                    class="right-header"
                  >
                    {{ data2.visitor_summer_count }}
                  </td>
                  <th>{{ data.total_visitors }}</th>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th class="right-footer rounded-be">
                    {{ $t("reportpublic.GrandTotal") }}
                  </th>
                  <th
                    class="right-footer rounded-be"
                    v-for="(data2, index) in results2.total_visitors_in_entity"
                    :key="index"
                  >
                    {{ data2.visitors }}
                  </th>
                  <th class="right-footer rounded-be">
                    {{ results2.total_for_all_visitors }}
                  </th>
                </tr>
              </tfoot>
            </table>
          </v-container>
        </v-col>
        <v-col cols="12" md="12">
          <v-card class="px-2 pt-1 my-4" style="height: 400px">
            <chart :option="option_10" autoresize />
          </v-card>
        </v-col>
      </v-row>
      <v-card v-else class="pa-2">
        <h3 class="text-center text-grey-darken-2">
          {{ $t("globals.not-found-items") }}
        </h3>
      </v-card>
    </v-card-item>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async mounted() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getWeeks();
      await this.fetchWeek()
      await this.getDateList()
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },

  methods: {
    ...mapActions({
      getWeeks: "summer/getWeeks",
    }),
    async getDateList() {
      if (this.lessonRecord.fk_week) {
        await this.axios
          .get(this.base_url + "api/summer/get-work-days", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              week_id: this.lessonRecord.fk_week,
            },
          })
          .then((response) => {
            this.listWeeks = response.data.date;
          })
          .catch((error) => {
              
          });
      } else {
        this.resetForm();
      }
    },
    async fetchWeek() {
      await axios
        .get(`${this.base_url}statistics/get-current-date`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.lessonRecord.fk_week = response.data.week_id;
          this.lessonRecord.date = response.data.current_date;
          this.currnet_week_id = response.data.week_id;
          this.currnet_date_id = response.data.current_date;       
        })
        .catch((error) => {
            
        });
    },
    async fetchData() {
      await axios
        .get(
          `${this.base_url}public-reports/directorate-reports/total-number-of-visitors-carried-out?date=${this.lessonRecord.date}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.results2 = response.data.results;
          if (this.results2.summer_data[0]) {
            this.header = this.results2.summer_data[0];
            this.header2 = this.header
              ? this.header.visitors_in_entity.map((it) => it.entity_name)
              : [];
            this.gover = response.data.results.summer_data;
            this.series = this.header2.map((item) => {
              return {
                type: "bar",
                label: {
                  show: true,
                  position: "top",
                  rotate: "90",
                },
                itemStyle: { borderRadius: [4, 4, 0, 0] },
              };
            });
            this.header2.unshift("name");
            this.source = this.gover.map((item) => [
              this.$i18n.locale == "ar"
                ? item.summer_name_ar
                : item.summer_name_en,
              [...item.visitors_in_entity.map((it) => it.visitor_summer_count)],
            ]);
            this.source = this.source.map(([firstItem, nestedArray]) => [
              firstItem,
              ...nestedArray,
            ]);
            this.fillChart();
          }
        })

        .catch((error) => {
            
        });
    },
    fillChart() {
      this.option_10 = {
        title: {
          right: "center",
          textStyle: {
            fontFamily: "Almarai",
            fontSize: 18,
          },
          subtextStyle: {
            fontFamily: "Almarai",
            fontSize: 10,
          },
        },
        dataset: [
          {
            dimensions: this.header2,
            source: this.source,
          },
          {
            transform: {
              type: "sort",
              config: { dimension: "constant", order: "desc" },
            },
          },
        ],
        xAxis: {
          type: "category",
          axisLabel: {
            interval: 0,
            rotate: 40,
          },
        },
        yAxis: {
          type: "value",
          position: this.$i18n.locale == "ar" ? "right" : "",
          boundaryGap: [0, 0.5],
        },
        series: this.series,
        legend: {
          bottom: "bottom",
          top: "85%",
          itemHeight: 12,
          itemWidth: 19,
          borderWidth: 1,
          borderColor: "black",
          bottom: 20,
        },
        grid: {
          bottom: "15%",
          containLabel: true,
          top: "10%",
        },

        toolbox: {
          feature: {
            saveAsImage: {
              title: this.$t("dashboard.save-as-png"),
            },
          },
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            return (
              "<h5>" +
              params.name +
              "</h5>" +
              params.value[params.componentIndex + 1] +
              "&nbsp;&nbsp;&nbsp;" +
              params.marker +
              " " +
              params.seriesName
            );
          },
        },
      };
    },
    resetForm(resetAll) {
      if (resetAll) {
        this.lessonRecord.date = null;
        this.listWeeks = [];
        this.header = [];
        this.results2 = [];
        this.option_10 = {};
      } else {
        this.header = [];
        this.results2 = [];
        this.option_10 = {};
      }
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
    async checkRule() {
      if (this.lessonRecord.date && this.lessonRecord.fk_week) {
        this.fetchData();
        this.exist = !this.exist;
      }
    },
     async clearweek() {
      this.listWeeks = [];
      this.data_charts = [];
      this.results = [];
      this.results2 = [];
      this.lessonRecord.date = this.currnet_date_id;
            await this.getDateList()

      this.lessonRecord.fk_week = this.currnet_week_id;
    },
    
  },

  data() {
    return {
      id: 1,
      gover: [],
      source: [],
      header: { visitors_in_entity: [] },
      header2: [],
      option_10: {},
      results: [],
      results2: [],
      series: [],
      lessonRecord: {},
      listWeeks: [],
      exist: true,
      currnet_date_id: null,
      currnet_week_id: null,
      rules: [(value) => (value ? true : this.$t("globals.required_field"))],
    };
  },
  computed: {
    ...mapState({
      weeks: (state) => state.summer.weeks,
    }),
  },
  components: {
    ReportHeader,
  },
};
</script>
