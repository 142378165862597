<template>
  <VCard class="justify-center ">
    
    <v-card
      v-if="
        CheckGetPermission('summer_center.add_schedule', ['sys_admin', 'gen_admin']) ||
        CheckGetPermission('summer_center.change_schedule', ['sys_admin', 'gen_admin'])
      "
      flat
      >
      <VForm ref="form" @submit.prevent="">
        <VCardText class="pb-0">
          <VRow class="mt-2">
            <VCol class="pt-0 " cols="12" xs="12" sm="12" md="4" lg="4">
              <VTextField
                v-model="schedule.quotas_start_time"
                :label="$t('school.schedule_start_time')"
                density="compact"
                type="time"
                clearable
                :error-messages="
                  v$.schedule.quotas_start_time.$errors.map((e) => e.$message)
                "
                
                ref="startWeek"
              >
              </VTextField>
            </VCol>
            <VCol class="pt-0 " cols="12" md="4">
              <VTextField
                v-model="schedule.quotas_end_time"
                :label="$t('school.schedule_end_time')"
                density="compact"
                type="time"
                clearable
                :error-messages="v$.schedule.quotas_end_time.$errors.map((e) => e.$message)"
                
                ref="endWeek"
              >
              </VTextField>
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12" xs="12" sm="12" md="4" lg="4">
              <VCardItem class="pa-0">
                <VTextField
                  clearable
                  v-model="schedule.quotas"
                  prepend-inner-icon="mdi-format-text"
                  :label="$t('school.schedule_quotas')"
                  :placeholder="$t('school.placeholder_schedule_quotas')"
                  density="compact"
                  type="text"
                  :error-messages="v$.schedule.quotas.$errors.map((e) => e.$message)"
                  
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol cols="12" xs="12" sm="12" md="6" lg="6">
              <VCardItem class="pa-0">
                <VRadioGroup
                  inline
                  v-model="schedule.period"
                >
                  <VRadio :label="$t('school.morning_period')" :value="true"></VRadio>
                  <VRadio :label="$t('school.evening_period')" :value="false"></VRadio>
                </VRadioGroup>
              </VCardItem>
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12" xs="12" sm="12" md="8" >
                <VTextarea
                  prepend-inner-icon="mdi-note-outline"
                  v-model="schedule.note"
                  clearable
                  :label="$t('globals.note')"
                  counter="250"
                  no-resize
                  hide-details="auto"
                  density="compact"
                  rows="2"
                  color="indigo"
                  :error-messages="v$.schedule.note.$errors.map((e) => e.$message)"
                  
                ></VTextarea>
            </VCol>
          </VRow>
        </VCardText>
      </VForm>
      <VCardActions
        class="px-3"
      >
        <VBtn
          :loading="loading"
          class="bg-indigo"
          @click="saveSchedule"
          size="small"
          v-if="
            !is_update &&
            CheckGetPermission('summer_center.add_schedule', ['sys_admin', 'gen_admin'])
          "
        >
          <span class="text-white">
            {{ $t("globals.add") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn
          :loading="loading"
          v-if="
            is_update &&
            CheckGetPermission('summer_center.change_schedule', ['sys_admin', 'gen_admin'])
          "
          class="bg-success"
          @click="updateSchedule"
          size="small"
        >
          <span class="text-white">
            {{ $t("globals.edit") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="resetForm">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
    <!-- ##########    data Table  ########### -->
    <v-divider />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card >
      <v-card-text>
        <div class="d-flex justify-space-between mb-2">
          <div class="d-flex flex-fill">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                  <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                  <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="header in headers"
                    :key="header.key"
                    :label="header.title"
                    :value="header.key"
                    color="indigo"
                    v-model="selectedHead"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>

            <div style="width: 100%">
              <v-text-field
                color="indigo"
                v-model="txt_search"
                style="
                  border-radius: 30px;
                  border: 1px solid #ccc;
                  color: black;
                  height: 30px;
                  width: 90%;
                "
                class="search"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                :placeholder="$t('globals.search-here')"
                density="compact"
                variant="text"
                @input="getSchedules((page = 1))"
              >
              </v-text-field>
            </div>
          </div>
        </div>
        <v-data-table-server
          :headers="selectedHeaders"
          :items="tableList"
          v-model:sort-by="sortBy"
          fixed-footer
          :search="txt_search"
          density="compact"
          class="elevation-1"
          @update:options="getSchedules((page = 1))"
        >
          <template v-slot:column.checkbox="{ column }">
            <v-checkbox hide-details density="compact"> </v-checkbox>
          </template>
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
                </v-checkbox>
                <span> {{ val }}</span>
                <span v-if="key === 'actions'">
                  <v-btn
                    variant="text"
                    class="me-1 v-btn-icon"
                    size="large"
                    v-if="
                      CheckGetPermission('summer_center.change_schedule', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="editSchedule(item.raw)"
                  >
                    <v-icon color="warning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    variant="text"
                    size="small"
                    class="v-btn-icon"
                    v-if="
                      CheckGetPermission('summer_center.delete_schedule', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="(schedule_del_id = item.raw.id), (del_dialog = true)"
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </v-card-text>

    </v-card>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("globals.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("globals.confirm-delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (schedule_del_id = undefined)"
            >{{ $t("globals.cancel") }}</v-btn
          >
          <v-btn :loading="del_loading" color="red" @click="deleteSchedule">{{
            $t("globals.delete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
import useValidate from "@vuelidate/core";
import {
  email,
  required,
  helpers,
  maxLength,
  url,
  alpha,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
export default {
  data() {
    return {
      rules: {
        // subjectNameArRule: [
        //   (value) => !!value || this.$t("globals.required_field"),
        //   (value) =>
        //     /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
        //   (value) =>
        //     (value && value.length <= 100) ||
        //     `${this.$t("globals.biggest_number_of_character")} 100`,
        //   (value) =>
        //     (value && value.length >= 3) ||
        //     `${this.$t("globals.lowest_number_of_character")} 3`,
        // ],
        // subjectNameEnRule: [
        //   (value) => !!value || this.$t("globals.required_field"),
        //   (value) =>
        //     /^[A-Za-z  0-9]+$/.test(value) ||
        //     this.$t("globals.must_be_letters_and_numbers_en"),
        //   (value) =>
        //     (value && value.length <= 100) ||
        //     `${this.$t("globals.biggest_number_of_character")} 100`,
        //   (value) =>
        //     (value && value.length >= 3) ||
        //     `${this.$t("globals.lowest_number_of_character")} 3`,
        // ],
        // subjectPhaseRule: [(value) => !!value || this.$t("globals.required_field")],
        // subjectRule: [
        //   (value) => {
        //     if (value == null || value.length < 1)
        //       return this.$t("globals.required_field");
        //     return 1;
        //   },
        // ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      schedules: [],
      loading: false,
      del_loading: false,
      v$: useValidate(),
      schedule: {
        quotas: "",
        quotas_start_time: undefined,
        quotas_end_time: undefined,
        period: true,
        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      is_update: false,
      del_dialog: false,
      schedule_del_id: undefined,

      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      headers: [
        { title: this.$t("school.schedule_quotas"), key: "quotas" },
        { title: this.$t("school.schedule_period"), key: "period" },
        {
          title: this.$t("school.schedule_start_time"),
          key: "quotas_start_time",
        },
        { title: this.$t("school.schedule_end_time"), key: "quotas_end_time" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ],
      selectedHead: [
        "quotas",
        "period",
        "quotas_start_time",
        "quotas_end_time",
        "note",
        this.checkrole(['sys_admin', 'gen_admin']) ? "actions":null,
      ],
      txt_search: undefined,
      // start_time_rule: [
      //   // (value)=> value < '08:00' && this.schedule.period == true && this.$t('school.schedule_timing_error4'),
      //   (value) =>
      //     this.schedule.quotas_end_time == undefined ||
      //     value < this.schedule.quotas_end_time ||
      //     this.$t("school.schedule_timing_error"),
      //   (value) => this.checksTime(value) || this.$t("school.schedule_timing_error2"),
      // ],
      // end_time_rule: [
      //   // (value)=> this.schedule.quotas_end_time > '13:30' && this.schedule.period == true &&  this.$t('school.schedule_timing_error3'),
      //   (value) =>
      //     this.schedule.quotas_start_time == undefined ||
      //     value > this.schedule.quotas_start_time ||
      //     this.$t("school.schedule_timing_error"),
      //   (value) => this.checkTime(value) || this.$t("school.schedule_timing_error2"),
      // ],
      quotas: [
        (value) => !!value || this.$t("globals.required_field"),
        (value) =>
          /^[\u0621-\u064A A-Za-z]+$/.test(value) ||
          this.$t("globals.must_be_full_letter"),
      ],

      perPage: 10,
      length: 0,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 10000000, text: this.$t("globals.all") },
      ],
      page: 1,
      totalItems: null,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      sortBy: [
        {
          key: "id",
          order: "asc",
        },
      ],
    };
  },

  validations() {
    return {
      schedule: {
        period: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
        },
        quotas: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
        },
        quotas_start_time: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
          value: helpers.withMessage(this.returnPeriodTitle, (value) => {
            if (value.split(":")[0] <= 11 && this.schedule.period == false) return false;
            if (value.split(":")[0] > 11 && this.schedule.period) return false;
            return true;
          }),
          minValue: helpers.withMessage(
            "وقت البداية يجب يكون اقل من وقت النهاية",
            (value) => {
              if (this.schedule.quotas_end_time !== undefined)
                return value < this.schedule.quotas_end_time;
              return true;
            }
          ),
          interfere:helpers.withMessage(this.$t("school.schedule_timing_error2"), (value)=>{
            let valid=true
            this.schedules.forEach((element) => {
              if (value > element.quotas_start_time && value+":00" < element.quotas_end_time && element.id != this.schedule.id)
                {
                  valid=false
                }
            });
            return valid
          }),
        },
        quotas_end_time: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
          value: helpers.withMessage(this.returnPeriodTitle, (value) => {
            if (value.split(":")[0] <= 11 && this.schedule.period == false) return false;
            if (value.split(":")[0] > 11 && this.schedule.period) return false;
            return true;
          }),
          minValue: helpers.withMessage(
            "وقت النهاية يجب يكون اكبر من وقت البداية",
            (value) => {
              if (this.schedule.quotas_start_time !== undefined)
                return value > this.schedule.quotas_start_time;
              return true;
            }
          ),
          interfere:helpers.withMessage(this.$t("school.schedule_timing_error2"), (value)=>{
            let valid=true
            this.schedules.forEach((element) => {
              if (value > element.quotas_start_time && value < element.quotas_end_time && element.id != this.schedule.id)
                valid=false
            });
            return valid
          }),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(250)
          ),
        },
      },
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if(this.checkrole(['sys_admin', 'gen_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    ...mapState({
      user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    // ...mapState({
    //   user: (state) => state.User,
    // }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      let list = [];
      this.schedules.forEach((element) => {
        list.push({
          quotas: element.quotas,
          quotas_start_time: element.quotas_start_time,
          quotas_end_time: element.quotas_end_time,
          period: element.period
            ? this.$t("school.morning_period")
            : this.$t("school.evening_period"),
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    returnPeriodTitle() {
      return this.schedule.period ? "الفتره صباحية" : "الفتره مسائية";
    },
    async getSchedules(page = 1) {
      await this.axios
        .get(this.base_url + "api/summer/schedule-summer", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            search: this.txt_search,
            page: page,
            page_size: this.perPage,
            sort_by: this.order_data,
          },
        })
        .then((response) => {
          if (response.data) {
            this.schedules = response.data.results;

            this.pagination = response.data.pagination;
            this.length = response.data.pagination.num_pages;
            this.totalItems = response.data.pagination.count;
          }
        });
    },
    ...mapActions({}),
    resetForm() {
      this.schedule = { period: true };
      this.v$.schedule.$reset();
      this.is_update = false;
    },
    editSchedule(data) {
      let schedule = this.schedules.find((item) => item.id == data.id);
      if (schedule != undefined) {
        for (const key in schedule) this.schedule[key] = schedule[key];
        this.schedule.data_entry = undefined;
        this.schedule.updated_by = undefined;
        this.is_update = true;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    checkTime(value) {
      let result = true;
      this.schedules.forEach((element) => {
        if (
          element.quotas_start_time <= value &&
          element.quotas_end_time.slice(0, -3) >= value &&
          element.id != this.schedule.id
        )
          result = false;
      });
      return result;
    },
    checksTime(value) {
      let result = true;
      this.schedules.forEach((element) => {
        if (
          element.quotas_start_time < value &&
          element.quotas_end_time.slice(0, -3) > value &&
          element.id != this.schedule.id
        )
          result = false;
      });
      return result;
    },
    async saveSchedule() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid && !this.v$.schedule.$error) {
        this.loading = true;
        this.schedule.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/schedule-summer", this.schedule, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.loading = false;
            this.$emit("successAlert", this.$t("globals.data_added"));
          })
          .catch((error) => {
            this.loading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.resetForm();

        this.getSchedules();
      }
    },
    async updateSchedule() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid && !this.v$.schedule.$error && this.schedule.id != undefined) {
        this.loading = true;
        this.schedule.updated_by = this.user;
        let result = await this.axios
          .put(
            `${this.base_url}/api/summer/schedule-summer/${this.schedule.id}`,
            this.schedule,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((responce) => {
            this.loading = false;
            this.$emit("successAlert", this.$t("globals.data_updated"));
          })
          .catch((error) => {
            this.loading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });

        this.resetForm();
        this.getSchedules();
      }
    },
    async deleteSchedule() {
      if (this.schedule_del_id != undefined) {
        this.del_loading = true;
        let result = await this.axios
          .delete(`${this.base_url}api/summer/schedule-summer/${this.schedule_del_id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.del_loading = false;

            this.$emit("successAlert", this.$t("globals.data_deleted"));
          })
          .catch((error) => {
            this.del_loading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.del_dialog = false;
        this.schedule_del_id = undefined;

        this.getSchedules();
        setTimeout(() => {}, 2000);
      }
    },
  },
  watch: {
    "schedule.quotas_start_time": function () {
      if (this.$refs.endWeek.value) this.$refs.endWeek.validate();
    },
    "schedule.quotas_end_time": function () {
      this.$refs.startWeek.validate();
    },
    perPage() {
      this.getSchedules();
    },
    page() {
      this.getSchedules(this.page);
    },
  },
};
</script>
