<template>
  <v-card id="report" >
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ this.$t("reportpublic.TotalSchoolsMalesFemalesByGovernorate") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>

      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{
                this.$t("reportpublic.TotalSchoolsMalesFemalesByGovernorate")
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4 v-if="results.totals">
                {{
                  results.totals.total_school_open +
                   results.totals.total_school_typical
                }}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="px-2 pt-1 my-4" style="height: 350px">
            <chart :option="totalOfSchoolsMF" autoresize />
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-container>
            <table class="report-table text-center">
              <thead>
                <tr>
                  <th rowspan="2" style="height: 60px">
                    {{ $t("reportpublic.Governorate") }}
                  </th>
             
                  <th colspan="4" class="main-header">
                    {{ $t("reportpublic.Open") }}
                  </th>
                  <th colspan="4" class="main-header">
                    {{ $t("reportpublic.Typical") }}
                  </th>
                </tr>
                <tr>

                  <template v-for="index in 2" :key="index">                
                    <th>{{ $t("reportpublic.Males") }}</th>
                    <th>{{ $t("reportpublic.Females") }}</th>
                    <th>{{ $t("reportpublic.General") }}</th>
                    <th>{{ $t("reportpublic.Total") }}</th>                  
                  </template>
           
                  
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, key) in results.results" :key="key">
                  <td>
                    {{
                      this.$i18n.locale == "ar"
                        ? data.governorate_name_ar
                        : data.governorate_name_en
                    }}
                  </td>
              
                  <td>
                    {{ data.summer_open_male }}
                  </td>
                  <td>
                    {{ data.summer_open_fmale }}
                  </td>
                  <td>
                    {{ data.summer_open_general }}
                  </td>
                  <td>
                    {{ data.summer_open_number }}
                  </td>
                  <td>
                    {{ data.summer_typical_male }}
                  </td>
                  <td>
                    {{ data.summer_typical_fmale }}
                  </td>
                  <td>
                    {{ data.summer_typical_general }}
                  </td>
                  <td>
                    {{ data.summer_typical_number }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr v-if="results.totals">
                  <th>{{ $t("reportpublic.GrandTotal") }}</th>
                 
                  <th>
                    {{ results.totals.total_school_open_male }}
                  </th>
                  <th>
                    {{ results.totals.total_school_open_fmale }}
                  </th>
                  <th>
                    {{ results.totals.total_school_open_general }}
                  </th>
                  <th>
                    {{ results.totals.total_school_open }}
                  </th>
                  <th>
                    {{ results.totals.total_school_typical_male }}
                  </th>
                  <th>
                    {{ results.totals.total_school_typical_fmale }}
                  </th>
                  <th>
                    {{ results.totals.total_school_typical_general }}
                  </th>
                  <th>
                    {{ results.totals.total_school_typical }}
                  </th>
                </tr>
              </tfoot>
            </table>
          </v-container>
        </v-col>
      </v-row>
    </v-card-item>
  </v-card>
</template>

<script>
import axios from "axios";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

import SummerAllDetailedSummaryTable from "@/components/reports/SummerAllDetailedSummaryTable.vue";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }

    this.totalOfSchoolsMF = {
      title: {
        text: this.$t("reportpublic.TotalSchoolsMalesFemalesByGovernorate"),
        right: "center",
        textStyle: {
          fontFamily: "Almarai",
          fontSize: 12,
        },
        subtextStyle: {
          fontFamily: "Almarai",
          fontSize: 10,
        },
      },
      dataset: [
        {
          dimensions: [
            "name",
            this.$t("reportpublic.OpenMales"),
            this.$t("reportpublic.OpenFemales"),
            this.$t("reportpublic.OpenGeneral"),
       
            this.$t("reportpublic.TypicalMales"),
            this.$t("reportpublic.TypicalFemales"),
            this.$t("reportpublic.TypicalGeneral"),
          ],
          source: this.results.results.map((item) => [
            this.$i18n.locale == "ar"
              ? item.governorate_name_ar
              : item.governorate_name_en,
            item.summer_open_male,
            item.summer_open_fmale,
            item.summer_open_general,
 

            item.summer_typical_male,
            item.summer_typical_fmale,
            item.summer_typical_general,
          ]),
        },
        {
          transform: {
            type: "sort",
            config: { dimension: "constant", order: "desc" },
          },
        },
      ],
      xAxis: {
        type: "category",
        axisLabel: {
          interval: 0,
          rotate: 50,
        },
        inverse: true,
      },
      yAxis: {
        type: "value",
        position: this.$i18n.locale == "ar" ? "right" : "",
        boundaryGap: [0, 0.2],
      },
      series: [
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            offset: [5, 7],
          },
        },
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            offset: [5, 7],
          },
        },
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            offset: [5, 7],
          },
        },
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            offset: [5, 7],
          },
        },
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            offset: [5, 7],
          },
        },
        {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            offset: [5, 7],
          },
        },
     
      ],
      legend: {
        bottom: "bottom",
        left: "center",
        textStyle: {
          fontSize: 10,
        },
        itemHeight: 10,
        itemWidth: 20,
        borderWidth: 1,
        borderColor: "black",
      },

      toolbox: {
        feature: {
          saveAsImage: {
            title: this.$t("dashboard.save-as-png"),
          },
        },
      },
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (params) {
          return (
            "<h5>" +
            params.name +
            "</h5>" +
            params.value[params.componentIndex + 1] +
            "&nbsp;&nbsp;&nbsp;" +
            params.marker +
            " " +
            params.seriesName
          );
        },
      },
      grid: {
        bottom: "30%",
        height: "50%",
        top: "15%",
      },
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(`${this.base_url}public-reports/summer-school-detailed-summary`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.results = response.data;
        })
        .catch((erroe) => {
          console.error("خطأ في السيرفر", erroe);
        });
    },

    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      results: {},
      totalOfSchoolsMF: {},
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  components: {
    ReportHeader,
    SummerAllDetailedSummaryTable,
  },
};
</script>
