<template>
  <VCard class="mb-2 pa-0">
    <!-- <VCard-title class="my-4">
      <VIcon icon="mdi-chart-line" />
      <h3 class="d-inline text-grey" style="padding: 0 4px">
        {{ $t("summer-sidebar.supplies-view") }}
      </h3>
      <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
    </VCard-title>

    <v-divider class="my-1" /> -->
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="pa-2" v-if="CheckGetPermission('summer_center.view_supplies',['sys_admin','summer_admin','user'])">
      <div class="d-flex justify-space-between pa-2">
        <div class="d-flex flex-fill">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
          <div style="width: 100%">
            <v-text-field
              color="indigo"
              v-model="txt_search"
              @input="getstatistic((page = 1))"
              style="
                border-radius: 30px;
                border: 1px solid #ccc;
                color: black;
                height: 30px;
                width: 100%;
              "
              class="search"
              hide-details
              prepend-inner-icon="mdi-magnify"
              single-line
              :placeholder="$t('globals.search-here')"
              density="compact"
              variant="text"
            >
            </v-text-field>
          </div>
          <div class="d-flex justify-end" style="flex: 1">
            <VBtn
              v-if="CheckGetPermission('summer_center.add_supplies',['sys_admin','summer_admin','user'])"
              class="bg-success"
              @click="addStatistic"
              size="small"
            >
              <span class="text-white">{{ $t("globals.create") }}</span>
              <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
            </VBtn>
          </div>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table-server
        :loading="loading"
        :headers="selectedHeaders"
        :items="statisticdata"
        v-model:sort-by="sortBy"
        item-value="title"
        item-key="id"
        id="vDataTable"
        fixed-footer
        @update:options="getstatistic((page = 1))"
        density="compact"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <span v-if="key == 'is_found'">
                <v-icon v-if="val == true" color="success">mdi-check-circle</v-icon>
                <v-icon v-if="val == false" color="error">mdi-close-circle</v-icon>
              </span>
              <span v-else> {{ val }}</span>
              <span v-if="key === 'actions'">
                <v-btn
                  variant="text"
                  class="me-1 v-btn-icon"
                  size="small"
                  @click="editstatistic(item.raw)"
                  v-if="CheckGetPermission('summer_center.change_supplies',['sys_admin','summer_admin','user'])"
                >
                  <v-icon color="warning">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  size="small"
                  class="v-btn-icon"
                  @click="(dialogVisible = true), (this.delete_id = item.raw.id)"
                  v-if="CheckGetPermission('summer_center.delete_supplies',['sys_admin','summer_admin','user'])"
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="pagination.num_pages"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ total_items }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div>
        </template>
      </v-data-table-server>
    </v-card>
  </VCard>
  <VDialog v-model="dialogVisible" scrollable max-width="350px">
    <VCard>
      <VCardTitle class="text-blue">
        {{ $t("globals.confirmation") }}
      </VCardTitle>
      <VCardText class="text-blue-grey-darken-4">
        {{ $t("globals.confirm_delete") }}
      </VCardText>
      <VCardActions>
        <VBtn
          class="font-weight-bold me-2"
          color="error"
          @click.prevent="deletestatistic"
          :loading="loading"
        >
          {{ $t("globals.delete") }}
        </VBtn>
        <VBtn
          class="bg-white font-weight-bold"
          color="blue"
          @click.prevent="cancelDeleteItem"
        >
          {{ $t("globals.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import Datetime from "vue3-datetime-js";
export default {
  data() {
    return {
      loading:false,
      homeworks: [],
      statistics: [],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      statistic: {
        fk_week: undefined,
        name: undefined,
        type: undefined,
        is_found: undefined,
        evaluation: undefined,
        note: "",
        data_entry: undefined,
        updated_by: undefined,
      },
      sortBy: [
        {
          key: "name",
          order: "asc",
        },
      ],
      type_statistic: [
        { key: 1, name: "مهام ادارية", is_task: false },
        { key: 2, name: "مستلزمات", is_task: true },
      ],
      type_statistics: [
        { key: 1, name: "مهام ادارية" },
        { key: 2, name: "مستلزمات" },
        { key: 3, name: "الكل" },
      ],
      evaluations: [
        { key: 1, name: "معدوم" },
        { key: 2, name: "ممتاز" },
        { key: 3, name: "جيد" },
        { key: 4, name: "جيد جدا" },
        { key: 5, name: "مقبول" },
      ],
      is_found: true,
      rules: {
        name: [(value) => !!value || this.$t("globals.required_field")],
        week: [(value) => !!value || this.$t("globals.required_field")],
        type_statistic: [(value) => !!value || this.$t("globals.required_field")],
        note: [
          (value) =>
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      dialogVisible: false,
      visitType: null,
      v$: useValidate(),
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      total_items: null,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      adj_type: "adjective",
      selectedHead: ["provider", "name", "company","is_found","supp_type","amount","date", "note", "actions"],
      headers: [
        { title: this.$t("report.entity"),key: "provider" },
        { title: this.$t("report.company"), key: "company" },
        { title: this.$t("summer.type-statistic"), key: "supp_type", sortable: false },
        { title: this.$t("summer-sidebar.supplies-name"), key: "name" },
        { title: this.$t("summer.is-statistic"), key: "is_found", sortable: false },
        { title: this.$t("report.quantity"), key: "amount", sortable: false },
        { title: this.$t("financial.date"), key: "date", sortable: false },
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      txt_search: undefined,
    };
  },
  components: { Datetime },
  computed: {
    statisticdata() {
      if (this.visitType == 1 || this.visitType == 2) {
        var x = this.statistics.filter((el) => el.type == this.visitType);
        this.total_items = x.length;
        return x;
      } else {
        var x = this.statistics;
        this.total_items = x.length;
        return x;
      }
    },
    ...mapState({
      user: (state) => state.User,
      weeks: (state) => state.summer.weeks,
      statistics_mode: (state) => state.summer_globals.statistics_mode,
    }),
    ...mapGetters({}),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },

    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getweeks();
      await this.getStatisticsMode();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getweeks: "summer/getweeks",
      getStatisticsMode: "summer_globals/getStatisticsMode",
    }),
    getStartWeek(e) {
      let date = e;
      date = this.toEnglishString(e);
      this.statistic.date = date;
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.statistic = {};
      this.is_update = false;
      this.updated_id = undefined;
    },
    editstatistic(item) {
      this.$router.push(`${item.id}/edit/`);
    },
    addStatistic() {
      this.$router.push({ name: "add-supplies" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async deletestatistic() {
      if (this.delete_id) {
        this.loading = true;
        let result = await this.axios
          .delete(this.base_url + "api/summer/supplies/" + this.delete_id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.dialogVisible = false;
        this.delete_id = undefined;
        this.getstatistic();
      }
    },

    async getstatistic(page = 1) {
      
      await this.axios(`${this.base_url}/api/summer/supplies`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.statistics = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.total_items = response.data.pagination.count;
      });
    },
  },
  watch: {
    perPage() {
      this.getstatistic();
    },
    page() {
      this.getstatistic(this.page);
    },
  },
};
</script>

<style scoped>
.v-application__wrap {
  background-color: #999;
}
</style>
