<template>
  <VCard >
    <v-card flat 
      v-if="
        CheckGetPermission('summer_center.add_initiative',['sys_admin','summer_admin','user']) ||
        CheckGetPermission('summer_center.change_initiative',['sys_admin','summer_admin','user'])
      "
    >
      <v-card-text class="pb-0">
        <VForm
          ref="form"
          @submit.prevent="saveInitiative"
          
        >
          <VRow class="mt-4" >
            <v-col cols="12" md="10" lg="10" >
              <v-row>
                <VCol class="pt-0 " cols="12" md="6">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="initiative.name_ar"
                      :label="$t('summer.initiative.name')"
                      persistent-hint
                      clearable
                      density="compact"
                      prepend-inner-icon="mdi-handshake"
                      :rules="rules.initiativeNameArRule"
                    ></VTextField>
                  </VCardItem>
                </VCol>
                <VCol class="pt-0 " cols="12" md="6">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="initiative.description"
                      :label="$t('summer.initiative.description')"
                      persistent-hint
                      clearable
                      density="compact"
                      prepend-inner-icon="mdi-help-rhombus"
                      :rules="rules.initiativeDescriptionRule"
                    ></VTextField>
                  </VCardItem>
                </VCol>
              </v-row>
            </v-col>
            <v-col cols="12" md="10" lg="10"  class="mt-6">
              <v-row>
                <VCol class="pt-0 " cols="12" md="4">
                  <v-text-field
                    v-model="initiative.time"
                    :label="$t('summer.initiative.time')"
                    density="compact"
                    clearable
                    readonly
                    :rules="rules.initiativeTimeRule"
                    style="min-width: 220px"
                    prepend-inner-icon="mdi-calendar-clock-outline"
                    id="time"
                  >
                  </v-text-field>

                  <Datetime
                    @keyup.enter="(e) => getTime(e)"
                    type="datetime"
                    element="time"
                    :color="'#0747a6'"
                    @input="(e) => getTime(e)"
                    :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
                    :placeholder="$t('summer.calendar.start-date-of-the-week')"
                    :calendar="'hijri'"
                  ></Datetime>
                </VCol>
                <VCol class="pt-0 " xs="12" md="4">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="initiative.supervisor_name"
                      :label="$t('summer.initiative.supervisor')"
                      persistent-hint
                      clearable
                      prepend-inner-icon="mdi-account-tie"
                      density="compact"
                      :rules="rules.initiativeSupervisorRule"
                    ></VTextField>
                  </VCardItem>
                </VCol>
                <VCol class="pt-0 " xs="12" md="4">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="initiative.number"
                      type="number"
                      :label="$t('summer.activity.student_count')"
                      persistent-hint
                      clearable
                      prepend-inner-icon="mdi mdi-numeric"
                      density="compact"
                      :rules="rules.number"
                    ></VTextField>
                  </VCardItem>
                </VCol>
              </v-row>
            </v-col>
            <v-col cols="12" md="10" lg="10"  class="mt-6">
              <v-row>
                <VCol cols="12"  xs="12" md="5" lg="12">
                  <VTextarea
                    v-model="initiative.note"
                    clearable
                    :label="$t('summer.initiative.note')"
                    no-resize
                    counter="250"
                    density="compact"
                    rows="2"
                    :rules="rules.note"
                    prepend-inner-icon="mdi-note-outline"
                  ></VTextarea>
                </VCol>
              </v-row>
            </v-col>

          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions
        class="px-3"
        v-if="
          CheckGetPermission('summer_center.add_initiative',['sys_admin','summer_admin','user']) ||
          CheckGetPermission('summer_center.change_initiative',['sys_admin','summer_admin','user'])
        "
      >
        <VBtn
          v-if="!is_update && CheckGetPermission('summer_center.add_initiative',['sys_admin','summer_admin','user'])"
          class="bg-indigo"
          @click="saveInitiative()"
          :loading="saveDataLoading"
          size="small"
        >
          <span class="text-white">
            {{ $t("globals.add") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn
          v-if="
            is_update && CheckGetPermission('summer_center.change_initiative',['sys_admin','summer_admin','user'])
          "
          class="bg-success"
          @click="updateInitiative()"
          :loading="editDataLoading"
          size="small"
        >
          <span class="text-white">
            {{ $t("globals.edit") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
    <v-divider  />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card
      flat
      v-if="CheckGetPermission('summer_center.view_initiative')"
    >
      <v-card-text>
        <div class="d-flex justify-space-between mb-2">
          <div class="d-flex flex-fill">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  variant="outlined"
                  color="#ccc"
                  size="small"
                >
                  <v-icon style="color: #5e7e96" class="me-2"
                    >mdi-eye-outline</v-icon
                  >
                  <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="header in headers"
                    :key="header.key"
                    :label="header.title"
                    :value="header.key"
                    color="indigo"
                    v-model="selectedHead"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <div style="width: 100%">
              <v-text-field
                color="indigo"
                v-model="txt_search"
                @input="getInitiatives((page = 1))"
                style="
                  border-radius: 30px;
                  border: 1px solid #ccc;
                  color: black;
                  height: 30px;
                  width: 100%;
                "
                class="search"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                :placeholder="$t('globals.search-here')"
                density="compact"
                variant="text"
              >
              </v-text-field>
            </div>
          </div>
        </div>
        <!-- ######## End Header Of Data Table Server ####### -->
        <v-data-table-server
          :loading="loading"
          :headers="selectedHeaders"
          :items="tableList"
          v-model:sort-by="sortBy"
          item-value="name"
          item-key="id"
          id="vDataTable"
          fixed-footer
          @update:options="getInitiatives((page = 1))"
          density="compact"
          class="elevation-1 rounded"
        >
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <span> {{ val }}</span>
                <span v-if="key === 'actions'">
                  <v-btn
                    variant="text"
                    class="me-1 v-btn-icon"
                    size="small"
                    @click="editInitiative(item.raw)"
                    v-if="CheckGetPermission('summer_center.change_initiative',['sys_admin','summer_admin','user'])"
                  >
                    <v-icon color="warning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    variant="text"
                    size="small"
                    class="v-btn-icon"
                    @click="
                      (dialogVisible = true), (this.delete_id = item.raw.id)
                    "
                    v-if="CheckGetPermission('summer_center.delete_initiative',['sys_admin','summer_admin','user'])"
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </v-card-text>

    </v-card>
  </VCard>
  <VDialog v-model="dialogVisible" scrollable max-width="350px">
    <VCard>
      <VCardTitle class="text-blue">
        {{ $t("globals.confirmation") }}
      </VCardTitle>
      <VCardText class="text-blue-grey-darken-4">
        {{ $t("globals.confirm_delete") }}
      </VCardText>
      <VCardActions>
        <VBtn
          class="font-weight-bold me-2"
          color="error"
          @click.prevent="deleteInitiative"
          :loading="deleteDataLoading"
        >
          {{ $t("globals.delete") }}
        </VBtn>
        <VBtn
          class="bg-white font-weight-bold"
          color="blue"
          @click.prevent="cancelDeleteItem"
        >
          {{ $t("globals.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Datetime from "vue3-datetime-js";

export default {
  data() {
    return {
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      initiatives: [],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      initiative: {},
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],
      rules: {
        number: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) => {
            if (value == null || value == undefined)
              return this.$t("globals.required_field");
            if (value.length > 3) {
              return `${this.$t("globals.max_numbers")} 3`;
            }
            return true;
          },
        ],
        initiativeNameArRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) ||
            this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        initiativeDescriptionRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) ||
            this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 250) ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        initiativeTimeRule: [
          (value) => !!value || this.$t("globals.required_field"),
        ],
        initiativeSupervisorRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) ||
            this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 50) ||
            `${this.$t("globals.biggest_number_of_character")} 50`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      dialogVisible: false,
      is_update: false,
      delete_id: undefined,
      selectedHead: [
        "name_ar",
        "description",
        "time",
        "supervisor_name",
        "number",
        "note",
        "actions",
      ],
      headers: [
        // { title: "", key: "checkbox" },
        { title: this.$t("summer.initiative.name"), key: "name_ar" },
        { title: this.$t("summer.initiative.description"), key: "description" },
        { title: this.$t("summer.initiative.time"), key: "time" },
        { title: this.$t('summer.activity.student_count'), key: "number" },
        {
          title: this.$t("summer.initiative.supervisor"),
          key: "supervisor_name",
        },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      txt_search: undefined,
    };
  },
  components: { Datetime },
  computed: {
    ...mapState({
      countries: (state) => state.school.countries,
      user: (state) => state.User,
    }),
    ...mapGetters({}),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      if (this.initiatives) {
        return JSON.parse(JSON.stringify(this.initiatives));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    getTime(e) {
      let date = e;
      date = this.toEnglishString(e);
      this.initiative.time = date;
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.initiative = {};
      this.is_update = false;
    },
    editInitiative(initiative) {
      this.initiative = initiative;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async saveInitiative() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.initiative.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/initiative", this.initiative, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.initiative = {};
            this.saveDataLoading = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
        this.resetForm();
        this.getInitiatives();
      }
    },
    async updateInitiative() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.editDataLoading = true;
        this.initiative.data_entry = this.user;
        let result = await this.axios
          .put(
            this.base_url + "api/summer/initiative/" + this.initiative.id,
            this.initiative,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
        this.resetForm();
        this.getInitiatives();
      }
    },
    async deleteInitiative() {
      if (this.delete_id) {
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(this.base_url + "api/summer/initiative/" + this.delete_id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.deleteDataLoading = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.deleteDataLoading = false;
          });
        this.dialogVisible = false;
        this.delete_id = undefined;
        this.getInitiatives();
      }
    },
    async getInitiatives(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/initiative`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.initiatives = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  watch: {
    perPage() {
      this.getInitiatives();
    },
    page() {
      this.getInitiatives(this.page);
    },
  },
};
</script>

<style>
.v-application__wrap {
  background-color: #999;
}
</style>
