<template>
<v-form  ref="form" @submitSchoolWeeklySchedule.prevent="saveSchoolWeeklySchedule"  >
    <v-expansion-panels model-value="1">
        <v-expansion-panel  elevation="1" value="1" >
            <v-expansion-panel-title class="px-4">
                <span>{{$t('globals.select-criteria')}}</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text >
            <v-row  class="py-2" >
                <v-col cols="6" md="4">
                    <v-autocomplete
                        color="indigo"
                        v-model="summer_time_table.fk_level" 
                        autofocus
                        item-title="level_with_phase_name"
                        item-value="id"
                        prepend-inner-icon="mdi-stairs-box"
                        :items="levels"
                        :label="$t('summer.level.name_ar')"
                        hide-details
                        @update:modelValue="checkAllData()"
                    />
                </v-col>
                <v-col cols="12" md="4">
                    <v-autocomplete
                        color="indigo"
                        v-model="summer_time_table.period_am" 
                        density="compact"
                        item-title="name"
                        item-value="id"
                        prepend-inner-icon="mdi-clock-outline"
                        clearable
                        :items="[{id:true,name:$t('globals.am')},{id:false,name:$t('globals.pm')}]"
                        :label="$t('school.schedule_period')"
                        @update:modelValue="checkAllData"
                        hide-details
                    ></v-autocomplete>
                </v-col>
            </v-row>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
    <v-progress-linear 
        color="indigo"
        v-if="loading"
        indeterminate
        height="2px"
        :reverse="$i18n.locale=='ar'"

        >
    </v-progress-linear>
    <v-card class="mt-2 px-1"  v-if="found&&summer_time_table.days.length>0&&timetable_schedules.length>0"> 
        <v-card-text class="pb-0">
            <v-form ref="table" >
                <v-card >
                    <v-table
                        density="compact" >
                        <thead>
                            <tr>
                                <th>
                                    {{$t('globals.day')}}/{{$t('globals.lecture')}}
                                </th>
                                <th v-for="schedule in timetable_schedules" :key="schedule" class="text-center">
                                    {{schedule.quotas}}
                                    <br>
                                    <small >
                                        {{schedule.quotas_start_time}}
                                        -
                                        {{schedule.quotas_end_time}}
                                    </small>
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <!-- {{addTimeTable(7)}} -->
                            <tr v-for="day,index in summer_time_table.days" :key="day">
                                <td >
                                    <span>{{days_choices[index].name}}</span>
                                </td>
                                <td v-for="subject,index in day.subjects" :key="subject" class="justify-center text--dark">
                                    <v-autocomplete 
                                        v-model="subject.fk_subject"
                                        density="compact"
                                        item-title="name"
                                        :clearable="!checkRole(['summer_admin','user'])"
                                        class="text-dark"
                                        item-value="id"
                                        :no-data-text="$t('subject.not-found')"
                                        :items="level_subjects"
                                        hide-details
                                        :readonly="checkRole(['summer_admin','user'])"
                                        :placeholder="$t('subject.select')"
                                        variant="underlined"
                                    >
                                    </v-autocomplete>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card>
            </v-form>
        </v-card-text>
        <v-card-actions class="mx-1">
            <v-btn :loading="saveProgress" v-if="!update&& CheckGetPermission('summer_center.add_schoolweeklyschedule',['sys_admin','gen_admin'])" @click="saveSchoolWeeklySchedule"   class="bg-indigo"  size="small">
                <span class="text-white">
                    {{$t('globals.save')}}
                </span>
                <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn>
            <v-btn :loading="saveProgress" v-if="update && CheckGetPermission('summer_center.change_schoolweeklyschedule',['sys_admin','gen_admin'])" @click="updateWeeklySchedule"   class=" bg-success"    size="small">
                <span class="text-white">
                    {{$t('globals.edit')}}
                </span>
                <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn>
            <v-btn  @click="dialog=true" v-if="update&&CheckGetPermission('summer_center.delete_schoolweeklyschedule',['sys_admin','gen_admin'])"  class="bg-red"    size="small">
                <span class="text-white">
                    {{$t('globals.delete')}}
                </span>
                <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn>
            <v-btn  @click="cleanSchoolWeeklySchedule" v-if="CheckGetPermission('summer_center.delete_schoolweeklyschedule',['sys_admin','gen_admin'])" class="mx-3 bg-ccc"  size="small">
                <span>
                    {{$t('globals.clear')}}
                </span>
                <v-icon icon="mdi-broom" color="golden" end></v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
    <v-card v-if="found&&timetable_schedules.length<1 &&!loading" class="text-center pa-2 mt-2">
        <span v-if="summer_time_table.period_am==true">
            {{$t('globals.no-periods-am')}}
        </span>
        <span v-if="summer_time_table.period_am==false">
            {{$t('globals.no-periods-pm')}}
        </span>
    </v-card>
</v-form>
 <v-dialog
    color="indigo"
    v-model="dialog"
    transition="dialog-top-transition"
    width="auto"
>
    <v-card > 
        <v-card-title>
            <v-icon>mdi</v-icon>
            {{$t('globals.confirmation')}}
        </v-card-title>
        <v-card-text>
            {{$t('globals.confirm-delete')}}
        </v-card-text>
        <v-card-actions>
            <v-btn color="info"  @click="dialog=false,selectedClass=''">{{$t('globals.cancel')}}</v-btn>
            <v-btn color="red" :loading="deleteProgress" @click="deleteWeeklySchedule" >{{$t('globals.delete')}}</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
 
import useValidate from '@vuelidate/core'
import {mapGetters,mapState,mapActions} from 'vuex'
import {required,helpers,maxLength,numeric} from '@vuelidate/validators'
export default{
    name:"WeeklyScheduleAdd",
    
    async created(){
        try {
     
            await this.$store.commit('updateLoadingValue',true)
            this.user=this.$store.getters.getuser
            this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
            await this.getDays()
            await this.getLevels()
            await this.getSchedules()
            await this.getSubjects()
            this.$store.commit('updateLoadingValue',false) 
        } catch (error) {
            this.$store.commit('updateLoadingValue',false) 
            
        }
    },
    data(){
        return{
            found:false,
            dialog:false,
            schedules_data:[],
            v$: useValidate(),
            saveProgress:false,
            deleteProgress:false,
            showAlert:false,
            update:false,
            user:null,
            loading:false,
            user:null,
            summer_time_table:{
                fk_level: null,
                period_am:null,
                days:[
                ],
                note:'',
                data_entry: undefined,
                updated_by: undefined
            },
            
            
        }
    },
    computed:{
        
        ...mapState({
            
            levels:(state)=>state.summer.levels,
            weeks:(state)=>state.summer.weeks,
            employees:(state)=>state.summer.employees,
            schedules:(state)=>state.summer.schedules,
            days:(state)=>state.summer.days,
            subjects:(state)=>state.summer.subjects,
        }),
        ...mapGetters({
        }),
        level_subjects(){
            if(this.summer_time_table.fk_level){
                const level=this.levels.find(item=>item.id==this.summer_time_table.fk_level)
                return this.subjects.filter(item=>level.subject.includes(item.id))
            }
        },
        days_choices(){
            return this.days.filter(el=>el.is_holiday==false||el.is_holiday==null)
        },
        scheduleData(){
            return [this.summer_time_table.fk_level,this.summer_time_table.period_am]
        },
        timetable_schedules(){
            return this.schedules.filter(el=>el.add_to_table==true&&el.period==this.summer_time_table.period_am)
        },
        uniqueSchedule(){
            return [this.summer_time_table.fk_level]
        },
        classesName(){
            return this.$i18n.locale=='ar'?'name_ar':'name_en' 
        },
        CheckGetPermission(){
            return (prem,role)=>{
                return this.$store.getters.checkpermission(prem,role);
            }
        },
        checkRole() {
            return (role) => {
                if (localStorage.getItem("userinfo")) return this.$store.getters.checkrole(role);
            };
        },
        
    },
    beforeUnmount(){
        this.$store.commit('updateLoadingValue',true)
    },

    validations(){
        return{
            summer_time_table:{
                fk_level: {
                    required:helpers.withMessage(this.$t('errors.required'),required),
                },
                
                period_am:{
                    required:helpers.withMessage(this.$t('errors.required'),required),

                }
                
            },
        }
    },
    methods:{
        
        ...mapActions({
            getLevels:"summer/getLevels",
            getWeeks: "summer/getWeeks",
            getSchedules:"summer/getSchedules",
            getDays:"summer/getDays",
            getSubjects:"summer/getSubjects",

        }),
        async checkAllData(){
            this.summer_time_table.days=[]
            this.found=false
            this.v$.$validate()
            if(!this.v$.$error){
                await this.axios.get(`${this.base_url}api/summer/summer-time-table/list/`,
                    {
                        headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
                        params:{
                            level:this.summer_time_table.fk_level,
                            period_am:this.summer_time_table.period_am,
                        }   
                    }).then(response=>{
                        if(response.status==204){
                            this.update=false
                            this.createTable()
                        }
                        else{
                            this.summer_time_table.days=response.data.days
                            this.found=true
                            this.update=true
                        }
                    }).catch(error=>{
                        this.$emit('errorAlert',this.$t('globals.error_in_data'))
                    })
            }
        },
        createTable(){
            // this.v$.summer_time_table.$validate()
            if(this.summer_time_table.period_am!==null){
                this.found=true
                this.loading=true
                this.summer_time_table.days=[]
                setTimeout(()=>{
                    this.addDays(this.days_choices.length)
                },1000)
                setTimeout(()=>{
                    this.addSubjects()
                },1000)
            }else{
                this.summer_time_table.days=[]
            }
        },
        
        deleteWeeklySchedule(){
            this.deleteProgress=true
            this.axios.post(`${this.base_url}api/summer/summer-time-table/delete/`,this.scheduleData,{
                headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
            }).then(response=>{
                this.$emit("successAlert", this.$t('alert.success.delete'));
                this.dialog=false
                this.cleanSchoolWeeklySchedule()
                this.deleteProgress=false 
            })
        },
        addDays(number){
            this.summer_time_table.days=[]
            for (let index = 0; index < number; index++) {
               this.summer_time_table.days.push(
                {
                    day: this.days_choices[index].id,
                    subjects: [

                    ],
                }
            )
                
            }
        },
        addSubjects(){
            this.summer_time_table.days.forEach(element => {
                this.timetable_schedules.forEach(schedule=>{
                    element.subjects.push(
                        {
                            fk_subject:null,
                            fk_schedule:schedule.id,
                        }
                    )
                })
            });
            this.loading=false
        },
        checkClassSubject(){
            if(this.summer_time_table.days.length>0){
                if(this.subjects.length<1){
                    this.summer_time_table.days.forEach(el=>{
                        if(el.subjects.length>0){
                            el.subjects.forEach(subject=>{
                                subject.fk_subject=null
                                subject.fk_teacher=null
                            })
                        }
                    })
                }else{
                    this.summer_time_table.days.forEach(el=>{
                        if(el.subjects.length>0){
                            el.subjects.forEach(subject=>{
                                if(!this.subjects.map(el=>el.id_subject).includes(subject.fk_subject)){
                                    subject.fk_subject=null
                                    subject.fk_teacher=null
                                }
                            })
                        }
                    })
                }
            }
            else{
                
            }
            
        },
        submitSchoolWeeklySchedule(){
            this.v$.$validate()
        },
        async saveSchoolWeeklySchedule(){
            this.submitSchoolWeeklySchedule()
            if(!this.v$.$error){
                this.saveProgress=true
                this.summer_time_table.data_entry=this.user
                this.axios.post(`${this.base_url}api/summer/summer-time-table/`,this.summer_time_table,{
                    headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
                    params:{
                        level:this.summer_time_table.fk_level,
                    }
                }).then(response=>{
                    this.$emit("successAlert", this.$t("alert.success.save"));
                    this.checkAllData()
                    this.saveProgress=false
                }).catch((error)=>{
                    this.$emit("errorAlert", this.$t("globals.error_in_data"));
                    this.saveProgress=false
                })
            }
            else{
                this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }

        },
        cleanSchoolWeeklySchedule(){
            this.v$.summer_time_table.$reset()
            this.summer_time_table.days=[]
            this.summer_time_table.period_am=null
            this.summer_time_table.fk_level=null
            this.found=false
        },
        updateWeeklySchedule(){
            this.submitSchoolWeeklySchedule()
            if(!this.v$.$error){
                this.saveProgress=true
                var listSchedules=[]
                this.summer_time_table.days.forEach(day => {
                    day.subjects.forEach(subject=>{
                        listSchedules.push({
                            id:subject.id,
                            fk_subject:subject.fk_subject,
                            data_updated:this.user,
                            fk_year:localStorage.getItem('current_year')
                        })
                    })
                });
                this.axios.put(`${this.base_url}api/summer/summer-time-table/${null}/`,listSchedules,{
                    headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
                    
                    }).then(response=>{
                        this.$emit("successAlert", this.$t("alert.success.update"));
                        this.saveProgress=false
                    }).catch(error=>{
                        this.$emit("errorAlert",this.$t('alert.error.update'));
                        this.saveProgress=false
                    })
                
            }

        }
    },
    
    
    
}
</script>
