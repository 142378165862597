<template>
<v-expansion-panels  v-if="role<4" class="mb-2">
        <v-expansion-panel  elevation="1" >
          <v-expansion-panel-title class="px-4">
              <span>{{$t('globals.select-criteria')}}</span>
          </v-expansion-panel-title>
          <v-expansion-panel-text >
            <v-row class="py-2" >
              <VCol  cols="6" md="3" v-if="role < 2">
                <VAutocomplete
                  :items="countries"
                  v-model="filter.country"
                  item-title="name_ar"
                  item-value="id"
                  hide-details="auto"
                  clearable
                  :label="$t('globals.country')"
                  density="compact"
                  prepend-inner-icon="mdi-flag"
                  @update:modelValue="filter.governorate=null,filter.directorate = null"
                ></VAutocomplete>
              </VCol>
              <VCol  cols="6" md="3"  v-if="role<2">
                <VAutocomplete
                  :items="filterGovernorates(filter.country)"
                  v-model="filter.governorate"
                  item-title="name_ar"
                  item-value="id"
                  hide-details="auto"
                  :label="$t('globals.governorate')"
                  density="compact"
                  clearable
                  prepend-inner-icon="mdi-city"
                  @update:modelValue="filter.directorate = null"
                ></VAutocomplete>
              </VCol>
              <VCol  cols="6" md="3">
                <VAutocomplete
                  :items="filterDirectorates(filter.governorate)"
                  v-model="filter.directorate"
                  item-title="name_ar"
                  item-value="id"
                  hide-details="auto"
                  clearable
                  :label="$t('globals.directorate')"
                  density="compact"
                  prepend-inner-icon="mdi-map-outline"
                ></VAutocomplete>
              </VCol>
              <VCol  cols="6" md="2" class="mt-2">
                <v-btn
                  density="comfortable"
                  append-icon="mdi-presentation"
                  class="w-100 text-white"
                  color="indigo"
                  :loading="loading"
                  @click="getSummerCamp(page=1)"
                >
                  <span >{{ $t("globals.show") }}</span>
                </v-btn>
              </VCol>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

  <VCard >
    <v-card >
      <v-card-text>
        <div class="d-flex justify-space-between mb-2">
          <div class="d-flex flex-fill">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                  <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                  <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="header in headers"
                    :key="header.key"
                    :label="header.title"
                    :value="header.key"
                    color="indigo"
                    v-model="selectedHead"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <div class="w-100 px-2">
              <v-text-field
                color="indigo"
                style="
                  border-radius: 30px;
                  border: 1px solid #ccc;
                  color: black;
                  height: 30px;
                "
                class="search"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                :placeholder="$t('globals.search-here')"
                density="compact"
                variant="text"
                v-model="txt_search"
                @input="getSummerCamp((page = 1))"
              >
              </v-text-field>
            </div>
            <div class="d-flex  justify-end" style="flex:1;" >
              <v-btn-group density="compact"  divided >
                  <v-btn  size="small"  @click="exportToExcel">
                      <span>{{$t('export.export')}} </span>
                      <v-icon size="large" color="green-darken-2" end>mdi-file-excel</v-icon>
                  </v-btn>
                  <VBtn @click="$router.push({ name: 'summer-school' })" size="small" v-if="role<4">
                      <span >{{$t('globals.create')}}</span>
                      <VIcon icon="mdi-plus-thick" color="success"  end></VIcon>
                  </VBtn>
              </v-btn-group>
            </div>
          </div>
        </div>
        <!-- ######## End Header Of Data Table Server ####### -->
        <v-data-table-server
          :headers="selectedHeaders"
          :items="tableList"
          fixed-footer
          fixed-header
          :height="tableList?.length>10?420:null"
          :search="txt_search"
          density="compact"
          class="elevation-1"
          :loading="loading"
          v-model:sort-by="sortBy"
          item-value="name"
          item-key="id"
          @update:options="getSummerCamp((page = 1))"
        >
          <template v-slot:column.checkbox="{ column }">
            <v-checkbox hide-details density="compact"> </v-checkbox>
          </template>
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
                </v-checkbox>
                <span
                  v-if="
                    key !== 'identity_image' &&
                    key !== 'logo' &&
                    key !== 'license_image' &&
                    key !== 'place_type' &&
                    key !== 'evaluation' &&
                    key !== 'have_license' &&
                    key !== 'is_garden' &&
                    key !== 'students_type' &&
                    key !== 'shift_time' &&
                    key !== 'school_type' &&
                    key !== 'fk_supervisor'
                  "
                >
                  {{ val }}
                </span>
                <span v-else-if="key == 'have_license' || key == 'is_garden'">
                  <v-icon v-if="val == true" color="success">mdi-check-circle</v-icon>
                  <v-icon v-if="val == false" color="error">mdi-close-circle</v-icon>
                </span>
                <span v-if="key == 'place_type'">
                  {{ getPlaceTypesListName(val) }}
                </span>
                <span v-if="key == 'evaluation'">
                  {{ getEvaluationsName(val) }}
                </span>
                <span v-if="key == 'students_type'">
                  {{ getStudentTypesName(val) }}
                </span>
                <span v-if="key == 'shift_time'">
                  {{ getShiftTimesName(val) }}
                </span>
                <span v-if="key == 'school_type'">
                  {{ getShoolTypesName(val) }}
                </span>
                <span v-if="key == 'fk_supervisor'">
                  {{ getSupervisorsName(val) }}
                </span>
                <span v-if="key == 'logo' || key == 'license_image'">
                  <button @click="showImage(val, key)" v-if="val">
                    <v-avatar
                      :image="
                        val != null && val.search(base_url) != -1 ? val : base_url + val
                      "
                      class=""
                      size="small"
                    ></v-avatar>
                  </button>
                  <span v-else>
                    <VIcon class="text-h4"> mdi-image-broken </VIcon>
                  </span>
                </span>
                <span v-if="key === 'actions'" style="min-width:100px !important">
                  <v-btn
                    variant="text"
                    class="me-1 v-btn-icon"
                    size="large"
                    v-if="CheckGetPermission('summer_center.change_schoolsummer')"
                    @click="editItem(item.raw)"
                  >
                    <v-icon color="warning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    variant="text"
                    size="small"
                    class="v-btn-icon"
                    @click="deleteItem(item.raw)"
                    v-if="CheckGetPermission('summer_center.delete_schoolsummer')"
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination?.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination?.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </v-card-text>
    </v-card>
  </VCard>
  <VDialog v-model="showImg_dialog" max-width="400px" max-height="400px">
    <VCard align="center">
      <VCardTitle>
        <h1 class="text-h6">{{ dialog_title }}</h1>
      </VCardTitle>
      <VCardItem align="center">
        <VImg :src="dialog_image" max-height="300px"></VImg>
      </VCardItem>
      <VCardText>
        <tr v-for="(item, index) in dialog_text" :key="index">
          <td class="text-grey-darken-1 font-weight-bold">{{ item.type }} :</td>
          <td>{{ item.contact }}</td>
        </tr>
      </VCardText>
      <VCardActions>
        <VBtn class="bg-info" @click="showImg_dialog = false">{{
          $t("globals.ok")
        }}</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <VDialog v-model="del_dialog" scrollable :overlay="false" max-width="350px">
    <VCard>
      <VCardTitle class="text-blue">
        {{ $t("globals.confirmation") }}
      </VCardTitle>
      <VCardText class="text-blue-grey-darken-4">
        {{ $t("globals.confirm_delete") }}
      </VCardText>
      <VCardActions>
        <VBtn
          class="font-weight-bold me-2"
          color="error"
          @click.prevent="deleteSummerSchool"
          :loading="deleteDataLoading"
        >
          {{ $t("globals.delete") }}
        </VBtn>
        <VBtn
          class="bg-white font-weight-bold"
          color="blue"
          @click.prevent="cancelDeleteItem"
        >
          {{ $t("globals.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <alert-protected-records
    v-model="protected_records"
    :data_message="data_message"
  ></alert-protected-records>
</template>
<script>
import { mapState, mapActions,mapGetters} from "vuex";
import useValidate from "@vuelidate/core";
import {
  email,
  required,
  helpers,
  maxLength,
  url,
  alpha,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
const englishOnly = helpers.regex(/^[a-zA-Z\-'\s]+$/);
const arabicOnly = helpers.regex(
  /^[\u0600-\u06ff\u0750-\u077f-\u080a-\u08ff\ufb50-\ufdff\ufe70-\ufeff\s]*$/
);
export default {
  data() {
    return {
      filter:{
        country:undefined,
        governorate:undefined,
        directorate:undefined
      },
      schools_summer:[],
      protected_records: false,
      data_message: [],
      deleteDataLoading: false,
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],
      alert: true,
      DataLoading: false,
      v$: useValidate(),
      country: {},
      updated_id: undefined,
      del_dialog: false,
      delete_id: undefined,
      headers: [
        { title: this.$t("summer.center_name_ar"), key: "name_ar" },
        { title: this.$t("summer.center_name_en"), key: "name_en" },
        { title: this.$t("summer.manager"), key: "manager_name",sortable:false },
        { title: this.$t("globals.phone"), key: "manager_phone",sortable:false },
        { title: this.$t("summer.summer_logo"), key: "logo", sortable: false },
        { title: this.$t("summer.class-count"), key: "class_count" },
        { title: this.$t("globals.governorate"), key: "governorate_name" },
        { title: this.$t("globals.directorate"), key: "directorate_name" },
        { title: this.$t("summer.solitude"), key: "address" },
        {
          title: this.$t("school.establish_date"),
          key: "date_of_establishment",
        },
        {
          title: this.$t("summer.overall-assessment"),
          key: "evaluation",
          sortable: false,
        },
        { title: this.$t("summer.school-has-license"), key: "have_license" },
        { title: this.$t("school.license_image"), key: "license_image", sortable: false },
        { title: this.$t("school.license_number"), key: "license_number" },
        { title: this.$t("summer.school-has-garden"), key: "is_garden" },
        { title: this.$t("summer.place-type"), key: "place_type" },
        { title: this.$t("summer.place-name"), key: "place_name" },
        { title: this.$t("summer.summer_type"), key: "school_type" },
        { title: this.$t("summer.shift-time"), key: "shift_time" },
        { title: this.$t("school.students_type"), key: "students_type" },
        {
          title: this.$t("summer.supervising-authority-name"),
          key: "fk_supervisor",
          sortable: false 
        },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ],
      selectedHead: [
        "name_ar",
        "manager_name",
        "manager_phone",
        "logo",
        "governorate_name",
        "directorate_name",
        "place_type",
        "students_type",
        "fk_supervisor",
        "actions",
      ],
      showImg_dialog: false,
      txt_search: undefined,
    };
  },
  computed: {
    ...mapState({
      placeTypesList: (state) => state.summer.placeTypesList,
      evaluations: (state) => state.summer.evaluations,
      studentTypes: (state) => state.summer.studentTypes,
      shiftTimes: (state) => state.summer.shiftTimes,
      shoolTypes: (state) => state.summer.shoolTypes,
      supervisors: (state) => state.summer.supervisors,
      countries: (state) => state.school.countries,
      user: (state) => state.User,
    }),
    role(){
        return localStorage.getItem('role')
    },
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.schools_summer) {
        var schools = JSON.parse(JSON.stringify(this.schools_summer));
        var changed_schools =schools.map(school=>{
          const manager = school.manager;
          return {
            ...school,
            manager_name:manager?.name_ar??'',
            manager_phone:manager?.phone_number??'',
          }
        })
        return changed_schools
      }
    },
    excelListData() {
      if (this.schools_summer) {
        var schools = JSON.parse(JSON.stringify(this.schools_summer),(key,value)=>value===null?'---':value);
        var changed_schools =schools.map(school=>{
          const manager = school.manager;
          return {
            ...school,
            manager_name:manager?.name_ar??'',
            manager_phone:manager?.phone_number??'',
            shift_time:this.getShiftTimesName(school.shift_time),
            is_garden:school.is_garden==true?this.$t('globals.yes'):this.$t('globals.no'),
            evaluation:this.getEvaluationsName(school.evaluation),
            have_license:school.have_license==true?this.$t('globals.yes'):this.$t('globals.no'),
            school_type:this.getShoolTypesName(school.school_type),
            place_type:this.getPlaceTypesListName(school.place_type),
            students_type:this.getStudentTypesName(school.students_type),
            fk_supervisor:this.getSupervisorsName(school.fk_supervisor)
            
          }
        })
        return changed_schools
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  validations() {
    return {
      country: {
        name_ar: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
          arabicOnly: helpers.withMessage(this.$t("globals.must_be_letters"), arabicOnly),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        name_en: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
          englishOnly: helpers.withMessage(
            this.$t("globals.must_be_letters_en"),
            englishOnly
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        nati_name_ar: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
          arabicOnly: helpers.withMessage(this.$t("globals.must_be_letters"), arabicOnly),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        nati_name_en: {
          required: helpers.withMessage(this.$t("globals.required_field"), required),
          englishOnly: helpers.withMessage(
            this.$t("globals.must_be_letters_en"),
            englishOnly
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(250)
          ),
        },
      },
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if(this.checkrole(['sys_admin','gen_admin','dir_admin','gov_admin','summer_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })
      // await this.getSummerCamp();
      await this.getCountries()
      await this.getGovernorates()
      await this.getDirectorate()
      await this.getPlaceTypesList();
      await this.getEvaluations();
      await this.getStudentTypes();
      await this.getShiftTimes();
      await this.getShoolTypes();
      await this.getSupervisors();
      if (this.role=2 || this.role==3){
        this.getGovernorateData()
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
      return this.$store.getters.checkrole(role);
    },
    exportToExcel() {
      const headers=this.headers.filter(el=>!['actions','logo','license_image','note'].includes(el.key)).map(item=>{
        return{ header:item.title,key:item.key }
      })
      this.$ExportToExcel(headers,this.excelListData,'بيانات الدورات الصيفية')
    },
    getGovernorateData(){
      this.axios(`${this.base_url}directorate/data/`,{
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }).then(response=>{
          this.filter.country=response.data.country
          this.filter.governorate=response.data.governorate
        })
    },
    ...mapActions({
      getPlaceTypesList: "summer/getPlaceTypesList",
      getEvaluations: "summer/getEvaluations",
      getStudentTypes: "summer/getStudentTypes",
      getShiftTimes: "summer/getShiftTimes",
      getShoolTypes: "summer/getShoolTypes",
      getSupervisors: "summer/getSupervisors",
      getSupervisors: "summer/getSupervisors",
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",

    }),
    getPlaceTypesListName(value) {
      let placeType = this.placeTypesList.find((element) => element.id === value);
      return placeType ? placeType.name : "";
    },
    getEvaluationsName(value) {
      let evaluation = this.evaluations.find((element) => element.id === value);
      return evaluation ? evaluation.name : "";
    },
    getStudentTypesName(value) {
      let studentType = this.studentTypes.find((element) => element.id === value);
      return studentType ? studentType.name : "";
    },
    getShiftTimesName(value) {
      let shiftTime = this.shiftTimes.find((element) => element.id === value);
      return shiftTime ? shiftTime.name : "";
    },
    getShoolTypesName(value) {
      let shoolType = this.shoolTypes.find((element) => element.id === value);
      return shoolType ? shoolType.name : "";
    },
    getSupervisorsName(value) {
      let supervisors = this.supervisors
        .filter((obj) => value.includes(obj.id))
        .map((obj) => obj.name)
        .join(", ");
      return supervisors ? supervisors : "";
    },
    async getSummerCamp(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/school`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
          country:this.filter.country,
          governorate:this.filter.governorate,
          directorate:this.filter.directorate
        },
      }).then((response) => {
        this.schools_summer = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data?.pagination?.num_pages;
        this.loading = false;
      });
    },
    editItem(summer) {
      this.$router.push({
        name: "summer-school-edit",
        params: { id: summer.id },
      });
    },
    resetForm() {
      this.$refs.form.reset();
      this.updated_id = undefined;
      this.deleteDataLoading = false;
    },
    deleteItem(data) {
      this.delete_id = data.id;
      this.del_dialog = true;
    },
    showImage(value, key) {
      this.dialog_text = [];
      if (value != null && value.search(this.base_url) != -1) this.dialog_image = value;
      else this.dialog_image = this.base_url + value;
      if (key == "license_image") this.dialog_title = this.$t("school.license_image");
      else this.dialog_title = this.$t("summer.summer_logo");
      this.showImg_dialog = true;
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async deleteSummerSchool() {
      if (this.delete_id != undefined) {
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(`${this.base_url}/api/summer/school/${this.delete_id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.deleteDataLoading = false;
          })
          .catch((error) => {
            this.deleteDataLoading = false;
            if (error.message.includes(418)) {
              this.protected_records = true;
              let error_messages = [];
              error.response.data.data.forEach((error) => {
                error_messages.push(
                  this.$t(`models.${error.model}`) + ` = ${error.count}`
                );
              });
              this.data_message = error_messages;
            } else {
              this.$emit("errorAlert", this.$t("alert.failure.delete"));
            }
          });
        this.del_dialog = false;
        this.delete_id = undefined;
        this.getSummerCamp();
      }
    },
  },
  watch: {
    perPage() {
      this.getSummerCamp();
    },
    page() {
      this.getSummerCamp(this.page);
    },
  },
};
</script>
<style scoped>
.v-application__wrap {
  background-color: #999;
}
</style>
