<template>
	<VExpansionPanels v-model="panel" :disabled="true" multiple>
		<VExpansionPanel density="compat" class="mb-2">
			<VExpansionPanelTitle density="compat" class="font-weight-bold">
				{{ $t("financial.receipt_header") }}
			</VExpansionPanelTitle>
			<VExpansionPanel-text density="compat">
				<VForm ref="form" class="" >
					<VCard class="h-100 mb-2 pa-0">
						<VRow class="pt-3 px-2 pb-0">
							<VCol class="pt-3 px-3" xs="12" md="3" lg="3">
								<VCardItem class="pa-0">
									<VSelect
										:items="isItBox"
										v-model="box_or_bank_analyst"
										item-title="name"
										item-value="id"
										:label="receiverAccount"
										persistent-hint
										density="compact"
										:rules="box_or_bank_analyst_rule"
									></VSelect>
								</VCardItem>
							</VCol>
							<VCol class="pa-0" xs="12" md="3" lg="3">
								<VCardItem class="pa-0">
									<VRadioGroup density="compact" inline :label="$t('financial.payment_type')"
									v-model="payment_type" @change="changeIsBox(payment_type)">
										<VRadio :label="$t('financial.check')" :value="0" color="grey-darken-1"></VRadio>
										<VRadio :label="$t('financial.cash')" :value="1" color="grey-darken-1"></VRadio>
									</VRadioGroup>
								</VCardItem>
							</VCol>
							<VCol class="pt-3 px-3" xs="12" md="3" lg="3">
								<VCardItem class="pa-0">
									<VSelect
										:items="doctype"
										v-model="selected_doctype"
										item-title="type_name"
										item-value="id"
										:label="$t('financial.doctype')"
										persistent-hint
										clearable
										density="compact"
									></VSelect>
								</VCardItem>
							</VCol>
							<VCol class="pa-0" xs="12" md="3" lg="3">
								<VCardItem density="compact">
									<h1 class="text-subtitle-1 text-grey-darken-1">
										{{$t('financial.date')}}
									</h1>
									<h1 class="text-subtitle-1 text-grey-darken-1 border">
										{{new Date().toLocaleDateString()}}
									</h1>
								</VCardItem>
							</VCol>
						</VRow>
						<VRow class="px-2 py-0">
							<VCol cols="12" class="pa-0 ma-0" xs="12" md="3" lg="3">
								<VCardItem v-if="!isBox">
									<VTextField
										v-model="check_number"
										:append-inner-icon="(check_number === null||check_number=='')? 'mdi-numeric': ''"
										:label=" $t('financial.check_number') "
										density="compact"
										type="number"
										:rules="check_number_rule"
									>
									</VTextField>
								</VCardItem>
							</VCol>
							<VCol cols="12" class="pa-0 ma-0" xs="12" md="3" lg="3">
								<VCardItem v-if="!isBox">
									<VTextField
										v-model="collect_date"
										:append-inner-icon="(collect_date === null||collect_date=='')? 'mdi-date': ''"
										:label=" $t('financial.collect_date') "
										density="compact"
										type="date"
										:rules="collect_date_rule"
									>
									</VTextField>
								</VCardItem>
							</VCol>
						</VRow>
						<VDivider class="border-opacity-100 mx-auto" color="blue-grey-lighten-4" length="5000" thickness="15">
						</VDivider>
						<VRow class="px-2 py-0">
							<VCol class="pt-3 px-3" xs="12" md="6" lg="6">
								<VCardItem class="pa-0">
									<VSelect 
										:items="accounts"
										v-model="fk_account"
										item-title="account_name"
										item-value="id"
										:label="$t('financial.receiver_account')"
										persistent-hint
										density="compact"
										:rules="fk_account_rule"
									></VSelect>
								</VCardItem>
							</VCol>
							<VCol class="pt-3 px-3" xs="12" md="6" lg="6" >
								<VCardItem class="pa-0" v-if="!isnoAnalyst">
									<VSelect
										:items="analytic"
										v-model="fk_analyst_account"
										item-title="name"
										item-value="id"
										:label="$t('financial.analyst_account')"
										persistent-hint
										density="compact"
										:rules="fk_analyst_account_rule"
									></VSelect>
								</VCardItem>
							</VCol>
						</VRow>
						<VRow class="px-2 py-0">
							<VCol cols="12" class="pa-0 ma-0" xs="12" md="4" lg="4">
								<VCardItem>
									<VTextField
										v-model="payment"
										:append-inner-icon="(payment === null||payment=='')? 'mdi-numeric': ''"
										:label=" $t('financial.payment_amount') "
										density="compact"
										type="number"
										:rules="payment_rule"
									>
									</VTextField>
								</VCardItem>
							</VCol>
							<VCol cols="12" class="pa-0 ma-0" xs="12" md="4" lg="4">
								<VCardItem>
									<VSelect
										:items="currency"
										v-model="fk_currency"
										item-title="currency_name"
										item-value="id"
										:label="$t('financial.fk_currency')"
										persistent-hint
										density="compact"
										:rules="fk_currency_rule"
									></VSelect>
								</VCardItem>
							</VCol>
							<VCol cols="12" class="pa-0 ma-0" xs="12" md="4" lg="4" v-if="seeIfLocalCurrency">
								<VCardItem>
									<VTextField
										v-model="exchange_price"
										:append-inner-icon="(exchange_price === null||exchange_price=='')? 'mdi-numeric': ''"
										:label="isITGoodExchangePrice"
										density="compact"
										type="number"
										:rules="exchange_price_rule"
									>
									</VTextField>
								</VCardItem>
							</VCol>
						</VRow>
						<VRow class="px-2 py-0">
							<VCol cols="12" class="pa-0 ma-0" xs="12" md="12" lg="12">
								<VCardItem>
									<VTextField
										v-model="numbersToWords"
										:append-inner-icon="(written_payment === null||written_payment=='')? 'mdi-text': ''"
										:label=" $t('financial.written_payment') "
										density="compact"
										:rules="written_payment_rule"
									>
									</VTextField>
								</VCardItem>
							</VCol>
						</VRow>
						<VRow class="px-2 py-0">
							<VCol cols="12" class="pa-0 ma-0" xs="12" md="12" lg="12">
								<VCardItem class="px-3">
									<VTextarea
										v-model="receipt_comments"
										bg-color="blue-grey-lighten-4"
										clearable
										:label="$t('financial.description')"
										:rules="receipt_comments_rule"
										variant="underlined"
										counter
										no-resize
										density="compact"
										rows="2"
									></VTextarea>
								</VCardItem>
							</VCol>
						</VRow>
					</VCard>
				</VForm>
			</VExpansionPanel-text>
		</VExpansionPanel>
    </VExpansionPanels>
    <VRow
		class="mt-4 d-flex align-content-center justify-center flex-wrap"
		elevation="4"
	>
		<VCol xs="1" lg="2" class="d-flex justify-center">
			<VBtn class="w-50" @click.prevent="saveReciept()">
				<VTooltip offset="5" activator="parent" location="bottom">
					{{ $t("globals.add") }}
				</VTooltip>
				<VIcon class="text-h5" color="success">mdi-plus-circle</VIcon>
			</VBtn>
		</VCol>
		<VCol xs="1" lg="2" class="d-flex justify-center">
			<VBtn
				class="w-50"
				@click.prevent=""
				disabled
			>
				<VTooltip offset="5" activator="parent" location="bottom">
					تعديل
				</VTooltip>
				<VIcon class="text-h5" color="secondary">
					mdi-square-edit-outline
				</VIcon>
			</VBtn>
		</VCol>
		<VCol xs="1" lg="2" class="d-flex justify-center">
			<VBtn class="w-50" @click.prevent="resetForm()">
				<VTooltip offset="5" activator="parent" location="bottom">
					{{ $t("globals.clear") }}
				</VTooltip>
				<VIcon class="text-h5">mdi-refresh-circle</VIcon>
			</VBtn>
		</VCol>
		<VCol xs="1" lg="2" class="d-flex justify-center">
			<VBtn
				class="w-50" disabled>
				<VTooltip offset="5" activator="parent" location="bottom">
					{{ $t("globals.delete") }}
				</VTooltip>
				<VIcon class="text-h5" color="error"
					>mdi-delete-circle-outline</VIcon
				>
			</VBtn>
		</VCol>
		<VCol xs="1" lg="2" class="d-flex justify-center">
			<VBtn class="w-50" >
				<VTooltip offset="5" activator="parent" location="bottom">
					{{ $t("globals.back") }}
				</VTooltip>
				<VIcon class="text-h5">mdi-logout</VIcon>
			</VBtn>
		</VCol>
	</VRow>
	<Alert
		v-model="process_alert"
		:iconColor="icon_color"
		:icon="icon"
		:title="alert_title"
		:message="alert_message"
		>
	</Alert>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { required, minValue, decimal, requiredIf,maxLength,numeric } from "@vuelidate/validators"
import useValidate from "@vuelidate/core"
import axios from "axios"
import tafqeet from "../../../assets/Tafqeet"

export default {

	name: "App",
	data() {
	return {
		panel: [0],
		process_alert:undefined,
		icon_color:undefined,
		icon:undefined,
		alert_title:undefined,
		alert_message:undefined,
		lowest_exchange:undefined,
		highest_exchange:undefined,
		local_curr:true,
		fk_currency:undefined,
		receiverAccount: this.$t('financial.box_account'),
		isBox: true,
		analyst: undefined,
		selected_doctype:undefined,
		payment_type: 1,
		payment: undefined,
		written_payment: "",
		receipt_comments: "",
		check_number: undefined,
		collect_date: undefined,
		exchange_price: undefined,
		box_or_bank_analyst: undefined,
		fk_account: undefined,
		fk_analyst_account: undefined,
		v$: useValidate(),
		box_or_bank_analyst_rule:[
			(value) => value || this.$t('globals.required_field'),
			],
		check_number_rule:[
			(value) => (this.payment_type==0? !!value:true) || this.$t('globals.required_field'),
			(value) => (!value || /^[0-9]+$/.test(value)) || this.$t('globals.must_be_numbers'),
			(value) => (value.length <= 50) || `${this.$t('globals.biggest_number_of_character')} 50`,
			],
		collect_date_rule:[
			(value) => (this.payment_type==0? !!value:true) || this.$t('globals.required_field'),
			(value) => new Date(value).getTime() >= new Date().getTime() || this.$t('financial.date_limit_exceeded')
			],
		fk_account_rule:[
			(value) => !!value || this.$t('globals.required_field'),
			],
		fk_analyst_account_rule:[
			(value) => (!this.noAnalyst? !!value:true) || this.$t('globals.required_field'),
			],
		payment_rule:[
			(value) => !!value || this.$t('globals.required_field'),
			(value) => (!value || /^[0-9 .]+$/.test(value)) || this.$t('globals.must_be_numbers'),
			(value) => (value && value.length <= 16) || `${this.$t('globals.biggest_number_of_character')} 16`,
			],
		fk_currency_rule:[
			(value) => !!value || this.$t('globals.required_field'),
			],
		exchange_price_rule:[
			(value) => (!this.noAnalyst? !!value:true) || this.$t('globals.required_field'),
			(value) => (!value || /^[0-9 .]+$/.test(value)) || this.$t('globals.must_be_numbers'),
			(value) => (value.length <= 9) || `${this.$t('globals.biggest_number_of_character')} 9`
			],
		written_payment_rule:[
			(value) => !!value || this.$t('globals.required_field'),
			(value) => (value && value.length <= 150) || `${this.$t('globals.biggest_number_of_character')} 150`,  
			],
		receipt_comments_rule:[
			(value) => !!value || this.$t('globals.required_field'),
			(value) => (value && value.length <= 255) || `${this.$t('globals.biggest_number_of_character')} 255`,  
			],
		};
    },
    computed: {
	numbersToWords(){
		if(this.payment)
			this.written_payment= tafqeet(this.payment)
		return tafqeet(this.payment)
	},
	...mapState({
		banks: (state) => state.financial.banks,
		boxes: (state) => state.financial.boxes,
		analytic: (state) => state.financial.analytic,
		accounts: (state) => state.financial.accounts,
		noAnalyst: (state) => state.financial.noAnalyst,
		currency: (state) => state.financial.currency,
		doctype: (state) => state.financial.doctype,
	}),
	isnoAnalyst(){
		if(this.fk_account)
			this.selectAnalyst(this.fk_account)
		return this.noAnalyst
	},
	seeIfLocalCurrency(){
		let curr=this.currency.find(currencies=>currencies.id==this.fk_currency)
		if(curr){
			this.lowest_exchange=curr.lowest_exchange_price
			this.highest_exchange=curr.highest_exchange_price
			this.exchange_price=curr.exchange_price
			if(curr.currency_type==1){
				this.local_curr=true
				return false
			} 
			else{
				this.local_curr=false
				return true
		  	} 
		}
		return false
	},
	isItBox(){
	  	if(this.isBox==true)
			return this.boxes
	  	else
			return this.banks
	},
	isITGoodExchangePrice(){
		if(!this.local_curr){
			if(this.exchange_price>this.highest_exchange)
				return this.$t('financial.high_exchange_warning')
			else if(this.exchange_price<this.lowest_exchange)
				return this.$t('financial.low_exchange_warning')
			else
				return this.$t('financial.curr_exchange_price')
	  	}
	},
	},
	async created() {
        try {
			await this.$store.commit('updateLoadingValue',true)
			await this.setAccounts();
			await this.getDocType(0)
			this.$store.commit('updateLoadingValue',false)
		} catch (error) {
			this.$store.commit('updateLoadingValue',false)
			
		}
	},
	validations() {
		return {
			payment: { required, minValue: minValue(0), decimal,maxLength:maxLength(15),numeric },
			written_payment: { required,maxLength:maxLength(150) },
			receipt_comments: { required,maxLength:maxLength(255) },
			check_number: { requiredIf: requiredIf(() => !this.payment_type),maxLength:maxLength(20) },
			box_or_bank_analyst: { required },
			fk_account: { required },
		};
	},
	methods: {
		...mapActions({
			setAccounts: "financial/setAccounts",
			selectAnalyst: "financial/selectAnalyst",
			getDocType: "financial/getDocType",
		}),
		enough_money(){
			let selectedAccount=undefined
			let payment=0
			if(this.isBox){
				selectedAccount=this.boxes.find(box=>box.id==this.box_or_bank_analyst)
			}
			else{
				selectedAccount=this.banks.find(bank=>bank.id==this.box_or_bank_analyst)
				if(selectedAccount.bank_type)
					return true
			}

			if(this.local_curr){
				payment=this.payment
			}
			else{
				payment=this.payment*this.exchange_price
			}
			
			if(selectedAccount.current_balance >= payment)
				return true
			else
				return false	  
		},
		resetForm(){
			this.$refs.form.reset()
			this.payment_type=1;
			this.changeIsBox(1)
		},
		
		changeIsBox(state) {
			this.isBox = state==1?true:false;
			this.receiverAccount = state ? this.$t('financial.box_account') : this.$t('financial.bank_account');
			this.box_or_bank_analyst=undefined
		},
		async saveReciept() {
			const { valid } = await this.$refs.form.validate()
			this.v$.$validate();
			if (!this.v$.$error && valid && this.enough_money()) {
				let result = await axios.post("http://127.0.0.1:3000/receipt/", {
					receipt_type: 0,
					payment_type: this.payment_type,
					payment: this.payment,
					written_payment: this.written_payment,
					receipt_comments: this.receipt_comments,
					check_number: this.check_number,
					collect_date: this.collect_date,
					exchange_price: this.exchange_price,
					box_or_bank_analyst: this.box_or_bank_analyst,
					fk_account: this.fk_account,
					fk_currency:this.fk_currency,
					fk_analyst_account: this.fk_analyst_account,
					fk_doctype:this.selected_doctype,
					box_or_bank_account: this.isBox,
					fk_doer: 1,
				});
			
				if (result.status == 204) {
					this.icon_color="success"
					this.icon="check-circle"
					this.alert_title=this.$t('financial.success')
					this.alert_message=this.$t('financial.added_successfully')
					this.process_alert=true
					setTimeout(()=>{
						this.process_alert=false
					},1500)
					resetForm()
				}
				else{
					this.icon_color="error"
					this.icon="cancel"
					this.alert_title=this.$t('financial.failure')
					this.alert_message=this.$t('financial.error_in_data')
					this.process_alert=true
					setTimeout(()=>{
						this.process_alert=false
					},1500)
				}
			} else {
					this.icon_color="error"
					this.icon="cancel"
					this.alert_title=this.$t('financial.failure')
					this.alert_message=this.$t('financial.error_in_data')
					this.process_alert=true
					setTimeout(()=>{
						this.process_alert=false
					},1500)
				}
		},
  	},
};
</script>

<style>
</style>