<template>
  <!-- filter data  -->
  <v-card flat class="justify-center" id="lectures-report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("summer.report.visitors-with-evaluation-and-recommendations") }}
          </div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="mt-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            density="compact"
            class="pa-0"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>
        </div>
      </div>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" v-if="items.length > 0" rounded="0" flat>
          <table id="stable" class="table" style="width: 100%; border-collapse: collapse">
            <thead class="table-light">
        
              <th
                v-for="header in selectedHeaders"
                :key="header.key"
                class="bg-blue-grey-lighten-4 pa-2"
              >
                {{ header.title }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border bg-blue-grey-lighten-4"
                  v-if="selectedHead.includes('id')"
                >
                  {{ key + 1 }}
                </td>
                <td class="text-center border" v-if="selectedHead.includes('name')">
                  {{ item.name_ar }}
                </td>
                <td class="text-center border" v-if="selectedHead.includes('adjective')">
                  {{ item.adjective }}
                </td>
                <td class="text-center border" v-if="selectedHead.includes('date')">
                  {{ spiltDate(item.date) }}
                </td>
                <td class="border pa-0" v-if="selectedHead.includes('impression')">
                  <table class="w-100" style="border-collapse: collapse;">
                    <tr>
                      <td style="border-top: unset !important">
                        {{ $t("summer.visitors.impression") }}:
                        {{ item.impression }}
                      </td>
                    </tr>
                    <tr>
                      <td style="border-bottom: unset !important">
                        {{ $t("summer.visitors.recommendations") }}:
                        {{ item.recommendation }}
                      </td>
                    </tr>
                  </table>
                </td>
                <td class="text-center border" v-if="selectedHead.includes('evaluation')">
                  {{ item.evaluation }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
      await this.getData();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      },

      school: [],
      current_year: "",
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id", "name", "adjective", "date", "impression", "evaluation"],
      headers: [
        { title: `${this.$t("globals.#")} ` , key: "id" },
    
        {
          title: `${this.$t("summer.visitors.name-visitor")} / ${this.$t(
            "summer.visitors.visitors"
          )}`,
          key: "name",
        },
        { title: this.$t("globals.adjective"), key: "adjective" },
        { title: this.$t("summer.visitors.date-visitor"), key: "date" },
        { title: this.$t("summer.report.impression-about-school"), key: "impression" },
        {
          title: this.$t("summer.report.school-visit-impact-assessment"),
          key: "evaluation",
        },
      ],

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),

    ...mapGetters({}),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({}),
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/visitors`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            search: this.txt_search,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
    spiltDate(date){
      var result=date.split("T")[0];
      return result;
    }
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
}

.pp2 {
  padding: 2px !important;
}
#stable th {
  padding:10px;                                                                                              
  border: 1px solid #649add !important;
  background-color: #fff;
  color:black
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
  padding: 8px;
}

.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>
