<template>
  <v-expansion-panels class="mb-2" >
    <v-expansion-panel  elevation="1" >
      <v-expansion-panel-title class="px-4">
          <span>{{$t('globals.select-criteria')}}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text >
        <v-row class="py-2" >
          <v-col cols="12" md="3" >
            <VAutocomplete
              :label="$t('log-entries.action')"
              item-title="val"
              item-value="id"
              :items="[{id:1,val:$t('permission.add')},{id:2,val:$t('permission.change')},{id:3,val:$t('permission.delete')},]"
              type="data"
              v-model="action_id"
              persistent-hint
              density="compact"
              clearable
              hide-details="auto"
            ></VAutocomplete>
          </v-col>
          <v-col cols="12" md="3" >
            <VAutocomplete
              :label="$t('log-entries.user')"
              item-title="username"
              item-value="id"
              :items="users_list"
              v-model="fk_user"
              persistent-hint
              density="compact"
              clearable
              hide-details="auto"
              @update:modelValue="getData(page=1)"
            ></VAutocomplete>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-card>
    <v-card-text>
        <!-- ######## Header Of Data Table Server ####### -->
        <div
            class="d-flex justify-space-between mb-1"
            v-if="CheckGetPermission('summer_center.view_commitee',['sys_admin','summer_admin','user'])"
            >
            <div class="d-flex" style="flex: 10">
                <v-menu :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                    <v-icon style="color: #5e7e96" class="me-2"
                        >mdi-eye-settings-outline</v-icon
                    >
                    <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-text>
                    <v-checkbox
                        v-for="header in headers"
                        :key="header.key"
                        :label="header.title"
                        :value="header.key"
                        color="indigo"
                        v-model="selectedHead"
                        density="compact"
                        hide-details
                    >
                    </v-checkbox>
                    </v-card-text>
                </v-card>
                </v-menu>

                <v-text-field
                color="indigo"
                v-model="txt_search"
                style="
                    border-radius: 30px;
                    border: 1px solid #ccc;
                    color: black;
                    height: 30px;
                    width: 90%;
                "
                class="search"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                :placeholder="$t('globals.search-here')"
                density="compact"
                variant="text"
                >
                </v-text-field>
            </div>
        </div>
        <!-- ######## End Header Of Data Table Server ####### -->
        <!-- Data Table  -->
        <v-data-table-server
        :headers="selectedHeaders"
        :items="log_entries"
        item-key="id"
        fixed-footer
        density="compact"
        class="elevation-1"
        id="vDataTable"
        @update:options="getData((page = 1))"
        v-model:sort-by="sortBy"
        >
        <template v-slot:item.content_type="{ item }">
            {{$t(`models.${item.value.content_type}`)}}
        </template>
        <template v-slot:item.object_repr="{ item }">
            {{ substring(item.value.object_repr,40)}}
        </template>
        <template v-slot:item.action_flag="{ item }">
           <v-chip v-if="item.value.action_flag==1" class="bg-indigo">
                {{ $t('permission.add') }}
           </v-chip>
           <v-chip v-else-if="item.value.action_flag==2" class="bg-success ">
              <span class="text-white">  {{ $t('permission.change') }}</span>
           </v-chip>
           <v-chip v-else-if="item.value.action_flag==3" class="bg-red">
                {{ $t('permission.delete') }}
           </v-chip>
        </template>
        <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
        <!-- End data Table  -->
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getData()
      await this.getUsersList()
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      loading: false,
      txt_search: null,
      log_entries:[],
      users_list:[],
      loading: false,
      sortBy: [{ key: "id", order: "asc" }],
      selectedHead: ["user","content_type","object_repr","action_time","action_flag",],
      headers: [
        { title: this.$t("log-entries.user"), key: "user" },
        { title: this.$t("log-entries.content-type"), key: "content_type" },
        { title: this.$t("log-entries.record"), key: "object_repr" },
        { title: this.$t("log-entries.action-time"), key: "action_time" },
        { title: this.$t("log-entries.action"), key: "action_flag"},
      ],
      page: 1,
      action_id:null,
      fk_user:null,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: null, text: this.$t("globals.all") },
      ],
    };
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({}),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
  },
  methods: {
    ...mapActions({
    }),
    async getData(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}api/log-entry`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
            page:page,
            page_size:this.perPage,
            txt_search:this.txt_search,
            order_by:this.order_data,
            fk_user:this.fk_user
        },
      }).then((response) => {
        this.loading = false;
        this.log_entries=response.data.results
        this.pagination=response.data.pagination
        this.itemsPerPage[4].value=this.pagination.count
      }).catch(error=>{
        this.loading=false
      });
    },
    async getUsersList() {
      this.loading = true;
      await this.axios(`${this.base_url}userdata/user-data/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
        },
      }).then((response) => {
        this.users_list=response.data
      })
    },
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
#vDataTable span {
  font-size: 11px;
}
</style>
