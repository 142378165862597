<template>
    <div>
        <v-expansion-panels   class="mb-2" v-if="role >= 0 && role <= 4">
        <v-expansion-panel    elevation="1" >
            <v-expansion-panel-title class="px-4 ">
                <div class="d-flex justify-space-between w-75">
                    <div >
                        <v-icon >mdi-tune</v-icon>   <span class="mx-1">{{$t('globals.select-criteria')}}</span>
                    </div>
                    <div class=" pt-1">
                        <span v-for="item in LocationBreadcrumb" :key="item" class=" text-indigo mx-1">{{item}}</span>
                    </div>
                </div>
                
            </v-expansion-panel-title>
            <v-expansion-panel-text >
                <v-row class="py-2" >
                    <VCol class="mt-1" cols="12" md="3" sm="6"  v-if="role==0||role==1">
                        <VAutocomplete
                        :items="filterGovernorates(1)"
                        v-model="fk_governorate"
                        item-title="name_ar"
                        item-value="id"
                        :label="$t('globals.governorate')"
                        persistent-hint
                        density="compact"
                        hide-details
                        clearable
                        :no-data-text="$t('governorate.not-found')"
                        prepend-inner-icon="mdi-city"
                        @update:modelValue="getSchoolStatisticsAttendance(),fk_directorate=null,fk_summer=null"
                        ></VAutocomplete>
                    </VCol>
                    <VCol class="mt-1" cols="12" md="3" sm="6" v-if="role >= 0 && role <= 2">
                        <VAutocomplete
                        :items="filterDirectorates(fk_governorate)"
                        v-model="fk_directorate"
                        item-title="name_ar"
                        item-value="id"
                        :label="$t('globals.directorate')"
                        persistent-hint
                        hide-details
                        density="compact"
                        clearable
                        :no-data-text="$t('directorate.not-found')"
                        prepend-inner-icon="mdi-map-outline"
                        @update:modelValue="getSchoolStatisticsAttendance(),fk_summer=null"
                        ></VAutocomplete>
                    </VCol> 
                    <v-col class="mt-1" cols="6" md="3" sm="6">
                        <v-autocomplete
                            color="indigo"
                            density="compact"
                            v-model="date"
                            item-title="date"
                            item-value="date"
                            prepend-inner-icon="mdi-calendar-today"
                            clearable
                            hide-details
                            :items="dates"
                            :label="$t('summer.calendar.hijri-date')"
                            @update:modelValue="getSchoolStatisticsAttendance()"
                        />
                    </v-col>
                    <v-col class="mt-1" cols="6" md="3" sm="6">
                        <v-autocomplete
                        color="indigo"
                        v-model="school_type"
                        density="compact"
                        item-title="type"
                        item-value="id"
                        :clearable="false"
                        prepend-inner-icon="mdi-calendar-week"
                        :items="typeList"
                        hide-details
                        :label="$t('summer.summer_type')"
                        @update:model-value="getSchoolStatisticsAttendance()"
                            
                        />
                    </v-col>
                </v-row>
            </v-expansion-panel-text>
        </v-expansion-panel>
        </v-expansion-panels>
        <!-- data table  -->
        <v-card v-if="statistics?.length>0"> 
            <v-col cols="12" md="4" class="mt-2">
                <v-text-field
                    color="indigo"
                    v-model="txt_search"
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    single-line
                    clearable
                    :placeholder="$t('globals.search-here')"
                    density="compact"
                >
                </v-text-field>
            </v-col>
            <v-card-text>

            <v-table  
                density="compact" 
                class="rounded elevation-1 " 
                v-if="searched_schools?.length>0"
                fixed-header
                :height="searched_schools?.length>10?400:null"
            >
                <thead>
                    <tr>
                        <th>{{$t('globals.number')}}</th>
                        <th v-if="school_type==2">{{$t('summer.summer-camp')}}</th>
                        <th v-if="school_type==2">{{$t('summer.place-name')}}</th>
                        <th v-if="school_type==1">{{$t('globals.directorate')}}</th>
                        <th v-if="school_type==1">{{$t('globals.governorate')}}</th>
                        <th >{{$t('statistics.statistics-pushed')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(statistic,index) in searched_schools" :key="statistic">
                        <td>{{index+1}}</td>
                        <td v-if="school_type==2">{{statistic.school}}</td>
                        <td v-if="school_type==2">{{statistic.place_name}}</td>
                        <td v-if="school_type==1">{{statistic.directorate}}</td>
                        <td v-if="school_type==1">{{statistic.governorate}}</td>
                        <td>
                            <v-icon color="success" v-if="statistic.done==true">mdi-check-circle</v-icon>
                            <v-icon color="error" v-else>mdi-close-circle</v-icon>
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <v-card v-else class="text-center py-2 ">
                <span>{{$t('globals.not-found-items')}}</span>
            </v-card>
            </v-card-text>

        </v-card>
        <v-card v-else class="text-center py-2">
            <span>{{$t('globals.not-found-items')}}</span>
            <!-- <v-alert class="text-success">تم رفع الاحصائيات لجميع الدورات الصيفية</v-alert> -->
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
    name: 'Home',
    async created(){
        try {
            await this.$store.commit('updateLoadingValue',true)
            if(localStorage.getItem('role')==2){
                this.fk_governorate=localStorage.getItem('governorate')
            }
            await this.getCalendar()
            await this.getGovernorates()
            await this.getDirectorate()
            await this.getSchoolStatisticsAttendance()
            this.$store.commit('updateLoadingValue',false)
        } catch (error) {
            this.$store.commit('updateLoadingValue',false)
        }

    },
    data() {
        return {
            fk_governorate:null,
            fk_directorate:null,
            statistics:{},
            dates:[],
            date:null,
            school_type:2,
            typeList: [
                { id: 1, type: this.$t("reportpublic.Open") },
                { id: 2, type: this.$t("reportpublic.Typical") },
            ],
            txt_search:null,
        };
    },
    computed:{
        ...mapState({
            countries: (state) => state.school.countries,
            governorates: (state) => state.school.governorates,
            directorates: (state) => state.school.directorate,
        }),
        searched_schools(){
            if(this.txt_search==null||this.txt_search==undefined){
                return this.statistics
            }
            return this.statistics.filter(statistic=>statistic?.school?.includes(this.txt_search) || statistic?.directorate?.includes(this.txt_search))
        },
        ...mapGetters({
            filterGovernorates: "school/getGovernoratesByCountry",
            filterDirectorates: "school/getDirectorateByGovernorates",
        }),
        role(){
            return localStorage.getItem('role')
        },
        CheckGetPermission(){
            return (prem)=>{
                return this.$store.getters.checkpermission(prem);
            }
        },
    },
    methods:{
        ...mapActions({
            getCountries: "school/getCountries",
            getGovernorates: "school/getGovernorates",
            getDirectorate: "school/getDirectorate",
        }),
        async getCalendar(){
            await this.axios
                .get(this.base_url + "api/summer/calendar", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                })
                .then((response) => {
                if (response.data) {
                    this.dates = response.data.results;
                }
            });
        },
        async getSchoolStatisticsAttendance(){
            await this.axios.get(`${this.base_url}api/summer/report/school-statistics-attendance`,{
                params:{
                    governorate:this.fk_governorate,
                    directorate:this.fk_directorate,
                    date:this.date,
                    school_type:this.school_type
                },
                headers:{'Authorization':'Bearer '+localStorage.getItem('token')  },
            }).then(response=>{
                this.statistics=response.data
            })
        },
        
        
    },
    
}

</script>

