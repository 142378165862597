<template>
  <VCard >
      <!-- v-if="
            CheckGetPermission('summer_center.change_supervisor', [
              'sys_admin',
              'gen_admin',
            ])
          " -->
          <!-- -->
      <v-card 
        flat
        v-if="
          CheckGetPermission('summer_center.add_supervisor', ['sys_admin', 'gen_admin']) ||
          CheckGetPermission('summer_center.change_supervisor', ['sys_admin', 'gen_admin'])
        " 
        >
        <v-card-text class="pb-0">
          <VForm ref="form" @submit.prevent="saveSupervisor">
            <VRow class="mt-2">
              
              <VCol  cols="12" md="5">
                  <VTextField
                    v-model="supervisor.name"
                    autofocus
                    :label="$t('summer.supervising-authority-name')"
                    density="compact"
                    :rules="rules.supervisor_name"
                    prepend-inner-icon="mdi-office-building"
                  >
                  </VTextField>
              </VCol>
              <VCol  cols="12" md="5">
                  <VAutocomplete
                    :items="SuperVisorType"
                    v-model="supervisor.type"
                    item-title="name"
                    item-value="id"
                    :label="$t('summer.supervision-type')"
                    persistent-hint
                    density="compact"
                    :rules="supervisor_type_rule"
                    prepend-inner-icon="mdi-text"
                  ></VAutocomplete>
              </VCol>
            </VRow>
          </VForm>
        </v-card-text>
        <VCardActions class=" px-3" >
          <VBtn
            v-if="!is_update && CheckGetPermission('summer_center.add_supervisor')"
            class="bg-indigo"
            @click="saveSupervisor()"
            size="small"
            :loading="saveDataLoading"
          >
            <span class="text-white">
              {{ $t("globals.add") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn
            v-if="
              is_update &&
              CheckGetPermission('summer_center.change_supervisor', [
                'sys_admin',
                'gen_admin',
              ])
            "
            class="bg-success"
            @click="updateSupervisor()"
            size="small"
            :loading="editDataLoading"
          >
            <span class="text-white">
              {{ $t("globals.edit") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn class="mx-3" size="small" @click="resetForm()">
            {{ $t("globals.clear") }}
            <VIcon icon="mdi-broom" color="golden" end></VIcon>
          </VBtn>
        </VCardActions>
      </v-card>
      <v-divider></v-divider>
      <!-- ######## Header Of Data Table  ####### -->
      <v-card  v-if="CheckGetPermission('summer_center.view_supervisor')" flat>
        <v-card-text>
          <div class="d-flex justify-space-between mb-2 ">
            <div class="d-flex flex-fill">
              <v-menu :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                    <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                    <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <v-checkbox
                      v-for="header in headers"
                      :key="header.key"
                      :label="header.title"
                      :value="header.key"
                      color="indigo"
                      v-model="selectedHead"
                      density="compact"
                      hide-details
                    >
                    </v-checkbox>
                  </v-card-text>
                </v-card>
              </v-menu>
              <div style="width: 100%">
                <v-text-field
                  color="indigo"
                  v-model="txt_search"
                  @input="getSupervisors((page = 1))"
                  style="
                    border-radius: 30px;
                    border: 1px solid #ccc;
                    color: black;
                    height: 30px;
                    width: 100%;
                  "
                  class="search"
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  :placeholder="$t('globals.search-here')"
                  density="compact"
                  variant="text"
                >
                </v-text-field>
              </div>
            </div>
          </div>
          <!-- ######## End Header Of Data Table Server ####### -->
          <v-data-table-server
            :loading="loading"
            :headers="selectedHeaders"
            :items="tableList"
            v-model:sort-by="sortBy"
            item-value="name"
            item-key="id"
            id="vDataTable"
            fixed-footer
            @update:options="getSupervisors((page = 1))"
            density="compact"
            class="border mb-2 rounded elevation-1"
          >
            <template v-slot:item="{ item }">
              <tr class="row-hover">
                <td v-for="(val, key) in item.columns" :key="key">
                  <span>{{ key === "type" ? getSuperVisorTypeName(val) : val }}</span>
                  <span v-if="key === 'actions'">
                    <v-btn
                      variant="text"
                      class="me-1 v-btn-icon"
                      size="large"
                      @click="editSupervisor(item.raw)"
                      v-if="
                        CheckGetPermission('summer_center.change_supervisor', [
                          'sys_admin',
                          'gen_admin',
                        ])
                      "
                    >
                      <v-icon color="warning">mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn
                      variant="text"
                      size="small"
                      class="v-btn-icon"
                      @click="(dialogVisible = true), (this.delete_id = item.raw.id)"
                      v-if="
                        CheckGetPermission('summer_center.delete_supervisor', [
                          'sys_admin',
                          'gen_admin',
                        ])
                      "
                    >
                      <v-icon color="error">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </span>
                </td>
              </tr>
            </template>
            <template v-slot:bottom>
              <div class="d-flex" id="pagination-bar">
                <v-pagination
                  v-model="page"
                  :length="pagination.num_pages"
                  density="compact"
                  show-first-last-page
                >
                </v-pagination>
                <div class="d-flex">
                  <v-select
                    v-model="perPage"
                    class="pa-0"
                    :items="itemsPerPage"
                    item-value="value"
                    item-title="text"
                    density="compact"
                    hide-details
                    variant="text"
                  >
                  </v-select>
                  <span class="mt-2 px-2"
                    >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                    {{ pagination.count }} ] {{ $t("globals.item") }}</span
                  >
                </div>
              </div>
            </template>
          </v-data-table-server>
        </v-card-text>
      </v-card>
  </VCard>
  <VDialog v-model="dialogVisible" scrollable max-width="350px">
    <VCard>
      <VCardTitle class="text-blue">
        {{ $t("globals.confirmation") }}
      </VCardTitle>
      <VCardText class="text-blue-grey-darken-4">
        {{ $t("globals.confirm_delete") }}
      </VCardText>
      <VCardActions>
        <VBtn
          class="font-weight-bold me-2"
          color="error"
          @click.prevent="deleteSupervisor"
          :loading="deleteDataLoading"
        >
          {{ $t("globals.delete") }}
        </VBtn>
        <VBtn
          class="bg-white font-weight-bold"
          color="blue"
          @click.prevent="cancelDeleteItem"
        >
          {{ $t("globals.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <alert-cascade-records 
    v-model="alert_cascade"
    :data_message="data_message"
    :title="alert_cascade_title"
    @confirm-delete="confirmDelete"
  >
  </alert-cascade-records>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      data_message:[],
      alert_cascade_title:'',
      alert_cascade:false,
      force_delete:false,
      supervisors: [],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      supervisor: {},
      sortBy: [
        {
          key: "name",
          order: "asc",
        },
      ],
      rules: {
        supervisor_name: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
      },
      dialogVisible: false,

      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      selectedHead: ["name", "type",this.checkrole(['sys_admin', 'gen_admin']) ? "actions":null],
      headers: [
        { title: this.$t("summer.supervising-authority-name"), key: "name" },
        { title: this.$t("summer.supervision-type"), key: "type", sortable: false }
      ],
      txt_search: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      SuperVisorType: (state) => state.summer.SuperVisorType,
    }),
    ...mapGetters({}),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.supervisors) {
        return JSON.parse(JSON.stringify(this.supervisors));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
    
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      // await this.getSupervisors();
      await this.getSuperVisorType();
      if(this.checkrole(['sys_admin', 'gen_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      getSuperVisorType: "summer/getSuperVisorType",
    }),
    resetForm() {
      this.$refs.form.resetValidation();
      this.supervisor = {};
      this.is_update = false;
      this.updated_id = undefined;
    },
    editSupervisor(supervisor) {
      this.supervisor = supervisor;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    getSuperVisorTypeName(type) {
      let superVisor = this.SuperVisorType.find((element) => element.id == type);
      return superVisor ? superVisor.name : "";
    },
    async saveSupervisor() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.supervisor.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/supervisor/", this.supervisor, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.supervisor = {};
            this.saveDataLoading = false;
          })
          .catch((error) => {
            if (error.response.data.name)
              this.$emit("errorAlert", error.response.data.name[0]);
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
        this.resetForm();
        this.getSupervisors();
      }
    },
    async updateSupervisor() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.editDataLoading = true;
        this.supervisor.data_entry = this.user;
        let result = await this.axios
          .put(
            `${this.base_url}api/summer/supervisor/${this.supervisor.id}/`,
            this.supervisor,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
        this.resetForm();
        this.getSupervisors();
      }
    },
    async deleteSupervisor() {
      if (this.delete_id) {
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(`${this.base_url}api/summer/supervisor/${this.delete_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.deleteDataLoading = false;
          })
          .catch((error) => {
            this.force_delete=false
            if(error.message.includes('406')){
              this.alert_cascade=true
              this.alert_cascade_title=this.$t('alert.attention.delete-supervisor-with-school')
              this.data_message=error.response.data
            }else{
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.deleteDataLoading = false;
          });
        this.dialogVisible = false;
        this.delete_id = undefined;
        this.getSupervisors();
      }
    },
    async getSupervisors(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/supervisor/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.supervisors = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  watch: {
    perPage() {
      this.getSupervisors();
    },
    page() {
      this.getSupervisors(this.page);
    },
    
  },
};
</script>

<style scoped>
.v-application__wrap {
  background-color: #999;
}
</style>
