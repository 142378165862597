<template>
  <!-- filter data  -->
  <v-card  v-if="!exist">
    <v-card-text>
      <h3 class="text-grey-darken-2 ">{{ $t("globals.select-criteria") }}</h3>
      <v-row class=" mt-4 mb-1">
        <v-col cols="12" md="4" sm="6" >
          <v-select
            color="indigo"
            v-model="filter_data.fk_level"
            density="compact"
            item-title="level_with_phase_name"
            item-value="id"
            prepend-inner-icon="mdi-stairs-box"
            :items="levels"
            :label="$t('summer.level.name_ar')"
            hide-details
            clearable
            :error-messages="v$.filter_data.fk_level.$errors.map((e) => e.$message)"
            @update:modelValue="filter_data.fk_week = null"
            :rules="rules.requird_filed"
          />
        </v-col>
        <v-col cols="12" md="4" sm="6" >
          <VAutocomplete
            :items="weeks"
            v-model="filter_data.fk_week"
            item-title="week_name"
            item-value="id"
            :label="$t('summer.calendar.select-week')"
            persistent-hint
            prepend-inner-icon="mdi-calendar-week"
            density="compact"
            clearable
            hide-details
            :error-messages="v$.filter_data.fk_week.$errors.map((e) => e.$message)"
          ></VAutocomplete>
        </v-col>

        <v-col cols="6" sm="2" class=" mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="indigo"
            :loading="loading"
            @click="checkFilterData"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader>
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("report.student_mark_by_level") }} ({{
              this.filter_data.fk_week ? getWeekName(this.filter_data.fk_week) + " -" : ""
            }}
            {{ $t("summer.level.level") }}:{{ getLevelName() }})
          </div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="mt-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-start" style="flex: 1"></div>
        <!-- search box  -->

        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            @click="printTable"
            style="min-width: 25px"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>
          <v-btn density="compact"  @click="exist = false">
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" v-if="items.length > 0" rounded="0" flat>
          <table id="stable" class="table" style="width: 100%; border-collapse: collapse">
            <thead class="table-light">
              <tr>
                <th class="bg-blue-grey-lighten-4 ">{{$t('globals.#')}}</th>
                <th class="bg-blue-grey-lighten-4 ">
                  {{ $t("report.student-name-cap") }}
                </th>
                <template v-for="week in items[0].weeks"
                  :key="week"
                 >
           
                  
                    
            
                        <th
                          class="bg-blue-grey-lighten-4  "
                          style="
                            width: 20%;
                            border-right-width: 0 !important;
                            color: black;
                          "
                        >
                          {{ $t("summer.marks.quran") }}
                        </th>
                        <th class="inside-th-cells bg-blue-grey-lighten-4 " >
                          {{ $t("summer.marks.approach") }}
                        </th>
                        <th class="inside-th-cells bg-blue-grey-lighten-4" >
                          {{ $t("summer.marks.attendance") }}
                        </th>
                        <th class="inside-th-cells bg-blue-grey-lighten-4" >
                          {{ $t("summer.marks.activity") }}
                        </th>
                        <th class="inside-th-cells bg-blue-grey-lighten-4 " >
                          {{ $t("summer.marks.ehsan") }}
                        </th>
                        <th class="inside-th-cells bg-blue-grey-lighten-4" >
                          {{ $t("report.total") }}
                        </th>
                      </template>
                   
            
         
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in items" :key="item">
                <td class="text-center border bg-blue-grey-lighten-4">
                  {{ key + 1 }}
                </td>
                <td class="text-center border">
                  {{ item.student_name }}
                </td>
                <template
                  class="text-center border"
                  v-for="week in item.weeks"
                  :key="week"
                  style="padding: 0"
                >
                
             
                     
                        <td
                         
                          class="text-center border "
                        >
                          {{ week.week_data.quran }}
                        </td>
                        <td
                         
                          class="text-center border "
                        >
                          {{ week.week_data.approach }}
                        </td>
                        <td
                       
                          class="text-center border "
                        >
                          {{ week.week_data.attendance }}
                        </td>
                        <td
                        
                          class="text-center border "
                        >
                          {{ week.week_data.activity }}
                        </td>
                        <td
                        
                          class="text-center border "
                        >
                          {{ week.week_data.ahsan }}
                        </td>
                        <td
                        
                          class="text-center border "
                        >
                          {{ week.week_data.total }}
                        </td>
                 
           
                
                </template>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import ReportHeader from "../../../components/SummerGlobals/ReportHeader";
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));

      await this.getLevels();
      await this.getWeeks();
      await this.getYears();

      this.current_year = this.getYearName(localStorage.getItem("current_year"));
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      },
      current_year: "",
      v$: useValidate(),
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      levels: (state) => state.summer.levels,
      user: (state) => state.User,
      weeks: (state) => state.summer.weeks,
    }),

    ...mapGetters({}),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getLevels: "summer/getLevels",
      getWeeks: "summer/getWeeks",
    }),
    getLevelName() {
      const level = this.levels.find(
        (element) => element.id == this.filter_data.fk_level
      );
      return level ? level.level_with_phase_name : "";
    },
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },
    getWeekName(driver_id) {
      if (driver_id) {
        const committees = this.weeks.find((committees) => committees.id === driver_id);
        return committees.week_name;
      }
      return "===========";
    },
    checkFilterData() {
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/student-marks`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            level: this.filter_data.fk_level,
            week: this.filter_data.fk_week,
          },
        })
        .then((response) => {
          this.loading = false;
          this.exist = true;
          this.items = response.data;
        })
        .catch((error) => {
          this.loading=false
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  validations() {
    return {
      filter_data: {
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_week: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
}

#stable th {
  padding: 10px;
  border: 1px solid #649add;
  border-bottom: unset;
  /* background-color: #fff; */
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
  padding: 4px;
}
#stable td:last-child {
  
  background-color: #cfd8dc;

}
.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}

/* .inside-th-cells {
  border-left-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 0px !important;
} */
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>
