<template>
  <!-- filter data  -->
  <v-card  v-if="!exist">
    <v-card-text>
      <h3 class="text-grey-darken-2 ">{{ $t("globals.select-criteria") }}</h3>
      <v-form ref="form">
        <v-row  ref="form" class="mt-4">
          <VCol  cols="12" md="4">
              <VAutocomplete
                :items="levels"
                v-model="filter_data.level"
                item-title="level_with_phase_name"
                item-value="id"
                :label="$t('summer.level.level')"
                persistent-hint
                prepend-inner-icon="mdi-stairs-box"
                density="compact"
                :rules="rules.level"
                @update:model-value="getDivisionByLevel(), (filter_data.division = null)"
              ></VAutocomplete>
          </VCol>
          <VCol  cols="12" md="4">
              <VAutocomplete
                :items="period"
                v-model="filter_data.period"
                item-title="name"
                item-value="key"
                :label="$t('summer.period')"
                persistent-hint
                prepend-inner-icon="mdi-clock-outline"
                density="compact"
                :rules="rules.period"
              ></VAutocomplete>
          </VCol>

          <v-col cols="6" sm="2" class="mt-2">
            <v-btn
              density="comfortable"
              append-icon="mdi-presentation"
              class="w-100 text-white"
              color="indigo"
              :loading="loading"
              @click="checkFilterData"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader>
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          {{ $t("report.schedule_week") }}
          ({{ $t("summer.level.level") }}:{{ getLevelName() }} /
          {{ $t("summer.shift-time") }} {{ getPeriodName() }}
          <!--  - {{ $t("division.division") }}:{{ getDivisionName() }} -->)
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="mt-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
         <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn
            density="compact"
            @click="(exist = false), v$.filter_data.$reset()"
          >
            <span class="text-indigo">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>

      <div id="myTable">
        <v-card v-if="items[0].days.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card
          class="pt-2"
          :loading="loading"
          v-if="items[0].days.length > 0"
          rounded="0"
          flat
        >
          <table id="stable" class="table" style="width: 100%; border-collapse: collapse">
            <thead class="table-light">
              <tr>
                <th class="bg-blue-grey-lighten-4 pa-2">
                  {{ this.$t("summer.calendar.day-name") }} / 
                  {{ this.$t("globals.lecture") }} 
                </th>
                <th
                  v-for="item in items[0].quotases"
                  :key="item.id"
                  class="bg-blue-grey-lighten-4 pa-2"
                >
                  {{ item.quotas }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in items[0].days" :key="item">
                <td class="text-center border" v-if="selectedHead.includes('day')">
                  {{ item.day }}
                </td>
                <td
                  class="text-center border"
                  v-for="quotase in items[0].quotases"
                  :key="quotase"
                >
                  <span v-if="checkSubject(item.subjects, quotase)">
                    {{ checkSubject(item.subjects, quotase).subject }}
                  </span>
                  <span v-else></span>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import ReportHeader from "../../../components/SummerGlobals/ReportHeader";
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));

      await this.getLevels();
      await this.getYears();
      this.current_year = this.getYearName(localStorage.getItem("current_year"));
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      period: [
        { key: 1, name: "صباحي" },
        { key: 0, name: "مسائي" },
      ],
      divisions: [],
      current_year: "",
      fees_types: [],
      sub_list: [],
      drivers: [],
      parents: [],
      sub_list_sub: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id", "day", "adjective", "entity", "phone_number"],
      headers: [
        // { title: "#", key: "id" },
        { title: this.$t("summer.calendar.week-name"), key: "day" },
        { title: this.$t("globals.adjective"), key: "adjective" },
        { title: this.$t("summer.employee.place-of-work"), key: "entity" },
        { title: this.$t("globals.phone_number"), key: "phone_number" },
      ],

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
      rules: {
        level: [(value) => !!value || this.$t("globals.required_field")],
        division: [(value) => !!value || this.$t("globals.required_field")],
        period: [
          (value) => value == true || value == false || this.$t("globals.required_field"),
        ],
      },
    };
  },
  computed: {
    ...mapState({
      levels: (state) => state.summer.levels,
      user: (state) => state.User,
    }),

    ...mapGetters({}),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getLevels: "summer/getLevels",
    }),
    checkSubject(firstList, subject) {
      let is_found = firstList.find((element) => element.quotas_id == subject.id);
      return is_found;
    },
    async getDivisionByLevel() {
      let levelDivisions = await this.axios.get(
        this.base_url + `api/summer/get-level-division/${this.filter_data.level}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.divisions = levelDivisions.data.division;
    },
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },

    getLevelName() {
      const level = this.levels.find((element) => element.id == this.filter_data.level);
      return level ? level.level_with_phase_name : "";
    },
    getPeriodName() {
      const period = this.period.find(
        (element) => element.key == this.filter_data.period
      );
      return period ? period.name : "";
    },
    getDivisionName() {
      const division = this.divisions.find(
        (element) => element.fk_division == this.filter_data.division
      );
      return division ? division.name : "";
    },
    async checkFilterData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.getData();
      }
    },
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/schedule-summer`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            search: this.txt_search,
            level: this.filter_data.level,
            division: this.filter_data.division,
            period: this.filter_data.period,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  validations() {
    return {
      filter_data: {
        commitee: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  width: 100%;
}

.pp2 {
  padding: 2px !important;
}
#stable th {
  border: 1px solid #649add !important;
  background-color: #fff;
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
  padding: 8px;
}

.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>
