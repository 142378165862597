<template>
  <VCard class="justify-center" :loading="loading">
    <template v-slot:loader>
      <v-progress-linear 
        color="indigo" 
        :reverse="$i18n.locale=='ar'"
        height="3px" 
        indeterminate 
        v-if="loading"
      >
      </v-progress-linear>
    </template>
    <VForm ref="form">
      <VCard-title class="my-1">
      <!-- <VIcon icon="mdi-clipboard-check-outline" />
        <h3 class="d-inline text-grey"  style="padding: 0 4px">
           {{ $t("summer.manage-state-employee") }}
        </h3>
        <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" /> -->
      </VCard-title>
    
      <VRow class="pt-0  px-2 pb-0">
        <VCol cols="12" md="12" class="pt-0 px-3 ">
          <v-text-field
            v-if="student_levels.length>0"
            color="indigo"
            v-model="search_student"
            density="compact"
            clearable
            prepend-inner-icon="mdi-magnify"
            :label="$t('globals.employee-search')"
            @input="searchStudentAttendance"
          />
        </VCol>
      </VRow>
      <v-table  density="compact"   class="mt-4 mx-2 elevation-1 rounded"  v-if="listEmployees.length>0">
        <thead>
          <tr>
                 <th style="width: 3%">{{ $t("globals.number") }}</th>
                    <th style="width: 20%">{{ $t("summer.name-employees") }}</th>
                    <th style="width: 17%">{{ $t("globals.continus") }}</th>
                    <th style="width: 17%">{{ $t("globals.drop-out") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(student,index) in listEmployees" :key="student" >
            <td class="text-center">{{index+1}}</td>
            <td >
              <span>{{student.employee_name}}</span>
            </td> 
            <td  > 
              
              <v-checkbox 
               
                v-model="student.state "
                :value=" student.state ? true  : true "
                hide-details
                density="compact" 
                color="indigo" 
                >
              </v-checkbox>

              
            </td>

                <td > 
              
              <v-checkbox 
                v-model="student.state"
                :value="student.state ? false : false"
                hide-details
                density="compact" 
                color="indigo" 
                >
              </v-checkbox>

              
            </td>
          </tr>
        </tbody>
      </v-table>
      <v-card v-else-if="!loading" class="pa-2 ma-2" >
        <h3 class="text-center text-grey-darken-2">
          {{ $t("globals.not-found-items") }}
        </h3>
      </v-card>
    </VForm>
    <VCardActions
      class="mx-1"
      v-if="
        listEmployees.length>0
      "
    >
       <VBtn
        :loading="loadingSave"
        v-if="
          CheckGetPermission('summer_center.add_employee',['sys_admin','summer_admin','user'])||
          CheckGetPermission('summer_center.change_employee',['sys_admin','summer_admin','user'])
        "
        class="bg-indigo"
        @click="saveData"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.save") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
    
    </VCardActions>
  </VCard>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      listEmployees:[],
      element :[],
      students:[],
      search_student:'',
      talent_selected:[],
      filter_talents:[],
      loading:false,
      student_levels: [],
      loadingSave:false,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      divisions: [],
      studenttalent: {
        division: undefined,
        level: undefined,
      },
    };
  },
  async created() {
    try {
    
      await this.$store.commit("updateLoadingValue", true);
 
      await this.getEmployeesForTable();
     
      
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
    
      
    }),

    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  methods: {
    ...mapActions({
     
    }),

      async getEmployeesForTable() {
     
      this.DataLoading = true;
      let result = await this.axios
        .get(this.base_url + "api/summer/employee-state", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.listEmployees = response.data.results;

          this.DataLoading = false;
         
        })
        .catch((error) => {
          this.DataLoading = false;
          if (error.response.data === "is_holiday") {
            this.$emit("warningAlert", this.$t("summer.is-holiday"));
          } else if (error.response.data === "out_of_range") {
            this.$emit("warningAlert", this.$t("summer.out-of-range"));
          } else {
            this.$emit("warningAlert", this.$t("summer.not-found-data"));
          }
        });
    },
  
  
    resetForm() {
   
     
    },
    async saveData() {
      let newListEmployees = [];
      
       
      for (let index = 0; index < this.listEmployees.length; index++) {
        newListEmployees.push({
          id: this.listEmployees[index].id,
          state: this.listEmployees[index].state
        })
      
        
      }
      this.loadingSave=true
      let result = await this.axios
        .post(this.base_url + `api/summer/employee-state`, newListEmployees, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
   
        })
        .then((response) => {
          this.loadingSave=false
          this.$emit("successAlert", this.$t("globals.data_added"));
          this.resetForm();
          
         
        })
        .catch((error) => {
          this.loadingSave=false

          if (error.response.data ="not_all_saved") {
            
            this.$emit("infoAlert", this.$t("globals.not_all_saved"));
          }else{

            this.$emit("errorAlert", this.$t("globals.error_in_data"));

          }
        });
    },
  },

};
</script>
<style scoped>

.select-container {
  height: 280px;
  box-shadow: rgb(0, 0, 0) -5px 5px 3px -5px inset;
  overflow-y: auto;
}
td span{
  padding: 0 !important;
  font-size: 12px !important;
}
</style>
