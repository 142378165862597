<template>
<v-card class="pb-2">
    <v-row>
        <v-col cols="12" md="4" lg="3" class="pt-5 ma-auto text-center">
            <v-img >
                <v-avatar  size="150" :image="employee_data.account_data.image_user?base_url+employee_data.account_data.image_user:''"></v-avatar>
            </v-img>
        </v-col>
        <v-col cols="12" md="4" lg="4" v-if="!personal_data_exists">
            <h2 class="text-start mt-4 mx-5">{{$t('globals.user-data')}}</h2>
            <v-list class="px-7">
                <v-list-item >
                    <v-list-item-title class="pb-1">{{$t('globals.name')}}</v-list-item-title>
                    <v-list-item-subtitle class="pb-1">{{employee_data.account_data.full_name}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item >
                    <v-list-item-title class="pb-1">{{$t('globals.email')}}</v-list-item-title>
                    <v-list-item-subtitle class="pb-1">{{employee_data.account_data.email}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item >
                    <v-list-item-title class="pb-1">{{$t('user.role')}}</v-list-item-title>
                    <v-list-item-subtitle class="pb-1">{{employee_data.account_data.role_name}}</v-list-item-subtitle>
                </v-list-item>
            </v-list>
            </v-col>
        <v-col cols="12" md="8" lg="9" class="pt-5" v-if="personal_data_exists">
            <h2 class="text-start mb-2">{{$t('globals.personal-information')}}</h2>
            <v-row class="">
                <v-col cols="12" md="4" lg="4">
                    <v-list>
                        <v-list-item >
                            <v-list-item-title class="pb-1">{{$t('globals.name')}}</v-list-item-title>
                            <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.name_ar}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item >
                            <v-list-item-title class="pb-1">{{$t('globals.birth_date')}}</v-list-item-title>
                            <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.birthdate}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item >
                            <v-list-item-title class="pb-1">{{$t('summer.employee.birth-place')}}</v-list-item-title>
                            <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.birth_place}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item >
                            <v-list-item-title class="pb-1">{{$t('summer.card-number')}}</v-list-item-title>
                            <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.identity_id}}</v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                    <v-list>
                        <v-list-item >
                            <v-list-item-title class="pb-1">{{$t('globals.gender')}}</v-list-item-title>
                            <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.gender}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item >
                            <v-list-item-title class="pb-1">{{$t('globals.marital-status')}}</v-list-item-title>
                            <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.marital_status}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item v-if="employee_data.personal_data.children">
                            <v-list-item-title class="pb-1">{{$t('globals.children')}}</v-list-item-title>
                            <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.children}}</v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                    <v-list>
                        <v-list-item >
                            <v-list-item-title class="pb-1">{{$t('globals.skills')}}</v-list-item-title>
                            <v-list-item-subtitle v-for="item in employee_data.personal_data.skill" :key="item" class="pb-1">
                                {{item}}
                            </v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-card class="mt-10 py-5" v-if="personal_data_exists">
        <v-row>
            <v-col cols="12" md="4" lg="4"  >
                <h2 class="text-start mt-4 mx-5">{{$t('globals.employment-information')}}</h2>
                <v-list class="px-7">
                    <v-list-item >
                        <v-list-item-title class="pb-1">{{$t('user.role')}}</v-list-item-title>
                        <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.role}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item >
                        <v-list-item-title class="pb-1">{{$t('summer.employee.place-of-work')}}</v-list-item-title>
                        <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.entity_type}}</v-list-item-subtitle>
                    </v-list-item>
                    
                    <v-list-item >
                        <v-list-item-title class="pb-1">{{$t('globals.work-period')}}</v-list-item-title>
                        <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.work_period}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item >
                        <v-list-item-title class="pb-1">{{$t('summer.employee.qualifications')}}</v-list-item-title>
                        <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.qualifications}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item >
                        <v-list-item-title class="pb-1">{{$t('summer.employee.other-work')}}</v-list-item-title>
                        <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.other_work}}</v-list-item-subtitle>
                    </v-list-item>
                </v-list>
            </v-col>
            <v-col cols="12" md="4" lg="4" >
                <h2 class="text-start mt-4 mx-5">{{$t('globals.contact-information')}}</h2>
                    <v-list class="px-7">
                        <v-list-item >
                            <v-list-item-title class="pb-1">{{$t('globals.country')}}</v-list-item-title>
                            <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.country}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item >
                            <v-list-item-title class="pb-1">{{$t('globals.governorate')}}</v-list-item-title>
                            <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.governorate}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item >
                            <v-list-item-title class="pb-1">{{$t('globals.directorate')}}</v-list-item-title>
                            <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.fk_directorate}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item >
                            <v-list-item-title class="pb-1">{{$t('summer.solitude')}}</v-list-item-title>
                            <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.address}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item >
                            <v-list-item-title class="pb-1">{{$t('globals.phone_number')}}</v-list-item-title>
                            <v-list-item-subtitle class="pb-1">{{employee_data.personal_data.phone_number}}</v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
            </v-col>
            <v-col cols="12" md="4" lg="4" >
                <h2 class="text-start mt-4 mx-5">{{$t('globals.user-data')}}</h2>
                <v-list class="px-7">
                    <v-list-item >
                        <v-list-item-title class="pb-1">{{$t('globals.name')}}</v-list-item-title>
                        <v-list-item-subtitle class="pb-1">{{employee_data.account_data.full_name}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item >
                        <v-list-item-title class="pb-1">{{$t('globals.email')}}</v-list-item-title>
                        <v-list-item-subtitle class="pb-1">{{employee_data.account_data.email}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item >
                        <v-list-item-title class="pb-1">{{$t('user.role')}}</v-list-item-title>
                        <v-list-item-subtitle class="pb-1">{{employee_data.account_data.role_name}}</v-list-item-subtitle>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
    </v-card>
</v-card>

</template>

<script>
export default ({
    mounted(){
        const items=[this.$t('user.user'),this.$t('user.account'),this.$t('user.profile')]
        this.$store.commit('updateBreadcrumbValue',items)
    },
    async created(){
        try {
            await this.$store.commit("updateLoadingValue", true);
            await this.getEmployeeProfile();
            this.$store.commit("updateLoadingValue", false);
        } catch (error) {
            this.$store.commit("updateLoadingValue", false);
        }
        
    },
    data(){
        return{
            employee_data:{
                account_data:{},
                personal_data:{}
            }
        }
    },
    methods:{
        async getEmployeeProfile(){
            await this.axios(this.base_url + "api/summer/employee/profile", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }).then(response=>{
            this.employee_data=response.data
          })
        }
    },
    computed:{
        personal_data_exists(){
            return Object.keys(this.employee_data.personal_data).length>0
        }
    }
})
</script>

<style scoped>
.row-hover:hover{
    background: #d4d3d3;
}
</style>