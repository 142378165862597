<template>
    <VTextField 
        v-model="date"
        :label="label"
        :placeholder="placeholder"
        :clearable="clearable"
        @keypress="validateDate"
        @keyup="validateDate"
        :rules="rules"
        :maxlength="maxLength"
        :density="density"
        :readonly="readOnly"
        :error-messages="errorMessages"
        @input="handelInput"
        @blur="handelBlur"
        >
        <template v-slot:prepend-inner>
            <v-icon :id="id_click" @click="handelIconDate" >{{icon}}</v-icon>
        </template>
    </VTextField>
    <Datetime
        :type="type"
        :forma="format"
        :color="'#0747a6'"
        @input="(e) => getDate(e)"
        :locale="locale"
        element="datetime_picker" 
        :calendar="calendar"
    ></Datetime>
</template>

<script>
import Datetime from "vue3-datetime-js";

export default{
    props:{
        modelValue:{
            type:String,
            default:null
        },
        type:{
            type:String,
            default:'date'
        },
        format:{
            type:String,
            default:null
        },
        maxLength:{
            type:Number,
            default:10
        },
        locale:{
            type:String,
            default:'ar-sa'
        },  
        color:{
            type:String,
            default:'#0747a6'
        },
        label:{
            type:String,
            default:''
        },
        calendar:{
            type:String,
            default:'hijri'
        },
        density:{
            type:String,
            default:'compact'
        },
        icon:{
            type:String,
            default:"mdi-calendar-outline"
        },
        placeholder:{
            type:String,
            default:'YYYY-MM-DD'
        },
        clearable:{
            type:Boolean,
            default:true
        },
        readOnly:{
            type:Boolean,
            default:false
        },
        errorMessages:{
            type:String,
            default:''
        },
        rules:{
            type:Array,
            default:[]
        },
        validation:{
            type:Boolean,
            default:false
        }
        
    },
    components:{
        Datetime 
    },
    created(){
        if(this.calendar=='gregorg'){
            this.maxMonthDay='31'
        }
    },
    data(){
        return{
            id_click:null,
            dateRule:[
                value=>!value || /^(\d{4})-(\d{2})-(\d{2})$/.test(value) ||/^(\d{4})\/(\d{2})\/(\d{2})$/.test(value) || this.errorMessage
            ],
            maxMonthDay:'30'
        }
    },
    methods:{
        validateDate(e){
            const len = this.date?.length
            if(e.keyCode!==8){

            if(len ===4 || len ===7){
                this.date+='-'
            }
            if(e.keyCode<47 || e.keyCode>57){
                e.preventDefault();
            }
            if(len===7){
                const month = this.date.substring(5,7)
                if(month>12){
                    this.date=this.date.substring(0,5)+'12'
                }
                if(month=='00'){
                    this.date=this.date.substring(0,5)+'01'
                }
            }
            if(len===10){
                const day = this.date.substring(8,10)
                if(day>31){
                    this.date=this.date.substring(0,8)+'31'
                }
                if(day=='00'){
                    this.date=this.date.substring(0,8)+'01'
                }
            }
            

        }
                
        },
        getDate(e) {
            let date = e;
            if (this.$i18n.locale == "ar" || this.$i18n.locale == "en") {
                date = this.toEnglishString(e);
            }
            this.date= date; 
        },
        handelIconDate(){
            this.id_click='datetime_picker'
        },
        handelBlur(event){
            this.$emit('blur',event)
        },
        handelInput(event){
            this.$emit('input',event)
        }
    },
    computed:{
        date: {
            get(){
                return this.modelValue
            },
            set(date){
                this.$emit('update:modelValue',date)
            }
        },
    }
}


</script>