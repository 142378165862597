<template>
  <!-- <VLocaleProvider :rtl="$i18n.locale==='en'?false:true"> -->
      <VApp  >
        <router-view />
      </VApp>

  <!-- </VLocaleProvider> -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  created(){
    
    const locale=localStorage.getItem('user-locale')
    if(locale=='en'){
      this.$store.dispatch("updatedirection", 'ltr');
      this.$store.dispatch("updatelanguage",'en');
      this.$i18n.locale="en"
    }else{
      this.$store.dispatch("updatedirection", 'rtl');
      this.$store.dispatch("updatelanguage",'ar');
      this.$i18n.locale="ar"
    }
    
  },
  mounted(){
    if (this.$i18n.locale=='en'){
      document.title="Summer Centers System"
    }
    
  }
}
</script>
<style >

</style>


