<template>
<v-expansion-panels class="mb-2">
      <v-expansion-panel  elevation="1"   >
          <v-expansion-panel-title class="px-4">
              <span>{{$t('globals.select-criteria')}}</span>
          </v-expansion-panel-title>
          <v-expansion-panel-text >
            <VRow class="mt-1">
              <VCol  cols="12" xs="12" sm="12" md="3" lg="3">
                  <VAutocomplete
                    :items="activityType"
                    v-model="fk_activity_type"
                    item-title="name"
                    item-value="id"
                    :label="$t('summer.activity.type')"
                    persistent-hint
                    prepend-inner-icon="mdi-soccer"
                    density="compact"
                    clearable
                  ></VAutocomplete>
              </VCol>
              <VCol  cols="12" xs="12" sm="12" md="3" lg="3">
                  <VAutocomplete
                    :items="activity_iterations"
                    v-model="iteration"
                    item-title="name"
                    item-value="id"
                    :label="$t('activity.iteration')"
                    persistent-hint
                    prepend-inner-icon="mdi-refresh"
                    density="compact"
                    clearable
                  >
                  </VAutocomplete>
              </VCol>
              <v-col cols="4" md="1" class="mt-2">
                  
                <v-btn  @click="this.page=1,getActivityList()" class="bg-indigo" size="small">
                  <span class="text-white">
                    {{ $t("globals.show") }}
                  </span>
                </v-btn>
              </v-col>
            </VRow>
          </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  <VCard >    
    <!-- ##########    data Table  ########### -->
    <!-- ######## Header Of Data Table  ####### -->
    <v-card-text>
      <div class="d-flex justify-space-between ">
        <div class="d-flex" style="flex: 10">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn
                
                v-bind="props"
                variant="outlined"
                color="#ccc"
                size="small"
              >
                <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  color="indigo"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-text-field
            color="indigo"
            v-model="txt_search"
            @input="getActivityList((page = 1))"
            style="
              border-radius: 30px;
              border: 1px solid #ccc;
              color: black;
              height: 30px;
              width: 100%;
            "
            class="search mx-1"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :placeholder="$t('globals.search-here')"
            density="compact"
            variant="text"
          >
          </v-text-field>
        </div>
        <div class="d-flex justify-end" style="flex: 1">
          <VBtn v-if="CheckGetPermission('summer_center.change_activitysummer',['sys_admin','gen_admin'])" class="bg-success" @click="addStudent" size="small">
            <span class="text-white">{{ $t("globals.create") }}</span>
            <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
          </VBtn>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-progress-linear
        color="indigo"
        class="my-1"
        :indeterminate="loading"
        height="2px"
        :reverse="$i18n.locale == 'ar'"
      >
      </v-progress-linear>
      <v-data-table
        :headers="selectedHeaders"
        :items="tableList"
        :items-per-page="perPage"
        item-value="name"
        item-key="id"
        fixed-footer
        density="compact"
        class="elevation-1"
        v-model:sort-by="sortBy"
        @update:options="getActivityList((page = 1))"
      >
        <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template>
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
              </v-checkbox>
              <span v-if="key === 'level'" class="p-1">
                <v-chip
                  density="compact"
                  style="display: block"
                  class="my-1"
                  v-for="item in val"
                  :key="item"
                >
                  {{ getLevelName(item) }}
                </v-chip>
              </span>
              <span v-else> {{ val }}</span>
              <span v-if="key === 'actions'">
                <v-btn
                      variant="text"
                      class="me-1 v-btn-icon"
                      size="small"
                      @click="editStudent(item.raw.id)"
                      v-if="CheckGetPermission('summer_center.change_activitysummer',['sys_admin','gen_admin'])"
                    >
                      <v-icon color="warning">mdi-pencil-outline</v-icon>
                    </v-btn>
                <v-btn
                      variant="text"
                      size="small"
                      class="v-btn-icon"
                      @click="(activity_del_id = item.raw.id), (del_dialog = true)"
                      v-if="CheckGetPermission('summer_center.delete_activitysummer',['sys_admin','gen_admin'])"
                    >
                      <v-icon color="error">mdi-trash-can-outline</v-icon>
                    </v-btn>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ totalItems }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="del_dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("globals.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("globals.confirm-delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(del_dialog = false), (activity_del_id = undefined)"
            >{{ $t("globals.cancel") }}</v-btn
          >
          <v-btn color="red" :loading="deleteProgress" @click="deleteActivity">{{ $t("globals.delete") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VCard>
  <VDialog v-model="dialog" max-width="500px" max-height="500px">
    <VCard align="center">
      <VCardTitle>
        <h1 class="text-h6">{{ dialog_title }}</h1>
      </VCardTitle>
      <!-- <VImg :src="dialog_image" :width="400"></VImg> -->
      <VCardItem>
        <img :src="dialog_image" class="mx-auto w-75" />
      </VCardItem>
      <VCardActions>
        <VBtn class="bg-info" @click="dialog = false">{{ $t("globals.ok") }}</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <alert-protected-records v-model="protected_records" :data_message="data_message"></alert-protected-records>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
export default {
  data() {
    return {
      protected_records:false,
      DataLoading: false,
      deleteProgress:false,
      v$: useValidate(),
      level: undefined,
      fk_activity_type: undefined,
      iteration: undefined,
      fk_employee: undefined,
      activity_list: [],
      del_dialog: false,
      activity_del_id: undefined,
      dialog: false,
      dialog_image: undefined,
      dialog_title: undefined,
      dialog_text: undefined,
      perPage: 10,
      length: 0,
      page: 1,
      pagination: {},
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 100000, text: this.$t("globals.all") },
      ],
      totalItems: null,
      sortBy: [{ key: "id", order: "asc" }],
      loading: false,
      headers: [
        { title: this.$t("summer.activity.type"), key: "fk_activity_type" },
        { title: this.$t("summer.activity.name"), key: "name" },
        { title: this.$t("summer.activity.evaluation_act"), key: "evalution" },
        { title: this.$t("summer.activity.student_count"), key: "student_number" },
        { title: this.$t("activity.goal"), key: "goal" },
        { title: this.$t("activity.iteration"), key: "iteration" },
        { title: this.$t("activity.school-recommendation"), key: "school_recommendation" },
        { title: this.$t("activity.media-recommendation"), key: "media_recommendation" },
        { title: this.$t("activity.execution"), key: "execution" },
        { title: this.$t("activity.participating-group"), key: "participating_group" },
        { title: this.$t("activity.entity-to-coordinate"), key: "entity_to_coordinate" },
        { title: this.$t("globals.note"), key: "note" },
      ],
      selectedHead: [
        "fk_activity_type",
        "name",
        "iteration",
        "execution",
        "goal",
        "school_recommendation",
        "actions",
        this.checkrole(['sys_admin', 'gen_admin']) ? "actions":null
      ],
      txt_search: undefined,
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.getActivityType();
      this.getActivityIterations();
      if(this.checkrole(['sys_admin', 'gen_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      activityType: (state) => state.summer.activityType,
      user: (state) => state.User,
      activity_iterations: (state) => state.summer_globals.activity_iterations,
    }),
    ...mapGetters({
    }),
    tableList() {
      let list = [];
      this.activity_list.forEach((element) => {
        let obj = element;
        let activity_type = this.activityType.find(
          (item) => item.id == element.fk_activity_type
        );
        if (activity_type) obj.fk_activity_type = activity_type.name;
        let iteration = this.activity_iterations.find(
          (item) => item.id == element.iteration
        );
        if (iteration) obj.iteration = iteration.name;
        list.push(obj);
      });
      return list;
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  methods: {
    ...mapActions({
      getActivityType: "summer/getActivityType",
      getActivityIterations: "summer_globals/getActivityIterations",
    }),
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    addStudent() {
      this.$router.push("add-activity");
    },
    editStudent(id) {
      this.$router.push(`${id}/edit-activity/`);
    },
    async deleteActivity() {
      if (this.activity_del_id != undefined) {
        this.deleteProgress=true
        let result = await this.axios
          .delete(`${this.base_url}api/summer/activity/${this.activity_del_id}`, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.getActivityList();
            this.deleteProgress=false

          })
          .catch((error) => {
               if ((error.code = 418)) {
              this.protected_records = true;
              let error_messages = [];
              error.response.data.data.forEach((error) => {
                error_messages.push(
                  this.$t(`models.${error.model}`) + ` = ${error.count}`
                );
              });
              this.data_message = error_messages;
            } 
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.deleteProgress=false
          });
        this.del_dialog = false;
        this.activity_del_id = undefined;
      }
    },
    async getActivityList() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/activity`, {
          params: {
            year: localStorage.getItem("current_year"),
            // level: this.fk_level,
            fk_activity_type: this.fk_activity_type,
            iteration: this.iteration,
            // fk_employee: this.fk_employee,
            search: this.txt_search,
            page: this.page,
            page_size: this.perPage,
            sort_by: this.order_data,
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.activity_list = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.itemsPerPage[4].value = response.data.pagination.count;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getActivityList();
    },
    page() {
      this.getActivityList(this.page);
    },
  },
};
</script>

