<template>

    <VCard class="justify-center" > 
        <VForm ref="form" 
            v-if="CheckGetPermission('summer_center.add_schedule') || CheckGetPermission('summer_center.change_schedule')">
            <VCard-title class="my-4">
                <VIcon icon="mdi-text-box-plus"/>
                <h2 class="d-inline text-grey" style="padding:0 4px;" >{{$t('school.add_schedule')}}</h2>
                <v-divider style="border-width:1px; opacity: 0.5;" class="my-2"/>
            </VCard-title>
            <VRow class="pt-0 px-16 pb-0">
               

             <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="8" lg="8">
                <VCardItem class="pa-0">
                    <VAutocomplete
                        :items="schedules"
                        v-model="schedule.level"
                        prepend-inner-icon="mdi-office-building"
                        item-title="quotas"   
                        item-value="id"
                        :label="$t('summer.class_name')"
                        persistent-hint
                        density="compact"
                        clearable
                    ></VAutocomplete>
                </VCardItem>
            </VCol>





         
            </VRow>


            

            <VRow class="pt-0 px-16 pb-0">
                <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
                    <VCardItem class="pa-0">
                        <VTextField
                            v-model="schedule.quotas_start_time"
                            :label="$t('school.schedule_start_time')"
                            density="compact"
                            type="time"
                            clearable
                            :rules="start_time_rule"
                            :error-messages="v$.schedule.quotas_start_time.$errors.map(e=>e.$message)" 
                            >
                            </VTextField>
                    </VCardItem>
                </VCol>
                <VCol class="pt-0 px-3" cols="12" xs="12" sm="12" md="4" lg="4">
                    <VCardItem class="pa-0">
                        <VTextField
                            v-model="schedule.quotas_end_time"
                            :label="$t('school.schedule_end_time')"
                            density="compact"
                            type="time"
                            clearable
                            :rules="end_time_rule"
                            :error-messages="v$.schedule.quotas_end_time.$errors.map(e=>e.$message)" 
                            >
                            </VTextField>
                    </VCardItem>
                </VCol>
                
            </VRow>
            <VRow class="pt-0 px-16 pb-0">
                <VCol cols="12" class="pa-0 ma-0" xs="12" sm="12" md="8" lg="8">
                    <VCardItem class="px-3">
                        <VTextarea
                            v-model="schedule.note"
                            clearable
                            :label="$t('globals.note')"
                            counter="250"
                            no-resize
                            density="compact"
                            rows="2"
                            :error-messages="v$.schedule.note.$errors.map(e=>e.$message)" 
                        ></VTextarea>
                    </VCardItem>
                </VCol>
            </VRow>
        </VForm>
        <VCardActions class="mx-16" v-if="CheckGetPermission('summer_center.add_schedule') || CheckGetPermission('summer_center.change_schedule')">
            <VBtn v-if="!is_update && CheckGetPermission('summer_center.add_schedule')"  class="bg-indigo" @click="saveSchedule" size="small">
                <span class="text-white">
                    {{$t('globals.add')}}
                </span>
                <VIcon icon="mdi-content-save" color="white" end></VIcon>
            </VBtn>
            <VBtn v-if="is_update && CheckGetPermission('summer_center.change_schedule')"  class=" bg-success" @click="updateSchedule" size="small">
                <span class="text-white">
                    {{$t('globals.edit')}}
                </span>
                <VIcon icon="mdi-content-save" color="white" end></VIcon>
            </VBtn>
            <VBtn  class="mx-3" size="small" @click="resetForm">
                {{$t('globals.clear')}}
                <VIcon icon="mdi-broom" color="golden" end></VIcon>
            </VBtn>
            
        </VCardActions>
        <!-- ##########    data Table  ########### -->
        <v-divider class="my-4"/> 
        <!-- ######## Header Of Data Table  ####### -->
        <v-card class="pa-2" >
            <div class="d-flex justify-space-between pa-2">
                <div class="d-flex  flex-fill">
                    <v-menu
                    :close-on-content-click="false">
                        <template
                            v-slot:activator="{ props }">
                            <v-btn
                                v-bind="props"
                                variant="outlined"
                                color="#ccc"
                                size="small">
                                <v-icon style="color:#5e7e96;" class="me-2">mdi-eye-outline</v-icon>
                                <v-icon style="color:#5e7e96;" >mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-text>
                                <v-checkbox v-for="header in headers" :key="header.key"
                                :label="header.title"
                                :value="header.key"
                                color="indigo"
                                v-model="selectedHead"
                                density="compact"
                                hide-details
                                >
                                </v-checkbox>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                    <v-btn
                        v-bind="props"
                        variant="text"
                        size="small"
                        >
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                    <div style="width:100%">
                        <v-text-field 
                            color="indigo"
                            v-model="txt_search"
                            style="border-radius:30px;border:1px solid #ccc; color:black;height:30px; width:90%;"
                            class=" search " 
                            hide-details 
                            prepend-inner-icon="mdi-magnify" 
                            single-line
                            :placeholder="$t('globals.search-here')" 
                            density="compact"
                            variant="text"
                            >
                        </v-text-field>
                    </div>
                </div>
                <div class="d-flex  justify-end" style="flex:1;" >
                    <span  class="pointer" style="padding:5px;">{{$t('globals.excel')}}
                        <v-icon>mdi-file-excel</v-icon>
                    </span>
                </div>
            </div>
            <!-- ######## End Header Of Data Table Server ####### -->
            <v-data-table
                :headers="selectedHeaders"
                :items="tableList"
                fixed-footer
                :search="txt_search"
                density="compact"
                class="elevation-1"
            >   
                
                <template v-slot:item="{item}">
                    <tr class="row-hover">
                        <td v-for="(val,key) in item.columns" :key="key">
                            <v-checkbox 
                                v-if="key=='checkbox'"
                                hide-details
                                density="compact">
                            </v-checkbox>
                            <span> {{val}}</span>
                            <span v-if="key==='actions'">
                                <span v-if="CheckGetPermission('summer_center.change_schedule')" class="me-1" style="color:#E5B254; border-radius:4px;">
                                    <v-icon  @click="editSchedule(item.raw)" size="small"  style="cursor:pointer" icon="mdi-pencil-outline"/>
                                </span>
                                <span v-if="CheckGetPermission('summer_center.delete_schedule')" style="color:#D05251; border-radius:4px;">
                                    <v-icon  @click="schedule_del_id=item.raw.id,del_dialog=true"  size="small" style="cursor:pointer" icon="mdi-trash-can-outline" /> 
                                </span>
                            </span>
                        </td>
                    </tr>
                </template>
                
            </v-data-table>
        </v-card>
        <!-- ############### Dialog Delete  ################### -->
        <v-dialog
            color="indigo"
            v-model="del_dialog"
            transition="dialog-top-transition"
            width="auto"
        >
            <v-card > 
                <v-card-title>
                    {{$t('globals.confirmation')}}
                </v-card-title>
                <v-card-text>
                    {{$t('globals.confirm-delete')}}
                </v-card-text>
                <v-card-actions>
                    <v-btn color="info"  @click="del_dialog=false,schedule_del_id=undefined">{{$t('globals.cancel')}}</v-btn>
                    <v-btn color="red" @click="deleteSchedule" >{{$t('globals.delete')}}</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </VCard>
    <Alert
        v-model="process_alert"
        :iconColor="icon_color"
        :icon="icon"
        :title="alert_title"
        :message="alert_message"
        >
    </Alert>
</template>
<script>
import { mapState,mapActions } from "vuex";
import useValidate from '@vuelidate/core'
import {email,required,helpers,maxLength,url,alpha,alphaNum,numeric} from '@vuelidate/validators'
export default ({
    data(){
        return{
            v$: useValidate(),
            schedule:{
                quotas:undefined,
                quotas_start_time:undefined,
                quotas_end_time:undefined,
                period:true,
                note:undefined,
                data_entry:undefined,
                updated_by:undefined,
                id:undefined,
            },
            is_update:false,
            del_dialog:false,
            schedule_del_id:undefined,
            process_alert:undefined,
            icon_color:undefined,
            icon:undefined,
            alert_title:undefined,
            alert_message:undefined,
            headers:[
                {title: this.$t('school.schedule_quotas'), key: "quotas" },
        
                {title: this.$t('school.schedule_start_time'), key: "quotas_start_time" },
                {title: this.$t('school.schedule_end_time'), key: "quotas_end_time" },
                {title: this.$t('globals.note'), key: "note" },
                {title: this.$t('globals.actions'), key: "actions", sortable: false },
            ],
            selectedHead:['quotas','quotas_start_time','quotas_end_time','note','actions'],
            txt_search:undefined,
            start_time_rule:[
                (value)=>this.schedule.quotas_end_time==undefined || value < this.schedule.quotas_end_time || this.$t('school.schedule_timing_error'),
                (value)=> this.checkTime(value) || this.$t('school.schedule_timing_error2'),
            ],
            end_time_rule:[
                (value)=> this.schedule.quotas_start_time==undefined || value > this.schedule.quotas_start_time || this.$t('school.schedule_timing_error'),
                (value)=> this.checkTime(value) || this.$t('school.schedule_timing_error2'),
            ]

        }
    },
    validations(){
        return{
            schedule:{
                period:{
                    required:helpers.withMessage(this.$t('globals.required_field'),required)
                },
                quotas:{
                    required:helpers.withMessage(this.$t('globals.required_field'),required),
                    // maxLength:helpers.withMessage(this.$t('globals.max_characters'),maxLength(100)),
                },
                quotas_start_time:{
                    required:helpers.withMessage(this.$t('globals.required_field'),required),
                    // numeric:helpers.withMessage(this.$t('globals.must_be_numbers'),numeric)
                },
                quotas_end_time:{
                    required:helpers.withMessage(this.$t('globals.required_field'),required),
                    // numeric:helpers.withMessage(this.$t('globals.must_be_numbers'),numeric)
                },
                note:{
                    maxLength:helpers.withMessage(this.$t('globals.max_characters'),maxLength(250)),
                },
            }
        }
    },
    async created(){
        try {
            await this.$store.commit('updateLoadingValue',true)
            await this.getLevels()
            await this.getSchedule()
            this.$store.commit('updateLoadingValue',false)
        } catch (error) {
            this.$store.commit('updateLoadingValue',false)
            
        }
    },
    computed:{
        ...mapState({
            user: (state) => state.User,
            levels: (state) => state.summer.levels,
            schedules: (state) => state.school.schedules,
            
        }),
        selectedHeaders(){
            return this.headers.filter(header=>
            this.selectedHead.includes(header.key))
        },
        tableList(){
            let list=[]
            this.schedules.forEach(element => {
                list.push({
                    quotas:element.quotas,
                    quotas_start_time:element.quotas_start_time,
                    quotas_end_time:element.quotas_end_time,
                    period:(element.period ? this.$t('school.morning_period'):this.$t('school.evening_period')),
                    note:element.note,
                    id:element.id
                })
            });
            return list 
        },
        CheckGetPermission(){
            return (prem)=>{
                return this.$store.getters.checkpermission(prem);
            }
        },

        },
    methods:{
        ...mapActions({
            getLevels: "summer/getLevels",
            getSchedule: "school/getSchedule",
        }),
        resetForm(){
            this.$refs.form.reset()
            this.v$.schedule.$reset()
            this.is_update=false
            this.schedule.id=undefined
        },
        editSchedule(data){
            let schedule=this.schedules.find(item=>item.id==data.id)
            if(schedule!=undefined)
            {
                for(const key in schedule)
                    this.schedule[key]=schedule[key]
                this.schedule.data_entry=undefined
                this.schedule.updated_by=undefined
                this.is_update=true
            }
            window.scrollTo({top:0,behavior:'smooth'})
        },
        checkTime(value){
            let result=true
            this.schedules.forEach((element)=>{
                if( element.quotas_start_time < value && element.quotas_end_time.slice(0,-3) > value && element.id != this.schedule.id)
                    result=false             
            })
            return result
        },
        
        async saveSchedule(){
            this.v$.$validate()
            const { valid } = await this.$refs.form.validate()
            if(valid && !this.v$.schedule.$error){
                this.schedule.data_entry=this.user
                let result = await this.axios.post(this.base_url+'/schedule/',this.schedule
                ,{headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})
                .then((responce)=>{
                    this.icon_color="success"
                    this.icon="check-circle"
                    this.alert_title=this.$t('globals.success')
                    this.alert_message=this.$t('globals.data_added')
                }).catch((error)=>{
                    this.icon_color="error"
                    this.icon="cancel"
                    this.alert_title=this.$t('globals.failure')
                    this.alert_message=this.$t('globals.error_in_data')
                })
                this.process_alert=true
                this.resetForm()
                this.getSchedule()
                setTimeout(()=>{
                    this.process_alert=false
                },2000)
            }
        },
        async updateSchedule(){
            this.v$.$validate()
            const { valid } = await this.$refs.form.validate()
            if(valid && !this.v$.schedule.$error && this.schedule.id!=undefined){
                this.schedule.updated_by=this.user
                let result = await this.axios.put(`${this.base_url}/schedule/${this.schedule.id}/`,
                this.schedule,{headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})
                .then((responce)=>{
                    this.icon_color="success"
                    this.icon="check-circle"
                    this.alert_title=this.$t('globals.success')
                    this.alert_message=this.$t('globals.data_updated')
                    
                }).catch((error)=>{
                    this.icon_color="error"
                    this.icon="cancel"
                    this.alert_title=this.$t('globals.failure')
                    this.alert_message=this.$t('globals.error_in_data')
                })
                this.process_alert=true
                this.resetForm()
                this.getSchedule()
                setTimeout(()=>{
                    this.process_alert=false
                },2000)
            }
        },
        async deleteSchedule(){
            if(this.schedule_del_id!=undefined){
                let result = await this.axios.delete(`${this.base_url}/schedule/${this.schedule_del_id}/`
                ,{headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})
                .then((responce)=>{
                    this.icon_color="success"
                    this.icon="check-circle"
                    this.alert_title=this.$t('globals.success')
                    this.alert_message=this.$t('globals.data_deleted')
                }).catch((error)=>{
                    this.icon_color="error"
                    this.icon="cancel"
                    this.alert_title=this.$t('globals.failure')
                    this.alert_message=this.$t('globals.error_in_data')
                })
                this.del_dialog=false
                this.schedule_del_id=undefined
                this.process_alert=true
                this.getSchedule()
                setTimeout(()=>{
                    this.process_alert=false
                },2000)
            }
        },
    }
})
</script>
