<template>
    <VCard > 
        <v-card flat
            v-if="CheckGetPermission('summer_center.change_calendar',['sys_admin','gen_admin']) || CheckGetPermission('summer_center.add_calendar',['sys_admin','gen_admin'])"
        >
            <v-card-text class="pb-0">
                <VForm ref="form">
                    <VRow class="mt-2" >
                        <VCol  cols="12" xs="12" sm="12" md="5" lg="5">
                            <v-text-field
                                style="min-width:220px;"
                                v-model="calendar.start_date"
                                density="compact"
                                :placeholder="$t('globals.start-date')"
                                clearable
                                prepend-inner-icon="mdi-calendar-clock-outline"
                                id="start-date"
                                :rules="start_date_rule"
                                readonly
                            >
                            </v-text-field>
                            <Datetime   
                                type="date"   
                                element="start-date"   
                                :color="'#0747a6'"
                                @input="(e)=>getPermissionTime(e)"
                                :locale="$i18n.locale=='ar'?'ar-sa':'en-us'" 
                                :placeholder="$t('globals.start-date')" 
                                :calendar="'hijri'"
                            ></Datetime>
                        </VCol>
                        <VCol   cols="12" xs="12" sm="12" md="5" lg="5">
                            <VTextField
                                v-model="calendar.week_count"
                                prepend-inner-icon="mdi-numeric"
                                :label="$t('summer.calendar.week-count')"
                                density="compact"
                                type="number"
                                clearable
                                :rules="count_rule"
                                >
                            </VTextField>
                        </VCol>
                    </VRow>
                </VForm>
            </v-card-text>
            <VCardActions  class="px-3" >
                <VBtn v-if="!is_update && CheckGetPermission('summer_center.add_calendar',['sys_admin','gen_admin'])" class="bg-indigo" @click="saveCalendar" size="small">
                    <span  class="text-white">
                        {{$t('globals.add')}}
                    </span>
                    <VIcon icon="mdi-content-save" color="white" end></VIcon>
                </VBtn>
                <VBtn v-if="is_update && CheckGetPermission('summer_center.change_calendar',['sys_admin','gen_admin'])" class=" bg-indigo" @click="checkValidation()" size="small">
                    <span class="text-white">
                        {{$t('globals.save')}}
                    </span>
                    <VIcon icon="mdi-content-save" color="white" end></VIcon>
                </VBtn>
                <VBtn  class="mx-3" size="small" @click="resetForm">
                    {{$t('globals.clear')}}
                    <VIcon icon="mdi-broom" color="golden" end></VIcon>
                </VBtn>
            </VCardActions>
        </v-card>
        <v-divider></v-divider>
        <!-- ##########    data Table  ########### -->
        <!-- ######## Header Of Data Table  ####### -->
        <v-card flat >
            <v-card-text>
                <div class="d-flex justify-space-between mb-2">
                    <div class="d-flex  flex-fill">
                        <v-menu
                        :close-on-content-click="false">
                            <template
                                v-slot:activator="{ props }">
                                <v-btn
                                    v-bind="props"
                                    variant="outlined"
                                    color="#ccc"
                                    size="small">
                                    <v-icon style="color:#5e7e96;" class="me-2">mdi-eye-outline</v-icon>
                                    <v-icon style="color:#5e7e96;" >mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text>
                                    <v-checkbox v-for="header in headers" :key="header.key"
                                    :label="header.title"
                                    :value="header.key"
                                    color="indigo"
                                    v-model="selectedHead"
                                    density="compact"
                                    hide-details
                                    >
                                    </v-checkbox>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                        <div style="width:100%" class="mx-2">
                            <v-text-field 
                                color="indigo"
                                v-model="txt_search"
                                style="border-radius:30px;border:1px solid #ccc; color:black;height:30px; width:90%;"
                                class=" search " 
                                hide-details 
                                prepend-inner-icon="mdi-magnify" 
                                single-line
                                :placeholder="$t('globals.search-here')" 
                                density="compact"
                                variant="text"
                                >
                            </v-text-field>
                        </div>
                    </div>
                </div>
                <!-- ######## End Header Of Data Table Server ####### -->
                <v-data-table
                    :headers="selectedHeaders"
                    :items="tableList"
                    fixed-footer
                    :search="txt_search"
                    density="compact"
                    class="elevation-1 rounded"
                >   
                    <template v-slot:column.checkbox="{ column }">
                        <v-checkbox 
                        hide-details
                        density="compact">
                        </v-checkbox>
                    </template>
                    <template v-slot:item="{item}">
                        <tr class="row-hover">
                            <td v-for="(val,key) in item.columns" :key="key">
                                <v-checkbox 
                                    v-if="key=='checkbox'"
                                    hide-details
                                    density="compact">
                                </v-checkbox>
                                <span v-if="key=='is_holiday'"> 
                                    <v-icon v-if="val==true" color="success">mdi-check-circle</v-icon>
                                    <v-icon v-if="val==false" color="error">mdi-close-circle</v-icon>
                            </span>
                                <span v-else> {{val}}</span>
                                <!-- <span v-if="key==='actions'">
                                    <span class="me-1" style="color:#E5B254; border-radius:4px;">
                                        <v-icon  @click="editCalendar(item.raw)" size="small"  style="cursor:pointer" icon="mdi-pencil-outline"/>
                                    </span>
                                    <span style="color:#D05251; border-radius:4px;">
                                        <v-icon  @click="calendar_del_id=item.raw.id,update_dialog=true"  size="small" style="cursor:pointer" icon="mdi-trash-can-outline" /> 
                                    </span>
                                </span> -->
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <!-- ############### Dialog Delete  ################### -->
        <v-dialog
            color="indigo"
            v-model="update_dialog"
            transition="dialog-top-transition"
            width="auto"
        >
            <v-card > 
                <v-card-title>
                    {{$t('globals.confirmation')}}
                </v-card-title>
                <v-card-text>
                    {{$t('summer.calendar.calendar-reset')}}
                </v-card-text>
                <v-card-actions>
                    <v-btn color="info"  @click="update_dialog=false">{{$t('globals.cancel')}}</v-btn>
                    <v-btn color="red" @click="updateCalendar" >{{$t('globals.confirm')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-dialog
            color="indigo"
            v-model="saturday_dialog"
            transition="dialog-top-transition"
            width="auto"
        >
            <v-card > 
                <v-card-title>
                    {{$t('globals.confirmation')}}
                </v-card-title>
                <v-card-text>
                    {{$t('summer.calendar.saturday-check')}}
                </v-card-text>
                <v-card-actions>
                    <v-btn color="red"  @click="calendar.start_date=undefined,saturday_dialog=false">{{$t('globals.cancel')}}</v-btn>
                    <v-btn color="info" @click="saturday_dialog=false" >{{$t('globals.confirm')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </VCard>
</template>
<script>
import { mapState,mapActions } from "vuex";
import useValidate from '@vuelidate/core'
import Datetime from 'vue3-datetime-js'
import moment from 'moment'
import 'moment-hijri'
export default ({
    components:{Datetime},
    data(){
        return{
            v$: useValidate(),
            calendar:{
                start_date:undefined,
                end_date:undefined,
            },
            loading:false,
            calendars:[],
            is_update:false,
            update_dialog:false,
            saturday_dialog:false,
            start_date_rule:[
                (value) => !!value || this.$t('globals.required_field'),
                // (value)=>this.calendar.end_date==undefined || value <= this.calendar.end_date|| this.$t('errors.end-date'),
                // (value)=>this.calendar.end_date==undefined || value != this.calendar.end_date|| this.$t('errors.start-equal-end-date'),
            ],
            // end_date_rule:[
            //     (value) => !!value || this.$t('globals.required_field'),
            //     (value)=>this.calendar.start_date==undefined || value >= this.calendar.start_date || this.$t('errors.start-date'),
            //     (value)=>this.calendar.start_date==undefined || value != this.calendar.start_date || this.$t('errors.start-equal-end-date'),
            // ],
            count_rule:[
                (value) => !!value || this.$t('globals.required_field'),
                (value)=> !!value && /^\d+$/.test(value) || this.$t('globals.must_be_numbers'),
                (value)=> !!value && parseInt(value) < 13 || this.$t('globals.max_numbers_value'),
                (value)=> !!value && parseInt(value) > 0 || this.$t('globals.min_numbers_value'),
            ],
            
            headers:[
                {title: this.$t('day.day'), key: "day" },
                {title: this.$t('summer.calendar.hijri-date'), key: "date" },
                {title: this.$t('summer.calendar.gregorian-date'), key: "date_m" },
                {title: this.$t('day.day_off'), key: "is_holiday" },
                // {title: this.$t('globals.note'), key: "note" },
                // {title: this.$t('globals.actions'), key: "actions", sortable: false },
            ],
            selectedHead:['day','date','date_m','is_holiday','note'],
            txt_search:undefined,
        }
    },
    // validations(){
    //     return{
    //         calendar:{
    //             name:{
    //                 required:helpers.withMessage(this.$t('globals.required_field'),required),
    //                 maxLength:helpers.withMessage(this.$t('globals.max_characters'),maxLength(20))
    //             },
    //             note:{
    //                 maxLength:helpers.withMessage(this.$t('globals.max_characters'),maxLength(250)),
    //             },
    //         }
    //     }
    // },
    async created(){
        try {
            await this.$store.commit("updateLoadingValue", true);
            this.getCalendar()
            await this.$store.commit("updateLoadingValue", false);
        }
        catch{
            await this.$store.commit("updateLoadingValue", false);
        }
    },
    computed:{
        ...mapState({
            user: (state) => state.User,
        }),
        
        selectedHeaders(){
            return this.headers.filter(header=>
            this.selectedHead.includes(header.key))
        },

        tableList(){
            let list=[]
            this.calendars.forEach(element =>{
                list.push({
                    day:element.day,
                    date:element.date,
                    date_m:element.date_m,
                    is_holiday:element.is_holiday,
                    note:element.note,
                })
            });
            return list
        }, 
        CheckGetPermission(){
            return (prem,role)=>{
                return this.$store.getters.checkpermission(prem,role);
            }
        },
        },
    methods:{
        resetForm(){
            this.$refs.form.reset()
            // this.v$.calendar.$reset()
            this.is_update=false
            this.calendar.id=undefined
            this.updated_id=undefined
            this.calendar.is_holiday=false
        },
        // editDay(data){
        //     let day=this.days.find(item=>item.id==data.id)
        //     if(day!=undefined){
        //         for(const key in day)
        //             this.day[key]=day[key]
        //         this.day.data_entry=undefined
        //         this.day.updated_by=undefined
        //         this.is_update=true
        //     }
        //     window.scrollTo({top:0,behavior:'smooth'})
        // },
        // getPermissionTime(e,start=true){
        getPermissionTime(e){
            let date=e
            let start_day=moment(e).format('dd')

            if(this.$i18n.locale=='ar'){
                date=this.toEnglishString(e)
            }
            this.calendar.start_date=date
            if(start_day != 'ث')
                this.saturday_dialog=true



            // if(start)
                // this.calendar.start_date=date
            // else
                // this.calendar.end_date=date
        },
        async saveCalendar(){
            const { valid } = await this.$refs.form.validate();
            if (valid) {
                this.loading=true
                this.calendar.data_entry=this.user
                this.calendar.fk_year = localStorage.getItem("current_year");
                await this.axios.post(this.base_url+'/api/summer/calendar',this.calendar
                ,{headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})
                .then((responce)=>{
                    this.loading=false
                    this.$emit('successAlert',this.$t('globals.data_added'))
                    // this.resetForm()
                    this.getCalendar()

                }).catch((error)=>{
                    this.loading=false
                    if(error.response.data=="day_count")
                        this.$emit('errorAlert',this.$t('summer.calendar.day-init'))
                    else if(error.response.data=="current_year")
                        this.$emit('errorAlert',this.$t('summer.calendar.year-error'))
                    else
                        this.$emit('errorAlert',this.$t('globals.error_in_data'))
                })
            }        
        },
        async checkValidation(){
            const { valid } = await this.$refs.form.validate();
            if (valid) {
                this.update_dialog=true
            }
        },
        async updateCalendar(){
            this.update_dialog=false
            const { valid } = await this.$refs.form.validate();
            if (valid) {
                this.calendar.data_entry=this.user
                this.calendar.fk_year = localStorage.getItem("current_year");
                await this.axios.put(`${this.base_url}/api/summer/calendar/1`,
                this.calendar,{headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})
                .then((responce)=>{
                    this.$emit('successAlert',this.$t('globals.data_updated'))
                    this.resetForm()
                    this.getCalendar()
                }).catch((error)=>{
                    if(error.response.data=='calender_used')
                        this.$emit('errorAlert',this.$t('summer.calendar.calendar-update-error'))
                    else
                        this.$emit('errorAlert',this.$t('globals.error_in_data'))
                })
            }
        },
        async getCalendar(){
            await this.axios.get(this.base_url+'/api/summer/calendar'
                ,{headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})
                .then((response)=>{
                    if(response.data)
                        this.calendars=response.data.results
                    if(this.calendars.length > 0)
                        this.is_update=true
                })
        }
    }
})
</script>
