<template>
  <v-container class="table-responsive">
    <v-row>
      <table class="report-table text-center">
        <thead>
          <tr>
            <th rowspan="2">
              {{ $t("reportpublic.Governorate") }}
            </th>
            <th colspan="2">
              {{ $t("reportpublic.NumberOfSchools") }}
            </th>
            <th colspan="2">
              {{ $t("reportpublic.Workers") }}
            </th>
            <th colspan="2">
              {{ $t("reportpublic.Students") }}
            </th>
          </tr>
          <tr>
           
            <th class="">{{ $t("reportpublic.Open") }}</th>
            <th class="">{{ $t("reportpublic.Typical") }}</th>
           
            <th class="">{{ $t("reportpublic.Open") }}</th>
            <th class="">{{ $t("reportpublic.Typical") }}</th>
         
            <th class="">{{ $t("reportpublic.Open") }}</th>
            <th class="">{{ $t("reportpublic.Typical") }}</th>
          </tr>

          <tr></tr>
        </thead>
        <tbody>
          <tr v-for="(summer, key) in governorate_data" :key="key">
            <td>
              {{
                this.$i18n.locale == "ar"
                  ? summer.governorate_name_ar
                  : summer.governorate_name_en
              }}
            </td>
             <td>{{ summer.school_summer_opened_counts }}</td>
            <td>{{ summer.school_summer_typical_counts }}</td>
             <td>{{ summer.worker_summer_opened_counts }}</td>
            <td>{{ summer.worker_summer_typical_counts }}</td>
             <td>{{ summer.student_summer_opened_counts }}</td>
            <td>{{ summer.student_summer_typical_counts }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th class="">{{ $t("reportpublic.GrandTotal") }}</th>
          
            <th>
              {{ totals.total_summer_opened }}
            </th>
            <th>
              {{ totals.total_summer_typical }}
            </th>
          
            <th>
              {{ totals.total_worker_opened }}
            </th>
            <th>
              {{ totals.total_worker_typical }}
            </th>
          
            <th>
              {{ totals.total_student_opened }}
            </th>
            <th>
              {{ totals.total_student_typical }}
            </th>
          </tr>
        </tfoot>
      </table>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["totals", "governorate_data"],
  methods: {
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
};
</script>
