<template>
  <v-layout class="mainbg" :style="backgroundStyle">
    <div :dir="getdir" class="ma-auto">
      <v-card
        width="320"
        elevation="15"
        rounded="lg"
      >
        <v-card-text class="text-center rou">
          <v-form
            ref="form"
            @keydown.enter="loginAction(username, password, year)"
          >
            <v-row justify="center" class="mt-1">
              <v-col cols="12" md="12" class="ma-1">
                <v-text-field
                  autofocus
                  v-model="username"
                  :label="$t('globals.username')"
                  :placeholder="$t('globals.username')"
                  density="compact"
                  aria-autocomplete="off"
                  clearable
                  @input="error ? (error = null) : null"
                  hide-details="auto"
                  :error-messages="v$.username.$errors.map((e) => e.$message)"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="indigo">mdi-account</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="ma-1">
                <v-text-field
                  v-model="password"
                  :type="showPassword?'text':'password'"
                  hide-details="auto"
                  clearable
                  name="password"
                  id="password"
                  @input="error ? (error = null) : null"
                  autocomplete="new-password"
                  :placeholder="$t('globals.placeholder_password')"
                  density="compact"
                  :label="$t('globals.password')"
                  :error-messages="v$.password.$errors.map((e) => e.$message)"
                >
                <template v-slot:prepend-inner >
                  <v-icon color="indigo" @click="showPassword=!showPassword">{{showPassword?'mdi-eye-off':'mdi-eye'}}</v-icon>
                </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="ma-1">
                <v-autocomplete
                  v-model="year"
                  :items="years"
                  item-title="year"
                  item-value="year"
                  hide-details="auto"
                  clearable
                  @input="error ? (error = null) : null"
                  density="compact"
                  :label="$t('year.select')"
                  :error-messages="v$.year.$errors.map((e) => e.$message)"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="indigo">mdi-calendar</v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
              <p
                v-if="error"
                class="text-error text-start mt-1"
                style="font-size: 11px"
              >
                {{ error }}
              </p>
              <v-col cols="12" class="my-2">
                <VBtn
                  width="100%"
                  :loading="loading"
                  class="bg-indigo"
                  size="small"
                  @click.prevent="loginAction(username, password, year)"
                >
                  <span class="text-white">
                    {{ $t("globals.enter") }}
                  </span>
                </VBtn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </v-layout>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import { email, required, helpers } from "@vuelidate/validators";
import axios from "axios";
import host from "./../../main";
// import store from './store'
// import axios from "axios";
export default {
  data() {
    return {
      v$: useValidate(),
      showPassword:false,
      overlay:false,
      years: [],
      year: null,
      username: undefined,
      loading: false,
      password: undefined,
      backgroundImage: require("@/assets/background.jpg"),
      error: null,
      lang: localStorage.getItem("user-locale")
        ? localStorage.getItem("user-locale")
        : "ar",
    };
  },
  created() {
    this.axios(`${this.base_url}api/all-years/`)
      .then((response) => {
        this.years = response.data;
      })
      .catch((error) => {
        if (error.message.includes("Network Error")) {
          this.error = this.$t("alert.failure.connect");
        } else {
          if (error.response.status == 403) {
            this.error = this.$t("errors.user-logged-in");
          }
          if (error.response.status == 401) {
            this.error = this.$t("user.login_error");
          }
        }
      });
    // const dir = localStorage.getItem("user-locale") === "en" ? "ltr" : "rtl";
    // this.$store.dispatch("updatelanguage", localStorage.getItem("user-locale"));
    // this.$store.dispatch("updatedirection", dir);
  },
  validations() {
    return {
      username: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        // email: helpers.withMessage(this.$t("errors.email"), email),
      },
      password: {
        required: helpers.withMessage(this.$t("errors.required"), required),
      },
      year: {
        // required: helpers.withMessage(this.$t("errors.required"), required),
      },
    };
  },
  computed: {
    backgroundStyle() {
      return {
        "background-image": `linear-gradient(rgba(52,135, 246,.2), rgba(52,135,246,.8)),url(${this.backgroundImage})`,
        "background-size": "cover",
      };
    },
    ...mapGetters(["getdir"]),
    ...mapState({
      login_error: (state) => state.login_error,
    }),
  },
  methods: {
    resetForm() {
      this.v$.$reset();
      this.$refs.form.reset();
      this.login_error = undefined;
    },
    async loginAction(username, password, year) {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          this.loading = true;
          await axios
            .post(host() + "/api/token/", { username, password, year })
            .then((res) => {
              if(res.data.status && (res.data.status==4000 || res.data.status==3000))
                this.error = this.$t("user.login_error");
              else{
              this.$store.dispatch("setAuthenticated", res);
              }
              this.loading = false;
            });
        } catch (error) {
          this.loading = false;
          if (error.message.includes("Network Error")) {
            this.error = this.$t("alert.failure.connect");
          } else {
            if (error.response.status == 403) {
              this.error = this.$t("errors.user-logged-in");
            }
            if (error.response.status == 401) {
              this.error = this.$t("user.login_error");
            }
          }

          return 0;
        }
      }
    },
  },
};
</script>
<style>
.v-application__wrap {
  background-color: #999;
}
</style>