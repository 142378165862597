<template>
  <VCard class="mb-2 pa-0">
    <VForm ref="form" class="" @submit.prevent="savestatistic">
      <VCard-title class="my-4">
        <!-- <VIcon icon="mdi-chart-line" />
        <h3 class="d-inline text-grey" style="padding: 0 4px">
          {{
            is_update
              ? $t("summer-sidebar.supplies-edit")
              : $t("summer-sidebar.supplies-add")
          }}
        </h3>
        <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" /> -->
      </VCard-title>
      <VRow class="pt-0 px-2 px-md-4 pb-0">
        <VCol cols="12" class="pt-0 px-3" xs="10" md="5" lg="5">
          <VCardItem class="pa-0">
            <v-select
              v-model="statistic.type"
              :label="$t('summer.type-statistic')"
              persistent-hint
              item-title="name"
              item-value="id"
              clearable
              density="compact"
              :items="statistics_mode"
              prepend-inner-icon="mdi-chart-pie"
              :rules="rules.type_statistic"
            ></v-select>
          </VCardItem>
        </VCol>
        <VCol cols="12" class="pt-0 px-3" xs="10" md="5" lg="5">
          <VCardItem class="pa-0">
            <VTextField
              v-model="statistic.name"
              :label="$t('summer-sidebar.supplies-name')"
              persistent-hint
              clearable
              density="compact"
              prepend-inner-icon="mdi-headphones"
              :rules="rules.name"
            ></VTextField>
          </VCardItem>
        </VCol>
        <VCol cols="12" class="pt-0 px-3" xs="10" md="5" lg="5">
          <VCardItem class="pa-0">
            <VTextField
              v-model="statistic.company"
              :label="$t('report.company')"
              persistent-hint
              clearable
              density="compact"
              prepend-inner-icon="mdi-factory"
              :rules="rules.name"
            ></VTextField>
          </VCardItem>
        </VCol>
        <VCol cols="12" class="pt-0 px-3" xs="10" md="5" lg="5">
          <VCardItem class="pa-0">
            <VTextField
              v-model="statistic.provider"
              :label="$t('report.entity')"
              persistent-hint
              clearable
              density="compact"
              prepend-inner-icon="mdi-account"
              :rules="rules.entity"
            ></VTextField>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-3" cols="12" xs="12" md="5" lg="5">
          <v-text-field
            style="min-width: 220px"
            v-model="statistic.date"
            density="compact"
            :label="$t('financial.date')"
            :placeholder="$t('financial.date')"
            clearable
            prepend-inner-icon="mdi-calendar-clock"
            id="date"
            :rules="rules.date"
          >
          </v-text-field>
          <Datetime
            type="date"
            element="date"
            :color="'#0747a6'"
            @input="(e) => getStartWeek(e)"
            :locale="$i18n.locale == 'ar' ? 'ar-sa' : 'en-us'"
            :placeholder="$t('summer.visitors.date-visitor')"
            :calendar="'hijri'"
          ></Datetime>          
        </VCol>
        <VCol cols="12" class="pt-0 px-3" xs="12" md="5" lg="5">
          <VCardItem class="pa-0">
            <VRadioGroup
              inline
              :label="$t('summer.is-statistic')"
              v-model="statistic.is_found"
              @update:modelValue="this.statistic.amount = null"
            >
              <VRadio :label="$t('globals.yes')" :value="true"></VRadio>
              <VRadio :label="$t('globals.no')" :value="false"></VRadio>
            </VRadioGroup>
          </VCardItem>
        </VCol>
        <VCol cols="12" class="pt-0 px-3" xs="10" md="5" lg="5" v-if="statistic.is_found">
          <VCardItem class="pa-0">
            <VTextField
              v-model="statistic.amount"
              :label="$t('report.quantity')"
              persistent-hint
              type="number"
              clearable
              density="compact"
              prepend-inner-icon="mdi-numeric"
              :rules="rules.count"
            ></VTextField>
          </VCardItem>
        </VCol>
        <VCol cols="12" class="pt-0 px-3" md="10">
          <VCardItem class="pa-0">
            <VTextarea
              v-model="statistic.note"
              clearable
              :label="$t('summer.initiative.note')"
              no-resize
              counter="250"
              density="compact"
              rows="2"
              prepend-inner-icon="mdi-note-outline"
              :rules="rules.note"
            ></VTextarea>
          </VCardItem>
        </VCol>
      </VRow>
    </VForm>
    <VCardActions
      class="mx-2"
      v-if="
        CheckGetPermission('summer_center.add_supplies',['sys_admin','summer_admin','user']) ||
        CheckGetPermission('summer_center.change_supplies',['sys_admin','summer_admin','user'])
      "
    >
      <VBtn
        v-if="!is_update && CheckGetPermission('summer_center.add_supplies',['sys_admin','summer_admin','user'])"
        class="bg-indigo"
        @click="savestatistic()"
        size="small"
        :loading="saveloading"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="is_update && CheckGetPermission('summer_center.change_supplies',['sys_admin','summer_admin','user'])"
        class="bg-success"
        @click="updatestatistic()"
        size="small"
        :loading="saveloading"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
    <v-divider class="my-4" />
    <!-- ######## Header Of Data Table  ####### -->
  </VCard>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import Datetime from "vue3-datetime-js";
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      saveloading:false,
      homeworks: [],
      statistics: [],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      statistic: {
        name: "",
        provider: undefined,
        company: "",
        is_found: false,
        amount: undefined,
        date: undefined,
        note: "",
        data_entry: undefined,
        updated_by: undefined,
      },
      sortBy: [
        {
          key: "name",
          order: "asc",
        },
      ],
      // is_found: true,
      rules: {
        name: [(value) => !!value || this.$t("globals.required_field")],
        entity: [(value) => !!value || this.$t("globals.required_field")],
        type_statistic: [(value) => !!value || this.$t("globals.required_field")],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
        count:[
          (value) => !!value || this.$t("globals.required_field"),
          (value)=> parseInt(value) < 9999 || `${this.$t('globals.max_numbers')} 9999`,
          (value)=> parseInt(value) > 0 || `${this.$t('globals.min_numbers_value')}`
          ]
      },
      dialogVisible: false,
      visitType: 3,
      v$: useValidate(),
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      adj_type: "adjective",
      selectedHead: ["week_name", "type_name", "name", "note", "actions"],
      headers: [
        { title: this.$t("summer.calendar.week-name"), key: "week_name" },
        { title: this.$t("summer.type-statistic"), key: "type_name" },
        { title: this.$t("summer.name-statistic"), key: "name" },
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions" },
      ],
      txt_search: undefined,
    };
  },
  components: { Datetime },
  computed: {
    statisticdata() {
      if (this.visitType == 1 || this.visitType == 2) {
        return this.statistics.filter((el) => el.type == this.visitType);
      } else {
        return this.statistics;
      }
    },
    ...mapState({
      user: (state) => state.User,
      weeks: (state) => state.summer.weeks,
      statistics_evaluation: (state) => state.summer_globals.statistics_evaluation,
      statistics_mode: (state) => state.summer_globals.statistics_mode,
    }),
    ...mapGetters({}),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  async created() {
    await this.$store.commit("updateLoadingValue", true);
    // await this.getstatistic();
    // await this.getweeks();
    // await this.getStatisticsEvaluation();
    await this.getStatisticsMode();
    if (this.id) {
      await this.axios
        .get(`${this.base_url}/api/summer/supplies/${this.id}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.statistic = response.data.results;
        });
      this.is_update = true;
    }
    this.$store.commit("updateLoadingValue", false);
  },
  methods: {
    ...mapActions({
      getweeks: "summer/getweeks",
      getStatisticsEvaluation: "summer_globals/getStatisticsEvaluation",
      getStatisticsMode: "summer_globals/getStatisticsMode",
    }),
    getStartWeek(e) {
      let date = e;
      date = this.toEnglishString(e);
      this.statistic.date = date;
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.statistic = {};
      this.is_update = false;
      this.statistic.is_found = false;
      this.updated_id = undefined;
    },
    editstatistic(statistic) {
      this.statistic = statistic;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async savestatistic() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveloading = true;
        this.statistic.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/supplies", this.statistic, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.statistic = {};
            this.saveloading = false;
            this.resetForm();
            this.getstatistic();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveloading = false;
            if (error.response.data.name)
              this.$emit("errorAlert", this.$t("globals.error_in_repeted_data"));
            else this.$emit("errorAlert", this.$t("alert.failure.unique"));
          });

      }
    },
    async updatestatistic() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveloading = true;
        this.statistic.data_entry = this.user;
        let result = await this.axios
          .put(
            this.base_url + "api/summer/supplies/" + this.statistic.id,
            this.statistic,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.saveloading = false;
            setTimeout(() => {
              this.$router.push({ name: `supplies` });
            }, 1500);
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.title"));
            this.saveloading = false;
            if (error.response.data.name)
              this.$emit("errorAlert", this.$t("globals.error_in_repeted_data"));
            else this.$emit("errorAlert", this.$t("alert.failure.unique"));
          });
      }
    },
    async deletestatistic() {
      if (this.delete_id) {
        let result = await this.axios
          .delete(this.base_url + "api/summer/supplies/" + this.delete_id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.icon_color = "success";
            this.icon = "check-circle";
            this.alert_title = this.$t("globals.success");
            this.alert_message = this.$t("globals.data_deleted");
          })
          .catch((error) => {
            this.icon_color = "error";
            this.icon = "cancel";
            this.alert_title = this.$t("globals.failure");
            this.alert_message = this.$t("globals.error_in_data");
          });
        this.dialogVisible = false;
        this.delete_id = undefined;
        this.getstatistic();
      }
    },

    async getstatistic(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/supplies`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.statistics = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  watch: {
    id(){
      this.resetForm()
    },
    perPage() {
      this.getstatistic();
    },
    page() {
      this.getstatistic(this.page);
    },
  },
};
</script>

<style scoped>
.v-application__wrap {
  background-color: #999;
}
</style>
