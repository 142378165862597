<template>
  <VCard > 
    <v-card 
      flat
      v-if="
        CheckGetPermission('summer_center.add_level', ['sys_admin', 'gen_admin']) ||
        CheckGetPermission('summer_center.change_level', ['sys_admin', 'gen_admin'])
      ">
      <v-card-text class="pb-0">
        <VForm ref="form"  @submit.prevent="saveLevel">
          <VRow class="mt-2">
            
            <VCol cols="12" md="5">
                <VTextField
                  v-model="level.name_ar"
                  autofocus
                  :label="$t('summer.level.name_ar')"
                  persistent-hint
                  clearable
                  density="compact"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :rules="rules.subjectNameArRule"
                ></VTextField>
            </VCol>
            <VCol cols="12" md="5">
                <VAutocomplete
                  :items="LevelPhaseChoice"
                  v-model="level.phase"
                  item-title="name"
                  item-value="key"
                  :label="$t('summer.level.phase-select')"
                  persistent-hint
                  prepend-inner-icon="mdi-ladder"
                  density="compact"
                  :rules="rules.subjectRule"
                ></VAutocomplete>
            </VCol>
            <div class="w-100"></div>
            <VCol cols="12" md="5">
                <VTextField
                  v-model="level.name_en"
                  :label="$t('summer.level.name_en')"
                  persistent-hint
                  clearable
                  density="compact"
                  prepend-inner-icon="mdi-alpha-e"
                  :rules="rules.subjectNameEnRule"
                ></VTextField>
            </VCol>
            <VCol cols="12" md="5">
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="subjects"
                  v-model="level.subject"
                  multiple
                  item-title="name"
                  item-value="id"
                  :label="$t('subject.select')"
                  persistent-hint
                  chips
                  closable-chips
                  density="compact"
                  prepend-inner-icon="mdi-bookshelf"
                  :rules="rules.subjectRule"
                >
                  <template v-slot:chip="{props , item}">
                    <v-chip 
                      class="mx-1"
                      style="font-size:0.9rem"
                      v-bind="props" :text="item.raw.name">
                    </v-chip>
                  </template>
                </VAutocomplete>
              </VCardItem>
            </VCol>
            <div class="w-100"></div>
            <VCol cols="12" class="pa-0 ma-0" xs="12" md="5" lg="10">
              <VCardItem class="px-3">
                <VTextarea
                  v-model="level.note"
                  clearable
                  :label="$t('summer.initiative.note')"
                  no-resize
                  color="indigo"
                  counter="250"
                  density="compact"
                  rows="2"
                  :rules="rules.note"
                  prepend-inner-icon="mdi-note-outline"
                ></VTextarea>
              </VCardItem>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="px-3">
        <VBtn
          v-if="!is_update"
          class="bg-indigo"
          @click="saveLevel()"
          size="small"
          :loading="saveDataLoading"
        >
          <span class="text-white">
            {{ $t("globals.add") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn
          v-if="is_update"
          class="bg-success"
          @click="updateLevel()"
          size="small"
          :loading="editDataLoading"
        >
          <span class="text-white">
            {{ $t("globals.edit") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
    <v-divider />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card >
      <v-card-text>
        <div class="d-flex justify-space-between mb-2">
          <div class="d-flex flex-fill">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                  <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                  <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="header in headers"
                    :key="header.key"
                    :label="header.title"
                    :value="header.key"
                    color="indigo"
                    v-model="selectedHead"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <div style="width: 100%">
              <v-text-field
                color="indigo"
                v-model="txt_search"
                style="
                  border-radius: 30px;
                  border: 1px solid #ccc;
                  color: black;
                  height: 30px;
                  width: 100%;
                "
                class="search"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                :placeholder="$t('globals.search-here')"
                density="compact"
                variant="text"
                @input="getLevels((page = 1))"
              >
              </v-text-field>
            </div>
          </div>
        </div>
        <!-- ######## End Header Of Data Table Server ####### -->
        <v-data-table-server
          :headers="selectedHeaders"
          :items="tableList"
          item-value="name"
          item-key="id"
          id="vDataTable"
          fixed-footer
          density="compact"
          class="elevation-1 rounded"
          @update:options="getLevels((page = 1))"
          v-model:sort-by="sortBy"
          :loading="loading"
          :search="txt_search"
        >
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <span v-if="key === 'phase_name'">
                  {{ val }}
                  <!-- {{ getPhaseNameByKey(val) }} -->
                </span>
                <span v-else> {{ val }}</span>
                <span v-if="key === 'actions'">
                  <v-btn
                    variant="text"
                    class="me-1 v-btn-icon"
                    size="large"
                    v-if="
                      CheckGetPermission('summer_center.change_level', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="editLevel(item.raw)"
                  >
                    <v-icon color="warning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    variant="text"
                    size="small"
                    class="v-btn-icon"
                    v-if="
                      CheckGetPermission('summer_center.delete_level', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="(dialogVisible = true), (this.delete_id = item.raw.id)"
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </template>
          <!-- <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template> -->
        </v-data-table-server>
      </v-card-text>

    </v-card>
  </VCard>
  <VDialog v-model="dialogVisible" scrollable max-width="350px">
    <VCard>
      <VCardTitle class="text-blue">
        {{ $t("globals.confirmation") }}
      </VCardTitle>
      <VCardText class="text-blue-grey-darken-4">
        {{ $t("globals.confirm_delete") }}
      </VCardText>
      <VCardActions class="ma-auto " dir="rtl">
        <VBtn
          class="font-weight-bold me-2"
          color="error"
          @click.prevent="deleteLevel"
          :loading="deleteDataLoading"
        >
          {{ $t("globals.delete") }}
        </VBtn>
        <VBtn
          class="bg-white font-weight-bold"
          color="blue"
          @click.prevent="cancelDeleteItem"
        >
          {{ $t("globals.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <alert-protected-records
    v-model="protected_records"
    :data_message="data_message"
  ></alert-protected-records>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      protected_records: false,
      data_message: [],
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      LevelPhaseChoice: [
        { key: 1, name: "تأهيلي" },
        { key: 2, name: "أساسي" },
        { key: 3, name: "متوسط" },
        { key: 4, name: "عالي" },
      ],
      levels: [],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      level: {},
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],
      rules: {
        subjectNameArRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        subjectNameEnRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[A-Za-z-  0-9]+$/.test(value) ||
            this.$t("globals.must_be_letters_and_numbers_en"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        subjectPhaseRule: [(value) => !!value || this.$t("globals.required_field")],
        subjectRule: [
          (value) => {
            if (value == null || value.length < 1)
              return this.$t("globals.required_field");
            return 1;
          },
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      dialogVisible: false,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      selectedHead: ["phase_name", "name_ar",'name_en', this.checkrole(['sys_admin', 'gen_admin']) ? "actions":null,],
      headers: [
        { title: this.$t("summer.level.phase"), key: "phase_name" },
        { title: this.$t("summer.level.name_ar"), key: "name_ar" },
        { title: this.$t("summer.level.name_en"), key: "name_en" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ],
      txt_search: undefined,
    };
  },
  computed: {
    ...mapState({
      subjects: (state) => state.summer.subjects,
      user: (state) => state.User,
    }),
    ...mapGetters({}),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.levels) {
        return JSON.parse(JSON.stringify(this.levels));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if(this.checkrole(['sys_admin', 'gen_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })
      await this.getSubjects();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      getSubjects: "summer/getSubjects",
    }),
    resetForm() {
      this.$refs.form.resetValidation();
      this.level = {};
      this.is_update = false;
      this.updated_id = undefined;
    },
    getPhaseNameByKey(key) {
      const phase = this.LevelPhaseChoice.find((obj) => obj.key === key);
      return phase ? phase.name : "";
    },
    editLevel(level) {
      this.level = level;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async saveLevel() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.level.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/level", this.level, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.level = {};
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if(error?.response?.data?.non_field_errors){
              this.$emit("errorAlert", this.$t("alert.failure.unique"));
            }else{
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveDataLoading = false;
          });
        this.getLevels();
      }
    },
    async updateLevel() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.editDataLoading = true;
        this.level.data_entry = this.user;
        let result = await this.axios
          .put(this.base_url + "api/summer/level/" + this.level.id, this.level, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if(error?.response?.data?.non_field_errors){
              this.$emit("errorAlert", this.$t("alert.failure.unique"));
            }else{
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.editDataLoading = false;
          });
        this.getLevels();
      }
    },
    async deleteLevel() {
      if (this.delete_id) {
        this.deleteDataLoading = true;

        let result = await this.axios
          .delete(this.base_url + "api/summer/level/" + this.delete_id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.resetForm()
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.deleteDataLoading = false;
          })
          .catch((error) => {
            this.deleteDataLoading = false;
            if (error.message.includes(418)) {
              this.protected_records = true;
              let error_messages = [];
              error.response.data.data.forEach((error) => {
                error_messages.push(
                  this.$t(`models.${error.model}`) + ` = ${error.count}`
                );
              });
              this.data_message = error_messages;
            } else {
              this.$emit("errorAlert", this.$t("alert.failure.delete"));
            }
          });
        this.dialogVisible = false;
        this.delete_id = undefined;
        this.getLevels();
      }
    },
    async getLevels(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/level`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.levels = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  watch: {
    perPage() {
      this.getLevels();
    },
    page() {
      this.getLevels(this.page);
    },
  },
};
</script>

<style>
.v-application__wrap {
  background-color: #999;
}
</style>
