<template>
  <VCard > 
    <v-card flat>
      <v-card-text class="pb-0">
        <VForm
          ref="form"
          v-if="
            CheckGetPermission('globaltable.add_directorate', ['sys_admin', 'gen_admin']) ||
            CheckGetPermission('globaltable.change_directorate', ['sys_admin', 'gen_admin'])
          "
        >
          <VRow
            class="mt-2"
            v-if="
              CheckGetPermission('globaltable.add_directorate', ['sys_admin', 'gen_admin'])
            "
          >
            <VCol  cols="12" md="5">
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="countries"
                  autofocus
                  v-model="directorate.country"
                  prepend-inner-icon="mdi-flag"
                  item-title="name_ar"
                  item-value="id"
                  :label="$t('school.country_name')"
                  persistent-hint
                  density="compact"
                  @update:modelValue="directorate.fk_governorate = undefined"
                  :rules="rules.country"
                ></VAutocomplete>
              </VCardItem>
            </VCol>
            <VCol  cols="12" md="5">
              <VCardItem class="pa-0">
                <VAutocomplete
                  :items="filterGovernorates(directorate.country)"
                  prepend-inner-icon="mdi-city"
                  v-model="directorate.fk_governorate"
                  item-title="name_ar"
                  item-value="id"
                  :label="$t('school.governorate_name')"
                  persistent-hint
                  density="compact"
                  :rules="rules.fk_governorate"
                ></VAutocomplete>
              </VCardItem>
            </VCol>
          </VRow>
          <VRow >
            <VCol  cols="12" md="5">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="directorate.name_ar"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('school.directorate_name')"
                  :placeholder="$t('school.placeholder_directorate_name')"
                  density="compact"
                  :rules="rules.name_ar"
                >
                </VTextField>
              </VCardItem>
            </VCol>
            <VCol  cols="12" md="5">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="directorate.name_en"
                  prepend-inner-icon="mdi-alpha-e"
                  :label="$t('school.directorate_name_en')"
                  :placeholder="$t('school.placeholder_directorate_name_en')"
                  density="compact"
                  :rules="rules.name_en"
                >
                </VTextField>
              </VCardItem>
            </VCol>
          </VRow>
          <VRow >
            <VCol cols="12" class="pa-0 ma-0" md="10">
              <VCardItem class="px-3">
                <VTextarea
                  v-model="directorate.note"
                  prepend-inner-icon="mdi-note-outline"
                  clearable
                  color="indigo"
                  :label="$t('globals.note')"
                  no-resize
                  counter="250"
                  density="compact"
                  rows="2"
                  :rules="rules.note"
                ></VTextarea>
              </VCardItem>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions
          class="px-3"
          v-if="
            CheckGetPermission('globaltable.add_directorate', ['sys_admin', 'gen_admin']) ||
            CheckGetPermission('globaltable.change_directorate', ['sys_admin', 'gen_admin'])
          "
        >
          <VBtn
            v-if="
              !is_update &&
              CheckGetPermission('globaltable.add_directorate', ['sys_admin', 'gen_admin'])
            "
            class="bg-indigo"
            @click="saveDirectorate()"
            size="small"
            :loading="saveDataLoading"
          >
            <span class="text-white">
              {{ $t("globals.add") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn
            v-if="
              is_update &&
              CheckGetPermission('globaltable.change_directorate', ['sys_admin', 'gen_admin'])
            "
            class="bg-success"
            @click="updateDirectorate()"
            size="small"
            :loading="editDataLoading"
          >
            <span class="text-white">
              {{ $t("globals.edit") }}
            </span>
            <VIcon icon="mdi-content-save" color="white" end></VIcon>
          </VBtn>
          <VBtn class="mx-3" size="small" @click="resetForm()">
            {{ $t("globals.clear") }}
            <VIcon icon="mdi-broom" color="golden" end></VIcon>
          </VBtn>
      </VCardActions>
    </v-card>
    <v-divider  />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card >
      <v-card-text>
        <div class="d-flex justify-space-between mb-2">
          <div class="d-flex flex-fill">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                  <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                  <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="header in headers"
                    :key="header.key"
                    :label="header.title"
                    :value="header.key"
                    color="indigo"
                    v-model="selectedHead"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <div class="w-100 px-2">
              <v-text-field
                color="indigo"
                v-model="txt_search"
                style="
                  border-radius: 30px;
                  border: 1px solid #ccc;
                  color: black;
                  height: 30px;
                "
                class="search"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                :placeholder="$t('globals.search-here')"
                density="compact"
                variant="text"
                @input="getDirectorate((page = 1))"
              >
              </v-text-field>
            </div>
          </div>
        </div>
        <!-- ######## End Header Of Data Table Server ####### -->
        <v-data-table-server
          :headers="selectedHeaders"
          :items="tableList"
          fixed-footer
          :search="txt_search"
          density="compact"
          class="elevation-1 rounded"
          :loading="loading"
          v-model:sort-by="sortBy"
          item-value="name"
          item-key="id"
          @update:options="getDirectorate((page = 1))"
        >
          
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
                </v-checkbox>
                <span> {{ val }} </span>
                <span v-if="key === 'actions'">
                  <v-btn
                    variant="text"
                    class="me-1 v-btn-icon"
                    size="large"
                    v-if="
                      CheckGetPermission('globaltable.change_directorate', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="editItem(item.raw)"
                  >
                    <v-icon color="warning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    variant="text"
                    size="small"
                    class="v-btn-icon"
                    v-if="
                      CheckGetPermission('globaltable.delete_directorate', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="deleteItem(item.raw)"
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </v-card-text>

    </v-card>
  </VCard>
  <VDialog v-model="del_dialog" scrollable :overlay="false" max-width="350px">
    <VCard>
      <VCardTitle class="text-blue">
        {{ $t("globals.confirmation") }}
      </VCardTitle>
      <VCardText class="text-blue-grey-darken-4">
        {{ $t("globals.confirm_delete") }}
      </VCardText>
      <VCardActions>
        <VBtn
          class="font-weight-bold me-2"
          color="error"
          @click.prevent="deleteDirectorate"
          :loading="deleteDataLoading"
        >
          {{ $t("globals.delete") }}
        </VBtn>
        <VBtn
          class="bg-white font-weight-bold"
          color="blue"
          @click.prevent="cancelDeleteItem"
        >
          {{ $t("globals.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
  <alert-protected-records
    v-model="protected_records"
    :data_message="data_message"
  ></alert-protected-records>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      protected_records: false,
      data_message: [],
      directorates: [],
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      data_message: [],
      rules: {
        country: [(value) => !!value || this.$t("globals.required_field")],
        fk_governorate: [(value) => !!value || this.$t("globals.required_field")],
        name_ar: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 2) ||
            `${this.$t("globals.lowest_number_of_character")} 2`,
        ],
        name_en: [
          (value) =>
            !value || /^[A-Za-z-]+$/.test(value) || this.$t("globals.must_be_letters_en"),
          (value) =>
            !value ||(value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            !value ||(value && value.length >= 2) ||
            `${this.$t("globals.lowest_number_of_character")} 2`,
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      directorate: {},
      is_update: false,
      updated_id: undefined,
      del_dialog: false,
      delete_id: undefined,
      selectedHead: [
        "name_ar",
        "name_en",
        "fk_country",
        "fk_governorate",
        "note",
        this.checkrole(['sys_admin', 'gen_admin']) ? "actions":null,
      ],
      headers: [
        { title: this.$t("school.country_name"), key: "fk_country" },
        { title: this.$t("school.governorate_name"), key: "fk_governorate" },
        { title: this.$t("school.directorate_name"), key: "name_ar" },
        { title: this.$t("school.directorate_name_en"), key: "name_en" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],

      txt_search: undefined,
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.school.countries,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      user: (state) => state.User,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      const list = [];
      let gov = undefined;
      this.directorates.forEach((element) => {
        gov = this.governorates.find((g) => g.id == element.fk_governorate);
        if (gov) {
          let country = this.countries.find((cun) => cun.id == gov.fk_country);
          if (country) {
            list.push({
              fk_governorate: gov.name_ar,
              fk_country: country.name_ar,
              name_ar: element.name_ar,
              name_en: element.name_en,
              note: element.note,
              id: element.id,
            });
          }
        }
      });
      return list;
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getGovernorates();
      await this.getCountries();
      if(this.checkrole(['sys_admin', 'gen_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })
      // await this.getDirectorate();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
      this.updated_id = undefined;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
    },
    editItem(data) {
      for (const key in data) this.directorate[key] = data[key];
      this.is_update = true;
      this.updated_id = data.id;
      let country = this.countries.find((con) => con.name_ar == data.fk_country);
      this.directorate.country = country.id;
      let governorate = this.governorates.find(
        (gov) => gov.name_ar == data.fk_governorate
      );
      this.directorate.fk_governorate = governorate.id;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    deleteItem(data) {
      this.delete_id = data.id;
      this.del_dialog = true;
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async saveDirectorate() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.saveDataLoading = true;
        this.directorate.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "/directorate/", this.directorate, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.name_ar) {
              this.$emit("warningAlert", this.$t("summer.already-directorate"));
            } else if (error.response.data.name_en) {
              this.$emit("warningAlert", this.$t("summer.already-directorate-en"));
            } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
        this.getDirectorate();
      }
    },
    async updateDirectorate() {
      const { valid } = await this.$refs.form.validate();

      if (valid && this.directorate.id != undefined) {
        this.editDataLoading = true;
        this.directorate.updated_by = this.user;
        let result = await this.axios
          .put(`${this.base_url}/directorate/${this.directorate.id}/`, this.directorate, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.name_ar) {
              this.$emit("warningAlert", this.$t("summer.already-directorate"));
            } else if (error.response.data.name_en) {
              this.$emit("warningAlert", this.$t("summer.already-directorate-en"));
            } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
        this.getDirectorate();
      }
    },
    async deleteDirectorate() {
      if (this.delete_id != undefined) {
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(`${this.base_url}/directorate/${this.delete_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.deleteDataLoading = false;
          })
          .catch((error) => {
            this.deleteDataLoading = false;
            if (error.message.includes(418)) {
              this.protected_records = true;
              let error_messages = [];
              error.response.data.data.forEach((error) => {
                error_messages.push(
                  this.$t(`models.${error.model}`) + ` = ${error.count}`
                );
              });
              this.data_message = error_messages;
            } else {
              this.$emit("errorAlert", this.$t("alert.failure.delete"));
            }
          });

        this.del_dialog = false;
        this.delete_id = undefined;
        this.getDirectorate();
      }
    },
    async getDirectorate(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}/directorate-for-data-table/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.directorates = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  watch: {
    perPage() {
      this.getDirectorate();
    },
    page() {
      this.getDirectorate(this.page);
    },
  },
};
</script>
<style>
.v-application__wrap {
  background-color: #999;
}
</style>
