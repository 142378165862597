<template>
  <v-expansion-panels  class="mb-2">
    <v-expansion-panel  elevation="1" >
      <v-expansion-panel-title class="px-4">
          <span>{{$t('globals.select-criteria')}}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text >
        <v-row class="py-2" >
          <v-col cols="12" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="fk_level"
              density="compact"
              item-title="level_with_phase_name"
              item-value="id"
              prepend-inner-icon="mdi-stairs-box"
              clearable
              :items="levels"
              hide-details="auto"
              :label="$t('summer.level.name_ar')"
              @update:modelValue="(fk_subject = null), getSubjects()"
            />
          </v-col>
          <v-col cols="6" md="3" sm="4">
            <v-autocomplete
              color="indigo"
              v-model="fk_subject"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-bookshelf"
              clearable
              hide-details="auto"
              :items="subjects"
              :label="$t('subject.select')"
            />
          </v-col>
          <v-col cols="6" md="1" class="mt-2">
            <v-btn
              @click="getLecturesAttendance((page = 1)), (DataLoading = true)"
              :loading="DataLoading"
              class="bg-indigo"
              size="small"
            >
              <span class="text-white">
                {{ $t("globals.show") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-progress-linear 
    color="indigo" 
    :reverse="$i18n.locale=='ar'"
    height="3px" 
    indeterminate 
    v-if="loading"
  >
  </v-progress-linear>
  <v-card v-if="CheckGetPermission('summer_center.view_lecturesummer')">
    <!-- ##########    data Table Server ########### -->
    <!-- ######## Header Of Data Table Server ####### -->
    <v-card-text>

    <div
      class="d-flex justify-space-between mb-2"
      v-if="CheckGetPermission('summer_center.view_lecturesummer')"
    >
      <div class="d-flex" style="flex: 10">
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
              <v-icon style="color: #5e7e96" class="me-2"
                >mdi-eye-settings-outline</v-icon
              >
              <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-checkbox
                v-for="header in headers"
                :key="header.key"
                :label="header.title"
                :value="header.key"
                color="indigo"
                v-model="selectedHead"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-text-field
          color="indigo"
          v-model="txt_search"
          style="
            border-radius: 30px;
            border: 1px solid #ccc;
            color: black;
            height: 30px;
            width: 90%;
          "
          class="search"
          hide-details
          single-line
          :placeholder="$t('globals.search-here')"
          type="search"
          density="compact"
          variant="text"
          @input="getLecturesAttendance((page = 1))"
        >
        <template v-slot:prepend-inner>
          <v-btn size="samll"  density="compact"  icon variant="text" class="mt-n1">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        </v-text-field>
        <!-- @input="getLecturesAttendance((page = 1))" -->
      </div>
      <div class="d-flex justify-end" style="flex: 1">
        <VBtn
          v-if="
            CheckGetPermission('summer_center.add_lecturesummer', [
              'sys_admin',
              'gen_admin',
            ])
          "
          class="bg-success"
          @click="addLectureAttendane"
          size="small"
        >
          <span class="text-white">{{ $t("globals.create") }}</span>
          <VIcon icon="mdi-plus-thick" color="white" end></VIcon>
        </VBtn>
      </div>
    </div>
    <!-- ######## End Header Of Data Table Server ####### -->
    <div >
      <v-data-table-server
        :headers="selectedHeaders"
        :items="tableList"
        item-key="id"
        fixed-header
        height="400"
        density="compact" 
        class="elevation-1 rounded"
        id="vDataTable"
        v-model:sort-by="sortBy"
        @update:options="getLecturesAttendance((page = 1))"
      >
        <template v-slot:column.checkbox="{ column }">
          <v-checkbox hide-details density="compact"> </v-checkbox>
        </template>
        <template v-slot:item="{ item }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <span> {{ val }}</span>
              <span v-if="key === 'actions'">
                <v-btn
                  variant="text"
                  class="me-1 v-btn-icon"
                  size="large"
                  v-if="
                    CheckGetPermission('summer_center.change_lecturesummer', [
                      'sys_admin',
                      'gen_admin',
                    ])
                  "
                  @click="editLectureAttendance(item.raw)"
                >
                  <v-icon color="warning">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  size="small"
                  class="v-btn-icon"
                  v-if="
                    CheckGetPermission('summer_center.delete_lecturesummer', [
                      'sys_admin',
                      'gen_admin',
                    ])
                  "
                  @click="(selected_lecture_attendance = item.raw.id), (dialog = true)"
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:bottom>
          <div class="d-flex" id="pagination-bar">
            <v-pagination
              v-model="page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ totalItems }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div>
        </template>
      </v-data-table-server>

    </div>
    </v-card-text>

    <!-- ############### Dialog Delete  ################### -->
    <v-dialog
      color="indigo"
      v-model="dialog"
      transition="dialog-top-transition"
      width="auto"
    >
      <v-card>
        <v-card-title>
          {{ $t("globals.confirmation") }}
        </v-card-title>
        <v-card-text>
          {{ $t("globals.confirm-delete") }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="(dialog = false), (selected_lecture_attendance = '')"
            >{{ $t("globals.cancel") }}</v-btn
          >
          <v-btn
            color="red"
            @click="deleteLectureAttendance(selected_lecture_attendance)"
            :loading="deleteDataLoading"
            >{{ $t("globals.delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ############### end Dialog Delete  ################### -->
  </v-card>
  <alert-protected-records
    v-model="protected_records"
    :data_message="data_message"
  ></alert-protected-records>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions, mapGetters } from "vuex";
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
export default {
  name: "LecturesList",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      if(this.checkrole(['sys_admin', 'gen_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })
      this.$store.dispatch("updateAuthenticated", localStorage.getItem("token"));
      await this.getlevels();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      protected_records: false,
      v$: useValidate(),
      txt_search: '',
      dialog: false,
      addDialog: false,
      overlay: false,
      update: false,
      alert: false,
      deleteDataLoading: false,
      DataLoading: false,
      loading: false,
      user: null,
      sortBy: [{ key: "name", order: "asc" }],
      selected_lecture_attendance: null,
      fk_level: null,
      fk_subject: null,
      fk_empoylee: null,

      from_date: null,
      lectures_attendance: [],
      subjects: [],
      to_date: null,
      rules: {
        level: [(value) => !!value || this.$t("globals.required_field")],
      },
      selectedHead: ["name", "fk_level", "fk_subject",this.checkrole(['sys_admin', 'gen_admin']) ? "actions":null,],
      headers: [
        { title: this.$t("lecture.name"), key: "name" },
        { title: this.$t("summer.level.name_ar"), key: "fk_level" },

        { title: this.$t("subject.name"), key: "fk_subject" },

        { title: this.$t("globals.note"), key: "note" },
      ],
      perPage: 10,
      length: 0,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 10000000, text: this.$t("globals.all") },
      ],
      page: 1,
      totalItems: null,
      pagination: {},
      class_divisions_hall: [],
    };
  },
  computed: {
    ...mapState({
      teachers: (state) => state.summer.teachers,
      levels: (state) => state.summer.levels,
      teacher_class: (state) => state.school.teacher_class,
    }),
    ...mapGetters({
      getClassDivisions: "summer/getClassDivisions",
    }),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },

    // levelsName() {
    //     return this.$i18n.locale == "ar" ? "level_with_phase_name" : "name_en";
    // },
    tableList() {
      var list = JSON.parse(JSON.stringify(this.lectures_attendance));
      list.forEach((element) => {
        let fk_level = element.fk_level;
        let fk_empoylee = element.fk_empoylee;
        let fk_subject = element.fk_subject;

        // element.fk_level=this.getClassName(fk_level)
        element.fk_level = this.getClassName(fk_level);
        element.fk_empoylee = this.getTeacherName(fk_empoylee);

        element.fk_subject = element.subject_name;
      });
      return list;
    },
    class_teachers() {
      var teacher_data = this.teachers;
      if (this.fk_level) {
        var teacher_id = this.teacher_class.results
          .filter((item) => item.fk_level == this.fk_level)
          .map((item) => item.fk_empoylee);
        var teacher_unique = [...new Set(teacher_id)];
        teacher_data = [];
        teacher_unique.forEach((el) => {
          teacher_data.push({ id: el, name_ar: this.getTeacherName(el) });
        });
      }
      return teacher_data;
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },

  validations() {
    return {
      lecture_attendance: {
        name: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(100)),
        },
        lecture_date: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        note: {
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(250)),
        },
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_subject: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_empoylee: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        data_entry: {},
        updated_by: {},
      },
    };
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      getlevels: "summer/getLevels",
    }),
    async getSubjects() {
      if (this.fk_level) {
        let subjects = await this.axios.get(
          this.base_url + "api/summer/subjects-by-level",
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            params: {
              level: this.fk_level,
            },
          }
        );
        this.subjects = subjects.data;
      } else {
        this.subjects = "";
      }
    },
    addLectureAttendane() {
      this.$router.push({ name: "lecture_add" });
    },
    getClassName(id) {
      var item = this.levels.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.level_with_phase_name
          : item.name_en
        : "";
    },
    getSubjectName(id) {
      var item = this.subjects.find((item) => item.id === id);
      return item ? (this.$i18n.locale == "ar" ? item.name : item.name) : "";
    },
    getTeacherName(id) {
      var item = this.teachers.find((item) => item.id === id);
      return item ? (this.$i18n.locale == "ar" ? item.name_ar : item.name_ar) : "";
    },

    editLectureAttendance(item) {
      this.$router.push(`${item.id}/edit/`);
    },

    async deleteLectureAttendance(id) {
      this.deleteDataLoading = true;
      await this.axios
        .delete(`${this.base_url}api/summer/lecture-summer/${id}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.deleteDataLoading = false;
          this.dialog = false;
          this.$emit("successAlert", this.$t("alert.success.delete"));
          this.getLecturesAttendance();
        })
        .catch((error) => {
          this.dialog = false;
          this.deleteDataLoading = false;
          if ((error.code = 418)) {
            this.protected_records = true;
            let error_messages = [];
            error.response.data.data.forEach((error) => {
              error_messages.push(this.$t(`models.${error.model}`) + ` = ${error.count}`);
            });
            this.data_message = error_messages;
          } else if (
            error.response &&
            error.response.data &&
            error.response.data == "current"
          )
            this.$emit("errorAlert", this.$t("globals.errpr_current"));
          else this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
      setTimeout(() => {}, 1500);
    },
    async getLecturesAttendance(page = 1) {
      this.loading=true
      await this.axios(`${this.base_url}api/summer/lecture-by-subject-and-level`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          level: this.fk_level,
          subject: this.fk_subject,
          sort_by: this.order_data,
        },
      })
        .then((response) => {
          this.loading=false
          this.lectures_attendance = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.DataLoading = false;
        })
        .catch((error) => {
          this.loading=false
          // this.DataLoading = false;
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
          // setTimeout(() => {
          //   this.showAlert = false;
          // }, 1500);
        });
    },
  },
  watch: {
    txt_search(){
      if(!this.txt_search){
        this.getLecturesAttendance()
      }
    },
    perPage() {
      this.getLecturesAttendance(this.page);
    },
    page() {
      this.getLecturesAttendance(this.page);
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
/* #vDataTable span {
  font-size: 12px;
} */
</style>
