import VueAxios from "vue-axios";
import { createStore, Store } from "vuex";
import Vuex from "vuex";
import host from "./../main";
import auth from "./auth";

import router from "@/router";
import financial from "./Financial/financial.module";
import globals from "./Globals/globals.module";
import school from "./School/school.module";
import summer_globals from "./Globals/summer_globals.module";
import users from "./Users/users.module";
import summer from "./Summer/summer.module";
import axios from "axios";
import jwt_decode from "jwt-decode";

 

const store= new Vuex.Store({    
    state:{
            Bearer:undefined,
            User:undefined,
            permissions:['school.dashboard'],
            lang:'ar',
            // role:undefined,
            direction:'rtl',
            expire:undefined,
            isAuthenticated:undefined,
            directorate:undefined,
            governorate:undefined,
            schoolsummer:undefined,
            username:undefined,
            login_error:false,
            loading:false,
            drawer:true,
            role_list:['sys_admin','gen_admin','gov_admin','dir_admin','summer_admin','user','student','parent','gov_financial'],
            API_URL:'http://localhost:5000/api/token/',
            breadcrumb:[],
            error_connection:false
    },
    mutations:{
        // setrole(state,role){
        //     state.role=role
        // },
        setErrorConnection(state,value){
          state.error_connection=value
        },
        updateBreadcrumbValue(state,value){
            state.breadcrumb=value
        },
        updateLoadingValue(state,value){
            state.loading=value
        },
        updateDrawer(state,value){
            state.drawer=value
        },
        setAuthenticated(state,bearer){
            state.Bearer=bearer 
        },
        setPermission(state,permission ){          
            state.permissions=permission
        
        },
        setUser(state,user ){          
            state.User=user            
        },
        logout(state,value){
            state.Bearer=''
            // state.permissions=value 
            state.User='' 
            
        },
        setlang(state,lang){
            state.lang=lang
        },
        setdir(state,dir){
            state.direction=dir
        },
        setExpire(state,expire){
            state.expire=expire;
        },
        setUsername(state,username){
            state.username=username;
        },
        setLoginError(state){
            state.login_error=true;
        },
        setDirectorate(state,directorate){
          state.directorate=directorate
        },
        setGovernorate(state,governorate){
          state.governorate=governorate
        },
        setSchoolSummer(state,schoolsummer){
          state.schoolsummer=schoolsummer
        }
    },
    getters:{
        // getrole:(state)=>state.role,

        getbrebar:(state)=>state.Bearer,      
        getpermission:(state)=>state.permissions,
        getuser:(state)=>state.User, 
        getdrawer:(state)=>state.drawer,    
        checkrole:(state)=>(role=[]) => role.includes(state.role_list[localStorage.getItem("role")]),
        checkpermission:(state)=>(perm,role=[])=>{
          if((role.includes(state.role_list[localStorage.getItem("role")]) || role.length == 0) &&
            localStorage.getItem('userinfo').includes(perm))
            return true
          else
            return false
        },
       
        getlang:(state)=>state.lang,
        

        getexpire:(state)=>state.expire,
        getdir:(state)=>state.direction,

        header(state){
            return 'Bearer '+state.Bearer
        }


      },
   
  
    actions:{
      async setAuthenticated({commit,getters,state,dispatch},response ){
          if(response && response.data){            
            
              const token= response.data.access 
              localStorage.setItem('refresh',response.data.refresh)
              const decoded=jwt_decode(token)
              commit('setAuthenticated',token)
              commit('setExpire',decoded.exp)
              commit('setUser',decoded.user_id)   ///////repat
              commit('setPermission',response.data.permission)
              localStorage.setItem('directorate',decoded.directorate)
              localStorage.setItem('governorate',decoded.governorate)
              localStorage.setItem('schoolsummer',decoded.schoolsummer)
              localStorage.setItem('role',decoded.role)

              localStorage.setItem('token',token)

              const user=await axios.get(host()+'api/user-info/',
              {
                  headers:{'Authorization':'Bearer '+token},
                  params:{
                      user:decoded.user_id
                  }
              }).catch(error=>{
                  commit('setLoginError')
              })
              if(user && user.data && user.data.username){
                  localStorage.setItem('username',user.data.username)
                  commit('setUsername',user.data.username)
              }
              setTimeout(() => {
                  dispatch('logout')
                  window.location.reload()
              }, (store.getters.getexpire-(Date.now()/1000))*1000);
              await axios.get(host()+'api/academic-years/current-year/',{headers:{'Authorization':'Bearer '+localStorage.getItem('token')  }}).then(res=>{
                  localStorage.setItem('current_year',res.data.id)               
                  localStorage.setItem('current_year_name_h',res.data.name_h)
                   
              })
              localStorage.setItem('userinfo',response.data.permission)
              router.push('/') 
          }
        
      },
    async updatePermission({ commit }, response) {
      try {
        commit("setPermission", response);
      } catch (error) {
        commit("setLoginError");
      }
    },
    async updateAuthenticated({ commit }, response) {
      try {
        const decoded = jwt_decode(response);
        commit("setExpire", decoded.exp);
        commit("setAuthenticated", response);
        commit("setUser", decoded.user_id);
      } catch (error) {
        commit("setLoginError");
      }
    },
    updatelanguage({ commit }, value) {
      commit("setlang", value);
    },
    updatedirection({ commit, state }, value) {
      commit("setdir", value);
    },
    logout({ commit }) {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userinfo");
      localStorage.removeItem("current_year");
      localStorage.removeItem("current_year_name_h");
      localStorage.removeItem("directorate");
      localStorage.removeItem("governorate");
      localStorage.removeItem("schoolsummer");
      localStorage.removeItem("role");
      commit("logout");
    },
  },
  modules: {
    financial,
    globals,
    users,
    school,
    summer,
    summer_globals,
  },
});

export default store;
